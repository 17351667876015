import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/LOGO-BLUE.png";
import NoMappings from "../../assets/images/no-flow.png";
import { gql, useQuery } from "@apollo/client";
import { FieldMappingComponent } from "./FieldMappingComponent";
import { Loaderr } from "../../components/Loaderr";
import SingleMapping from "./SingleMapping";
import { TypingDots } from "../../assets/images/three-dots-loading";

const GetFubAllData = gql`
  query GetFubAllData($type: String!) {
    GetFubAllData(type: $type) {
      output
      status
      info
    }
  }
`;

const GetFieldMappings = gql`
  query GetFieldMappings {
    GetFieldMappings {
      id
      interface_field_id
      platform
      sync_direction
      platform_resource_type
      platform_field_id
      field_type
      field_name
    }
  }
`;

const GetFieldMappingByFormId = gql`
  query GetFieldMappingByFormId($formId: String) {
    GetFieldMappingByFormId(form_id: $formId) {
      id
      interface_field_id
      platform
      sync_direction
      platform_resource_type
      platform_field_id
    }
  }
`;

// const FormsNames = gql`
// query FormsNames($teamId: String) {
//   formsNames(team_id: $teamId) {
//     form_id
//     form_name
//   }
// }
// `;

const defaultDealFields = [
  { label: "Deal Name", name: "name", type: "text" },
  { label: "Pipeline", name: "pipeline", type: "text" },
  { label: "Stage ID", name: "stageId", type: "text" },
  { label: "Status", name: "status", type: "text" },
  { label: "Price", name: "price", type: "number" },
  { label: "Description", name: "description", type: "text" },
  { label: "Projected Close Date", name: "projectedCloseDate", type: "date" },
  { label: "Agent Split", name: "agentCommission", type: "number" },
  { label: "Team Split", name: "teamCommission", type: "number" },
  { label: "Commision", name: "commissionValue", type: "number" },
];

const defaultPersonFields = [
  { label: "First Name", name: "firstName", type: "text" },
  { label: "Last Name", name: "lastName", type: "text" },
  { label: "Stage", name: "stage", type: "text" },
  { label: "Source", name: "source", type: "text" },
  { label: "Source URL", name: "sourceUrl", type: "text" },
  { label: "Price", name: "price", type: "number" },
  { label: "Assigned User ID", name: "assignedUserId", type: "number" },
  { label: "Background", name: "background", type: "text" },
];

const FieldMappingDashboard = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [selectedForm, setSelectedForm] = useState("");
  const [activeTab, setActiveTab] = useState("mapped");

  const {
    data: fieldMappingData,
    loading: fieldMappingLoading,
    refetch,
  } = useQuery(GetFieldMappings);

  const { data: customFieldsData, loading: customFieldsLoading } = useQuery(
    GetFubAllData,
    {
      variables: { type: "customfields" },
    }
  );

  const { data: dealFieldsData, loading: dealFieldsLoading } = useQuery(
    GetFubAllData,
    {
      variables: { type: "dealcustomfields" },
    }
  );

  // const { data: formNamesData, loading: formNamesLoading } = useQuery(FormsNames, {
  //   variables: { teamId: null},
  // });

  const [dealCustomFields, setDealCustomFields] = useState(defaultDealFields);

  const [personCustomFields, setPersonCustomFields] = useState(defaultPersonFields);

  const {
    data: fieldMappingByFormIdData,
    loading: fieldMappingByFormIdLoading,
  } = useQuery(GetFieldMappingByFormId, {
    skip: !selectedForm,
    variables: { formId: selectedForm },
  });

  useEffect(() => {
    if (dealFieldsData) {
      const customFields = dealFieldsData.GetFubAllData.output || [];
      setDealCustomFields([...defaultDealFields, ...customFields]);
    }
  }, [dealFieldsData]);

  useEffect(() => {
    if (customFieldsData) {
      const customFields = customFieldsData.GetFubAllData.output || [];
      setPersonCustomFields([...defaultPersonFields, ...customFields]);
    }
  }, [customFieldsData]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  function searchList() {
    let mappings = selectedForm
      ? dealCustomFields
      : fieldMappingData?.GetFieldMappings;
    const filteredOptions = mappings?.filter((option) => {
      const interfaceFieldId = option.interface_field_id
        ? option.interface_field_id.toString()
        : "";
      const fubFieldId = option.platform_field_id
        ? option.platform_field_id.toString()
        : "";

      // If type is "system_default", only show primary fields
      if (activeTab === "system") {
        if (option.field_type !== "primary") {
          return false;
        }
      } 
      // If type is not "system_default", then show custom and default fields
      else {
        if (option.field_type === "primary") {
          return false;
        }
      }

      return (
        interfaceFieldId
          .toLowerCase()
          .split(" ")
          .join("")
          .includes(searchValue.toLowerCase().split(" ").join("")) ||
        fubFieldId
          .toLowerCase()
          .split(" ")
          .join("")
          .includes(searchValue.toLowerCase().split(" ").join(""))
      );
    });

    const renderedOptions = filteredOptions?.map((field_mapping, index) => (
      <>
        <SingleMapping
          field_mapping={field_mapping}
          index={index}
          refetch={refetch}
          customfields={personCustomFields}
          dealcustomfields={dealCustomFields}
        />
        <FieldMappingComponent
          field_mapping={field_mapping}
          index={index}
          refetch={refetch}
          customfields={personCustomFields}
          dealcustomfields={dealCustomFields}
          customFieldsLoading={customFieldsLoading}
          dealFieldsLoading={dealFieldsLoading}
          activeTab={activeTab}
        />
      </>
    ));
    return (
      <div>
        {renderedOptions?.length !== 0 ? (
          renderedOptions
        ) : (
          <div className="mt-3">No matches found.</div>
        )}
      </div>
    );
  }

  if (fieldMappingLoading) return <Loaderr />;

  return (
    <>
      <header className="header bg-white border-bottom" id="header">
        <div>
          <div className="pointer" onClick={() => navigate("/forms/")}>
            <div className="d-flex align-items-center ms-2">
              <span className="navbar-brand m-0 p-0">
                <img className="img-fluid" src={logo} width="30" alt="" />
              </span>
              <i className="bx bx-chevron-left nav_icon mx-md-2 mx-1"></i>
              <span className="p-0 m-0 text-decoration-underline">
                Form Dashboard
              </span>
            </div>
          </div>
        </div>
      </header>
      {/*Container Main start*/}
      <div className="main fmc-main p-0">
        <section className="container-lg container-fluid pt-5">
          <nav className="mb-4">
            <div className="nav nav-tabs flex-nowrap fi-tab gap-2" role="tablist">
              <button
                className={`nav-link text-dark fm-ty ${
                  activeTab === "mapped" ? "active" : ""
                }`}
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#mapped"
                type="button"
                role="tab"
                onClick={() => setActiveTab("mapped")}
              >
                <span className="d-flex align-items-center justify-content-center">
                  <span>Mapped Fields</span>
                </span>
              </button>
              <button
                className={`nav-link text-dark fm-ty ${
                  activeTab === "system" ? "active" : ""
                }`}
                id="nav-custom-tab"
                data-bs-toggle="tab"
                data-bs-target="#system"
                type="button"
                role="tab"
                onClick={() => setActiveTab("system")}
              >
                <span className="d-flex align-items-center justify-content-center">
                  <span className="d-none d-xl-block">System Defaults</span>
                </span>
              </button>
            </div>
          </nav>

          <div className="d-md-flex align-items-center gap-3 mt-4">
            <div className="col-md-6 col-lg-6 col-xxl-4">
              <input
                type="search"
                className="form-control input-bn search-fgy mb-3"
                placeholder="Search mapping"
                value={searchValue}
                onChange={handleSearchChange}
              />
            </div>

            <div className="col-md-6 col-lg-6 col-xxl-4">
              {/* <select class="form-control form-select input-bn search-fgy mb-3">
                        <option>All Form type</option>
                    </select> */}
              {/* <Select
                className="mb-3"
                placeholder={`${
                  formNamesLoading ? "Loading forms" : "Select form"
                }`}
                isClearable={true}
                options={formNamesData?.formsNames?.map((form) => ({
                  value: form.form_id,
                  label: form.form_name,
                }))}
                onChange={(selectedOption) => {
                  setSelectedForm(
                    selectedOption ? selectedOption?.value : null
                  );
                }}
                formatCreateLabel={() => undefined}
              /> */}
            </div>
            <div
              className="btn btn-primary mb-3 ms-auto"
              data-bs-toggle="modal"
              data-bs-target="#new-sync"
            >
              New Sync
            </div>
          </div>

          <div className="tab-content pt-3">
            {activeTab === "mapped" && (
              <div className="tab-pane fade show active" id="mapped" role="tabpanel">
                <p className="text-secondary mt-2">
                  Map custom fields to FUB for sync and easy integration. To
                  learn more about mapping fields click here
                </p>
                {fieldMappingByFormIdLoading ? (
                  <section className="d-flex flex-column py-4 gap-3 mt-3">
                    <div className="">Loading mappings {TypingDots}</div>
                  </section>
                ) : (
                  <section className="d-flex flex-column py-4 gap-3">
                    {fieldMappingData?.GetFieldMappings?.length !== 0 ? (
                      searchList()
                    ) : (
                      <div
                        id="workflow-list"
                        className="row gx-4 gy-1 flex-wrap mt-2 pb-5 mb-5"
                      >
                        <div className="d-flex flex-column align-items-center justify-content-center col-lg-9 col-xl-4 mx-auto pt-5">
                          <img className="img-fluid mb-3" src={NoMappings} alt="" />
                          <h5>No Mappings Created</h5>
                        </div>
                      </div>
                    )}
                  </section>
                )}
              </div>
            )}
            {activeTab === "system" && (
              <div className="tab-pane fade show active" id="system" role="tabpanel">
                {fieldMappingByFormIdLoading ? (
                  <section className="d-flex flex-column py-4 gap-3 mt-3">
                    <div className="">Loading mappings {TypingDots}</div>
                  </section>
                ) : (
                  <section className="d-flex flex-column py-4 gap-3">
                    {fieldMappingData?.GetFieldMappings?.length !== 0 ? (
                      searchList()
                    ) : (
                      <div
                        id="workflow-list"
                        className="row gx-4 gy-1 flex-wrap mt-2 pb-5 mb-5"
                      >
                        <div className="d-flex flex-column align-items-center justify-content-center col-lg-9 col-xl-4 mx-auto pt-5">
                          <img className="img-fluid mb-3" src={NoMappings} alt="" />
                          <h5>No Mappings Created</h5>
                        </div>
                      </div>
                    )}
                  </section>
                )}
              </div>
            )}
          </div>
        </section>
      </div>
      <FieldMappingComponent
        key={activeTab} 
        index="newModal"
        refetch={refetch}
        customfields={personCustomFields}
        dealcustomfields={dealCustomFields}
        customFieldsLoading={customFieldsLoading}
        dealFieldsLoading={dealFieldsLoading}
        activeTab={activeTab}
      />
    </>
  );
};

export default FieldMappingDashboard;
