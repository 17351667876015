export const whisselTeamAppointmentFormBuyer = [
    {
        "elementid": "InputField-opp_last_name",
        "type": "InputField",
        "name": "Last Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Last Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-Appointment-Set-By",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2603",
                    "value": "ISA",
                    "isCheck": false
                },
                {
                    "id": "6926",
                    "value": "OSA",
                    "isCheck": false
                }
            ],
            "label": "Appt Set By",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appt Set By",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-opp_isa",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1548",
                    "value": "Andrew Canavan",
                    "isCheck": false
                },
                {
                    "id": "0791",
                    "value": "Francis Ruiz",
                    "isCheck": false
                },
                {
                    "id": "2561",
                    "value": "Marcus Bryant",
                    "isCheck": false
                },
                {
                    "id": "4832",
                    "value": "Paul Harrison",
                    "isCheck": false
                },
                {
                    "id": "4430",
                    "value": "Tad Murroughs",
                    "isCheck": false
                },
                {
                    "id": "2089",
                    "value": "Trystin Mesa",
                    "isCheck": false
                }
            ],
            "label": "ISAs",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "ISAs",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-opp_assigned_osa",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": 0,
                    "value": "Abhishek Yadav",
                    "isCheck": false
                },
                {
                    "id": 1,
                    "value": "Suraj Mahangade",
                    "isCheck": false
                },
                {
                    "id": 2,
                    "value": "Varad More",
                    "isCheck": false
                }
            ],
            "label": "Assigned OSA",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Assigned OSA",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Headings-Appointment_Details",
        "type": "Headings",
        "settings": {
            "title": "Appointment Details",
            "size": "left",
            "inputType": "text"
        },
        "name": "Appointment Details"
    },
    {
        "elementid": "Date-opp_appt_date",
        "type": "Date",
        "name": "Appointment Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Appointment Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-appointment_time",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8549",
                    "value": "07:30 AM",
                    "isCheck": false
                },
                {
                    "id": "0622",
                    "value": "07:45 AM",
                    "isCheck": false
                },
                {
                    "id": "1053",
                    "value": "08:00 AM",
                    "isCheck": false
                },
                {
                    "id": "2429",
                    "value": "08:15 AM",
                    "isCheck": false
                },
                {
                    "id": "7607",
                    "value": "08:30 AM",
                    "isCheck": false
                },
                {
                    "id": "7015",
                    "value": "08:45 AM",
                    "isCheck": false
                },
                {
                    "id": "8754",
                    "value": "09:00 AM",
                    "isCheck": false
                },
                {
                    "id": "2661",
                    "value": "09:15 AM",
                    "isCheck": false
                },
                {
                    "id": "5901",
                    "value": "09:30 AM",
                    "isCheck": false
                },
                {
                    "id": "4511",
                    "value": "09:45 AM",
                    "isCheck": false
                },
                {
                    "id": "7091",
                    "value": "10:00 AM",
                    "isCheck": false
                },
                {
                    "id": "7097",
                    "value": "10:15 AM",
                    "isCheck": false
                },
                {
                    "id": "1382",
                    "value": "10:30 AM",
                    "isCheck": false
                },
                {
                    "id": "5593",
                    "value": "10:45 AM",
                    "isCheck": false
                },
                {
                    "id": "4954",
                    "value": "11:00 AM",
                    "isCheck": false
                },
                {
                    "id": "2253",
                    "value": "11:15 AM",
                    "isCheck": false
                },
                {
                    "id": "9829",
                    "value": "11:30 AM",
                    "isCheck": false
                },
                {
                    "id": "9547",
                    "value": "11:45 AM",
                    "isCheck": false
                },
                {
                    "id": "4389",
                    "value": "12:00 PM",
                    "isCheck": false
                },
                {
                    "id": "2574",
                    "value": "12:15 PM",
                    "isCheck": false
                },
                {
                    "id": "7299",
                    "value": "12:30 PM",
                    "isCheck": false
                },
                {
                    "id": "5511",
                    "value": "12:45 PM",
                    "isCheck": false
                },
                {
                    "id": "3709",
                    "value": "01:00 PM",
                    "isCheck": false
                },
                {
                    "id": "4504",
                    "value": "01:15 PM",
                    "isCheck": false
                },
                {
                    "id": "9839",
                    "value": "01:30 PM",
                    "isCheck": false
                },
                {
                    "id": "5701",
                    "value": "01:45 PM",
                    "isCheck": false
                },
                {
                    "id": "7845",
                    "value": "02:00 PM",
                    "isCheck": false
                },
                {
                    "id": "2670",
                    "value": "02:15 PM",
                    "isCheck": false
                },
                {
                    "id": "3748",
                    "value": "02:30 PM",
                    "isCheck": false
                },
                {
                    "id": "1009",
                    "value": "02:45 PM",
                    "isCheck": false
                },
                {
                    "id": "4116",
                    "value": "03:00 PM",
                    "isCheck": false
                },
                {
                    "id": "9611",
                    "value": "03:15 PM",
                    "isCheck": false
                },
                {
                    "id": "5398",
                    "value": "03:30 PM",
                    "isCheck": false
                },
                {
                    "id": "6397",
                    "value": "03:45 PM",
                    "isCheck": false
                },
                {
                    "id": "9668",
                    "value": "04:00 PM",
                    "isCheck": false
                },
                {
                    "id": "2302",
                    "value": "04:15 PM",
                    "isCheck": false
                },
                {
                    "id": "4081",
                    "value": "04:30 PM",
                    "isCheck": false
                },
                {
                    "id": "0982",
                    "value": "04:45 PM",
                    "isCheck": false
                },
                {
                    "id": "6533",
                    "value": "05:00 PM",
                    "isCheck": false
                },
                {
                    "id": "9547",
                    "value": "05:15 PM",
                    "isCheck": false
                },
                {
                    "id": "4524",
                    "value": "05:30 PM",
                    "isCheck": false
                },
                {
                    "id": "4888",
                    "value": "05:45 PM",
                    "isCheck": false
                },
                {
                    "id": "2253",
                    "value": "06:00 PM",
                    "isCheck": false
                },
                {
                    "id": "8042",
                    "value": "06:15 PM",
                    "isCheck": false
                },
                {
                    "id": "5531",
                    "value": "06:30 PM",
                    "isCheck": false
                },
                {
                    "id": "9782",
                    "value": "06:45 PM",
                    "isCheck": false
                },
                {
                    "id": "6503",
                    "value": "07:00 PM",
                    "isCheck": false
                },
                {
                    "id": "2563",
                    "value": "07:15 PM",
                    "isCheck": false
                },
                {
                    "id": "3195",
                    "value": "07:30 PM",
                    "isCheck": false
                },
                {
                    "id": "2061",
                    "value": "07:45 PM",
                    "isCheck": false
                },
                {
                    "id": "8391",
                    "value": "08:00 PM",
                    "isCheck": false
                },
                {
                    "id": "1604",
                    "value": "08:15 PM",
                    "isCheck": false
                },
                {
                    "id": "1468",
                    "value": "08:30 PM",
                    "isCheck": false
                }
            ],
            "label": "Appointment Time",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appointment Time",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-Appt-Location",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "5484",
                    "value": "Carlsbad Office",
                    "isCheck": false
                },
                {
                    "id": "6709",
                    "value": "La Mesa Office",
                    "isCheck": false
                },
                {
                    "id": "9927",
                    "value": "North Park Office",
                    "isCheck": false
                },
                {
                    "id": "0865",
                    "value": "Temecula Office",
                    "isCheck": false
                },
                {
                    "id": "2231",
                    "value": "Other Address",
                    "isCheck": false
                },
                {
                    "id": "7205",
                    "value": "Seller Property Address",
                    "isCheck": false
                },
                {
                    "id": "0146",
                    "value": "Buyer Inquiry Address",
                    "isCheck": false
                },
                {
                    "id": "9290",
                    "value": "Zoom",
                    "isCheck": false
                },
                {
                    "id": "4834",
                    "value": "Phone",
                    "isCheck": false
                }
            ],
            "label": "Appointment Location",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appointment Location",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-zoom-link",
        "type": "InputField",
        "name": "Zoom Link",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Zoom Link",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "FreeText-Address_of_Appointment",
        "type": "FreeText",
        "name": "Address of Appointment",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address",
        "type": "InputField",
        "name": "Address of Appointment-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-Street Address",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address2",
        "type": "InputField",
        "name": "Address of Appointment-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_city",
        "type": "InputField",
        "name": "Address of Appointment-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-City",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_state",
        "type": "InputField",
        "name": "Address of Appointment-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-State",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_postal_code",
        "type": "InputField",
        "name": "Address of Appointment-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-Zip Code",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-opp_country",
        "type": "DropDown",
        "linked_to": "opp_country",
        "name": "Address of Appointment-Country",
        "settings": {
            "options": [
                {
                    "id": 25545,
                    "value": "USA",
                    "isCheck": false
                },
                {
                    "id": 2551,
                    "value": "Canada",
                    "isCheck": false
                }
            ],
            "label": "Country",
            "size": "large",
            "inputType": "dropdown",
            "required": false,
            "hidden": false,
            "minValue": "",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "TextArea-App-Description",
        "type": "TextArea",
        "name": "Appointment Description (Clients will see this)",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Appointment Description (Clients will see this)",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-Are_you_the_primary_decision_maker",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2609",
                    "value": "Yes - Primary and Only Decision Maker",
                    "isCheck": false
                },
                {
                    "id": "0688",
                    "value": "Yes - Primary but NOT Only Decision Maker",
                    "isCheck": false
                },
                {
                    "id": "7732",
                    "value": "No - Not Primary Decision Maker",
                    "isCheck": false
                }
            ],
            "label": "Are you the primary decision maker, and are there any other decision makers?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Are you the primary decision maker, and are there any other decision makers?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-secondary_contact",
        "type": "InputField",
        "name": "Secondary Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-Will_they_be_available_for_the_appointment",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1615",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "4146",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Will they be available for the appointment?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Will they be available for the appointment?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Contact-secondary_contact_phone",
        "type": "Contact",
        "name": "Secondary Contact Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-secondary_contact_email",
        "type": "Email",
        "name": "Secondary Contact Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Headings-Buyer_Section_Appt_Form",
        "type": "Headings",
        "settings": {
            "title": "Buyer Section",
            "size": "left",
            "inputType": "text"
        },
        "name": "Buyer Section"
    },
    {
        "elementid": "DropDown-Do_you_currently_rent_or_own_your_own_home",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7597",
                    "value": "Rent",
                    "isCheck": false
                },
                {
                    "id": "3130",
                    "value": "Own",
                    "isCheck": false
                }
            ],
            "label": "Do they currently rent or own your own home?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Do they currently rent or own your own home?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-When_does_your_lease_end",
        "type": "InputField",
        "name": "When does their lease end?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "When does their lease end?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-home_search_price_low",
        "type": "NumberField",
        "name": "Home Search Price Low",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Home Search Price Low",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-home_search_price_high",
        "type": "NumberField",
        "name": "Home Search Price High",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Home Search Price High",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-have_you_been_pre_approved_by_a_mortgage_company?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "5195",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "5334",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Have they been pre-approved by a mortgage company?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Have they been pre-approved by a mortgage company?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-house_details__what_are_they_looking_for?",
        "type": "InputField",
        "name": "House Details: What are they looking for?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "House Details: What are they looking for?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-What_areas_are_you_most_interested_in",
        "type": "InputField",
        "name": "What areas are they most \ninterested in?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "What areas are they most \ninterested in?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "FreeText-Inquiry_Address_1",
        "type": "FreeText",
        "name": "Inquiry Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "IInputField-inquiry_address_street_address",
        "type": "InputField",
        "name": "Inquiry Address-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address-Street Address",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-inquiry_address_address_line_2",
        "type": "InputField",
        "name": "Inquiry Address-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-inquiry_address_city",
        "type": "InputField",
        "name": "Inquiry Address-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address-City",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-inquiry_address_state",
        "type": "InputField",
        "name": "Inquiry Address-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address-State",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-inquiry_address_zip_code",
        "type": "InputField",
        "name": "Inquiry Address-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address-Zip Code",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-inquiry_address_country",
        "type": "DropDown",
        "name": "Inquiry Address-Country",
        "field_sub_type": "opportunity",
        "settings": {
            "options": [
                {
                    "id": 25545,
                    "value": "USA",
                    "isCheck": false
                },
                {
                    "id": 2551,
                    "value": "Canada",
                    "isCheck": false
                }
            ],
            "label": "Inquiry Address-Country",
            "size": "large",
            "inputType": "dropdown",
            "required": false,
            "hidden": false,
            "minValue": "",
            "maxValue": "",
            "instructions": ""
        }

        
    },
    {
        "elementid": "Headings-Additional_Information",
        "type": "Headings",
        "settings": {
            "title": "Additional Information",
            "size": "left",
            "inputType": "text"
        },
        "name": "Additional Information"
    },
    {
        "elementid": "InputField-bio_",
        "type": "InputField",
        "name": "Bio:",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Bio:",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-what_is_the_clients_timeline?",
        "type": "InputField",
        "name": "What is the clients timeline?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "What is the clients timeline?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-what_s_the_clients_motivation?",
        "type": "InputField",
        "name": "What's the clients Motivation?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "What's the clients Motivation?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-finances_",
        "type": "InputField",
        "name": "Finances:",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Finances:",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "CheckBox-D_I_S_C-Profile",
        "type": "CheckBox",
        "settings": {
            "options": [
                {
                    "id": "5203",
                    "value": "(D)ominant",
                    "isCheck": false
                },
                {
                    "id": "8398",
                    "value": "(I)nfluencer",
                    "isCheck": false
                },
                {
                    "id": "6345",
                    "value": "(S)upportive",
                    "isCheck": false
                },
                {
                    "id": "5450",
                    "value": "(C)onscientiousness",
                    "isCheck": false
                }
            ],
            "label": "D.I.S.C. Profile",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "D.I.S.C. Profile",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "TextArea-Notes",
        "type": "TextArea",
        "name": "Notes",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Notes",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp-key",
        "type": "InputField",
        "name": "Opp Key",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opp Key",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please Do not Touch this field"
        }
    },
    {
        "elementid": "InputField-Follow-up-boss-lead-id",
        "type": "InputField",
        "name": "Follow Up Boss Lead ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Follow Up Boss Lead ID",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    }
]

export const whisselTeamAppointmentFormConditons = [
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Other Address",
                    "operator": "equals",
                    "field_id": "DropDown-Appt-Location"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "FreeText-Address_of_Appointment",
            "InputField-opp_address",
            "InputField-opp_address2",
            "InputField-opp_city",
            "InputField-opp_state",
            "InputField-opp_postal_code",
            "DropDown-opp_country"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes - Primary but NOT Only Decision Maker",
                    "operator": "equals",
                    "field_id": "DropDown-Are_you_the_primary_decision_maker"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "No - Not Primary Decision Maker",
                    "operator": "equals",
                    "field_id": "DropDown-Are_you_the_primary_decision_maker"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-secondary_contact",
            "DropDown-Will_they_be_available_for_the_appointment",
            "Email-secondary_contact_email",
            "Contact-secondary_contact_phone"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "ISA",
                    "operator": "equals",
                    "field_id": "DropDown-Appointment-Set-By"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-opp_isa"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Rent",
                    "operator": "equals",
                    "field_id": "DropDown-Do_you_currently_rent_or_own_your_own_home"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-When_does_your_lease_end"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Zoom",
                    "operator": "equals",
                    "field_id": "DropDown-Appt-Location"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-zoom-link"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "ISA",
                    "operator": "equals",
                    "field_id": "DropDown-Appointment-Set-By"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-Are_you_the_primary_decision_maker",
            "InputField-bio_",
            "InputField-what_is_the_clients_timeline?",
            "InputField-what_s_the_clients_motivation?",
            "InputField-finances_",
            "NumberField-home_search_price_low",
            "NumberField-home_search_price_high",
            "DropDown-have_you_been_pre_approved_by_a_mortgage_company?",
            "DropDown-Do_you_currently_rent_or_own_your_own_home",
            "InputField-What_areas_are_you_most_interested_in",
            "InputField-house_details__what_are_they_looking_for?"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "OSA",
                    "operator": "equals",
                    "field_id": "DropDown-Appointment-Set-By"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-opp_assigned_osa"
        ],
        "ConditionName": "Condition Name"
    }
]