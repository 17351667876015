import React from "react";

const Utilities = () => {
  return (
    <>
      <div className="d-flex align-items-center card-g bg-white p-3 border rounded-3">
        <i className="iconify fs-5 me-1" data-icon="bxs:widget"></i>
        <span>Utilities</span>
        <i
          className="iconify text-secondary ms-auto"
          data-icon="mdi:info-circle"
        ></i>
      </div>
    </>
  );
};

export default Utilities;
