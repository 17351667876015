import { boardConetxt, conditionContext } from "../dashboard/ContextHelper";
import { gql, useMutation } from '@apollo/client';
import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import useFormApiHelper from '../../../utils/useFormApiHelper';
import AddConditions from '../../../components/form-builder-components/FormConditions/AddConditions';
import { Loaderr } from '../../../components/Loaderr';


const ConditionUpdate = gql`
mutation UpdateFormConditions($form_conditions: GraphQLJSON, $form_id: String) {
  UpdateFormConditions(form_conditions: $form_conditions, form_id: $form_id) {
    form_id
    team_id
  }
}`

export default function ConditionCreateEdit({ setShowDashBoardComponent, setConditionIndexValue, showComponent, board, ConditionIndexValue, ConditionIntialState, setConditionIntialState }) {
  let initialConditionset = {
    condtions: [
      [
        {
          data_type: board?.[0]?.type ? board[0].type.toLowerCase() : "",
          expected_value: "",
          operator: "equals",
          field_id: board?.[0]?.elementid ? board[0].elementid : "",
        },
      ],
    ],
    Visibility: "show",
    Fields: [],
    ConditionName: "Condition Name",
  };

  const [IntialCondition, setIntialCondition] = useState(ConditionIntialState || initialConditionset)
  // console.log("ConditionIntialState",IntialCondition)


  const { GetFormData } = useFormApiHelper();
  const { condition, setCondition } = useContext(conditionContext);
  const params = useParams()
  const { formDataLoading, formDataError, formData } = GetFormData(params)







  const andCondition = {
    "field_id": "",
    "expected_value": "",
    "operator": "",
    "data_type": "",
  };
  const orCondition = [andCondition];
  const [conditions, setConditions] = useState(ConditionIntialState?.condtions || [orCondition]);//ConditionIntialState.condtions || 





  useEffect(() => {
    if (ConditionIntialState) {
      setConditions(ConditionIntialState.condtions)

    }

  }, [ConditionIntialState])











  const [updateFormConditions, { data, loading, error }] = useMutation(ConditionUpdate, {
    onCompleted: (data) => {
      // console.log("data", data)
      setShowDashBoardComponent(true)

    }
  });


  const HandleSave = (e) => {
    e.preventDefault();
    setCondition((prev) => {
      let newCondition = [...prev];
      updateFormConditions({
        variables: {
          form_id: params.id,
          form_conditions: newCondition,

        }
      })

      return newCondition
    });







  }
  const handleGoback = () => {
    setShowDashBoardComponent(true)
    setConditionIntialState(null)
    const temp = [...condition]
    const FieldsArr = temp[ConditionIndexValue].Fields
    if (FieldsArr.length === 0) {
      temp.splice(ConditionIndexValue, 1);
    }
    setCondition(temp);

  }


  if (formDataLoading || loading) return <Loaderr />

  return (
    <div className="main fmc-main">
      <section className="fm-condition">
        <div
          className="d-lg-none alert alert-warning banner-top p-3 py-2 mb-3 rounded-0 m-0"
          role="alert"
        >
          <div className="d-flex flex-wrap align-items-center">
            <div className="d-flex align-items-center my-2">
              <i className="bx bx-mobile fs-5" />
              <i className="bx bx-arrow-to-right me-2 fs-5" />
              <i className="bx bx-desktop me-2 fs-5" />
            </div>
            <span className="my-2">
              Please switch to desktop and optimize this view. Smaller screens are
              not efficient. To learn more click here
            </span>
          </div>
        </div>
        <section className="col-xxl-6 col-lg-7 col-md-9 mb-5 pb-5 col-12 mx-auto">
          <div className="d-flex flex-wrap justify-content-between align-items-center mb-4 py-4 border-bottom">

            <div
              className="m-0 text-decoration-underline pointer"

            >
              <i className="iconify" data-icon="mdi:chevron-left" />
              <span onClick={() => handleGoback()}>Go back</span>
            </div>
            <input
              type="button"
              disabled={IntialCondition.Fields.length === 0}
              // disabled={updateFormLoading}
              className="d-flex align-items-center btn btn-primary"
              onClick={(e) => HandleSave(e)}
              value="Save"
            />


          </div>
          <div className="input-group align-items-center mb-3 mt-4">
            <i className="iconify fs-4" data-icon="carbon:branch" />
            <input
              // placeholder={"Condition"}
              className="form-control input-lbn input-title disabled"
              type="text"
              disabled="true"
              required=""
              maxLength={30}
              minLength=""
              name=""
              id=""
              style={{ backgroundColor: "inherit" }}
              value={IntialCondition?.ConditionName + " " + parseInt(ConditionIndexValue + 1) || ""}
            />
          </div>
          <div>
            {condition && IntialCondition?.condtions && (
              <AddConditions
                ConditionIntialState={ConditionIntialState}
                IntialCondition={IntialCondition}
                setIntialCondition={setIntialCondition}
                ConditionIndexValue={ConditionIndexValue}
                condition={condition}
                setCondition={setCondition}
                formdata={formData?.formData?.form_fields}
                setConditionIndexValue={setConditionIndexValue}
              />
            )}



          </div>

        </section>
      </section>
    </div>

  )
}
