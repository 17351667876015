import { useState, useEffect } from "react";
import { ModalFooter } from "./ModalFooter";

const RadioButtonSettings = ({ elementid, board, maxLengthofLabel, handleCloseModalClick, elementSettingsData }) => {
  const ElementName = board.find((item) => item?.elementid === elementid).name;
  const initialvalue = {
    options: [],
    label: ElementName,
    size: "large",
    inputType: "text",
    required: false,
    //duplicate: true,
    minValue: "",
    maxValue: "",
    instructions: "",
  };
  const showingFieldId = /\d/.test(elementid);


  const [RadioBtn, setRadioBtn] = useState(
    Object.keys(elementSettingsData).length !== 0 ? elementSettingsData : initialvalue
  );
  const [showErrorInFooter, setshowErrorInFooter] = useState(true);

  //////console.log("ElementSetting", ElementSetting);
  const handlerAddNewOption = () => {
    setshowErrorInFooter(true)
    const id = Math.floor(Math.random() * 1000);
    const values = [...RadioBtn.options];
    values.push({ id: id, value: "", isCheck: false });
    setRadioBtn({ ...RadioBtn, options: values });
  };
  const handleInputChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
       // Set default value if the input is empty
    if (name === "label" && value.trim() === "") {
      value = ElementName; // Set the default value to the element name
    }
    setRadioBtn({ ...RadioBtn, [name]: value });
  };
  const handleChangeInAddOption = (event, index) => {
    // Get the current value from the event
    const { name, value } = event.target;
  
    // Create a copy of the options array from the current state
    const updatedOptions = [...RadioBtn.options];
  
    // Update the specific option at the given index
    updatedOptions[index] = {
      ...updatedOptions[index],
      [name]: value,
    };
  
    // Set the new state with the updated options array
    setRadioBtn({ ...RadioBtn, options: updatedOptions });
  
    // For debugging, you might want to log the updated state
    console.log("Updated Values>", updatedOptions);
  };
  const handleDeleteForOptions = (event, id) => {
    event.preventDefault();
    const values = [...RadioBtn.options];
    values.splice(id, 1);
    // const newboard = values.filter((item) => item.id !== id);
    setRadioBtn({ ...RadioBtn, options: values });
  };

  const handleChangeInAdd = (event) => {
    if (event.target.value) {
      setshowErrorInFooter(false);
    } else {
      setshowErrorInFooter(true);
    }
  };

  useEffect(() => {
    const data = [...RadioBtn.options];
    const check = data.some((item) => {
      if (+(data.length) === 0) {
       return setshowErrorInFooter(false);
      } else if (item.value === "") {
       return setshowErrorInFooter(true);
      }else{
        return setshowErrorInFooter(false);
      }
    });
    console.log("check", check);
  }, [RadioBtn.options]);
  return (
    <>
      <div className="modal-body py-4 px-lg-5 p-md-4">
        <h5 className="mb-4">
          <i
            className="iconify fs-5 pointer me-1"
            data-icon="tabler:checkbox"
          />
          RadioButton Field
        </h5>
        <div className="mb-4">
          <label>
            <span className="d-flex mb-2 align-items-center">
              <span>Radio Button label</span>
            </span>
          </label>
          <input
            className="form-control input-bn"
            type="text"
            name="label"
            maxLength={maxLengthofLabel}
            defaultValue={RadioBtn.label === "" ? ElementName : RadioBtn.label}
            onBlur={handleInputChange}
            placeholder="Enter label for this field"
          />
          {/* placeholder for errors */}
        </div>
        {/* RadioBtn UI */}

        {Object.keys(RadioBtn.options).length > 0 ? (
          RadioBtn.options.map((input, index) => {
            // ////console.log(index);
            //////console.log(input);
            return (
              <div key={input?.id}>
                <div className="mb-2">
                  <div className="d-flex align-items-center">
                    <i
                      className="iconify mx-1 pointer text-secondary fs-5"
                      data-icon="ci:grid-vertical"
                    />
                    <input
                      className="form-control input-bn"
                      type="text"
                      defaultValue={input.value || ""}
                      id={input.id}
                      name="value"
                      placeholder="Enter option"
                      onBlur={(event) => handleChangeInAddOption(event, index)}
                      onChange={(event) => handleChangeInAdd(event, index)}

                    />
                    <span onClick={(e) => handleDeleteForOptions(e,index)}>
                      <i
                        className="iconify fs-5 text-danger pointer ms-2"
                        data-icon="mdi:trash"
                      />
                    </span>
                  </div>
                  {/* placeholder for errors */}
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
        {/* {showErrorInFooter && Object.keys(RadioBtn.options).length == 1 && (
          // <div className="d-flex flex-column ">
          <span
            className="text-danger"
            style={{ marginLeft: "30px", paddingBottom: "5px" }}
          >
            Please enter at least one option for this field
          </span>
          // </div>
        )} */}

        {/* RadioBtn UI */}
        <div className="d-flex align-items-center">
          <i
            className="iconify fs-5 pointer text-secondary mx-1"
            data-icon="ci:plus"
          />
          <div
            className="btn btn-primary rounded-pill "
            onClick={() => {
              handlerAddNewOption();
            }}
          >
            Add new option
          </div>
        </div>
        <hr className="hr my-5" />
        <h5 className="mb-4">
          <i className="iconify fs-5 pointer me-1" data-icon="bx:slider" />
          General Settings
        </h5>
        {/* dropdowns----------- */}
        <div className="mt-4">
          <div className="mb-4">
            <label className="w-100">
              <span className=" mb-2 d-flex justify-content-between align-items-center">
                <span className="me-2">Size</span>
                {/* <div>
                  <i
                    className="iconify fs-6 text-secondary ms-1"
                    data-icon="icon-park-outline:to-left"
                  />
                  <i
                    className="iconify fs-6 text-secondary ms-1"
                    data-icon="entypo:align-horizontal-middle"
                  />
                  <i
                    className="iconify fs-6 text-secondary ms-1"
                    data-icon="icon-park-outline:to-right"
                  />
                </div> */}
              </span>
            </label>
            <select
              className="form-select input-bn"
              value={RadioBtn.size || ""}
              name="size"
              onChange={handleInputChange}
            >
              <option value="large">Large</option>
              <option value="medium">Medium</option>
              <option value="small">Small</option>
            </select>
          </div>
        </div>
        {/* dropdowns----------- */}
        <div className="mt-4">
          <div className="mb-4">
            <label className="w-100">
              <span className=" mb-2 d-flex justify-content-between align-items-center">
                <span className="me-2">Input type</span>
              </span>
            </label>
            <select
              disabled="true"
              className="form-select input-bn"
              value={RadioBtn.inputType || ""}
              name="inputType"
              onChange={handleInputChange}
            >
              <option value="radio">Radio</option>
              {/* <option value="Password">Password</option>
              <option value="Numbers">Numbers</option> */}
            </select>
          </div>
        </div>
        <div className="mb-4">
          <span>Options</span>
          <div className="mt-2 d-flex align-items-center">
            <div className="me-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={RadioBtn.required || ""}
                onChange={handleInputChange}
                name="required"
                id="check-10"
              />
              <label className="form-check-label ms-1" htmlFor="check-10">
                Required
              </label>
            </div>
            <div className="me-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={RadioBtn.hidden || ""}
                onChange={handleInputChange}
                name="hidden"
                id="check-1"
              />
              <label className="form-check-label ms-1" htmlFor="check-1">
                Hidden
              </label>
            </div>
            {/* <div className="me-3 ">
              <input
                className="form-check-input"
                type="checkbox"
                checked={RadioBtn.duplicate || ""}
                onChange={handleInputChange}
                name="duplicate"
                id="check-2"
              />
              <label className="form-check-label ms-1" htmlFor="check-2">
                No Duplicate
              </label>
            </div> */}
          </div>
        </div>
        {/* <div className="mb-4">
          <label>
            <span className="d-flex mb-2 align-items-center">
              <span>Character Range</span>
            </span>
          </label>
          <div className=" d-flex">
            <div className="col-6 pe-1">
              <input
                placeholder="min value"
                defaultValue={RadioBtn.maxValue || ""}
                onBlur={handleInputChange}
                name="maxValue"
                className="form-control input-bn col-5"
                type="number"
              />
            </div>
            <div className="col-6 ps-1">
              <input
                placeholder="max value"
                defaultValue={RadioBtn.minValue || ""}
                onBlur={handleInputChange}
                name="minValue"
                className="form-control input-bn col-5"
                type="number"
              />
            </div>
          </div>
        </div> */}
        {/* text-area--------- */}
        <div className="mb-4">
          <div className="form-floating w-100 form-group">
            <textarea
              style={{ minHeight: "100px !important" }}
              defaultValue={RadioBtn.instructions || ""}
              name="instructions"
              onBlur={handleInputChange}
              placeholder="Instructions for Users (max 100 char)"
              className="form-control input-bn"
              type="text"
              maxLength="100"
            />
            <label>
              <span className="d-flex align-items-center">
                <i className="iconify me-1 mr-1 fs-5" data-icon="mdi:text" />
                <span>Instructions for Users (max 100 char)</span>
              </span>
            </label>
          </div>
          {/* placeholder for errors */}
        </div>
        {!showingFieldId && <span className="h6">System Field Id : {showingFieldId ? null : `${elementid}`}</span>
        }
      </div>
      <ModalFooter
        ElementSettingData={RadioBtn}
        handleCloseModalClick={handleCloseModalClick}
        elementid={elementid}
        showErrorInFooter={showErrorInFooter}
      />
    </>
  );
};

RadioButtonSettings.propTypes = {};

export default RadioButtonSettings;
