import React, { useContext, useEffect } from "react";

import {
  boardConetxt,

} from "../../../pages/form-builder/dashboard/ContextHelper";
import { Controller, useFormContext } from "react-hook-form";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { useLocation } from "react-router-dom";
export const TextareaUi = ({
  elementid,
  showHide,
  previewFlag = false,
  oppFields

}) => {
  const { board } = useContext(boardConetxt);
  const disableElementForBuild = window.location.pathname.includes("build")

  const ElementSettingsData = board.find(
    (item) => item?.elementid === elementid
  ).settings;
  const {
    label,
    inputType,
    instructions,
    minValue,
    maxValue,
    predefine,
    required,
    hidden,
  } = ElementSettingsData;
  const initialStyle = {
    display: showHide === "show" ? "none" : "block",
  };

  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const Location = useLocation();
  const params = new URLSearchParams(Location.search);

  useEffect(() => {
    if (oppFields) {
      setValue(elementid, oppFields)
    }
  }, [oppFields, elementid, setValue])



  useEffect(() => {
    if ((elementid, params.get(elementid.replace(/-\d+$/, "")))) {
      setValue(
        elementid,
        elementid,
        params.get(elementid.replace(/-\d+$/, ""))
      );
    }
  }, [elementid, params.get(elementid.replace(/-\d+$/, ""))]);



  const ElementName = board.find((item) => item?.elementid === elementid).name;
  const checkingValidation = () => {
    if (required === true && showHide === "show") {
      return false
    } else if (required === false && showHide === "show") {
      return false
    } else if (required === true && showHide === "hide") {
      return false
    } else if (required === false && showHide === "hide") {
      return false
    } else if (required === true && hidden === true) {
      return false
    } else if (required === true) {
      return true
    } else {
      return false
    }

  }
  const normalizeFieldName = (name) => {
    return name.replace(/[.\s]/g, '_'); // Replace dots and spaces with underscores
  };
  return (
    <div
      className={(previewFlag && GetStyleClasses(elementid)) || ""}
      style={initialStyle}
      hidden={hidden && previewFlag}
    >
      <div className="form-floating w-100 form-group">

        {Object.keys(ElementSettingsData).length === 0 ? (
          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            rules={{
              maxLength: {
                value: 500,
                message: `Please Enter Maximum 500 Characters`,
              },
            }}
            render={({ field }) => (
              <textarea
                placeholder="Text Area"
                className="form-control input-bn"
                type="text"
                name="textarea"
                disabled={disableElementForBuild}
                id={normalizeFieldName(elementid)}
                {...field}
              />
            )}
          />
        ) : (
          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            rules={{
              required: {
                value: checkingValidation(),
                message: "This Field is Required",
              },
              minLength: {
                value: minValue === "" ? 0 : minValue,
                message: `Please Enter More then ${minValue === "" ? 0 : minValue
                  } Characters`,
              },
              maxLength: {
                value: maxValue === "" ? 500 : maxValue,
                message: `Please Enter Maximum ${maxValue === "" ? 500 : maxValue
                  } Characters`,
              },
            }}
            render={({ field }) => (
              <textarea
                placeholder="Text Area"
                className="form-control input-bn"
                type={inputType ? inputType : "text"}
                name="textarea"
                disabled={disableElementForBuild}
                defaultValue={predefine === undefined ? "" : predefine}
                {...field}
              />
            )}
          />
        )}


        <label>
          <span className="d-flex align-items-center">
            <i className="iconify me-1 mr-1 fs-5" data-icon="mdi:text"></i>

            {label == undefined || label == "" ? ElementName : label}





            {required ? <span style={{ color: "red" }}>*</span> : ""}
          </span>
        </label>
      </div>
      <small className="text-secondary">{instructions}</small>

      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>

    </div>
  );
};
