import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import CustomSelectField from "../../components/fields/CustomSelectField";
import SingleselecCustom from "../../components/fields/SingleselecCustom";
import { TypingDots } from "../../assets/images/three-dots-loading";

const UpdateUser = ({
  data_mapper,
  action_id,
  select_modal,
  selected_modal_id,
  UpdateAction
}) => {
  const updateUserKey = "updatePerson";
  const [updateUserMapper, setUpdateUserMapper] = useState(
    data_mapper?.[updateUserKey] ? data_mapper?.[updateUserKey] : []
  );

  const [contactedOptions, setContactedOptions] = useState([]);
    const GetFubAllData = gql`
    query GetFubAllData($type: String!) {
      GetFubAllData(type: $type) {
          info
          output
          status
      }
    }
    `
    const { loading, data } = useQuery(GetFubAllData, {
      variables: {type: "stages"},
    });

    const { loading:users_loading, data:users_data } = useQuery(GetFubAllData, {
      variables: {type: "users"},
    });

    const usersData = users_data?.GetFubAllData?.output
    .map((user) => ({
      key: `${user.id}`,
      value: `${user.id}`,
      keyShow: user.name,
    }));

    const stageOptions = data?.GetFubAllData?.output
    .map((stage) => ({
      key: `${stage.id}`,
      value: `${stage.id}`,
      keyShow: stage.name,
    }));

  useEffect(() => {
    UpdateAction({ data_mapper: {[updateUserKey]: updateUserMapper} });
  }, [updateUserMapper]);

  if(loading || users_loading) return(<div>Loading{ TypingDots }</div>);

  const field = {
    stage: { options: stageOptions },
    contacted: { options: contactedOptions },
    users: { options: usersData },
  };

  return (
    <>
      <div className="mt-2">
        <div>
          <CustomSelectField
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"stage"}
            field={{ name: "Stage" }}
            field_options={field["stage"].options}
            text_value={updateUserMapper["stage"]}
            update_data_mapper={(key, value) => {
              setUpdateUserMapper((prevMapper) => ({
                ...prevMapper,
                stage: value,
              }));
            }}
            setUpdateUserMapper={setUpdateUserMapper}
            data_mapper={data_mapper}
          />
        </div>
        <div>
          <CustomSelectField
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"assignedUserId"}
            field={{ name: "Assigned User Name" }}
            field_options={field["users"].options}
            text_value={updateUserMapper["assignedUserId"]}
            update_data_mapper={(key, value) => {
              setUpdateUserMapper((prevMapper) => ({
                ...prevMapper,
                assignedUserId: value,
              }));
            }}
            setUpdateUserMapper={setUpdateUserMapper}
            data_mapper={data_mapper}
          />
        </div>
        <div>
          <CustomSelectField
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"assignedLenderId"}
            field={{ name: "Assigned Lender Name" }}
            field_options={field["users"].options}
            text_value={updateUserMapper["assignedLenderId"]}
            update_data_mapper={(key, value) => {
              setUpdateUserMapper((prevMapper) => ({
                ...prevMapper,
                assignedLenderId: value,
              }));
            }}
            setUpdateUserMapper={setUpdateUserMapper}
            data_mapper={data_mapper}
          />
        </div>
        <div>
          <SingleselecCustom
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"background"}
            field={{ name: "Background" }}
            text_value={updateUserMapper["background"]}
            update_data_mapper={(key, value) => {
              setUpdateUserMapper((prevMapper) => ({
                ...prevMapper,
                background: value,
              }));
            }}
            setUpdateUserMapper={setUpdateUserMapper}
          />
        </div>
        {/* <div>
          <SingleselecCustom
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"firstName"}
            field={{ name: "FirstName" }}
            text_value={updateUserMapper["firstName"]}
            update_data_mapper={(key, value) => {
              setUpdateUserMapper((prevMapper) => ({
                ...prevMapper,
                firstName: value,
              }));
            }}
            setUpdateUserMapper={setUpdateUserMapper}
          />
        </div> */}
        {/* <div>
          <SingleselecCustom
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"lastName"}
            field={{ name: "LastName" }}
            text_value={updateUserMapper["lastName"]}
            update_data_mapper={(key, value) => {
              setUpdateUserMapper((prevMapper) => ({
                ...prevMapper,
                lastName: value,
              }));
            }}
            setUpdateUserMapper={setUpdateUserMapper}
          />
        </div> */}
        {/* <div>
          <CustomSelectField
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"contacted"}
            field={{ name: "Contacted" }}
            field_options={field["contacted"].options}
            text_value={updateUserMapper["contacted"]}
            update_data_mapper={(key, value) => {
              setUpdateUserMapper((prevMapper) => ({
                ...prevMapper,
                contacted: value,
              }));
            }}
            setUpdateUserMapper={setUpdateUserMapper}
            data_mapper={data_mapper}
          />
        </div> */}
        {/* <div>
          <SingleselecCustom
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"price"}
            field={{ name: "Price" }}
            text_value={updateUserMapper["price"]}
            update_data_mapper={(key, value) => {
              setUpdateUserMapper((prevMapper) => ({
                ...prevMapper,
                price: value,
              }));
            }}
            setUpdateUserMapper={setUpdateUserMapper}
          />
        </div> */}
        {/* <div>
          <SingleselecCustom
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"assignedTo"}
            field={{ name: "Assigned To" }}
            text_value={updateUserMapper["assignedTo"]}
            update_data_mapper={(key, value) => {
              setUpdateUserMapper((prevMapper) => ({
                ...prevMapper,
                assignedTo: value,
              }));
            }}
            setUpdateUserMapper={setUpdateUserMapper}
          />
        </div> */}
        {/* <div>
          <CustomSelectField
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"assignedPondId"}
            field={{ name: "Assigned Pond Id" }}
            field_options={field["assignedPondId"]}
            text_value={updateUserMapper["assignedPondId"]}
            update_data_mapper={(key, value) => {
              setUpdateUserMapper((prevMapper) => ({
                ...prevMapper,
                assignedPondId: value,
              }));
            }}
            setUpdateUserMapper={setUpdateUserMapper}
            data_mapper={data_mapper}
          />
        </div> */}
        {/* <div>
          <SingleselecCustom
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"assignedLenderId"}
            field={{ name: "Assigned Lender Id" }}
            text_value={updateUserMapper["assignedLenderId"]}
            update_data_mapper={(key, value) => {
              setUpdateUserMapper((prevMapper) => ({
                ...prevMapper,
                assignedLenderId: value,
              }));
            }}
            setUpdateUserMapper={setUpdateUserMapper}
          />
        </div> */}
        {/* <div>
          <SingleselecCustom
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"emails"}
            field={{ name: "Emails" }}
            text_value={updateUserMapper["emails"]}
            update_data_mapper={(key, value) => {
              setUpdateUserMapper((prevMapper) => ({
                ...prevMapper,
                emails: value,
              }));
            }}
            setUpdateUserMapper={setUpdateUserMapper}
          />
        </div>
        <div>
          <SingleselecCustom
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"phones"}
            field={{ name: "Phones" }}
            text_value={updateUserMapper["phones"]}
            update_data_mapper={(key, value) => {
              setUpdateUserMapper((prevMapper) => ({
                ...prevMapper,
                phones: value,
              }));
            }}
            setUpdateUserMapper={setUpdateUserMapper}
          />
        </div> */}
        {/* <div>
          <SingleselecCustom
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"tags"}
            field={{ name: "Tags" }}
            text_value={updateUserMapper["tags"]}
            update_data_mapper={(key, value) => {
              setUpdateUserMapper((prevMapper) => ({
                ...prevMapper,
                tags: value,
              }));
            }}
            setUpdateUserMapper={setUpdateUserMapper}
          />
        </div> */}
        {/* <div>
          <SingleselecCustom
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"custom"}
            field={{ name: "Custom" }}
            text_value={updateUserMapper["custom"]}
            update_data_mapper={(key, value) => {
              setUpdateUserMapper((prevMapper) => ({
                ...prevMapper,
                custom: value,
              }));
            }}
            setUpdateUserMapper={setUpdateUserMapper}
          />
        </div> */}
      </div>
    </>
  );
};
export default UpdateUser;
