import { Elements } from "../../../components/Elements";
import DragableElement from "../../../components/DragableElement";
import { Droppable } from "react-beautiful-dnd";
import { useCallback } from "react";

import { ElementsUIGenrate } from "../../../components/ElementsUIGenrate";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const FormBuilderLeftSideBar = ({isCollapsed,setIsCollapsed}) => {

  const Element = useCallback(({ id }) => {
    const Store = ElementsUIGenrate(id);
    let flex
    if(id==="InputField"){
      flex = "Single Line Text"
    }else if(id = id.replace(/([A-Z])/g, ' $1').trim()){
      flex=id;
    }else{
      flex=id;
    }
    console.log("Store", id);
    return (
      <div
        className="d-flex p-2 my-2 border rounded align-items-center gap-2"
        id={id}
      >
        <i className="fs-3 iconify" data-icon={Store?.icon} />
        <span>{flex}</span>
      </div>
    );
  }, []);
  console.log("isCollapsed",isCollapsed)


  return (
    <div className="w-100 pb-5 mb-5">
      <div className="d-flex mx-3 mt-2 justify-content-between align-items-center border-bottom pb-2">
        <span className="">Add New Field</span>
        <a className="iconify fs-3 text-secondary pointer" data-icon="basil:cancel-solid" data-bs-toggle="collapse" data-bs-target="#newF"></a>
      </div>

      <Droppable droppableId="Elements" isDropDisabled={true}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="fm-component-cont fm-component-cont-2 mt-3 row row-cols-1 p-0 m-0"
          >
            {Elements?.map((element, index) => {
              return (
                <DragableElement
                  id={element.id}
                  key={element.id}
                  index={index}
                  element={<Element id={element.id} />}
                />
              );
            })}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default FormBuilderLeftSideBar;
