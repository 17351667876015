import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import CustomSelectField from "../../components/fields/CustomSelectField";
import { TypingDots } from "../../assets/images/three-dots-loading";

const ActionPlans = ({
  data_mapper,
  action_id,
  select_modal,
  selected_modal_id,
  UpdateAction
}) => {
  const actionPlanKey = "actionPlan";
  const [options, setoptions] = useState([])
  const [actionPlanMapper, setActionPlanMapper] = useState(
    data_mapper?.[actionPlanKey] ? data_mapper?.[actionPlanKey] : {}
  );
  const [text_value, settext_value] = useState(actionPlanMapper["actionPlanId"]);

  const GetFubAllData = gql`
  query GetFubAllData($type: String!) {
    GetFubAllData(type: $type) {
        info
        output
        status
    }
  }
  `
  const { loading, data } = useQuery(GetFubAllData, {
    variables: {type: "actionPlans"},
  });

  useEffect(() => {
    let __options = []
    let __data = data?.GetFubAllData?.output;
    __data?.map((item) => {
      __options.push({ key: item.id, keyShow: item.name, value: item.status })
    })
    setoptions(__options)
  }, [data?.GetFubAllData?.output])

  useEffect(() => {
    if (data) {
      const desiredObject = data?.GetFubAllData?.output.find(item => item.id === text_value);
      if (desiredObject) {
        settext_value(desiredObject.id);
      } 
    }
  }, [data, text_value]);

  useEffect(() => {
    UpdateAction({ data_mapper: {actionPlan: actionPlanMapper} });
  }, [actionPlanMapper]);

  if(loading) return(<div>Loading{TypingDots}</div>);

  return (
    <>
      <div className="mt-2">
        {/* <div>
          <SingleselecCustom
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"personId"}
            field={{ name: "Person Id" }}
            text_value={actionPlanMapper["personId"]}
            update_data_mapper={(key, value) => {
              setActionPlanMapper((prevMapper) => ({
                ...prevMapper,
                personId: value,
              }));
            }}
            setActionPlanMapper={setActionPlanMapper}
            data_mapper={data_mapper}
            field_options={options}
          />
        </div> */}
        <div>
          <CustomSelectField
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"actionPlanId"}
            field={{ name: "Action Plan Id" }}
            text_value={text_value}
            update_data_mapper={(key, value) => {
              setActionPlanMapper((prevMapper) => ({
                ...prevMapper,
                actionPlanId: value,
              }));
            }}
            setActionPlanMapper={setActionPlanMapper}
            field_options={options}
          />
        </div>
      </div>
    </>
  );
};
export default ActionPlans;