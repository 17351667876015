import React, { useState, useEffect, useContext, useMemo } from "react";
import { generatekeys_per_action } from "../../utils/parser";
import { WorkFlowSessionInfo } from "../../pages/automation-builder/workflows/EditWorkflow";
import { uniqueId } from "lodash";
import AccordionDefaultOptions from "../../components/AccordionDefaultOptions";

const  CustomSelectField = ({
  action_id,
  select_modal,
  selected_modal_id,
  field_key,
  field,
  text_value,
  update_data_mapper,
  field_options,
  field_type = "custom_field",
  field_name = ""
}) => {
  const { workflow_helper } = useContext(WorkFlowSessionInfo);
  const [insert_value, set_insert_value] = useState(null);
  const field_id = useMemo(() => uniqueId(), []);
  const [selectedOption, setSelectedOption] = useState("");
  const [customFieldError, setCustomFieldError] = useState("");
  const { session } = useContext(WorkFlowSessionInfo);

  useEffect(() => {
    if (selected_modal_id === field_id) {
      document.querySelector("#modal_" + field_id).style.display = "block";
    } else {
      document.querySelector("#modal_" + field_id).style.display = "none";
    }
  }, [selected_modal_id, field_id]);

  var keys_ = generatekeys_per_action(session);
  var keys = {};
  const triggerValues = workflow_helper.current.get_parent_chain(action_id);

  const store = triggerValues.map((val) => {
    keys[val] = [];
    const checker = Object.keys(keys_).map((key) => {
      if (key ===val) {
        return (keys[val] = keys_[val]);
      }
    });
    return checker ===undefined ? (keys_[val] = []) : checker;
  });

  useEffect(() => {
    if (insert_value !== null && insert_value !== undefined) {
      update_data_mapper(field_key, insert_value);
      setCustomFieldError("");
    }
  }, [insert_value]);

  useEffect(() => {
    if (text_value !== undefined && text_value !== null && text_value !== "" && field_options) {
      let optionValue = findValueByKey(text_value);
      if (optionValue !== null) {
        setSelectedOption(optionValue);
        setCustomFieldError("");
      }
      else {
        setCustomFieldError("Custom field not found in the follow up boss.");
      }
    }
  }, [field_options]);

  function findValueByKey(keyToFind) {
    for (var i = 0; i < field_options.length; i++) {
      if (field_options[i]?.key){
      if (field_options[i].key === keyToFind) {
        return field_options[i].keyShow;
      }
    }
    if (field_options[i]?.value){
      if (field_options[i].value === keyToFind) {
        return field_options[i].keyShow;
      }
    }
    }
    return null;
  }

  return (
    <>
      <div className="mb-3">
        <span className="text-truncate txt-gy">{field?.name}</span>{(field_name === "pipeline" || field_name === "stageId") && <span className="text-danger"> *</span> }
        <div
          className="d-flex mt-1"
          onClick={(e) => {
           select_modal(field_id);
          }}
        >
          <div
            className={`custom-select d-flex align-items-center border bg-white p-1 pb-0 rounded-start txt-container input-bn fgoo ${field_options?.length > 2 && "form-select"}`}
            role="textbox"
          >
            <div className="d-flex align-items-center border-0 txt-container ps-2 fg-cs ">
              {selectedOption ? selectedOption : text_value}
            </div>
          </div>
        </div>
        { field_type === "custom_field" && field_options?.length > 2 && (
        <span className="text-truncate txt-gy text-danger">{customFieldError !== "" ? customFieldError :""}</span>
        )}
        { field_options?.length === 0 && (
        <span className="text-truncate txt-gy text-danger">Please select pipeline first!</span>
        )}
      </div>
      <AccordionDefaultOptions
        setSelectedOption={setSelectedOption}
        set_insert_value={set_insert_value}
        field_id={field_id}
        select_modal={select_modal}
        options={field_options}
        insert_value={insert_value}
        text_value={text_value}
      />
    </>
  );
};
export default CustomSelectField;
