import React, { useState, useEffect } from "react";
import SingleselecCustom from "./SingleselecCustom";
import { v4 as uuidv4 } from 'uuid';

const CustomArray = ({
  action_id,
  select_modal,
  selected_modal_id,
  UpdateAction,
  AutiomationfieldsInfo,
  data_mapper,
  update_data_mapper,
  field_key = "tags",
  name = "Tag Name",
}) => {
  const [arrayElements, setArrayElements] = useState(data_mapper?.[field_key] ? data_mapper?.[field_key].map(option => ({ id: uuidv4(), value: option })) : [{ id: uuidv4(), value: "" }]);

  useEffect(() => {
    UpdateAction({ data_mapper: { [field_key]: arrayElements.map(tag => tag.value) } });
  }, [arrayElements]);

  const handleDeleteField = (tagId) => {
    setArrayElements((prevTags) => prevTags.filter((tag) => tag.id !== tagId));
  };

  const handleUpdateTag = (tagId, newValue) => {
    setArrayElements((prevTags) => prevTags.map((tag) => tag.id === tagId ? { ...tag, value: newValue } : tag));
  };

  return (
    <div className="mt-2">
      {arrayElements.map((tag, index) => (
        <div className="col-12" key={tag.id}>
          <SingleselecCustom
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={tag.id}
            AutiomationfieldsInfo={AutiomationfieldsInfo}
            field={{ name: name }}
            text_value={tag.value}
            update_data_mapper={handleUpdateTag}
          />
          {index !== 0 && (
            <div className="p-0 mb-3 d-flex justify-content-end">
              <div
                className="filt-del-tag"
                onClick={() => handleDeleteField(tag.id)}
              >
                <i
                  className="iconify fs-6 pointer text-danger"
                  data-icon="ep:circle-close-filled"
                ></i>
              </div>
            </div>
          )}
        </div>
      ))}
      {arrayElements.length < 5 && (
        <div
          className="btn btn-primary btn-sm"
          onClick={() => {
            if (arrayElements.length < 5) {
              setArrayElements([...arrayElements, { id: uuidv4(), value: "" }]);
            }
          }}
        >
          +
        </div>
      )}
    </div>
  );
};

export default CustomArray;