import React, { useMemo, useCallback, useEffect, memo, useContext } from 'react'
import { Editor, Transforms, createEditor, Node } from 'slate'
import { withHistory } from 'slate-history'
import {get_action_icon} from "../assets/logos"
import { WorkFlowSessionInfo } from "../pages/automation-builder/workflows/EditWorkflow";
import { parseValuesession } from '../utils/parser';
import {
    Slate,
    Editable,
    withReact,
    useSelected,
    useFocused,
} from 'slate-react'


const serialize = editor => {
    // unsure if this is the best way to get the text
    const nodesList = editor.children;
    //console.log("nodesList", nodesList)
    const textBlocks = nodesList.map(node => 
            {   if (node.children) {
                return node.children.map(child => {
                    if (child.type === "placeholder") {
                        return child.placeholdertext
                    }
                    else{
                        return Node.string(child)
                    }
                }).join("")
            }
                if (node.type === "placeholder") {
                    return node.placeholdertext
                }
                else{
                    return Node.string(node)
                }
            });
    //console.log("textBlocks", textBlocks)
    // To join them together, use .join() function
    const joinedText = textBlocks.join("\n"); // To join by a new line 
    //console.log("joinedText", joinedText)
    return joinedText;
  }

  const PlaceHolderElement = ({ attributes, children, element }) => {
    const selected = useSelected()
    const focused = useFocused()
    const style = {
        padding: '3px 3px 2px',
        margin: '0 1px',
        verticalAlign: 'baseline',
        display: 'inline-block',
        borderRadius: '4px',
        backgroundColor: '#eee',
        fontSize: '0.9em',
        boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
    }
    // See if our empty text child has any styling marks applied and apply those
    if (element.children[0].bold) {
        style.fontWeight = 'bold'
    }
    if (element.children[0].italic) {
        style.fontStyle = 'italic'
    }
    // Important: ensure that the user can't "select" the placeholder
    style.userSelect = "none"
    const {session, workflow_helper} = useContext(WorkFlowSessionInfo);
    //console.log("session_info", session)
    //console.log("element",element.placeholdertext)
    let placeholderArray = element.placeholdertext.replace("{{", "").replace("}}", "").split("__");
    let keyshow = placeholderArray.length > 1 ? placeholderArray.splice(1).join('.') : placeholderArray[0];

    let action_name = placeholderArray[0]
    if(!(action_name in session) && action_name !== "todays_date"){
        action_name = "Unknown"
    }

    const keys = workflow_helper.current.get_actions_by_order().map(({ action_id }, index) => ({ index, action_id }));

    const findIndexByActionId = (action_id) => {
        const action = keys.find(key => key.action_id === action_id);
        return action ? action.index + 1 : keys.length;
    };
    const action_index = findIndexByActionId(action_name);

    return (
        <>
      {element.placeholdertext ? (
        <div {...attributes}
        contentEditable={false}
        style={style}  className="justify-content-between border rounded-1 p-1 m-1"
        title={ action_name !== "Unknown" ? action_index + ". " + action_name.replace(/\d+$/, '') : action_name}
        >
        <img
          className="m-1 p-0 fs-5"
          height="10"
          src={get_action_icon(action_name)}
          alt=""
        />
        <div className='d-flex'>
        {/* {item.filter_id ? item.filter_id + ". " : ""}{" "}
        {item.expected_value} */}
        {keyshow}: { String(parseValuesession(element.placeholdertext, session) || 'No Data')}
        {children}
      </div>
      </div>
      ) : (
        ""
      )}
    </>
  )
}


export const TextBox = ({ text, settext,select_modal, insert_value, set_insert_value, readOnly }) => {
    const {isModalOpen,setIsModalOpen} = useContext(WorkFlowSessionInfo);
    readOnly = (readOnly === undefined || readOnly === null )? false : readOnly
    //console.log("Input Text", text)
    const PlaceHolder = props => {
        const { attributes, children, element } = props
        switch (element.type) {
            case 'placeholder':
                // can pass this function as a prop
                return <PlaceHolderElement {...props} />
            default:
                return <p {...attributes}>{children}</p>
        }
    }
    const renderElement = useCallback(props => <PlaceHolder {...props} />, [])
    // may be add withRichText here
    const editor = useMemo(
        () => withPlaceholders(withReact(withHistory(createEditor()))),
        []
    )

    useEffect(() => {
        if (insert_value && (!readOnly)) {
            // insertPlaceholder(editor, value)
            Transforms.insertText(editor, insert_value)
            set_insert_value(null)
        }
    }, [insert_value])

    useEffect(() => {
        var converted_text = ""
        for (var [node, path] of Editor.nodes(editor, {
            at: [],
            reverse: false
        })) {
            //console.log("node", node)
            //console.log("path", path)
            //console.log("string", Node.string(node))
        }

    }, [])

    useEffect(() => {
        //console.log("Use Effect Called")
        // findWithRegex(editor)
        for (let range of findWithRegex(editor)) {
            //console.log("range", range)
                        const placeholdertext = Editor.string(editor, range);
            // for some reason, even if the match is correct editor is returning empty string
            // console.log("text:: placeholdertext", placeholdertext)
            if (placeholdertext == "" || placeholdertext == null || placeholdertext == undefined) {
                continue
            }
            // console.log("text:: placeholdertext", placeholdertext)
            //console.log("word", placeholdertext)
            Transforms.select(editor, range)
            // Transforms.delete(editor)
            insertPlaceholder(editor, placeholdertext)
        }
    }, [])
    
    return (
        <Slate
            editor={editor}
            initialValue={[
                {
                    type: 'paragraph',
                    children: [
                        { text: text || ""  },
                    ],
                },
            ]}
            
            // onFocus={() => {setIsModalOpen(!isModalOpen)}}
            onFocus={() => {
                // const { selection } = editor
                // for (let range of findWithRegex(editor)) {
                //     //console.log("range", range)
                //     const placeholdertext = Editor.string(editor, range);
                //     //console.log("word", placeholdertext)
                //     Transforms.select(editor, range)
                //     // Transforms.delete(editor)
                //     insertPlaceholder(editor, placeholdertext)
                // }
                setIsModalOpen(!isModalOpen)
                // let serialized_text = serialize(editor)
                // //console.log(">> serialized_text", serialized_text)
                
                // if (serialized_text !== null && serialized_text !== undefined) settext(serialized_text);
            }}
            onChange={()=>{
                   for (let range of findWithRegex(editor)) {
                    //console.log("range", range)
                    const placeholdertext = Editor.string(editor, range);
                    // console.log("text:: placeholdertext", placeholdertext)
                    if (placeholdertext === "" || placeholdertext == null || placeholdertext === undefined) {
                        // console.log("text:: placeholdertext", placeholdertext)
                        continue
                    }
                    Transforms.select(editor, range)
                    // Transforms.delete(editor)
                    insertPlaceholder(editor, placeholdertext)
                }
                 let serialized_text = serialize(editor)
                //console.log(">> serialized_text", serialized_text)
                
                if (serialized_text !== null && serialized_text !== undefined) settext(serialized_text);
            }}
        >
            <Editable
            onFocus={
                () => {
                    //console.log("On Focus Called")
                    select_modal("")
                    setIsModalOpen(!isModalOpen)
                }
            }
                // className="form-control input-bn"
                className="ms-1 form-control form-select bg-white form-control-sm txt-slate border-0 ps-1 p-0"
                renderElement={renderElement}
                placeholder="Select data..."
                readOnly={readOnly}

            />
        </Slate>
    )
}

const withPlaceholders = editor => {
    const { isInline, isVoid, markableVoid } = editor

    editor.isInline = element => {
        return element.type === 'placeholder' ? true : isInline(element)
    }

    editor.isVoid = element => {
        return element.type === 'placeholder' ? true : isVoid(element)
    }

    editor.markableVoid = element => {
        return element.type === 'placeholder' || markableVoid(element)
    }

    return editor
}

const findWithRegex = (editor) => {

    //console.log("---------------------------------------------------------------")
    var ranges = []
    for (var [node, path] of Editor.nodes(editor, {
        at: [],
        match: (n) => {
            // we just want to find first occurance here
            const regex = /\{{2}[^{]*?\}{2}/;
            return regex.exec(n.text)
        },
        reverse: false
    }
    )) {
        // We want to find all occurances here
        const regex = /\{{2}[^{]*?\}{2}/g
        const { text } = node;
        // Don't use/print exec here to //console.log as it will not work/break for global regex, read about lastIndex
        // console.log("text::", text, ...text.matchAll(regex))
        var match;
        while ((match = regex.exec(text)) != null) {
            //console.log("Found Match")
            ranges.push({
                anchor: { path, offset: match.index },
                focus: { path, offset: match.index + match[0].length }
            })
            //console.log("string found", Editor.string(editor, {
            //     anchor: { path, offset: match.index },
            //     focus: { path, offset: match.index + match[0].length }
            // }))
        }

    }
    //console.log("ranges", ranges)
    //console.log("---------------------------------------------------------------")
    return ranges;
}

const insertPlaceholder = (editor, placeholdertext) => {
    const placeholder = {
        type: 'placeholder',
        placeholdertext,
        children: [{ text: '' }],
    }
    Transforms.insertNodes(editor, placeholder)
    Transforms.move(editor)
}

export default memo(TextBox)