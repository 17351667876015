import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from '@apollo/client';
import useFormApiHelper from "../../../utils/useFormApiHelper";
import { gql, useQuery } from "@apollo/client";
// import { LeaderboardContext } from './ContextHelper'
import { AgGridReact } from "ag-grid-react";
// import { OpportunityModal } from "./OpportunityModal";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { GridChartsModule } from "@ag-grid-enterprise/charts";
import { LicenceManager } from "ag-grid-enterprise";
import debounce from 'lodash.debounce';

import { type } from "jquery";
const GET_OPPORTUNITY_TYPES = gql`
  query OpportunityTypeByTeam {
    opportunityTypeByTeam {
      opp_type
    }
  }
`;
const ItemsPerPage = 10;
const oppsData = gql`
query GetTeamSettings($filter: OppFilterInput) {
  Opps(filter: $filter) {
    opp_key
    sisu_client_updated_ts
    opp_updated_ts
    opp_stage
    opp_agreement_signed_date
    fub_deal_created_ts
    opp_type
    opp_appt_date
    fub_deal_stage_name
    opp_appt_met_date
    fub_person_id
    sisu_client_created_ts
    opp_created_ts
    fub_deal_id
    opp_appt_disposition
    teamFubDealId
    team
    fub_deal_entered_stage_ts
    sisu_client_id
    opp_assigned_osa
    opp_isa
    opp_notes
    opp_address
    opp_agreement_expiration_date
    appt_set_entry_id
    fub_appt_start_time
    fub_original_appt_start_time
    disp_text_wait_timestamp
    appt_set_lead_type
    appt_set_platform
    disp_text_original_wait_timestamp
    opp_address2
    opp_city
    opp_postal_code
    opp_last_name
    opp_state
    previous_opp_stage
    CreateEntryId
    pipeline_entry_id
    opp_forecasted_close_date
    opp_under_contract_date
    appt_form_id
    form_id_entry_id
    opp_settlement_date
    CreateFormId
    appt_outcome
    external_system_key_buyer
    otc_property_id
    external_system_key_seller
    FormId_EntryIds
    opp_price
    opp_commission_percent
    fub_appt_id
    opp_custom_fields
    custom_fields
   
  }
}`

export default function Notes() {
  const navigate = useNavigate();
  const { Opps } = useFormApiHelper();
  // const { leaderboard, setLeaderboard } = React.useContext(LeaderboardContext)

  const handleEntryView = (oppid) => {
    navigate(`/opportunity/${oppid}`);
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [showCustomise, setShowCustomise] = useState(false);
  // const [checkedItems, setCheckedItems] = useState([]);
  // const [isChecked, setIsChecked] = useState(false);
  const [getValueForm, setValueForm] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [filter, setFilter] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(['Buyer','Seller']);

  const [selectedCommission, setSelectedCommission] = useState(null);
  const [opplist, setOpplist] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({
    'Appointment Set': false,
    'Appointment Met': false,
    'Agreement Signed': false,
    Pending: false,
    Closed: false,
  });


  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const dateFormatter = (params) => {
    if (!params.value) return '';
    const dateStr = params.value.toString();

    let year, month, day;

    if (dateStr.includes('-')) {
      // Handle YYYY-MM-DD format
      [year, month, day] = dateStr.split('-');
    } else {
      // Handle YYYYMMDD format
      year = dateStr.substring(0, 4);
      month = dateStr.substring(4, 6);
      day = dateStr.substring(6, 8);
    }

    return `${day}/${month}/${year}`;
  };


  // const dateFormatter = (params) => {
  //   if (!params.value) return '';
  //   const dateStr = params.value.toString();
  //   const year = dateStr.substring(0, 4);
  //   const month = dateStr.substring(4, 6);
  //   const day = dateStr.substring(6, 8);
  //   return `${day}/${month}/${year}`;
  // };

  const columnDefs = [
    {
      headerName: "",
      type: "action",
      // headerCheckboxSelection: true,
      // checkboxSelection:true,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        innerRenderer: (params) => (
          <i
            className="iconify pt-1 fs-5 pointer"
            data-icon="fluent:eye-24-regular"
          ></i>
        ),
      },
    },
    {
      headerName: "Agent Name", field: "opp_assigned_osa", type: 'agent', enableRowGroup: true, filter: 'agTextColumnFilter',
      filterParams: {
        applyMiniFilterWhileTyping: true,
        suppressMiniFilter: false,
      },
    },
    {
      headerName: "FUB Lead ID", field: "fub_person_id", filter: 'agTextColumnFilter',
      filterParams: {
        applyMiniFilterWhileTyping: true,
        suppressMiniFilter: false,
      },
    },
    {
      headerName: "FUB Deal ID", field: "fub_deal_id", filter: 'agTextColumnFilter',
      filterParams: {
        applyMiniFilterWhileTyping: true,
        suppressMiniFilter: false,

      },
    },
    {
      headerName: "Title", field: "opp_last_name", filter: 'agTextColumnFilter',
      filterParams: {
        applyMiniFilterWhileTyping: true,
        suppressMiniFilter: false,
        debounceMs: 500,
      },
    },
    { headerName: "Stage", field: "opp_stage" },
    {
      headerName: "Agreement Signed Date",
      field: "opp_agreement_signed_date",
      valueFormatter: dateFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterDate, cellValue) => {
          if (!cellValue) return -1;
          const cellDate = new Date(cellValue.substring(0, 4), cellValue.substring(4, 6) - 1, cellValue.substring(6, 8));
          return cellDate.getTime() - filterDate.getTime();
        },
        browserDatePicker: true,
      },
      // valueFormatter: (params) => formatDate(params.value),
    },
    { headerName: "Type", field: "opp_type" },
    {
      headerName: "Appt Date",
      field: "opp_appt_date",
      valueFormatter: dateFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterDate, cellValue) => {
          if (!cellValue) return -1;
          const cellDate = new Date(cellValue.substring(0, 4), cellValue.substring(4, 6) - 1, cellValue.substring(6, 8));
          return cellDate.getTime() - filterDate.getTime();
        },
        browserDatePicker: true,
      },
      headerTooltip: "Appointment Date of opportunity or lead"
      // valueFormatter: (params) => formatDate(params.value),
    },
    {
      headerName: "Appt Met Date",
      field: "opp_appt_met_date",
      valueFormatter: dateFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterDate, cellValue) => {
          if (!cellValue) return -1;
          const cellDate = new Date(cellValue.substring(0, 4), cellValue.substring(4, 6) - 1, cellValue.substring(6, 8));
          return cellDate.getTime() - filterDate.getTime();
        },
        browserDatePicker: true,
      },
      // valueFormatter: (params) => formatDate(params.value),
    },
    {
      headerName: "Price", field: "opp_price", filter: 'agNumberColumnFilter',
      filterParams: {
        applyButton: true,
        clearButton: true,
        inRangeInclusive: true,
      }, cellStyle: params => params.value > 80 ? { color: 'green' } : { color: 'black' }
    },
    {
      headerName: "Sisu Client ID", field: "sisu_client_id", filter: 'agTextColumnFilter',
      filterParams: {
        applyMiniFilterWhileTyping: true,
        suppressMiniFilter: false,
      },
    },
    {
      headerName: "Agreement Expiration Date",
      field: "opp_agreement_expiration_date",
      valueFormatter: dateFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterDate, cellValue) => {
          if (!cellValue) return -1;
          const cellDate = new Date(cellValue.substring(0, 4), cellValue.substring(4, 6) - 1, cellValue.substring(6, 8));
          return cellDate.getTime() - filterDate.getTime();
        },
        browserDatePicker: true,
      },
      // valueFormatter: (params) => formatDate(params.value),
    },
    {
      headerName: "Commission Percent", field: "opp_commission_percent", filter: 'agNumberColumnFilter',
      filterParams: {
        applyButton: true,
        clearButton: true,
        inRangeInclusive: true,
      }
    },
    { headerName: "Commission Point", field: "custom_commission_point" },
    {
      headerName: "Postal Code", field: "opp_postal_code", filter: 'agTextColumnFilter',
      filterParams: {
        applyMiniFilterWhileTyping: true,
        suppressMiniFilter: false,
      },
    },
    {
      headerName: "Last Name", field: "opp_last_name", filter: 'agTextColumnFilter',
      filterParams: {
        applyMiniFilterWhileTyping: true,
        suppressMiniFilter: false,
      },
    },
    { headerName: "State", field: "opp_state" },
    {
      headerName: "Forecasted Close Date",
      field: "opp_forecasted_close_date",
      valueFormatter: dateFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterDate, cellValue) => {
          if (!cellValue) return -1;
          const cellDate = new Date(cellValue.substring(0, 4), cellValue.substring(4, 6) - 1, cellValue.substring(6, 8));
          return cellDate.getTime() - filterDate.getTime();
        },
        browserDatePicker: true,
      },
      // valueFormatter: (params) => formatDate(params.value),
    },
    {
      headerName: "Under Contract Date",
      field: "opp_under_contract_date",
      valueFormatter: dateFormatter,
      filterParams: {
        comparator: (filterDate, cellValue) => {
          if (!cellValue) return -1;
          const cellDate = new Date(cellValue.substring(0, 4), cellValue.substring(4, 6) - 1, cellValue.substring(6, 8));
          return cellDate.getTime() - filterDate.getTime();
        },
        browserDatePicker: true,
      },
      // valueFormatter: (params) => formatDate(params.value),
    },
    {
      headerName: "Settlement Date",
      field: "opp_settlement_date",
      valueFormatter: dateFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterDate, cellValue) => {
          if (!cellValue) return -1;
          const cellDate = new Date(cellValue.substring(0, 4), cellValue.substring(4, 6) - 1, cellValue.substring(6, 8));
          return cellDate.getTime() - filterDate.getTime();
        },
        browserDatePicker: true,
      },
      // valueFormatter: (params) => formatDate(params.value),
    },
  ];



  // ----- Default state of ag grid------
  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
    floatingFilter: true,
    cellClass: 'ag-cellz',
  };

  // ----- Design each column type------
  const columnTypes = useMemo(() => {
    return {
      agent: {
        minWidth: 240,
        cellStyle: { fontWeight: '500' },
      },
      action: {
        minWidth: 100,
      },
    };
  }, []);

  // ----- to edit the tool and sidebar------
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: false,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
        },
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },



    ],
  };


  const { loading: loadingTypes, error: errorTypes, data: dataTypes } = useQuery(GET_OPPORTUNITY_TYPES);
  const handleDropdownChange = (option) => {
    setSelectedOptions(prevSelectedOptions => {
      if (prevSelectedOptions.includes(option)) {
        // Remove option if already selected
        return prevSelectedOptions.filter(selectedOption => selectedOption !== option);
      } else {
        // Add option if not already selected
        return [...prevSelectedOptions, option];
      }
    });
  };


  const handleClickCommision = (option) => {
    setSelectedCommission(option);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    // Refresh the data when the filter changes
    params.api.addEventListener("filterChanged", handleRefetchClick);
  };


  function CustomGroupCellRenderer(params) {
    return params.value;
  }

  const createChart = () => {
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    const params = {
      cellRange: {
        columns: ['opp_price', 'opp_stage'], // Example columns to include in the chart
      },
      chartType: 'groupedColumn',
      chartContainer: document.querySelector('#chartContainer'),
      processChartOptions: (params) => {
        // Customize chart options here
        return params.options;
      },
    };
    gridApi.createRangeChart(params);
  };

  useEffect(() => {
    const temp = []
    for (const key in checkboxStates) {
      if (checkboxStates[key]) {
        temp.push(key)
      }
    }
    setValueForm(temp)
  }, [checkboxStates])


  const { loading, error, data, refetch } = useQuery(oppsData, {
    variables: {
      filter: {
        opp_stage: getValueForm,
        opp_type: selectedOptions.length ? selectedOptions : ['Buyer','Seller'],
        opp_custom_fields: selectedCommission

      }
    },
  });



  const filteredOpps = useMemo(() => {
    return data?.Opps.filter((opp) =>
      Object.values(opp).some((value) =>
        value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [data, searchTerm]);
  const totalOppPrice = useMemo(() => {
    return filteredOpps?.reduce((accumulator, opp) => {
      if (typeof opp.opp_price === 'number' && !isNaN(opp.opp_price)) {
        return parseFloat((opp.opp_price + accumulator).toFixed(2));
      } else {
        return accumulator;
      }
    }, 0);
  }, [filteredOpps]);

  const handleReset = () => {
    setSelectedOptions(['Buyer','Seller']);
  };


  const totalCgi =

    useMemo(() => {
      return filteredOpps?.reduce((accumulator, opp) => {
        if (typeof opp.opp_price === 'number' && !isNaN(opp.opp_price) &&
          typeof opp.opp_commission_percent === 'number' && !isNaN(opp.opp_commission_percent)) {
          const totalPriceWithCommission = opp.opp_price * (opp.opp_commission_percent);
          return parseFloat((totalPriceWithCommission + accumulator).toFixed(2));
        } else {
          return accumulator;
        }
      }, 0);
    }, [filteredOpps]);


  function currencyConvert(totalOppPrice) {
    if (totalOppPrice >= 1000000000) {
      return (totalOppPrice / 1000000000).toFixed(2) + ' B';
    } else if (totalOppPrice >= 1000000) {
      return (totalOppPrice / 1000000).toFixed(2) + ' M';
    } else {
      return totalOppPrice?.toString();
    }
  }
  // const currentItems = filteredOpps?.slice(offset, offset + ItemsPerPage);



  const handleRefetchClick = useCallback(() => {
    refetch();
    setFilter(true);
    setShowFilter(false);
  }, [refetch, setFilter, setShowFilter]);

  useEffect(() => {
    ""
    console.log("Data", filteredOpps)
    // setOpplist(filteredOpps)
    // setLeaderboard({ ...leaderboard, opplist: filteredOpps })


  }, [data])

  const resetFilters = () => {
    if (gridApi) {
      gridApi.setFilterModel(null);
    }
  };



  console.log("Data", opplist)
  useEffect(() => {

  })
  console.log("??", filter)

  // const preparedData = filteredOpps.map(opp => ({
  //   ...filteredOpps,
  //   custom_commission_point: opp.opp_custom_fields?.custom_commission_point || ''
  // }));

  const preparedData = Array.isArray(filteredOpps) ? filteredOpps.map(opp => ({
    ...opp,
    custom_commission_point: opp.opp_custom_fields?.custom_commission_point || ''
  })) : [];



  return (
    <>
      {/* <OpportunityModal filter={filter} opplist={opplist} /> */}
      {/* <OppsNavBar /> */}
      <div>
        <section className="container-lg container-fluid pt-5">

          <section className="d-flex flex-column py-4 gap-3">
            {showFilter && (

              <div class="modal fade show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                <div class="modal-dialog modald-md w-100 modal-fullscreen-sm-down modal-dialog-scrollable h-100 mt-0 me-0 mb-0">
                  <div class="modal-content rounded-0 h-100">

                    <div class="modal-header px-4 rounded-0"  >
                      <div class="d-flex justify-content-center align-items-center pointer">
                        <i class='iconify fs-5 me-2' data-icon="mdi:filter"></i>
                        <h5 class="modal-title fs-6">Filter</h5>
                      </div>

                      <button type="button" class="btn-close small" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowFilter(false)}></button>
                    </div>


                    <div class="modal-body p-0">

                      <div class="accordion opp-accordion pb-5" id="accordionPanelsStayOpenExample">

                        {/* commisoin point */}
                        <div class="accordion-item rounded-0 border-top-0">
                          <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                            <button class="accordion-button rounded-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo">
                              Commision Point
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
                            <div className="accordion-body">
                              <div>
                                <span
                                  className={`rounded-pill ${selectedCommission === 'Yes' ? 'bg-primary text-white' : 'border border-primary text-primary'} px-3 py-1 small pointer`}
                                  onClick={() => handleClickCommision('Yes')}
                                >
                                  Yes
                                </span>
                                <span
                                  className={`rounded-pill ${selectedCommission === 'No' ? 'bg-primary text-white' : 'border border-primary text-primary'} px-3 py-1 small pointer`}
                                  onClick={() => handleClickCommision('No')}
                                >
                                  No
                                </span>
                              </div>
                            </div>
                          </div>

                        </div>
                        {/* commision point */}






                      </div>

                    </div>


                    <div class="modal-footer">

                      <button type="button" class="btn btn-primary-outline" data-dismiss="modal" onClick={() => setShowFilter(false)}>Close</button>
                      <button type="button" class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleRefetchClick()}>Save changes</button>
                    </div>

                  </div>
                </div>
              </div>
            )}

            <h5 class="m-0 fs-5 fw-bold">Notes</h5>
            <p class="text-secondary">View and edit all your notes emails and customize your data.</p>
            
            <div className="d-md-flex align-items-center">

              <div className="col-md-5 col-lg-5 col-xxl-4">
                <label className="small mb-2">Search</label>
                <input
                  className="form-control input-bn search-fgy mb-3"
                  placeholder="search opportunities"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 px-4">
                  <label className="small mb-2 ">Opportunity Type</label>
                  <div className="input-group mb-3">
                    <a
                      className="input-bn search-fgy d-flex justify-content-between align-items-center dropdown-toggle bg-white px-3 w-100"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {selectedOptions.length ? selectedOptions.join(' | ') : 'Buyer | Seller'}
                    </a>
                    <ul className="dropdown-menu w-100">
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleReset();
                          }}
                        >
                          <div className="form-check">
                            <label className="form-check-label" htmlFor="reset-selection">
                              Reset
                            </label>
                          </div>
                        </a>
                      </li>
                      {dataTypes?.opportunityTypeByTeam.map((type, index) => (
                        <li key={index}>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleDropdownChange(type.opp_type);
                            }}
                          >
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={type.opp_type}
                                checked={selectedOptions.includes(type.opp_type)}
                                onChange={() => {}}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`buyers-and-sellers-${index}`}
                              >
                                {type.opp_type}
                              </label>
                            </div>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
              </div>


            </div>
            {/* /test */}

            {/* test */}

            {/* modal */}

            <div class="row row-cols-3 py-3">

              <div>
                <div class="bg-white border rounded-3 py-3 px-4 text-center">

                  <span class="text-primary fw-bold">Counts</span>
                  <h2>{filteredOpps?.length}</h2>
                  <span class="small text-secondary">{filteredOpps?.length} after split Counts</span>

                </div>
              </div>

              <div>
                <div class="bg-white border rounded-3 py-3 px-4 text-center">

                  <span class="text-primary fw-bold">Total Volume</span>
                  <h2>$ {currencyConvert(totalOppPrice)}</h2>
                  <span class="small text-secondary">{totalOppPrice?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>

                </div>
              </div>

              <div>
                <div class="bg-white border rounded-3 py-3 px-4 text-center">

                  <span class="text-primary fw-bold">Total GCI</span>
                  <h2>$ {currencyConvert(totalCgi)}</h2>
                  <span class="small text-secondary">{totalCgi?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>

                </div>
              </div>
            </div>
            {/* <div>
              <a className="btn btn-sm btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {selectedOptions.length ? selectedOptions.join(' | ') : 'Buyer | Seller'}
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#" onClick={(e) => {
                    e.preventDefault();
                    handleReset();
                  }}>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="reset-selection">
                        Reset
                      </label>
                    </div>
                  </a>
                </li>
                {dataTypes?.opportunityTypeByTeam.map((type, index) => (
                  <li key={index}>
                    <a className="dropdown-item" href="#" onClick={(e) => {
                      e.preventDefault();
                      handleDropdownChange(type.opp_type);
                    }}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={type.opp_type}
                          checked={selectedOptions.includes(type.opp_type)}
                          onChange={() => { }}
                        />
                        <label className="form-check-label" htmlFor={`buyers-and-sellers-${index}`}>
                          {type.opp_type}
                        </label>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div> */}


            <div className="w-100">
              <section className="w-100 mb-5 px-0">
                <button onClick={resetFilters}>Reset Filters</button>
                <div className="ag-theme-quartz" style={{ height: 700 }}>
                  <AgGridReact
                    rowData={preparedData}
                    columnDefs={columnDefs}
                    columnTypes={columnTypes}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    modules={[GridChartsModule]}
                    frameworkComponents={{
                      agGroupCellRenderer: CustomGroupCellRenderer,
                    }}
                    sideBar={sideBar}
                    enableRangeSelection={true}
                    enableCharts={true}
                    headerHeight={40}
                    rowHeight={30}
                    rowSelection={"multiple"}
                    rowGroupPanelShow={"always"}
                    pagination={true}
                    paginationPageSizeSelector={[50, 100, 200]}
                    paginationPageSize={50}
                    tooltipShowDelay={500}
                    tooltipHideDelay={500}
                  />
                </div>
              </section>
              {/* <button onClick={createChart}>Create Chart</button> */}
              {/* <div id="chartContainer" style={{ width: '100%', height: '500px' }}></div> */}
            </div>

          </section>
          {/* Modal */}


        </section>
      </div>

    </>
  );
}
