import React, { useEffect, useContext,useState ,useRef} from 'react'
import { useParams } from 'react-router-dom'
import useFormApiHelper from '../../../utils/useFormApiHelper'
import { Loaderr } from '../../../components/Loaderr';
import { gql,useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { boardConetxt } from './ContextHelper';

const getFubData = gql`
query GetFubAllData($type: String!) {
  GetFubAllData(type: $type) {
    output
    status
    info
  }
}`
const UpdateFormSettingss = gql`
mutation UpdateFormSettings($selectDispForm: String, $dispSchedHour: String, $formId: String, $formDescription: String, $pipeline: String, $stage: String, $isThisApptForm: String, $status: Boolean, $dispText: String, $dispSchedMinutes: String, $dispReminderHour: String, $dispReminderMinutes: String, $makeReminderRec: Boolean) {
  UpdateFormSettings(select_disp_form: $selectDispForm, disp_sched_hour: $dispSchedHour, form_id: $formId, form_description: $formDescription, pipeline: $pipeline, stage: $stage, is_this_appt_form: $isThisApptForm, status: $status, disp_text: $dispText, disp_sched_minutes: $dispSchedMinutes, disp_reminder_hour: $dispReminderHour, disp_reminder_minutes: $dispReminderMinutes, make_reminder_rec: $makeReminderRec) {
    form_id
    team_id
  }
}`
const GetForms=gql`
query GetDispForms {
  getDispForms {
    form_name
    form_id
  }
}`
export default function SettingsPage() {
  const { GetFormData, UpdateForm, UpdateFormSettings} = useFormApiHelper();
  const [selectedFields, setSelectedFields] = useState([]);
  const [showScheduleReminder, setShowScheduleReminder] = useState(false);
  const [disposition_text, Setdisposition_text] = useState('');
  const [formtype, setFormType] = useState('');

  const [ disp_reminder_time, Set_disp_reminder_time] = useState({
    hours: '',
    minutes: ''
  }); // State for storing disp_reminder_time
  const [disp_sched_time, Set_disp_sched_time] = useState({
    hours: '',
    minutes: ''
  }); // State for storing disp_sched_time
  const [isRecurring, setIsRecurring] = useState(false);

  const { board } = useContext(boardConetxt);
  // const { FormFieldsData, refetch } = GetFormFields();
  // const { updateFormSetting } = UpdateFormSettings();
  const [pipelineInteracted, setPipelineInteracted] = React.useState(false);
  const [stageInteracted, setStageInteracted] = React.useState(false);
  const [
    updateFormSetting, {
      loading: updateFormSettingsLoading,
      error: updateFormSettingsError,
      data: updateFormSettingsData,
    }] = useMutation(UpdateFormSettingss, {
      onCompleted: (data) => {
        console.log("data", data);
      },
    });
  const params = useParams();
  const { formDataLoading, formData } = GetFormData(params);
  const [description, setDescription] = React.useState("")
  const [FormEntryData, setFormEntryData] = React.useState({
    "pipelineName": "",
    "StageName": "",
    "Description": "",
   "Appointmentform":"",
   "selectDispForm":""
  });
  const { data: fubData } = useQuery(getFubData, {
    variables: {
      type: "pipelines"
    },

  })
  const { data: GetFormsData } = useQuery(GetForms);
  console.log("GetFormsData", GetFormsData)

  const [status] = React.useState(null)
  console.log("fubData", fubData,FormEntryData);
  const inputHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "pipelineName") setPipelineInteracted(true);
    if (name === "StageName") setStageInteracted(true);
    console.log("name>", name, value,FormEntryData)
    if(name === "pipelineName"){
      fubData?.GetFubAllData?.output.map((item, index) => {
        if (item.id == value) {
          setFormEntryData((prev) => ({
            ...prev,
            "pipelineName": value,
            "StageName": String(item.stages[0].id)
          }))
        }
      }
      )
    }

    setFormEntryData((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  const {  updateFormLoading} = UpdateForm();
console.log("FormEntryData>", FormEntryData)

  const prevValues = useRef({});
  const hasMounted = useRef(false);

  useEffect(() => {
    if (!hasMounted.current) {
      // Skip the first render
      hasMounted.current = true;
      return;
    }
  
    // Check if any of the values have changed
    const hasChanged =
      prevValues.current.description !== description ||
      prevValues.current.pipeline !== FormEntryData?.pipelineName ||
      prevValues.current.stage !== FormEntryData?.StageName ||
      prevValues.current.status !== formData?.formData?.status ||
      prevValues.current.isThisApptForm !== FormEntryData?.Appointmentform ||
      prevValues.current.dispText !== disposition_text ||
      prevValues.current.dispSchedHour !== disp_sched_time.hours ||
      prevValues.current.dispSchedMinutes !== disp_sched_time.minutes ||
      prevValues.current.dispReminderHour !== disp_reminder_time.hours ||
      prevValues.current.dispReminderMinutes !== disp_reminder_time.minutes ||
      prevValues.current.makeReminderRec !== isRecurring ||
      prevValues.current.selectDispForm !== FormEntryData?.selectDispForm ;
  
    if (hasChanged) {
      console.log("Value changed, calling updateFormSetting...");
  
      updateFormSetting({
        variables: {
          formId: params.id,
          formDescription: description,
          pipeline: FormEntryData?.pipelineName,
          stage: FormEntryData?.StageName,
          status: formData?.formData?.status,
          isThisApptForm: FormEntryData?.Appointmentform,
          dispText: disposition_text,
          dispSchedHour: disp_sched_time.hours,
          dispSchedMinutes: disp_sched_time.minutes,
          dispReminderHour: disp_reminder_time.hours,
          dispReminderMinutes: disp_reminder_time.minutes,
          makeReminderRec: isRecurring,
          selectDispForm: FormEntryData?.selectDispForm

        },
      });
  
      // Update previous values to current ones
      prevValues.current = {
        description,
        pipeline: FormEntryData?.pipelineName,
        stage: FormEntryData?.StageName,
        status: formData?.formData?.status,
        isThisApptForm: FormEntryData?.Appointmentform,
        dispText: disposition_text,
        dispSchedHour: disp_sched_time.hours,
        dispSchedMinutes: disp_sched_time.minutes,
        dispReminderHour: disp_reminder_time.hours,
        dispReminderMinutes: disp_reminder_time.minutes,
        makeReminderRec: isRecurring,
        selectDispForm: FormEntryData?.selectDispForm
      };
    }
  }, [
    description,
    FormEntryData?.pipelineName,
    FormEntryData?.StageName,
    formData?.formData?.status,
    FormEntryData?.Appointmentform,
    disposition_text,
    disp_sched_time.hours,
    disp_sched_time.minutes,
    disp_reminder_time.hours,
    disp_reminder_time.minutes,
    isRecurring,
    FormEntryData?.selectDispForm
  ]);
  // useEffect(() => {
  //   console.log(">><<", FormEntryData?.Appointmentform)
  //   // if (pipelineInteracted || stageInteracted ) {
  //     updateFormSetting({
  //       variables: {
  
      
  //           formId: params.id,
  //           formDescription: description,
  //           pipeline: FormEntryData?.pipelineName,
  //           stage: FormEntryData?.StageName,
  //           status: formData?.formData?.status,
  //           isThisApptForm: FormEntryData?.Appointmentform,
  //           dispText: disposition_text,
  //           dispSchedHour: disp_sched_time.hours,
  //           dispSchedMinutes: disp_sched_time.minutes,
  //           dispReminderHour: disp_reminder_time.hours,
  //           dispReminderMinutes: disp_reminder_time.minutes,
  //           makeReminderRec: isRecurring 
  //       }
  //     })
  //   // }
  // }, [ description, pipelineInteracted, stageInteracted, disposition_text, disp_sched_time, disp_reminder_time, isRecurring,FormEntryData?.Appointmentform])

  console.log(">>>", formData?.formData?.is_this_appt_form)
const handleDescription=(e)=>{
  setDescription(e.target.value)

}
 useEffect(()=>{
// if(description){
  if (!description && formData?.formData?.form_description) {
    setDescription(formData.formData.form_description);
  }
  setFormEntryData((prev)=>({
    ...prev,
    "pipelineName":formData?.formData?.pipeline,
    "StageName": formData?.formData?.stage,
    "Appointmentform":formData?.formData?.is_this_appt_form,
    "selectDispForm":formData?.formData?.select_disp_form
  }))
  Setdisposition_text(formData?.formData?.disp_text)
  Set_disp_sched_time({
    hours: formData?.formData?.disp_sched_hour,
    minutes: formData?.formData?.disp_sched_minutes
  })
  Set_disp_reminder_time({
    hours: formData?.formData?.disp_reminder_hour,
    minutes: formData?.formData?.disp_reminder_minutes
  })
  setIsRecurring(formData?.formData?.make_reminder_rec)
  setFormType(formData?.formData?.form_type)

 },[formData,description])
 console.log("FormEntryData??", FormEntryData)
 const handleFieldChange = (e, item) => {
  const isChecked = e.target.checked;

  if (isChecked) {
    // Add the field to the selectedFields array
    setSelectedFields([...selectedFields, item.elementid]);
  } else {
    // Remove the field from the selectedFields array
    setSelectedFields(
      selectedFields.filter((fieldName) => fieldName !== item.elementid)
    );
  }
};

  
  console.log("FormEntryData", description,formData?.formData)
  let SaveStatus;
  if (updateFormLoading) {
    SaveStatus = (
      <span
        className="text-secondary d-flex align-items-center"
        style={{ fontSize: "11px" }}
      >
        {" "}
        <i className="bx bx-loader-circle fs-4"></i>{" "}
        <span className="d-none d-lg-block">Saving</span>
      </span>
    );
  } else {
    SaveStatus = (
      <span
        className="d-flex align-items-center"
        style={{ fontSize: "11px", color: "green" }}
      >
        <i className="bx bx-check-double fs-4"></i>{" "}
        <span className="d-none d-lg-block">Form Saved</span>
      </span>
    );
  }

  // Toggle visibility of the schedule reminder
  const toggleScheduleReminder = () => {
    setShowScheduleReminder(prevState => !prevState);
  };

  // Handle deleting the schedule reminder
  const deleteScheduleReminder = () => {
    // Here you can add additional logic for deletion (like API calls or confirmation)
    setShowScheduleReminder(false);
    Set_disp_reminder_time({
      hours: '',
      minutes: ''
    });
  };

  // const handleReminderHoursChange = (e) => {
  //   setReminderHours(e.target.value);
  // };

  // const handleReminderMinutesChange = (e) => {
  //   setReminderMinutes(e.target.value);
  // };
  const handleDispotionTextChange = (e) => {
    Setdisposition_text(e.target.value);
  };


const handleDispotionScheduleChange = (e) => {
  const value = e.target.value;
  // Allow only 1-2 digits
  const name = e.target.name;
  if (/^\d{0,2}$/.test(value) && parseInt(value) <= 99) {
    Set_disp_sched_time({ ...disp_sched_time, [name]: value });
  }

}
const handleDispositionReminderChange=(e)=>{
  const value = e.target.value;
  // Allow only 1-2 digits
  const name = e.target.name;
  if (/^\d{0,2}$/.test(value) && parseInt(value) <= 99) {
    Set_disp_reminder_time({ ...disp_reminder_time, [name]: value });
  }
}

const handleRecurringChange = (e) => {
  setIsRecurring(e.target.checked);
};

console.log("Hello",isRecurring,disp_reminder_time,disp_sched_time)
  const AppointmentFieldValue=[{
    id: 1, name: "Yes appointment form",value:"yes_appt_form"
  },
  {
    id: 2, name: "Yes appointment disposition form",value:"yes_disp_form"
  },
  {
    id: 3, name: "No",value:"no"
  }]

  if (formDataLoading) return <Loaderr />
  return (
    <>
      <div className="main fmc-main">
        <section className="fm-condition">
          <section className="col-xxl-5 col-lg-6 col-md-9 mb-5 pb-5 col-12 mx-auto">
            <div className="d-flex flex-wrap justify-content-between align-items-center mb-2 py-4">
              <h5 className="m-0">
                <i className="iconify me-1 nav_icon" data-icon="fa-solid:cog" />{" "}
                Settings
              </h5>
            </div>
            {/* -------------------------------------------------------------- */}
            <div className="mx-auto bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-5 p-4 ">
              <div className="w-100 d-flex justify-content-between m-0 mb-3">
              <h5 className="fs-6 m-0 mb-4">Form Settings</h5>
          <span>{board?.length >= 0 ? SaveStatus : ""}</span>
        </div>
              <div className="mb-3 disable-default-field-div">
                <label className="mb-2 d-flex align-items-center">
                  <i
                    className="iconify fs-6 me-2 nav_icon"
                    data-icon="tdesign:form"
                  />
                  Form Type
                </label>
                <select className="form-control form-select input-bn search-fgy mb-3 disable-default-field">
                  <option >{formData?.formData?.form_type}</option>
                </select>
              </div>
              <div className="mb-3">
              <label>
                    <span className="d-flex align-items-center">
                      <i
                        className="iconify me-1 mr-1 fs-5"
                        data-icon="mdi:text"
                      />
                      <span>Form Description</span>
                    </span>
                  </label>
                <div className=" mb-3">
                  <textarea
                    type="text"
                    className="form-control input-bn"
                    id="floatingInput"
                    name='Description'
                    placeholder="Form Description"
                    value={description} 
                    onChange={(e)=>{handleDescription(e)}}
                  />
                </div>
              </div>
              {formData?.formData?.form_type == "Opportunity Forms" && <div className="mb-3">
                <label className="mb-2 d-flex align-items-center">
                  <i
                    className="iconify fs-6 me-2 nav_icon"
                    data-icon="fa6-solid:house-user"
                  />
                  Select Pipeline
                </label>
                <select className="form-control form-select input-bn search-fgy mb-3"
                  onChange={inputHandler}
                  name="pipelineName"
                value={FormEntryData?.pipelineName}
                >
                  <option value="" disabled selected hidden>Choose</option>
                  {fubData?.GetFubAllData?.output?.map((item, index) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))


                  }

                </select>
              </div>}
       {formData?.formData?.form_type == "Opportunity Forms" && <div className="mb-3">
                <label className="mb-2 d-flex align-items-center">
                  <i
                    className="iconify fs-6 me-2 nav_icon"
                    data-icon="fa6-solid:bars-staggered"
                  />
                  Select stage
                </label>
                <select className="form-control form-select input-bn search-fgy mb-3"
                  onChange={inputHandler}
                  name="StageName"
                value={FormEntryData?.StageName}
                >
                  <option value="" disabled selected hidden>Choose</option>
                  {

                    fubData?.GetFubAllData?.output.map((item, index) => {
                      console.log("C", FormEntryData?.pipelineName,item.id)
                      if (item.id == FormEntryData?.pipelineName) {
                        return (
                          item.stages.map((items, index) => {
                            return (
                              <option key={items.id} value={items.id}>{items.name}</option>
                            )
                          })
                        )
                      }
                    }
                    )
                  }

            
                </select>


                
              </div>}
             {formData?.formData?.form_type == "Opportunity Forms" && <div className="mb-4">
                      <div className="form-floating w-100 form-group">
                        <select
                          className="form-select input-bn"
                          placeholder="Please Select"
                          //onChange={inputHandler}
                          onChange={inputHandler}
                          name="Appointmentform"
                          value={FormEntryData?.Appointmentform}
                        >
                          <option value="" disabled hidden>Choose</option>
                    
                          {
                            AppointmentFieldValue.map((item, index) => {
                              return (
                                <option key={item.id} value={item.value}>{item.name}</option>
                              )
                            }
                            )
                          }



                        </select>



                        <label>
                          <span className="d-flex align-items-center">
                            <i
                              className="iconify me-1 mr-1 fs-5"
                              data-icon="mdi:text"
                            />
                            <span>Is this is an appointment form ?</span>
                          </span>
                        </label>

                      </div>
                    </div>}
                  
                    {FormEntryData?.Appointmentform == "yes_appt_form" && <div className="mb-4">
                      <div className="form-floating w-100 form-group">
                        <select
                          className="form-select input-bn"
                          placeholder="Please Select"
                          onChange={inputHandler}
                          name="selectDispForm"
                          value={FormEntryData?.selectDispForm}
                        >
                    <option value="" disabled selected hidden>Choose</option>
                    
                          {
                            GetFormsData?.getDispForms.map((item, index) => {
                              return (
                                <option key={item.form_id} value={item.form_id}>{item.form_name}</option>
                              )
                            }
                            )
                          }



                        </select>



                        <label>
                          <span className="d-flex align-items-center">
                            <i
                              className="iconify me-1 mr-1 fs-5"
                              data-icon="mdi:text"
                            />
                            <span>Select Disposition form</span>
                          </span>
                        </label>

                      </div>
                    </div>}

          

            </div>

        {
           formtype == "Opportunity Forms" && FormEntryData?.Appointmentform == "yes_disp_form" && (
            <div className="mx-auto bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-5 p-4">
            <div className="w-100 d-flex flex-column m-0 mb-4 pb-2 border-bottom">
              <h5 className="fs-6 m-0 mb-2">Disposition Settings</h5>
              <p className='small text-secondary'>Set and schedule disposition text sent to your agents.</p>
            </div>
          
            <div className="mb-4 dispo-text">
              <label className='mb-2'>
                <span>Disposition Text</span>
              </label>
              <div className='pre-dispo'>
                <p className='m-0'>Hey! This is the link to your Disposition Form: URL for your appointment ID: with contact_name</p>
              </div>
          
              <textarea
                type="text"
                className="form-control input-bn"
                id="floatingInput"
                name='Description'
                value={disposition_text}
                onChange={handleDispotionTextChange}
                placeholder="“Your text can go here”"
                maxLength={150}
              ></textarea>
            </div>
          
            <div className="w-100 d-flex flex-column m-0">
              <h5 className="fs-6 m-0 mb-2">
                <i className="iconify fs-6 me-2 nav_icon" data-icon="fa6-solid:clock"></i>
                Schedule timer
              </h5>
              <p className='small text-secondary'>The disposition text will be sent based on the timer you set, with the countdown starting once the appointment begins.</p>
          
              <div className='d-flex flex-wrap gap-3'>
                <div className="mb-3 col-lg-4 col">
                  <input
                    type="text"
                    className="form-control input-bn mb-3"
                    placeholder="Hours"
                    name='hours'
                    value={disp_sched_time.hours}
                    onChange={(e)=>{handleDispotionScheduleChange(e)}}
                    maxLength="2" // Limit input to 2 digits
                  />
                </div>
                <div className="mb-3 col-lg-4 col">
                  <input
                    type="text"
                    className="form-control input-bn mb-3"
                    placeholder="Minutes"
                    name='minutes'
                    value={disp_sched_time.minutes}
                    onChange={(e)=>{handleDispotionScheduleChange(e)}}
                    maxLength="2" // Limit input to 2 digits
                  />
                </div>
              </div>
          
              {/* Button to schedule the timer */}
            </div>
          
            <hr className='m-0 mb-4' />
          
            {/* Add Schedule Reminder Button */}
            {!showScheduleReminder && (
              <button className="btn btn-primary mb-3" onClick={toggleScheduleReminder}>
                Add Schedule Reminder
              </button>
            )}
          
            {/* Schedule Reminder Section */}
            {showScheduleReminder && (
              <div className="w-100 d-flex flex-column m-0">
                <div className='d-flex justify-content-between'>
                  <h5 className="fs-6 m-0 mb-2">
                    <i className="iconify fs-6 me-2 nav_icon" data-icon="fa6-solid:clock"></i>
                    Schedule reminder
                  </h5>
                  {/* Trash Icon to delete the reminder */}
                  <span className="" onClick={deleteScheduleReminder}>
                    <i className="iconify fs-6 me-2 nav_icon text-danger" data-icon="fa6-solid:trash" ></i>
                  </span>
                </div>
          
                <p className='small text-secondary'>To be sent if disposition form has not been filled even after disposition text has been sent.</p>
          
                <div className='d-flex flex-wrap gap-3'>
                  <div className="mb-3 col-lg-4 col">
                    <input
                      type="text"
                      className="form-control input-bn mb-3"
                      placeholder="Hoursreminder"
                      name='hours'
                      value={disp_reminder_time.hours}
                      onChange={(e)=>{handleDispositionReminderChange(e)}}
                      maxLength="2" // Limit input to 2 digits
                    />
                  </div>
                  <div className="mb-3 col-lg-4 col">
                    <input
                      type="text"
                      className="form-control input-bn mb-3"
                      placeholder="Minutes"
                      name='minutes'
                      value={disp_reminder_time.minutes}
                      onChange={(e)=>{handleDispositionReminderChange(e)}}
                      maxLength="2" // Limit input to 2 digits
                    />
                  </div>
                </div>
          
                <div className="form-check">
                  <input className="form-check-input" type="checkbox"     value={isRecurring} checked={isRecurring}
           id="flexCheckDefault"   onChange={handleRecurringChange}  />
                  <label className="form-check-label small" htmlFor="flexCheckDefault">
                    Make reminder recurring
                  </label>
                </div>
              </div>
            )}
          </div>
          )
        }


            
           

           


          
          </section>
        </section>
      </div>

    </>

  )
}
