export const AppointmentFormBuyer=[
    {
        "elementid": "InputField-opp_last_name",
        "type": "InputField",
        "name": "Last Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Last Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-Appointment-Set-By",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "0033",
                    "value": "ISA",
                    "isCheck": false
                },
                {
                    "id": "1490",
                    "value": "OSA",
                    "isCheck": false
                },
                {
                    "id": "8597",
                    "value": "Admin",
                    "isCheck": false
                }
            ],
            "label": "Appointment Set By",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appointment Set By",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-opp_isa",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "3135",
                    "value": "Daniel Poston",
                    "isCheck": false
                },
                {
                    "id": "0245",
                    "value": "Deeksha T",
                    "isCheck": false
                }
            ],
            "label": "ISAs",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "ISAs",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-Admin",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": 0,
                    "value": "MJ Kim",
                    "isCheck": false
                },
                {
                    "id": 1,
                    "value": "Sai Kiran",
                    "isCheck": false
                },
                {
                    "id": 2,
                    "value": "Viral S",
                    "isCheck": false
                },
                {
                    "id": 3,
                    "value": "Abhishek Yadav",
                    "isCheck": false
                },
                {
                    "id": 4,
                    "value": "Varad More",
                    "isCheck": false
                },
                {
                    "id": 5,
                    "value": "Mihir Lodhavia",
                    "isCheck": false
                },
                {
                    "id": 6,
                    "value": "Aleksandra Stojanovic",
                    "isCheck": false
                },
                {
                    "id": 7,
                    "value": "Suraj Mahangade",
                    "isCheck": false
                },
                {
                    "id": 8,
                    "value": "Daniel Poston",
                    "isCheck": false
                }
            ],
            "label": "Admin",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Admin",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-did_you_schedule_an_appointment?",
        "name": "Did you schedule an appointment?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1359",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "2357",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Did you schedule an appointment?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Headings-Appointment_Details",
        "type": "Headings",
        "settings": {
            "title": "Appointment Details",
            "size": "left",
            "inputType": "text"
        },
        "name": "Appointment Details"
    },
    {
        "elementid": "Date-opp_appt_date",
        "type": "Date",
        "name": "Appointment Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Appointment Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-appointment_time",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2631",
                    "value": "07:30 AM",
                    "isCheck": false
                },
                {
                    "id": "8298",
                    "value": "07:45 AM",
                    "isCheck": false
                },
                {
                    "id": "9673",
                    "value": "08:00 AM",
                    "isCheck": false
                },
                {
                    "id": "1180",
                    "value": "08:15 AM",
                    "isCheck": false
                },
                {
                    "id": "9161",
                    "value": "08:30 AM",
                    "isCheck": false
                },
                {
                    "id": "2920",
                    "value": "08:45 AM",
                    "isCheck": false
                },
                {
                    "id": "2854",
                    "value": "09:00 AM",
                    "isCheck": false
                },
                {
                    "id": "5796",
                    "value": "09:15 AM",
                    "isCheck": false
                },
                {
                    "id": "2269",
                    "value": "09:30 AM",
                    "isCheck": false
                },
                {
                    "id": "7865",
                    "value": "09:45 AM",
                    "isCheck": false
                },
                {
                    "id": "8321",
                    "value": "10:00 AM",
                    "isCheck": false
                },
                {
                    "id": "1097",
                    "value": "10:15 AM",
                    "isCheck": false
                },
                {
                    "id": "4610",
                    "value": "10:30 AM",
                    "isCheck": false
                },
                {
                    "id": "6448",
                    "value": "10:45 AM",
                    "isCheck": false
                },
                {
                    "id": "2773",
                    "value": "11:00 AM",
                    "isCheck": false
                },
                {
                    "id": "7103",
                    "value": "11:15 AM",
                    "isCheck": false
                },
                {
                    "id": "7926",
                    "value": "11:30 AM",
                    "isCheck": false
                },
                {
                    "id": "6207",
                    "value": "11:45 AM",
                    "isCheck": false
                },
                {
                    "id": "3145",
                    "value": "12:00 PM",
                    "isCheck": false
                },
                {
                    "id": "9780",
                    "value": "12:15 PM",
                    "isCheck": false
                },
                {
                    "id": "8225",
                    "value": "12:30 PM",
                    "isCheck": false
                },
                {
                    "id": "7180",
                    "value": "12:45 PM",
                    "isCheck": false
                },
                {
                    "id": "1397",
                    "value": "01:00 PM",
                    "isCheck": false
                },
                {
                    "id": "1156",
                    "value": "01:15 PM",
                    "isCheck": false
                },
                {
                    "id": "1314",
                    "value": "01:30 PM",
                    "isCheck": false
                },
                {
                    "id": "9429",
                    "value": "01:45 PM",
                    "isCheck": false
                },
                {
                    "id": "5830",
                    "value": "02:00 PM",
                    "isCheck": false
                },
                {
                    "id": "0137",
                    "value": "02:15 PM",
                    "isCheck": false
                },
                {
                    "id": "8106",
                    "value": "02:30 PM",
                    "isCheck": false
                },
                {
                    "id": "7518",
                    "value": "02:45 PM",
                    "isCheck": false
                },
                {
                    "id": "4236",
                    "value": "03:00 PM",
                    "isCheck": false
                },
                {
                    "id": "6496",
                    "value": "03:15 PM",
                    "isCheck": false
                },
                {
                    "id": "8654",
                    "value": "03:30 PM",
                    "isCheck": false
                },
                {
                    "id": "4531",
                    "value": "03:45 PM",
                    "isCheck": false
                },
                {
                    "id": "2566",
                    "value": "04:00 PM",
                    "isCheck": false
                },
                {
                    "id": "9237",
                    "value": "04:15 PM",
                    "isCheck": false
                },
                {
                    "id": "7183",
                    "value": "04:30 PM",
                    "isCheck": false
                },
                {
                    "id": "1165",
                    "value": "04:45 PM",
                    "isCheck": false
                },
                {
                    "id": "5182",
                    "value": "05:00 PM",
                    "isCheck": false
                },
                {
                    "id": "8351",
                    "value": "05:15 PM",
                    "isCheck": false
                },
                {
                    "id": "4363",
                    "value": "05:30 PM",
                    "isCheck": false
                },
                {
                    "id": "6599",
                    "value": "05:45 PM",
                    "isCheck": false
                },
                {
                    "id": "5660",
                    "value": "06:00 PM",
                    "isCheck": false
                },
                {
                    "id": "3808",
                    "value": "06:15 PM",
                    "isCheck": false
                },
                {
                    "id": "6672",
                    "value": "06:30 PM",
                    "isCheck": false
                },
                {
                    "id": "9783",
                    "value": "06:45 PM",
                    "isCheck": false
                },
                {
                    "id": "6459",
                    "value": "07:00 PM",
                    "isCheck": false
                },
                {
                    "id": "6671",
                    "value": "07:15 PM",
                    "isCheck": false
                },
                {
                    "id": "3639",
                    "value": "07:30 PM",
                    "isCheck": false
                },
                {
                    "id": "6921",
                    "value": "07:45 PM",
                    "isCheck": false
                },
                {
                    "id": "8969",
                    "value": "08:00 PM",
                    "isCheck": false
                },
                {
                    "id": "6555",
                    "value": "08:15 PM",
                    "isCheck": false
                },
                {
                    "id": "1155",
                    "value": "08:30 PM",
                    "isCheck": false
                }
            ],
            "label": "Appointment Time",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appointment Time",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-Appt-Location",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4864",
                    "value": "Office",
                    "isCheck": false
                },
                {
                    "id": "6480",
                    "value": "Other Address",
                    "isCheck": false
                },
                {
                    "id": "6319",
                    "value": "Seller Property Address",
                    "isCheck": false
                },
                {
                    "id": "7533",
                    "value": "Buyer Inquiry Address",
                    "isCheck": false
                },
                {
                    "id": "5931",
                    "value": "Zoom",
                    "isCheck": false
                },
                {
                    "id": "8067",
                    "value": "Phone",
                    "isCheck": false
                }
            ],
            "label": "Appointment Location",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appointment Location",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-appointment_type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2003",
                    "value": "No Type",
                    "isCheck": false
                },
                {
                    "id": "3602",
                    "value": "Buyer consultation",
                    "isCheck": false
                },
                {
                    "id": "3727",
                    "value": "Listing",
                    "isCheck": false
                },
                {
                    "id": "8100",
                    "value": "Showing",
                    "isCheck": false
                }
            ],
            "label": "Appointment Type",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appointment Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-zoom-link",
        "type": "InputField",
        "name": "Zoom Link",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Zoom Link",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "FreeText-address",
        "type": "FreeText",
        "name": "Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address",
        "type": "InputField",
        "name": "Address of Appointment-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-Street Address",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address2",
        "type": "InputField",
        "name": "Address of Appointment-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_city",
        "type": "InputField",
        "name": "Address of Appointment-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-City",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_state",
        "type": "InputField",
        "name": "Address of Appointment-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-State",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_postal_code",
        "type": "InputField",
        "name": "Address of Appointment-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-Zip Code",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-opp_country",
        "type": "DropDown",
        "field_sub_type": "opportunity",
        "name": "Address of Appointment-Country",
        "settings": {
            "options": [
                {
                    "id": 25545,
                    "value": "USA",
                    "isCheck": false
                },
                {
                    "id": 2551,
                    "value": "Canada",
                    "isCheck": false
                }
            ],
            "label": "Country",
            "size": "large",
            "inputType": "dropdown",
            "required": false,
            "hidden": false,
            "minValue": "",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "TextArea-App-Description",
        "type": "TextArea",
        "name": "Appointment Description",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Appointment Description",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "This field will be added to the appointment description and the client will be able to read it."
        }
    },
    {
        "elementid": "Headings-Buyer_Section_Appt_Form",
        "type": "Headings",
        "settings": {
            "title": "Buyer Section",
            "size": "left",
            "inputType": "text"
        },
        "name": "Buyer Section"
    },
    {
        "elementid": "DropDown-do_you_currently_rent_or_own_your_own_home?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4688",
                    "value": "Rent",
                    "isCheck": false
                },
                {
                    "id": "2923",
                    "value": "Own",
                    "isCheck": false
                }
            ],
            "label": "Do you currently rent or own your own home?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Do you currently rent or own your own home?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-when_does_your_lease_end?",
        "type": "InputField",
        "name": "When does your lease end?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "When does your lease end?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-home_search_price_low",
        "type": "NumberField",
        "name": "Home Search Price Low",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Home Search Price Low",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-home_search_price_high",
        "type": "NumberField",
        "name": "Home Search Price High",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Home Search Price High",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-have_you_been_pre_approved_by_a_mortgage_company?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "5145",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "7229",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Have you been pre-approved by a mortgage company?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Have you been pre-approved by a mortgage company?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "FreeText-inquiry_address",
        "type": "FreeText",
        "name": "Inquiry Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-inquiry_address_street_address",
        "type": "InputField",
        "name": "Inquiry Address-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address-Street Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-inquiry_address_address_line_2",
        "type": "InputField",
        "name": "Inquiry Address-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-inquiry_address_city",
        "type": "InputField",
        "name": "Inquiry Address-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address-City",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-inquiry_address_state",
        "type": "InputField",
        "name": "Inquiry Address-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address-State",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-inquiry_address_zip_code",
        "type": "InputField",
        "name": "Inquiry Address-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address-Zip Code",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-inquiry_address_country",
        "type": "DropDown",
        "name": "Inquiry Address-Country",
        "field_sub_type": "opportunity",
        "settings": {
            "options": [
                {
                    "id": 25545,
                    "value": "USA",
                    "isCheck": false
                },
                {
                    "id": 2551,
                    "value": "Canada",
                    "isCheck": false
                }
            ],
            "label": "Inquiry Address-Country",
            "size": "large",
            "inputType": "dropdown",
            "required": false,
            "hidden": false,
            "minValue": "",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Headings-Additional_Info",
        "type": "Headings",
        "settings": {
            "title": "Additional Info",
            "size": "left",
            "inputType": "text"
        },
        "name": "Additional Info"
    },
    {
        "elementid": "DropDown-opp_assigned_osa",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": 0,
                    "value": "Abhishek Yadav",
                    "isCheck": false
                },
                {
                    "id": 1,
                    "value": "Suraj Mahangade",
                    "isCheck": false
                },
                {
                    "id": 2,
                    "value": "Varad More",
                    "isCheck": false
                }
            ],
            "label": "Assigned OSA",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Assigned OSA",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "CheckBox-D_I_S_C-Profile",
        "type": "CheckBox",
        "settings": {
            "options": [
                {
                    "id": "9167",
                    "value": "(D)ominant",
                    "isCheck": false
                },
                {
                    "id": "0514",
                    "value": "(I)nfluencer",
                    "isCheck": false
                },
                {
                    "id": "4571",
                    "value": "(S)upportive",
                    "isCheck": false
                },
                {
                    "id": "6512",
                    "value": "(C)onscientiousness",
                    "isCheck": false
                }
            ],
            "label": "D.I.S.C. Profile",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "D.I.S.C. Profile",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "TextArea-Notes",
        "type": "TextArea",
        "name": "Notes",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Notes",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp-key",
        "type": "InputField",
        "name": "Opp Key",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opp Key",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-Follow-up-boss-lead-id",
        "type": "InputField",
        "name": "Follow Up Boss Lead ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Follow Up Boss Lead ID",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    }
]

export const AppointmentFormBuyerCondition=[
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "ISA",
                    "operator": "equals",
                    "field_id": "DropDown-Appointment-Set-By"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-opp_isa"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "OSA",
                    "operator": "equals",
                    "field_id": "DropDown-Appointment-Set-By"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-opp_assigned_osa"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Admin",
                    "operator": "equals",
                    "field_id": "DropDown-Appointment-Set-By"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-Admin"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Rent",
                    "operator": "equals",
                    "field_id": "DropDown-do_you_currently_rent_or_own_your_own_home?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-when_does_your_lease_end?"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-did_you_schedule_an_appointment?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "Headings-Appointment_Details",
            "Date-opp_appt_date",
            "DropDown-appointment_time",
            "DropDown-Appt-Location",
            "DropDown-appointment_type",
            "TextArea-App-Description"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Other Address",
                    "operator": "equals",
                    "field_id": "DropDown-Appt-Location"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "FreeText-address",
            "InputField-opp_address",
            "InputField-opp_address2",
            "InputField-opp_city",
            "InputField-opp_state",
            "InputField-opp_postal_code",
            "DropDown-opp_country"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Zoom",
                    "operator": "equals",
                    "field_id": "DropDown-Appt-Location"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-zoom-link"
        ],
        "ConditionName": "Condition Name"
    }
]