export const WhisselTeamAgreementFormSeller = [
    {
        "elementid": "InputField-opp_last_name",
        "type": "InputField",
        "name": "Last Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Last Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-lead_type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "0077",
                    "value": "Agent Generated Lead",
                    "isCheck": false
                },
                {
                    "id": "1126",
                    "value": "Team Generated Lead",
                    "isCheck": false
                }
            ],
            "label": "Lead Type",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Lead Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-transaction_type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9349",
                    "value": "Regular Transaction",
                    "isCheck": false
                },
                {
                    "id": "4984",
                    "value": "REO/Auction",
                    "isCheck": false
                },
                {
                    "id": "9113",
                    "value": "Agent's Capital",
                    "isCheck": false
                },
                {
                    "id": "9400",
                    "value": "Homelight Trade-In",
                    "isCheck": false
                },
                {
                    "id": "9961",
                    "value": "Homelight Simple Sale",
                    "isCheck": false
                },
                {
                    "id": "5675",
                    "value": "Platinum Marketing (3 Week Notice & List Price of $2.5M+)",
                    "isCheck": false
                }
            ],
            "label": "Transaction Type",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Transaction Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is_there_a_secondary_client?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4694",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "5927",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is there a secondary client?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is there a secondary client?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-secondary_contact",
        "type": "InputField",
        "name": "Secondary Client",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Client",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-secondary_contact_email",
        "type": "Email",
        "name": "Secondary Client Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Client Email",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Contact-secondary_contact_phone",
        "type": "Contact",
        "name": "Secondary Client Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Client Phone",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not add letters or extensions to this field"
        }
    },
    {
        "elementid": "InputField-second_whissel_agent_name_(write_n/a_if_not_applicable)",
        "type": "InputField",
        "name": "Second Whissel Agent Name (Write N/A if not applicable)",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Second Whissel Agent Name (Write N/A if not applicable)",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Write NA if not applicable"
        }
    },
    {
        "elementid": "FreeText-property_address",
        "type": "FreeText",
        "name": "Property Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Property Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address",
        "type": "InputField",
        "name": "Property Address-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Property Address-Street Address",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address2",
        "type": "InputField",
        "name": "Property Address-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Property Address-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_city",
        "type": "InputField",
        "name": "Property Address-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Property Address-City",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_state",
        "type": "InputField",
        "name": "Property Address-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Property Address-State",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_postal_code",
        "type": "InputField",
        "name": "Property Address-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Property Address-Zip Code",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-opp_country",
        "type": "DropDown",
        "field_sub_type": "opportunity",
        "name": "Property Address-Country",
        "settings": {
            "options": [
                {
                    "id": 25545,
                    "value": "USA",
                    "isCheck": false
                },
                {
                    "id": 2551,
                    "value": "Canada",
                    "isCheck": false
                }
            ],
            "label": "Country",
            "size": "large",
            "inputType": "dropdown",
            "required": false,
            "hidden": false,
            "minValue": "",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-opp_agreement_signed_date",
        "type": "Date",
        "name": "Signed Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Signed Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-opp_agreement_expiration_date",
        "type": "Date",
        "name": "Agreement Expiration Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Agreement Expiration Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-transaction_amount",
        "type": "NumberField",
        "name": "Transaction Amount",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Transaction Amount",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "For a seller agreement signed, enter the list price here.For a buyer agreement signed, enter the estimated purchase price/budget for the buyer."
        }
    },
    {
        "elementid": "DropDown-are_you_using_the_listing_management_system?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "0070",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "0555",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Are you using the listing management system?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Are you using the listing management system?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Date-target_photo_date",
        "type": "Date",
        "name": "Target Photo Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Target Photo Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "- Photo shoots are available Monday-Friday.- The minimum notice for scheduling the photo shoot is 1 business day."
        }
    },
    {
        "elementid": "Date-target_mls_live_date",
        "type": "Date",
        "name": "Target MLS Live Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Target MLS Live Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "- The live date must be Monday-Friday- The minimum notice for going live is 7 days."
        }
    },
    {
        "elementid": "CheckBox-coming_soon_marketing",
        "type": "CheckBox",
        "settings": {
            "options": [
                {
                    "id": "4205",
                    "value": "MLS Coming Soon Listing",
                    "isCheck": false
                },
                {
                    "id": "5244",
                    "value": "Zillow Coming Soon Listing",
                    "isCheck": false
                },
                {
                    "id": "6540",
                    "value": "Coming Soon Yard Sign",
                    "isCheck": false
                }
            ],
            "label": "Coming Soon Marketing",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please note: in order to do any coming soon marketing, we must post a \"coming soon\" listing on the MLS, which requires:1) At least 1 photo of the property(you can take this with your cell phone, and the media team can edit it for you)2) A previous MLS sheet for this property, including measurements of each roomIf we do not have both of the items listed above, we are only able to do coming soon marketing if the photo shoot is at least 7 days prior to the live date."
        },
        "name": "Coming Soon Marketing",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-staging_needed",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9375",
                    "value": "None",
                    "isCheck": false
                },
                {
                    "id": "9366",
                    "value": "Full Install - SD Staging",
                    "isCheck": false
                },
                {
                    "id": "9528",
                    "value": "Full Install - Agent Already Scheduled",
                    "isCheck": false
                },
                {
                    "id": "1119",
                    "value": "Owner Occupied Consult (Alisha Lynn)",
                    "isCheck": false
                },
                {
                    "id": "4439",
                    "value": "Owner Occupied Consult - Agent Already Scheduled (Alisha Lynn)",
                    "isCheck": false
                }
            ],
            "label": "Staging Needed",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Staging Needed",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-who_is_paying_for_staging?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2172",
                    "value": "Seller",
                    "isCheck": false
                },
                {
                    "id": "3334",
                    "value": "Agent",
                    "isCheck": false
                },
                {
                    "id": "7302",
                    "value": "Agent covering & to be reimbursed by seller at closing",
                    "isCheck": false
                }
            ],
            "label": "Who is paying for staging?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Who is paying for staging?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-sign_requirements",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "6097",
                    "value": "Yard Sign",
                    "isCheck": false
                },
                {
                    "id": "1408",
                    "value": "Yard Sign + Directional Sign",
                    "isCheck": false
                },
                {
                    "id": "7716",
                    "value": "Directional Sign",
                    "isCheck": false
                },
                {
                    "id": "8056",
                    "value": "Window Sign (for condos)",
                    "isCheck": false
                },
                {
                    "id": "4476",
                    "value": "No Sign",
                    "isCheck": false
                }
            ],
            "label": "Sign Requirements",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Sign Requirements",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-directional_sign_location",
        "type": "InputField",
        "name": "Directional Sign Location",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Directional Sign Location",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-photo_requirements_($900k+_listings)",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "6811",
                    "value": "Daytime Photos with Day-to-Night Conversion",
                    "isCheck": false
                },
                {
                    "id": "5485",
                    "value": "Daytime & Twilight Photos (Luxury Properties Only)",
                    "isCheck": false
                }
            ],
            "label": "Photo Requirements ($900k+ Listings)",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Photo Requirements ($900k+ Listings)",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-photo_requirements_(under_$900k_listings)",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9385",
                    "value": "Daytime Photos with Day-to-Night Conversion",
                    "isCheck": false
                },
                {
                    "id": "5346",
                    "value": "NA",
                    "isCheck": false
                }
            ],
            "label": "Photo Requirements (Under $900k Listings)",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Photo Requirements (Under $900k Listings)",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-photo_notes_(as_much_detail_as_possible)",
        "type": "InputField",
        "name": "Photo Notes (as much detail as possible)",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Photo Notes (as much detail as possible)",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Example: Complex amenities, what you want drone to show, etc."
        }
    },
    {
        "elementid": "DropDown-direct_mail_marketing",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9879",
                    "value": "Just Listed Letters",
                    "isCheck": false
                },
                {
                    "id": "8198",
                    "value": "None",
                    "isCheck": false
                }
            ],
            "label": "Direct Mail Marketing",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "IMPORTANT - FOR OPEN HOUSE MAILER INVITESYou must send the following information AT LEAST 7 BUSINESS DAYS before the open house:1. Open House Date(s) & Time(s)2. Type of Food & Beverage being offered3. Property Description/Remarks4. Photos must be completed 2 days prior to deadline"
        },
        "name": "Direct Mail Marketing",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-occupancy",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4850",
                    "value": "Owner Occupied",
                    "isCheck": false
                },
                {
                    "id": "7046",
                    "value": "Vacant",
                    "isCheck": false
                },
                {
                    "id": "3916",
                    "value": "Tenant Occupied: One Tenant",
                    "isCheck": false
                },
                {
                    "id": "9329",
                    "value": "Tenant Occupied: Two Tenants",
                    "isCheck": false
                }
            ],
            "label": "Occupancy",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Occupancy",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-do_you_have_a_key?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "6927",
                    "value": "Yes - will drop off at Santee office",
                    "isCheck": false
                },
                {
                    "id": "1074",
                    "value": "Yes - contractor box hung at property",
                    "isCheck": false
                },
                {
                    "id": "3854",
                    "value": "No - Agent will coordinate ASAP",
                    "isCheck": false
                }
            ],
            "label": "Do you have a key?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Do you have a key?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-contractor_box_code",
        "type": "InputField",
        "name": "Contractor Box Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Contractor Box Code",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-tenant_name",
        "type": "InputField",
        "name": "Tenant Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Tenant Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Contact-tenant_phone",
        "type": "Contact",
        "name": "Tenant Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Tenant Phone",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not add letters or extensions to this field"
        }
    },
    {
        "elementid": "Email-tenant_email",
        "type": "Email",
        "name": "Tenant Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Tenant Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-tenant_two_name",
        "type": "InputField",
        "name": "Tenant Two Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Tenant Two Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Contact-tenant_two_phone",
        "type": "Contact",
        "name": "Tenant Two Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Tenant Two Phone",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not add letters or extensions to this field"
        }
    },
    {
        "elementid": "Email-tenant_two_email",
        "type": "Email",
        "name": "Tenant Two Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Tenant Two Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "FreeText-seller_mailing_address",
        "type": "FreeText",
        "name": "Seller Mailing Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-seller_mailing_address_street_address",
        "type": "InputField",
        "name": "Seller Mailing Address-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address-Street Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-seller_mailing_address_address_line_2",
        "type": "InputField",
        "name": "Seller Mailing Address-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-seller_mailing_address_city",
        "type": "InputField",
        "name": "Seller Mailing Address-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address-City",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-seller_mailing_address_state",
        "type": "InputField",
        "name": "Seller Mailing Address-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address-State",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-seller_mailing_address_zip_code",
        "type": "InputField",
        "name": "Seller Mailing Address-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address-Zip Code",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-seller_mailing_address_country",
        "type": "DropDown",
        "name": "Seller Mailing Address-Country",
        "field_sub_type": "opportunity",
        "settings": {
            "options": [
                {
                    "id": 25545,
                    "value": "USA",
                    "isCheck": false
                },
                {
                    "id": 2551,
                    "value": "Canada",
                    "isCheck": false
                }
            ],
            "label": "Seller Mailing Address-Country",
            "size": "large",
            "inputType": "dropdown",
            "required": false,
            "hidden": false,
            "minValue": "",
            "maxValue": "",
            "instructions": ""
        }

    },
    {
        "elementid": "DropDown-do_the_sellers_text?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "3676",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "5718",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Do the sellers text?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Do the sellers text?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is_there_an_hoa?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "3485",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "0906",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is there an HOA?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is there an HOA?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-hoa_name",
        "type": "InputField",
        "name": "HOA Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "HOA Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-hoa_management_company",
        "type": "InputField",
        "name": "HOA Management Company",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "HOA Management Company",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Contact-hoa_management_phone",
        "type": "Contact",
        "name": "HOA Management Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "HOA Management Phone",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not add letters or extensions to this field"
        }
    },
    {
        "elementid": "DropDown-solar",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1734",
                    "value": "None",
                    "isCheck": false
                },
                {
                    "id": "8538",
                    "value": "Solar - Paid in Full",
                    "isCheck": false
                },
                {
                    "id": "1960",
                    "value": "Solar - Financed",
                    "isCheck": false
                },
                {
                    "id": "7489",
                    "value": "Solar - PPA",
                    "isCheck": false
                },
                {
                    "id": "2759",
                    "value": "Solar - Leased",
                    "isCheck": false
                },
                {
                    "id": "3173",
                    "value": "Solar - Ownership Unknown",
                    "isCheck": false
                }
            ],
            "label": "Solar",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Solar",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is_this_a_flipped_property_with_an_existing_title_binder?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8528",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "9239",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is this a flipped property with an existing title binder?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is this a flipped property with an existing title binder?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-water",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "0942",
                    "value": "Public Water",
                    "isCheck": false
                },
                {
                    "id": "0232",
                    "value": "Well Water",
                    "isCheck": false
                },
                {
                    "id": "7211",
                    "value": "Unknown",
                    "isCheck": false
                }
            ],
            "label": "Water",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Water",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-sewer",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1362",
                    "value": "Public Sewer",
                    "isCheck": false
                },
                {
                    "id": "4880",
                    "value": "Septic",
                    "isCheck": false
                },
                {
                    "id": "4097",
                    "value": "Unknown",
                    "isCheck": false
                }
            ],
            "label": "Sewer",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Sewer",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-gate_code",
        "type": "InputField",
        "settings": {
            "label": "Gate Code",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Gate Code"
    },
    {
        "elementid": "DropDown-title_company",
        "name": "Title Company",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4096",
                    "value": "Fidelity National Title Company - Tom Chapman & Lynne Lapinski",
                    "isCheck": false
                },
                {
                    "id": "9701",
                    "value": "Lawyer's Title - DeeDee Burland (Homelight Leads)",
                    "isCheck": false
                },
                {
                    "id": "6194",
                    "value": "First American - Greg Ives",
                    "isCheck": false
                },
                {
                    "id": 140,
                    "value": "Other",
                    "isCheck": false
                }
            ],
            "label": "Title Company",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-title_rep_name",
        "type": "InputField",
        "settings": {
            "label": "Title Rep Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Title Rep Name"
    },
    {
        "elementid": "Email-title_rep_email",
        "name": "Title Rep Email",
        "type": "Email",
        "settings": {
            "label": "Title Rep Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-title_company_name",
        "type": "InputField",
        "settings": {
            "label": "Title Company Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Title Company Name"
    },
    {
        "elementid": "TextArea-type_property_description_for_mls_and_marketing_(max_1600_ch",
        "type": "TextArea",
        "name": "Type Property Description for MLS and Marketing (max 1600 characters)",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Type Property Description for MLS and Marketing (max 1600 characters)",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "DO NOT COPY ANYTHING FROM PREVIOUS REMARKS.If you are struggling with writing the property description, use the template that's posted on Workplace! Search for \"listing description template\" and it should be the first result."
        }
    },
    {
        "elementid": "TextArea-Notes",
        "type": "TextArea",
        "name": "Notes",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Notes",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Anything the listing coordinator should know, for example:- Potential changes to time frames (photo date, live date, etc)- Additional seller contact info (if not provided earlier)- Specific showing instructions the sellers have asked for (2 hour notice, 1 day notice, etc.)- Why are they selling, if they are in escrow on another property, any deaths related to sale, divorce situation, etc."
        }
    },
    {
        "elementid": "CheckBox-cancellation/expiration_fee",
        "type": "CheckBox",
        "settings": {
            "options": [
                {
                    "id": "9198",
                    "value": "I have read and agree to the cancellation/expiration fee in the popup window",
                    "isCheck": false
                }
            ],
            "label": "Cancellation/Expiration Fee",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Cancellation/Expiration Fee: When utilizing the listing management system, if the listing expires or cancels after the photo/video shoot is complete, a fee of $500 will be charged. This will be auto-debited from the credit card you have on file with the company."
        },
        "name": "Cancellation/Expiration Fee",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-opp-key",
        "type": "InputField",
        "name": "Opp Key",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opp Key",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not touch this field"
        }
    },
    {
        "elementid": "InputField-Follow-up-boss-lead-id",
        "type": "InputField",
        "name": "Follow Up Boss Lead ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Follow Up Boss Lead ID",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    }
]

export const whisselTeamAgreementFormSellerCondition=[
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-is_there_a_secondary_client?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "Email-secondary_contact_email",
            "InputField-secondary_contact",
            "Contact-secondary_contact_phone"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Full Install - SD Staging",
                    "operator": "equals",
                    "field_id": "DropDown-staging_needed"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Full Install - Agent Already Scheduled",
                    "operator": "equals",
                    "field_id": "DropDown-staging_needed"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-who_is_paying_for_staging?"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-is_there_an_hoa?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-hoa_name",
            "InputField-hoa_management_company",
            "Contact-hoa_management_phone"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "numberfield",
                    "expected_value": "899995",
                    "operator": "greater_than",
                    "field_id": "NumberField-transaction_amount"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-photo_requirements_($900k+_listings)"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "numberfield",
                    "expected_value": "899984",
                    "operator": "less_than",
                    "field_id": "NumberField-transaction_amount"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-photo_requirements_(under_$900k_listings)"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Tenant Occupied: One Tenant",
                    "operator": "equals",
                    "field_id": "DropDown-occupancy"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Tenant Occupied: Two Tenants",
                    "operator": "equals",
                    "field_id": "DropDown-occupancy"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Vacant",
                    "operator": "equals",
                    "field_id": "DropDown-occupancy"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-tenant_name",
            "Contact-tenant_phone",
            "Email-tenant_email",
            "FreeText-seller_mailing_address",
            "InputField-seller_mailing_address_street_address",
            "InputField-seller_mailing_address_address_line_2",
            "InputField-seller_mailing_address_city",
            "InputField-seller_mailing_address_state",
            "InputField-seller_mailing_address_zip_code",
            "DropDown-seller_mailing_address_country"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-do_the_sellers_text?"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "No",
                    "operator": "equals",
                    "field_id": "DropDown-do_the_sellers_text?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-is_there_an_hoa?",
            "DropDown-water",
            "DropDown-sewer",
            "DropDown-is_this_a_flipped_property_with_an_existing_title_binder?",
            "DropDown-title_company",
            "DropDown-solar",
            "InputField-gate_code"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Fidelity National Title Company - Tom Chapman & Lynne Lapinski",
                    "operator": "equals",
                    "field_id": "DropDown-title_company"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Lawyer's Title - DeeDee Burland (Homelight Leads)",
                    "operator": "equals",
                    "field_id": "DropDown-title_company"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "First American - Greg Ives",
                    "operator": "equals",
                    "field_id": "DropDown-title_company"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Other",
                    "operator": "equals",
                    "field_id": "DropDown-title_company"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-title_rep_name",
            "Email-title_rep_email",
            "TextArea-type_property_description_for_mls_and_marketing_(max_1600_ch",
            "CheckBox-cancellation/expiration_fee",
            "InputField-title_company_name"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Vacant",
                    "operator": "equals",
                    "field_id": "DropDown-occupancy"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Tenant Occupied: One Tenant",
                    "operator": "equals",
                    "field_id": "DropDown-occupancy"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Tenant Occupied: Two Tenants",
                    "operator": "equals",
                    "field_id": "DropDown-occupancy"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-do_you_have_a_key?"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes - contractor box hung at property",
                    "operator": "equals",
                    "field_id": "DropDown-do_you_have_a_key?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-contractor_box_code"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Tenant Occupied: Two Tenants",
                    "operator": "equals",
                    "field_id": "DropDown-occupancy"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-tenant_two_name",
            "Contact-tenant_two_phone",
            "Email-tenant_two_email"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yard Sign + Directional Sign",
                    "operator": "equals",
                    "field_id": "DropDown-sign_requirements"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Directional Sign",
                    "operator": "equals",
                    "field_id": "DropDown-sign_requirements"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-directional_sign_location"
        ],
        "ConditionName": "Condition Name"
    }
]