import React, { useContext } from 'react'
import { ElementsSettingsWidget } from './ElementsSettingsWidget'
import { BoardDeleteHandler } from '../../BoardHandlerFunctions'
import { boardConetxt } from '../../../pages/form-builder/dashboard/ContextHelper'
import { DropDownUi } from './DropDownUi'

export const DndDropDownUi = ({elementid,element,oppFields,setDeleteField,IsaList,osaList,AdminList,Elementtype}) => {
  const {board,setboard} = useContext(boardConetxt)
  return (
    <div className={`fm-comp ${Elementtype}`} id={elementid}>
        <ElementsSettingsWidget
        setDeleteField={setDeleteField}
        elementid={elementid}
        BoardDeleteHandler={BoardDeleteHandler}
        board={board}
        setboard={setboard}
        element={element}

/>
        <DropDownUi elementid={elementid} oppFields={oppFields} IsaList={IsaList} osaList={osaList} AdminList={AdminList}/>

    </div>
  )
}
