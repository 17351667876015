import { ContactUi } from "./ContactUi";
import React from "react";
import { BoardDeleteHandler } from "../../BoardHandlerFunctions";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { useContext } from "react";
import { ElementsSettingsWidget } from "./ElementsSettingsWidget";

export const DnDContact = ({ elementid, element, PreviewData = false, oppFields, setDeleteField,OppFieldName,Elementtype }) => {
  const { board, setboard } = useContext(boardConetxt);


  return (
    <>
         <div className={`fm-comp ${Elementtype}`}>

        <ElementsSettingsWidget
          setDeleteField={setDeleteField}
          BoardDeleteHandler={BoardDeleteHandler}
          elementid={elementid}
          board={board}
          setboard={setboard}
          element={element}

        />


        <ContactUi PreviewData={PreviewData} oppFields={oppFields} elementid={elementid} OppFieldName={OppFieldName} />
      </div>
    </>
  );
};
