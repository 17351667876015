

export const DispositionForm=[
    {
        "elementid": "InputField-opp_last_name",
        "type": "InputField",
        "name": "Lead Last Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Lead Last Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-appt_disposition__did_the_appt_happen?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1297",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "8261",
                    "value": "No",
                    "isCheck": false
                },
                {
                    "id": "4205",
                    "value": "Rescheduled",
                    "isCheck": false
                }
            ],
            "label": "Appt Disposition: Did the Appt Happen?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appt Disposition: Did the Appt Happen?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Date-opp_appt_met_date",
        "type": "Date",
        "name": "Appointment Met Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Appointment Met Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Ratings-app-rating",
        "type": "Ratings",
        "name": "Appt Rating",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Appt Rating",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-why_didn_t_the_appt_happen?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7667",
                    "value": "Cancelled",
                    "isCheck": false
                },
                {
                    "id": "6088",
                    "value": "No Show",
                    "isCheck": false
                }
            ],
            "label": "Why Didn't the Appt Happen?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Why Didn't the Appt Happen?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "FreeText-rescheduled_appointment_details",
        "type": "FreeText",
        "name": "Rescheduled Appointment Details",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Date-rescheduled-app-date",
        "type": "Date",
        "name": "Rescheduled Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Rescheduled Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Time-rescheduled-time",
        "name": "Rescheduled Appointment Time",
        "type": "Time",
        "field_sub_type": "opportunity",
        "settings": {}
    },
    {
        "elementid": "TextArea-what_are_the_next_steps_to_get_the_client_to_the_finish_line",
        "type": "TextArea",
        "name": "What are the next steps to get the client to the finish line?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "What are the next steps to get the client to the finish line?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "TextArea-Notes",
        "type": "TextArea",
        "name": "Notes",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Notes",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp-key",
        "type": "InputField",
        "name": "Opp Key",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opp Key",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-Follow-up-boss-lead-id",
        "type": "InputField",
        "name": "Follow Up Boss Lead ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Follow Up Boss Lead ID",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    }
]




export const DispositionFormConditions=[
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-appt_disposition__did_the_appt_happen?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "Date-opp_appt_met_date"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "No",
                    "operator": "equals",
                    "field_id": "DropDown-appt_disposition__did_the_appt_happen?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-why_didn_t_the_appt_happen?"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Rescheduled",
                    "operator": "equals",
                    "field_id": "DropDown-appt_disposition__did_the_appt_happen?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "Date-rescheduled-app-date",
            "Time-rescheduled-time",
            "FreeText-rescheduled_appointment_details"
        ],
        "ConditionName": "Condition Name"
    }
]