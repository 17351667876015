import React from 'react'
import { useState, useEffect} from 'react'
import { useParams ,useNavigate} from 'react-router-dom';
import { Loaderr } from "../../../components/Loaderr";

import { gql,useMutation, useQuery } from "@apollo/client";
import logo from "../../../assets/images/LOGO-BLUE.png";

const formName=gql`
query FormsNames($teamId: String) {
  formsNames(team_id: $teamId) {
    form_id
    form_name
  }
}`
const formData=gql`
query FormData {
  formData {
    team_id
  }
}`

const opportunityData=gql`query Opportunity($oppKey: String) {
    opportunity(opp_key: $oppKey) {
      opp_key
      sisu_client_updated_ts
      opp_updated_ts
      opp_stage
      opp_agreement_signed_date
      fub_deal_created_ts
      opp_type
      opp_appt_date
      fub_deal_stage_name
      opp_appt_met_date
      fub_person_id
      sisu_client_created_ts
      opp_created_ts
      fub_deal_id
      opp_appt_disposition
      teamFubDealId
      team
      fub_deal_entered_stage_ts
      sisu_client_id
      opp_assigned_osa
      opp_isa
      opp_notes
      opp_address
      opp_agreement_expiration_date
      appt_set_entry_id
      fub_appt_start_time
      fub_original_appt_start_time
      disp_text_wait_timestamp
      appt_set_lead_type
      appt_set_platform
      disp_text_original_wait_timestamp
      opp_address2
      opp_city
      opp_postal_code
      opp_last_name
      opp_state
      previous_opp_stage
      CreateEntryId
      pipeline_entry_id
      opp_forecasted_close_date
      opp_under_contract_date
      appt_form_id
      form_id_entry_id
      opp_settlement_date
      CreateFormId
      appt_outcome
      external_system_key_buyer
      otc_property_id
      external_system_key_seller
      FormId_EntryIds
      formid_entryids
    appt_form_entry_ids
      opp_price
      opp_commission_percent
      fub_appt_id
      stage_id
      pipeline_id
      opp_custom_fields
      custom_fields
      appt_form_entry_ids
    }
  }`
export const OpportunityDetailPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [opportunity, setOpportunity] = useState(null);

  
    const { loading, error, data } = useQuery(opportunityData, {
      variables: { oppKey: params?.oppkey },
    //   skip: !params?.oppKey,
    });
    const { data: formDataData,loadings } = useQuery(formData);
  
    const { data: formNamesData,loading: formNamesLoading } = useQuery(formName, {
      variables: { teamId: formDataData?.formData?.team_id },
    });
    console.log("Params:", params.oppkey); // Log the params to verify oppKey
    console.log("Loading:", loading); // Log loading state
    console.log("Error:", error); // Log any error
    console.log("Data:", data?.opportunity); // Log the fetched data
    
    useEffect(() => {
      if (data) {
        setOpportunity(data.opportunity);
      }
    }, [data]);
    console.log("Opportunity:", opportunity); // Log the opportunity data
    if (loading && formNamesLoading) return <Loaderr/>;
    if (error) return <div>Error fetching opportunity data</div>;
   
         console.log("Formname", formNamesData);

    
      return (
        <div className="main fmc-main p-0">
        <section className="container-lg container-fluid pt-5">
          <b
            className="text-secondary pointer"
            onClick={() => navigate('/opportunities')}
          >
            <i className="iconify me-2" data-icon="mdi:arrow-left" />
            Back to Opportunity
          </b>
  
          <div className="p-4 bg-white border rounded-3 mb-3 mt-3 d-flex justify-content-between align-items-center">
            <span>
              <h5>
                <i className="iconify me-2" data-icon="tdesign:form" />
                Opportunity Details
              </h5>
              <hr className="hr op-10" />
            </span>
          </div>
  
          <section className="d-flex flex-column py-4 gap-3">
            <>
              <header className="header bg-white border-bottom" id="header">
                <div>
                  <div className="pointer" onClick={() => navigate("/forms/")}>
                    <div className="d-flex align-items-center ms-2">
                      <span className="navbar-brand m-0 p-0">
                        <img className="img-fluid" src={logo} width="30" alt="" />
                      </span>
                      <i className="bx bx-chevron-left nav_icon mx-md-2 mx-1"></i>
                      <span className="p-0 m-0 text-decoration-underline">
                        Form Dashboard
                      </span>
                    </div>
                  </div>
                </div>
              </header>
  
              <div className="p-4 bg-white border rounded-3 mb-3 mt-3">
                <div className="d-flex flex-column gap-5 mb-3 mt-3">
                  <div className="fs-6">


                  {opportunity && opportunity.formid_entryids && (
  <div className="fs-6">
    <div className="text-black-50 fw-bold mt-5">
      <strong>Form Entries:</strong>
    </div>
    <hr className="hr op-10" />

    {opportunity.formid_entryids.map((entry, entryIndex) => {
      const [formId, entryId] = entry.split("_");
      const url = `/forms/${formId}/entries/${entryId}`;

      // Find the form name using the formId
      const Formname = formNamesData?.formsNames?.find(
        (form) => form.form_id === formId
      );

      return (
        <div className="fs-6" key={entryIndex}>
          {/* Display Entry Number and Form Name */}
          <div className="d-flex align-items-center mb-4">
            <div className="text-black-50 fw-bold me-2 ">
              <strong>{`Entry_${entryIndex + 1}:`}</strong>
            </div>

            {/* Display form name or a loading spinner */}
            {formNamesLoading ? (
              <div className="d-flex justify-content-center"
              
              >
                <div className="spinner-border" 
                style={{ width: '1.5rem', height: '1.5rem' }} 
                role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <button className="btn btn-link p-0" onClick={() => navigate(url)}>
                {Formname?.form_name || "Form name not found"}
              </button>
            )}
          </div>
        </div>
      );
    })}
  </div>
)}

                  {/* {opportunity && opportunity.formid_entryids && (
                    <div className="fs-6">
                     
                      {opportunity.formid_entryids.map((entry, entryIndex) => {
                        const [formId, entryId] = entry.split("_");

                        const url = `/forms/${formId}/entries/${entryId}`;
                        console.log("Formname", formId);
                        const Formname = formNamesData?.formsNames.find(
                          (form) => form.form_id === formId
                        );


                        return (
                          <div className="fs-6" key={entryIndex}>
                            <div className="text-black-50 fw-bold mt-5">
                              <strong>{`Entry_${entryIndex + 1}`}:</strong>
                            </div>
                            <hr className="hr op-10" />
                            <button
                              className="btn btn-link"
                              onClick={() => navigate(url)}
                            >
 {formNamesLoading ? (
                                <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status">
                                   <span className="sr-only">Loading...</span>
                                </div>
                             </div>
                              ) : (
                                Formname?.form_name || "Form name not found"
                              )}                            </button>
                          </div>
                        );
                      })}
                    </div>
                  )} */}

  
                  {opportunity && 
                  Object.entries(opportunity).map(([key, value], index) => {
                    
                    if (key === 'opp_custom_fields') return null;
                   

                    return (
                      <div className="fs-6" key={index}>
                        <div className="text-black-50 fw-bold mt-5">
                          <strong>{key.replace(/_/g, ' ')}:</strong>
                        </div>
                        <hr className="hr op-10" />
                        <input
                          className="col-5 form-control p-1"
                          value={value ? value.toString() : 'N/A'}
                          disabled
                        />
                      </div>
                    );
})
                }
                  </div>
                </div>
              </div>
            </>
          </section>
        </section>
      </div>
      );
}
