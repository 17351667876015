import React, { useContext } from 'react'
import { ElementsSettingsWidget } from './ElementsSettingsWidget'
import { TimeUi } from './TimeUi'
import { BoardDeleteHandler } from '../../BoardHandlerFunctions'
import { boardConetxt } from '../../../pages/form-builder/dashboard/ContextHelper'

export const DndTimeUi = ({elementid,element,oppFields,setDeleteField,Elementtype}) => {
    const {board,setboard}=useContext(boardConetxt)
  return (
    <div className={`fm-comp ${Elementtype}`} elementid={elementid}>
    <ElementsSettingsWidget
    setDeleteField={setDeleteField}
    BoardDeleteHandler={BoardDeleteHandler}
    elementid={elementid}
    board={board}
    setboard={setboard}
    element={element}
    />
    <TimeUi elementid={elementid} oppFields={oppFields}/>
  </div>
  )
}
