import React, { useEffect, useContext } from "react";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { Controller, useFormContext } from "react-hook-form";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";

export function ContactUi({
  elementid,
  showHide,
  oppFields,
  previewFlag = false,
  OppFieldName
}) {

  const { board } = useContext(boardConetxt);
  const { formState: { errors }, setValue, control } = useFormContext();
  const disableElementForBuild = window.location.pathname.includes("build")

  useEffect(() => {
    if (oppFields) {
      setValue(elementid, oppFields)
    }
  }, [oppFields, elementid, setValue])


  const ElementSettingsData = board.find(
    (item) => item?.elementid === elementid
  ).settings;
  const Elemlabel = board.find(
    (item) => item?.elementid === elementid
  ).name;
  const {
    inputType,
    label,
    instructions,
    minValue,
    maxValue,
    predefine,
    required,
    hidden
  } = ElementSettingsData;
  const initialStyle = {
    display: showHide === "show" ? "none" : "block",
  };


  // useEffect(() => {
  //   setValue(elementid, predefine)
  // }, [predefine])

  const checkingValidation = () => {
    if ((required === true) && (showHide === "show")) {
      return false
    } else if ((required === false) && (showHide === "show")) {
      return false
    } else if ((required === true) && (showHide === "hide")) {
      return false
    } else if ((required === false) && (showHide === "hide")) {
      return false
    } else if ((required === true) && (hidden === true)) {
      return false
    } else if (required === true) {
      return true
    } else {
      return false
    }

  }

  const normalizeFieldName = (name) => {
    return name.replace(/[.\s]/g, '_'); // Replace dots and spaces with underscores
  };



  return (
    <div
      id={elementid}
      className={previewFlag && GetStyleClasses(elementid) || ""}
      style={initialStyle}
      hidden={hidden && previewFlag}
    >
      <div className="form-floating w-100 form-group">

        {Object.keys(ElementSettingsData).length === 0 ? (<Controller
          control={control}
          name={normalizeFieldName(elementid)}
          rules={{
            maxLength: {
              value: 10,
              message: `Please Enter Less then  10 Characters`
            },
            pattern: {
              value: /^[0-9]*$/,
              message: "Only numbers are allowed",
            },
          }}
          render={({ field }) => (
            <input
              type="number"
              className="form-control input-bn"
              placeholder="Contact"
              disabled={disableElementForBuild}
              {...field}
            />
          )}
        />) :
          (<Controller
            control={control}
            name={normalizeFieldName(elementid)}
            rules={{
              required: {
                value: checkingValidation(),
                message: "This field is required",
              },
              minLength: {
                value: minValue === " " ? 10 : minValue,
                message: `Please Enter More then ${minValue === " " ? 10 : minValue} Characters`
              },
              maxLength: {
                value: maxValue === "" || undefined ? 10 : maxValue,
                message: `Please Enter Less then ${maxValue === "" ? 10 : maxValue}   Characters`
              },
              pattern: {
                value: /^[0-9]*$/,
                message: "Only numbers are allowed",
              },
            }}
            defaultValue={predefine === undefined ? "" : predefine}
            render={({ field }) => (
              <input
                type={inputType ? inputType : "number"}
                className="form-control input-bn"
                placeholder="Contact"
                disabled={disableElementForBuild}
                {...field}
              />
            )}
          />)
        }
        <label>
          <span className="d-flex align-items-center">
            <i className="iconify me-1 mr-1 fs-5" data-icon="carbon:phone" />
            <span>

              {label == undefined || label == "" ? Elemlabel : label}

              {required ? <span style={{ color: "red" }}>*</span> : ""}
            </span>
          </span>
        </label>
        <small className="text-secondary">{instructions}</small>
        <div className="d-flex flex-column gap-2">
          {errors && (
            <span className="text-danger">{errors[elementid]?.message}</span>
          )}
        </div>
      </div>
    </div>
  );
}
