import React from "react";

import ZapierLogo from "../assets/images/zapier-logo.png";

const WebhookToZapier = () => {
  return (
    <>
      <div className="d-flex align-items-center card-g bg-white p-3 border rounded-3">
        <div title="Contact support">
        <img className="me-2 p-0 fs-5" height="20" src={ZapierLogo} alt="" />
        <span> Webhook To Zapier</span>
        </div>
        <div className="tooltip-container ms-auto">
        <i
          className="iconify text-secondary"
          data-icon="mdi:info-circle"
        ></i>
        <div className="tooltip-text">
          Contact support
        </div>
        </div>
      </div>
    </>
  );
};

export default WebhookToZapier;
