import React, { useContext, useEffect } from "react";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { Controller, useFormContext } from "react-hook-form";


export const CheckBoxUi = ({
  elementid,
  showHide,
  oppFields,
  previewFlag = false,
}) => {
  const { board } = useContext(boardConetxt);
  const disableElementForBuild = window.location.pathname.includes("build")

  const { register,
    formState: { errors }, control, setValue
  } = useFormContext();
  const ElementSettingsData = board.find(
    (item) => item?.elementid === elementid
  ).settings;

  useEffect(() => {
    if (oppFields) {
      setValue(elementid, oppFields)
    }
  }, [oppFields, elementid, setValue])






  const { label, hidden, instructions, required } = ElementSettingsData;

  const initialStyle = {
    display: showHide === "show" ? "none" : "block",
  };


  const ElementName = board.find((item) => item?.elementid === elementid).name;
  const checkingValidation = () => {
    if (required === true && showHide === "show") {
      return false
    } else if ((required === false) && (showHide === "show")) {
      return false
    } else if ((required === true) && (showHide === "hide")) {
      return false
    } else if ((required === false) && (showHide === "hide")) {
      return false
    } else if ((required === true) && (hidden === true)) {
      return false
    } else if (required === true) {
      return true
    } else {
      return false
    }

  }
  const normalizeFieldName = (name) => {
    return name.replace(/[.\s]/g, '_'); // Replace dots and spaces with underscores
  };
  return (
    <div
      className={previewFlag && GetStyleClasses(elementid) || ""}
      id={elementid}
      style={initialStyle}
      hidden={hidden && previewFlag}
    >

      {label == undefined || label == "" ? ElementName : label}



      {required ? <span style={{ color: "red" }}>*</span> : ""}

      {ElementSettingsData?.options !== undefined ? (
        ElementSettingsData?.options?.map((item, i) => {
          // //("item", item)
          return (
            <div className="mt-3" key={i}>
              <Controller

                control={control}
                name={normalizeFieldName(elementid)}
                rules={{
                  required: {
                    value: checkingValidation(),
                    message: "This field is required",
                  },
                }}
                render={({ field: { } }) => (
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={item?.id}
                    value={item?.value}
                    disabled={disableElementForBuild}
                    {...register(normalizeFieldName(elementid))}
                  />
                )}
              />
              <label

                className="form-check-label ms-2"
                htmlFor={item?.value}
              >
                {item?.value}
              </label>


            </div>
          );
        })
      ) : (
        <>

          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            disabled={disableElementForBuild}
            render={({ field: { } }) => (
              <div className="mt-2">

              </div>
            )}
          />

          <label
            className="form-check-label text-secondary "
            htmlFor="check-1"
          >
            Please Select an option or Add option
          </label>
        </>
      )}

      <small className="text-secondary">{instructions}</small>
      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>
    </div>
  );
};
