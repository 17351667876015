import React, { useContext } from 'react'
import { ElementsSettingsWidget } from './ElementsSettingsWidget'
import { boardConetxt } from '../../../pages/form-builder/dashboard/ContextHelper'
import { BoardDeleteHandler } from '../../BoardHandlerFunctions';
import { NumberFieldUi } from './NumberFieldUi';

export const DndNumberFieldUi = ({elementid,element,oppFields,setDeleteField,Elementtype}) => {
  console.log("NumberFieldUi 2nd Level",oppFields,elementid,Elementtype,element)
   const {board,setboard}=useContext(boardConetxt);

  return (
    <div className={`fm-comp ${Elementtype}`} id={elementid}>
        <ElementsSettingsWidget
          setDeleteField={setDeleteField}
         BoardDeleteHandler={BoardDeleteHandler}
         board={board}
         setboard={setboard}
        elementid={elementid}
        element={element}
         />
         <NumberFieldUi elementid={elementid} oppFields={oppFields} />
    </div>
  )
}
