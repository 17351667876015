import React from "react";
import ArrPng from "../../assets/images/arr.png";
import AdvancedFiltersViewOnly from "./AdvancedFiltersViewOnly";

const TemplateFiltersViewOnly = ({
  action_index,
  selectAutomation,
  filterType,
  test_result = false,
}) => {
  return (
    <>
      {filterType === "template" ? (
        <>
          <div className="d-flex justify-content-center">
            <img height="70" src={ArrPng} alt="" />
          </div>
          <div className="dot-border bg-white rounded-3 p-4 mb-2 border position-relative">
            {test_result && (
              <div className="d-flex align-items-end mb-3 tooltip-container bvjk">
                <i
                  className="iconify text-danger mns-auto"
                  data-icon="mdi:info-circle"
                ></i>
                <div className="tooltip-text">
                  Please test the filter before saving workflow!
                </div>
              </div>
            )}
            <h6 className="w-100 mb-3">
              <strong> {action_index + ". "}</strong>
              <i className="iconify" data-icon="mdi:filter-cog" />
              Filter Template
            </h6>
            <div className="mb-4 mt-2">
              <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center p-3 border-0 alert-primary col text-dark form-control-sm">
                  {selectAutomation
                    ? "Selected Filter Template : " + selectAutomation
                    : "Select a filter template"}
                  <i
                    className="iconify fs-5"
                    data-icon="akar-icons:settings-horizontal"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <AdvancedFiltersViewOnly action_index={action_index} />
      )}
    </>
  );
};

export default TemplateFiltersViewOnly;
