import React from "react";
import ArrPng from "../../assets/images/arr.png";

const AdvancedFiltersViewOnly = ({ action_index, test_result = false }) => {
  return (
    <>
    <div className="d-flex justify-content-center">
        <img height="70" src={ArrPng} alt="" />
      </div>
    <div className="dot-border rounded-3 bg-white gx-2 p-4 mb-2 border position-relative">
      {test_result && (
        <div className="d-flex align-items-end mb-3 tooltip-container bvjk">
          <i
            className="iconify text-danger mns-auto"
            data-icon="mdi:info-circle"
          ></i>
          <div className="tooltip-text">
            Please test the filter before saving workflow!
          </div>
        </div>
      )}
      <div className="">
        <h5 className="fs-6 m-0 mb-4 mt-2">
          <strong> {action_index + ". "}</strong>
          <i className="iconify p-0 me-1" data-icon="mdi:filter"></i>Advanced
          Filter Setup
        </h5>
        <div className="mb-3">
          <div className="d-flex justify-content-between align-items-center p-3 border-0 alert-primary col text-dark form-control-sm">
            Continue if the following condition is met
            <i
              className="iconify fs-5"
              data-icon="akar-icons:settings-horizontal"
            ></i>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default AdvancedFiltersViewOnly;
