import React from "react";

const Filter = () => {
  return (
    <>
        <div className="d-flex align-items-center card-g bg-white p-3 border rounded-3">
          <i
            className="iconify me-2 p-0 fs-5 text-primary"
            data-icon="bi:input-cursor"
          ></i>
          <span>Filter</span>
          <i
            className="iconify text-secondary ms-auto"
            data-icon="mdi:info-circle"
          ></i>
        </div>
    </>
  );
};

export default Filter;
