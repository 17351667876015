import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import Select from "react-select/creatable";

const defaultPersonFields = [
  { value: "Name", key: "name" },
  { value: "First Name", key: "firstName" },
  { value: "Last Name", key: "lastName" },
  // { value: "Prequalified", key: "prequalified" },
  { value: "Stage", key: "stage" },
  { value: "Stage Id", key: "stageId" },
  { value: "Source", key: "source" },
  { value: "Source Url", key: "sourceUrl" },
  { value: "Contacted", key: "contacted" },
  { value: "Price", key: "price" },
  { value: "Assigned Lender Id", key: "assignedLenderId" },
  { value: "Assigned Lender Name", key: "assignedLenderName" },
  { value: "Assigned User Id", key: "assignedUserId" },
  { value: "Assigned To", key: "assignedTo" },
  { value: "Assigned Pond Id", key: "assignedPondId" },
];

const defaultDealFields = [
  { key: "pipelineId", value: "Pipeline" },
  { key: "stageId", value: "Stage" },
  { key: "status", value: "Status" },
  { key: "price", value: "Price" },
  // { key: "createdAt", value: "Created At" },
  { key: "description", value: "Description" },
  { key: "projectedCloseDate", value: "Projected Close Date" },
  { key: "people", value: "People" },
  { key: "users", value: "Users" },
  { key: "name", value: "Deal Name" },
  { key: "agentCommission", value: "agentCommission" },
  { key: "teamCommission", value: "teamCommission" },
];

const FieldBasedTriggerModal = ({
  setFieldKey,
  handleTriggerDetails,
  trigger,
  triggertype,
}) => {
  const [personCustomFields, setPersonCustomFields] = useState([]);
  const [dealCustomFields, setDealCustomFields] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  const GetFubAllData = gql`
    query GetFubAllData($type: String!) {
      GetFubAllData(type: $type) {
        info
        output
        status
      }
    }
  `;

  const { loading, data } = useQuery(GetFubAllData, {
    variables: { type: "customfields" },
  });

  const {
    loading: dealFieldsLoading,
    data: dealFieldsData,
  } = useQuery(GetFubAllData, {
    variables: { type: "dealcustomfields" },
  });

  useEffect(() => {
    if (data?.GetFubAllData?.output) {
      const newOptions = data.GetFubAllData.output.map((item) => ({
        key: item.name,
        keyShow: item.name,
        value: item.label,
      }));
      setPersonCustomFields(newOptions);
    }
  }, [data]);

  useEffect(() => {
    if (dealFieldsData?.GetFubAllData?.output) {
      const newOptions = dealFieldsData.GetFubAllData.output.map((item) => ({
        key: item.name,
        keyShow: item.name,
        value: item.label,
      }));
      setDealCustomFields(newOptions);
    }
  }, [dealFieldsData]);

  useEffect(() => {
    let allOptions = [];
    if (trigger.name === "Person Updated") {
      allOptions = [...defaultPersonFields, ...personCustomFields];
    }
    if (trigger.name === "Deal Updated") {
      allOptions = [...defaultDealFields, ...dealCustomFields];
    }
    if (selectedOption) {
      const selectedValue = selectedOption
        .toString()
        .toLowerCase()
        .split(" ")
        .join("");
      const foundOption = allOptions.find(
        (option) =>
          option.value.toLowerCase().split(" ").join("") === selectedValue
      );
      if (foundOption) {
        setFieldKey(foundOption.key);
      }
    }
  }, [selectedOption]);

  const handleSubmit = () => {
    handleTriggerDetails(trigger, triggertype);
  };

  return (
    <div className="border rounded my-3">
      <div className="text-left p-4">
        <p>
          Select a field to be updated or created in FUB{" "}
          {trigger.name.split(" ")[0]}. This would be the trigger for your
          automation.
        </p>
        <div>
          {trigger.name === "Person Updated" && (
            <Select
              placeholder={`${loading ? "Loading fields" : "Select field" }`}
              options={[...defaultPersonFields, ...personCustomFields].map(
                (option) => ({
                  value: option.value,
                  label: option.value,
                })
              )}
              onChange={(selectedOption) =>
                setSelectedOption(selectedOption?.value)
              }
              formatCreateLabel={() => undefined}
              isClearable={true}
              isLoading={loading ? true: false}
            />
          )}
          {trigger.name === "Deal Updated" && (
            <Select
              placeholder={`${dealFieldsLoading ? "Loading fields" : "Select field" }`}
              isClearable={true}
              options={[...defaultDealFields, ...dealCustomFields].map(
                (option) => ({
                  value: option.value,
                  label: option.value,
                })
              )}
              onChange={(selectedOption) =>
                setSelectedOption(selectedOption?.value)
              }
              formatCreateLabel={() => undefined}
              isLoading={dealFieldsLoading ? true: false}
            />
          )}
        </div>
        <div className="d-flex pt-4 gap-3 justify-content-end">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
            data-bs-target="#triggerModal"
            aria-label="Close"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={selectedOption ? false : true}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default FieldBasedTriggerModal;
