import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import Switch from "react-switch";
import WorkflowHeader from "../../../components/WorkflowHeader";
import { gql, useMutation, useQuery } from "@apollo/client";
import { WorkflowHelper } from "../../../utils/WorkflowHelper";
import cloneDeep from "lodash/cloneDeep";
import { Loaderr } from "../../../components/Loaderr";
import { ErrorPage } from "../../../utils/ErrorPage";
import ViewOnlyActions from "../automation/ViewOnlyActions";
import TriggerViewOnly from "../../../actions/view-only-actions/TriggerViewOnly";

const UpdateWorkflowActions = gql`
  mutation UpdateWorkflowActions(
    $workflow_id: String!
    $actions_info: GraphQLJSON!
  ) {
    UpdateWorkflowActions(
      workflow_id: $workflow_id
      actions_info: $actions_info
    ) {
      statusCode
    }
  }
`;
const GETWORKFLOW = gql`
  query Workflow($workflowId: String!) {
    Workflow(workflow_id: $workflowId) {
      actions {
        action_id
        next_action_ids
        next_acion_id
        meta_data
        data_mapper
        action_path
        api_name
        api_type
        automation_name
        test_status
        is_default
        platform
      }
      workflow_name
      workflow_description
      workflow_id
      trigger_id
      status
    }
    TestSession(workflow_id: $workflowId) {
      statusCode
      message
      data
    }
  }
`;

function ViewOnlyWorkflow() {
  const { workflow_id } = useParams();
  const {
    loading: workflow_loading,
    error: workflow_error,
    data: workflow_data,
  } = useQuery(GETWORKFLOW, {
    variables: { workflowId: workflow_id },
  });
  const [workflow_status, setWorkflowStatus] = useState(null);

  const [workflowactions, setWorkflowactions] = useState(null);
  const [actionsCount, setActionsCount] = useState(null);
  const workflow_helper = useRef(
    new WorkflowHelper(cloneDeep(workflowactions), setWorkflowactions)
  );
  const [session, setsession] = useState(null);
  const [triggerdetails, setTriggerDetails] = useState({});
  const old_trigger_id = useRef(null);
  const [saveTrigger, setSaveTrigger] = useState(false);

  const [
    update_actions,
    { data: updatedata, loading: updating, error: updateerror },
  ] = useMutation(UpdateWorkflowActions);

  useEffect(() => {
    if (workflow_data?.TestSession?.statusCode === 200) {
      setsession(workflow_data?.TestSession?.data);
    }
  }, [workflow_data]);

  useEffect(() => {
    if (workflow_data?.Workflow?.actions) {
      setWorkflowactions(workflow_data?.Workflow?.actions);
    }
    if (workflow_data?.Workflow?.actions.length > 0) {
      setTriggerDetails(
        workflow_data?.Workflow?.actions.find(
          (action) => action.action_id === "trigger"
        )
      );
      old_trigger_id.current = workflow_data?.Workflow?.actions.find(
        (action) => action.action_id === "trigger"
      ).trigger_id;
    }
  }, [workflow_data?.Workflow?.actions]);

  useEffect(() => {
    if (workflow_data?.Workflow) {
      setWorkflowStatus(workflow_data?.Workflow?.status || false);
    }
  }, [workflow_data, workflow_data?.Workflow?.status]);

  useEffect(() => {
    if (workflowactions === null) return;
    workflow_helper.current = new WorkflowHelper(
      cloneDeep(workflowactions),
      setWorkflowactions
    );
    update_actions({
      variables: {
        workflow_id: workflow_id,
        actions_info: workflow_helper.current.get_workflow(),
      },
    });
    setActionsCount(workflow_helper.current.get_actions_by_order().length);
  }, [workflowactions, saveTrigger]);

  if (updateerror !== undefined) return <ErrorPage updateerror={updateerror} />;
  if (workflow_loading) return <Loaderr />;

  return (
    <>
      <WorkflowHeader
        updatedata={updatedata}
        updating={updating}
        updateerror={updateerror}
        viewOnly={true}
      />
      <div className="main container mx-auto" id="body-pd">
        <section className="col-xl-7 mx-auto">
          <div
            className="d-lg-none alert alert-warning banner-top p-3 py-2 mb-3 rounded-0 m-0"
            role="alert"
          >
            <div className="d-flex flex-wrap align-items-center">
              <div className="d-flex align-items-center my-2">
                <i className="bx bx-mobile fs-5" />
                <i className="bx bx-arrow-to-right me-2 fs-5" />
                <i className="bx bx-desktop me-2 fs-5" />
              </div>
              <span className="my-2">
                Please switch to desktop and optimize this view. Smaller screens
                are not efficient. To learn more click here
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <input
              id="workflow_title"
              placeholder="Workspace title "
              className="workflow-title text-dark"
              type="text"
              name="name"
              value={workflow_data?.Workflow?.workflow_name}
              disabled={true}
              style={{
                width: `${workflow_data?.Workflow?.workflow_name.length + 2}ch`,
              }}
            />
            <div className="py-4">
              <div className="react-switch">
                <Switch
                  checked={workflow_status}
                  width={50}
                  className="switch-rect"
                  height={27}
                  offstyle="primary"
                  disabled={true}
                />
              </div>
            </div>
          </div>

          {workflow_data?.Workflow?.actions &&
          triggerdetails.data_mapper?.trigger_id ? (
            <ViewOnlyActions
              workflow_helper={workflow_helper}
              triggerdetails={triggerdetails}
            />
          ) : (
            <TriggerViewOnly
              triggerdetails={triggerdetails}
              setTriggerDetails={setTriggerDetails}
            />
          )}
        </section>
      </div>
    </>
  );
}

export default ViewOnlyWorkflow;
