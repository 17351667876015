import React, { useContext } from "react";
import { ElementsSettingsWidget } from "./ElementsSettingsWidget";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { BoardDeleteHandler } from "../../BoardHandlerFunctions";
import { StarRatingUi } from "./StarRatingUi";


export const DndStarRatingUi = ({ elementid, element,oppFields,setDeleteField,Elementtype }) => {
  const { board, setboard } = useContext(boardConetxt);
  return (
    <div className={`fm-comp ${Elementtype}`}id={elementid}>
      <ElementsSettingsWidget
        setDeleteField={setDeleteField}
        BoardDeleteHandler={BoardDeleteHandler}
        board={board}
        setboard={setboard}
        elementid={elementid}
        element={element}
      />
      <StarRatingUi elementid={elementid} oppFields={oppFields} />
    </div>
  );
};
