export const PendingFormBuyer = [
    {
        "elementid": "InputField-opp_last_name",
        "type": "InputField",
        "name": "Last Name",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-lead_type",
        "type": "DropDown",
        "name": "Lead Type",
        "settings": {
            "options": [
                {
                    "id": "9201",
                    "value": "Agent Generated Lead",
                    "isCheck": false
                },
                {
                    "id": "0654",
                    "value": "Team Generated Lead",
                    "isCheck": false
                }
            ],
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "",
            "label": "Lead Type"
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-transaction-type",
        "type": "DropDown",
        "name": "Transaction Type",
        "settings": {
            "options": [
                {
                    "id": "4477",
                    "value": "New Construction",
                    "isCheck": false
                },
                {
                    "id": "2694",
                    "value": "Referral",
                    "isCheck": false
                },
                {
                    "id": "0221",
                    "value": "Regular Transaction",
                    "isCheck": false
                }
            ],
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "",
            "label": "Transaction Type"
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is_there_a_secondary_client?",
        "type": "DropDown",
        "name": "Is there a secondary client?",
        "settings": {
            "options": [
                {
                    "id": "5360",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "1534",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "",
            "label": "Is there a secondary client?"
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-secondary_contact",
        "type": "InputField",
        "settings": {
            "label": "Secondary Contact Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Secondary Contact Name",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Contact-secondary_contact_phone",
        "name": "Secondary Contact Phone",
        "type": "Contact",
        "settings": {
            "label": "Secondary Contact Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Email-secondary_contact_email",
        "type": "Email",
        "settings": {},
        "name": "Secondary Contact Email",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-signed_vip_agreement",
        "type": "DropDown",
        "name": "Signed VIP Agreement",
        "settings": {
            "options": [
                {
                    "id": "6668",
                    "value": "VIP Signed",
                    "isCheck": false
                },
                {
                    "id": "9700",
                    "value": "No VIP",
                    "isCheck": false
                }
            ],
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "",
            "label": "Signed VIP Agreement"
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "FreeText-address",
        "type": "FreeText",
        "name": "Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address",
        "type": "InputField",
        "name": "Address of Appointment-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-Street Address",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address2",
        "type": "InputField",
        "name": "Address of Appointment-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_city",
        "type": "InputField",
        "name": "Address of Appointment-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-City",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_state",
        "type": "InputField",
        "name": "Address of Appointment-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-State",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_postal_code",
        "type": "InputField",
        "name": "Address of Appointment-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-Zip Code",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-opp_country",
        "type": "DropDown",
        "field_sub_type": "opportunity",
        "name": "Address of Appointment-Country",
        "settings": {
            "options": [
                {
                    "id": 25545,
                    "value": "USA",
                    "isCheck": false
                },
                {
                    "id": 2551,
                    "value": "Canada",
                    "isCheck": false
                }
            ],
            "label": "Country",
            "size": "large",
            "inputType": "dropdown",
            "required": false,
            "hidden": false,
            "minValue": "",
            "maxValue": "",
            "instructions": ""
        }
    }
,
    {
        "elementid": "NumberField-transaction_amount",
        "type": "NumberField",
        "name": "Transaction Amount",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "NumberField-buyer_agent_commission_percent",
        "type": "NumberField",
        "name": "Buyer Agent Commission Percent",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Date-opp_under_contract_date",
        "name": "Under Contract Date",
        "type": "Date",
        "settings": {
            "label": "Under Contract Date",
            "dateformat": "DD/MM/YYYY",
            "predefinedate": "",
            "size": "large",
            "inputType": "text",
            "required": true,
            "minValue": "",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-opp_forecasted_close_date",
        "type": "Date",
        "name": "Forecasted Close Date",
        "opportunity_field_id": "date_opp_forecasted_close_date",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-cooperating_agent_company",
        "type": "InputField",
        "name": "Cooperating Agent Company",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-cooperating_agent_name",
        "type": "InputField",
        "name": "Cooperating Agent Name",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Contact-cooperating_agent_phone",
        "type": "Contact",
        "name": "Cooperating Agent Phone",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Email-cooperating_agent_phone",
        "type": "Email",
        "name": "Cooperating Agent Email",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-cooperating_tc_name",
        "type": "InputField",
        "name": "Cooperating TC Name",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-cooperating_tc_phone",
        "type": "InputField",
        "name": "Cooperating TC Phone",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Email-cooperating_tc_email",
        "type": "Email",
        "name": "Cooperating TC Email",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-opposite_side_client_name",
        "type": "InputField",
        "name": "Opposite Side Client Name",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is_there_an_opposite_side_secondary_client?",
        "type": "DropDown",
        "name": "Is there an opposite side secondary client?",
        "settings": {
            "options": [
                {
                    "id": "7394",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "5262",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "",
            "label": "Is there an opposite side secondary client?"
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-opposite_side_secondary_client_name",
        "type": "InputField",
        "name": "Opposite Side Secondary Client Name",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-escrow_company",
        "type": "DropDown",
        "name": "Escrow Company",
        "settings": {
            "options": [
                {
                    "id": "4523",
                    "value": "Pinnacle Escrow",
                    "isCheck": false
                },
                {
                    "id": "9030",
                    "value": "Other",
                    "isCheck": false
                }
            ],
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "",
            "label": "Escrow Company"
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-escrow_company_name",
        "type": "InputField",
        "name": "Escrow Company Name",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-escrow_officer_name",
        "type": "InputField",
        "name": "Escrow Officer Name",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Contact-escrow_officer_phone",
        "type": "Contact",
        "name": "Escrow Officer Phone",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-financing_type",
        "type": "DropDown",
        "name": "Financing Type",
        "settings": {
            "options": [
                {
                    "id": "1764",
                    "value": "Conventional",
                    "isCheck": false
                },
                {
                    "id": "4537",
                    "value": "VA",
                    "isCheck": false
                },
                {
                    "id": "3566",
                    "value": "FHA",
                    "isCheck": false
                },
                {
                    "id": "3464",
                    "value": "Cash",
                    "isCheck": false
                }
            ],
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "",
            "label": "Financing Type"
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-loan_officer",
        "type": "DropDown",
        "name": "Loan Officer",
        "settings": {
            "options": [
                {
                    "id": "6695",
                    "value": "Jason Hall - Team Home Loans",
                    "isCheck": false
                },
                {
                    "id": "1428",
                    "value": "AJ Schackman - Mavin Mortgage",
                    "isCheck": false
                },
                {
                    "id": "2803",
                    "value": "Other",
                    "isCheck": false
                }
            ],
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "",
            "label": "Loan Officer"
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-mortgage_company",
        "type": "InputField",
        "name": "Mortgage Company",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-loan_officer_name",
        "type": "InputField",
        "name": "Loan Officer Name",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Email-loan_officer_email",
        "type": "Email",
        "settings": {},
        "name": "Loan Officer Email",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Contact-loan_officer_phone",
        "type": "Contact",
        "name": "Loan Officer Phone",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "NumberField-loan_amount",
        "type": "NumberField",
        "name": "Loan Amount",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-title_company",
        "type": "DropDown",
        "name": "Title Company",
        "settings": {
            "options": [
                {
                    "id": "3871",
                    "value": "Title Co 1",
                    "isCheck": false
                },
                {
                    "id": "7382",
                    "value": "Title Co 2",
                    "isCheck": false
                },
                {
                    "id": "6386",
                    "value": "Other",
                    "isCheck": false
                }
            ],
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "",
            "label": "Title Company"
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-title_company_name",
        "type": "InputField",
        "name": "Title Company Name",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Email-title_rep_email",
        "type": "Email",
        "settings": {
            "label": "Title Rep Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Title Rep Email",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-title_rep_name",
        "type": "InputField",
        "name": "Title Rep Name",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is_there_an_hoa?",
        "type": "DropDown",
        "name": "Is there an HOA?",
        "settings": {
            "options": [
                {
                    "id": "3212",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "5715",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "",
            "label": "Is there an HOA?"
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-hoa_name",
        "type": "InputField",
        "name": "HOA Name",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-hoa_management_company",
        "type": "InputField",
        "name": "HOA Management Company",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Contact-hoa_management_phone",
        "type": "Contact",
        "name": "HOA Management Phone",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-solar",
        "type": "DropDown",
        "name": "Solar",
        "settings": {
            "options": [
                {
                    "id": "1760",
                    "value": "None",
                    "isCheck": false
                },
                {
                    "id": "7585",
                    "value": "Solar - Paid in Full",
                    "isCheck": false
                },
                {
                    "id": "1289",
                    "value": "Solar - Financed",
                    "isCheck": false
                },
                {
                    "id": "3139",
                    "value": "Solar - PPA",
                    "isCheck": false
                },
                {
                    "id": "6970",
                    "value": "Solar - Leased",
                    "isCheck": false
                },
                {
                    "id": "3900",
                    "value": "Solar - Ownership Unknown",
                    "isCheck": false
                }
            ],
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "",
            "label": "Solar"
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-are_you_charging_the_admin_fee__and_if_so__who_is_responsibl",
        "type": "DropDown",
        "name": "Are you charging the admin fee, and if so, who is responsible for paying it?",
        "settings": {
            "options": [
                {
                    "id": "1512",
                    "value": "No - N/A",
                    "isCheck": false
                },
                {
                    "id": "8596",
                    "value": "Yes - Seller Paying Fee",
                    "isCheck": false
                },
                {
                    "id": "8045",
                    "value": "Yes - Buyer Paying Fee",
                    "isCheck": false
                },
                {
                    "id": "7324",
                    "value": "Yes - Buyer-to-be-credited-by-Team-Home-Loans",
                    "isCheck": false
                }
            ],
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "",
            "label": "Are you charging the admin fee, and if so, who is responsible for paying it?"
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-referral_fee",
        "type": "DropDown",
        "name": "Referral Fee",
        "settings": {
            "options": [
                {
                    "id": "7018",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "6191",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "",
            "label": "Referral Fee"
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-referral_fee__company",
        "type": "InputField",
        "name": "Referral Fee: Company",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-referral_fee__agent",
        "type": "InputField",
        "name": "Referral Fee: Agent",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "NumberField-referral_fee__percent",
        "type": "NumberField",
        "name": "Referral Fee: Percent",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-are_you_co_representing_with_another_agent?",
        "type": "DropDown",
        "name": "Are you co-representing with another agent?",
        "settings": {
            "options": [
                {
                    "id": "4985",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "5970",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "",
            "label": "Are you co-representing with another agent?"
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-2nd_agent_name",
        "type": "DropDown",
        "name": "2nd Agent Name",
        "settings": {
            "options": [
                {
                    "id": "7160",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "7094",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "3974",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "",
            "label": "2nd Agent Name"
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "TextArea-Notes",
        "type": "TextArea",
        "name": "Notes",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-opp-key",
        "type": "InputField",
        "settings": {},
        "name": "Opp key",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-Follow-up-boss-lead-id",
        "type": "InputField",
        "name": "Follow Up Boss Lead ID",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-lead_type",
        "name": "Lead Type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9201",
                    "value": "Agent Generated Lead",
                    "isCheck": false
                },
                {
                    "id": "0654",
                    "value": "Team Generated Lead",
                    "isCheck": false
                }
            ],
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "",
            "label": "Lead Type"
        }
    }
]

export const PendingFormBuyerCondition=[
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-is_there_a_secondary_client?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-secondary_contact",
            "Contact-secondary_contact_phone",
            "Email-secondary_contact_email"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Other",
                    "operator": "equals",
                    "field_id": "DropDown-loan_officer"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-mortgage_company",
            "InputField-loan_officer_name",
            "Contact-loan_officer_phone",
            "Email-loan_officer_email"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Conventional",
                    "operator": "equals",
                    "field_id": "DropDown-financing_type"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "VA",
                    "operator": "equals",
                    "field_id": "DropDown-financing_type"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "FHA",
                    "operator": "equals",
                    "field_id": "DropDown-financing_type"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-loan_officer"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Other",
                    "operator": "equals",
                    "field_id": "DropDown-title_company"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-title_company",
            "InputField-title_company_name",
            "InputField-title_rep_name",
            "Email-title_rep_email"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-is_there_an_hoa?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-hoa_management_company",
            "Contact-hoa_management_phone",
            "InputField-hoa_name"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "",
                    "operator": "equals",
                    "field_id": "DropDown-referral_fee"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-referral_fee__company",
            "InputField-referral_fee__agent",
            "NumberField-referral_fee__percent"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Buyer",
                    "operator": "equals",
                    "field_id": "DropDown-client-type"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-signed_vip_agreement"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Other",
                    "operator": "equals",
                    "field_id": "DropDown-escrow_company"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-escrow_company_name",
            "InputField-escrow_officer_name",
            "Contact-escrow_officer_phone"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-is_there_an_opposite_side_secondary_client?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-opposite_side_secondary_client_name"
        ],
        "ConditionName": "Condition Name"
    }
]