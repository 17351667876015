import React, { useContext } from "react";
import { gql, useMutation } from "@apollo/client";
import { WorkFlowSessionInfo } from "../../../pages/automation-builder/workflows/EditWorkflow";

const CreateFormTrigger = gql`
  mutation CreateFormTrigger($formId: String!) {
    CreateFormTrigger(form_id: $formId) {
      statusCode
      message
      data
      trigger_id
    }
  }
`;

export function FormsTriggersList({
  platform,
  triggerdetails,
  setplatform,
  setTriggerDetails,
  availableTriggers,
  details,
  setdetails,
  setIsTriggerCreated,
}) {
  const { workflow_helper } = useContext(WorkFlowSessionInfo);

  const [createformtrigger, { loading: creatingtrigger, error: createerror }] =
    useMutation(CreateFormTrigger, {
      onCompleted: (createformdata) => {
        setTriggerDetails({
          ...details,
          test_status: "Not Tested",
          data_mapper: {
            ...details.data_mapper,
            trigger_id: createformdata?.CreateFormTrigger.trigger_id,
          },
        });

        workflow_helper.current.add_trigger({
          ...details,
          test_status: "Not Tested",
          data_mapper: {
            ...details.data_mapper,
            trigger_id: createformdata?.CreateFormTrigger.trigger_id,
          },
        });
        setIsTriggerCreated("created");
        if (createformdata?.CreateFormTrigger?.statusCode >= 300) {
          createformdata?.CreateFormTrigger?.data?.errorMessage
            ? alert(createformdata?.CreateFormTrigger?.data?.errorMessage)
            : alert("An unkown error occured.");
        }
      },
    });

  const handleTriggerDetails = (trigger, triggertype) => {
    if (trigger?.form_id) {
      setdetails({
        ...triggerdetails,
        action_id: "trigger",
        api_name: "trigger",
        api_type: "trigger",
        data_mapper: {
          type: triggertype,
          name: trigger?.form_name,
          description: trigger?.form_description,
          trigger_id: "",
          platform: "INTERFACE",
          form_id: trigger?.form_id,
        },
      });

      createformtrigger({
        variables: {
          formId: trigger?.form_id,
        },
      });

      setIsTriggerCreated("creating");
    }
  };

  if (creatingtrigger) return "Loading...";

  if (createerror) return `Error! ${createerror.message}`;

  return (
    <>
      <div className="d-flex align-items-center">
        <div
          onClick={() => {
            setplatform("");
          }}
        >
          <i className="iconify me-2 back-btn" data-icon="ep:back"></i>
        </div>
        <p className="m-0">Select Trigger you want to Connect</p>
      </div>
      {Object.entries(availableTriggers)?.length > 0 ? (
      <div className="my-4 row row-cols-m2 gx-4">
        {Object.entries(availableTriggers).map((triggers, index) => (
          <div className="ps-lg-2 pt-3" key={index}>
            {triggers.map(
              (trigger, i) =>
                typeof trigger === "object" && (
                  <div
                    className="text-primary d-flex justify-content-between align-items-center py-2 pointer border rounded-2 px-2 mb-2"
                    key={i}
                    data-bs-toggle="modal"
                    data-bs-target="#triggerModal"
                    onClick={() => {
                      handleTriggerDetails(trigger, "FORM");
                    }}
                  >
                    <span>
                      <i className="iconify" data-icon="ic:sharp-bolt"></i>{" "}
                      {trigger.form_name}
                    </span>
                    <i className="iconify" data-icon="mdi:chevron-right"></i>
                  </div>
                )
            )}
          </div>
        ))}
      </div>
      ) : (
        <div className="my-4 row row-cols-2 gx-4 ">
          <div className="p-d0 mt-4 w-100">
              <h6>No forms created yet.</h6>
          </div>
        </div>
      )}
    </>
  );
}

export default FormsTriggersList;
