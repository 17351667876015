import React from "react";
import TriggerViewOnly from "../../../actions/view-only-actions/TriggerViewOnly";
import FubViewOnly from "../../../actions/view-only-actions/FubViewOnly";
import TemplateFiltersViewOnly from "../../../actions/view-only-actions/TemplateFiltersViewOnly";
import DateUtilityViewOnly from "../../../actions/view-only-actions/DateUtilityViewOnly";

const ViewOnlyAction = ({
  action_index,
  action_info,
  triggerdetails,
  action_id,
  workflow_helper,
}) => {
  const showAction = () => {
    let action_platform =
      action_id === "trigger"
        ? "trigger"
        : action_info.platform?.toLowerCase() ||
          action_id.toLowerCase().split("#")[0];
    var action = <></>;
    if (action_platform === "trigger") {
      action = (
        <TriggerViewOnly
          action_index={action_index}
          triggerdetails={triggerdetails}
        />
      );
    } else if (action_platform.includes("fub")) {
      action = (
        <FubViewOnly
          action_index={action_index}
          selectAutomation={action_info.automation_name}
        />
      );
    } else if (action_platform === "filter") {
      action = (
        <TemplateFiltersViewOnly
          action_index={action_index}
          selectAutomation={action_info?.meta_data?.automation_name}
          filterType={action_info?.meta_data?.filterType || "template"}
        />
      );
    } else if (action_platform.includes("utility")) {
      action = (
        <DateUtilityViewOnly
          action_index={action_index}
          selectAutomation={action_info.automation_name}
        />
      );
    }
    return action;
  };

  return <>{showAction()}</>;
};

export default ViewOnlyAction;
