import React from "react";
import ViewOnlyAction from "./ViewOnlyAction";

export default function ViewOnlyActions({ workflow_helper, triggerdetails }) {
  let actions = [];
  let last_action_id = null;
  workflow_helper.current.get_actions_by_order().map((action, index) => {
    actions.push(
      <ViewOnlyAction
        action_index={index + 1}
        key={action.action_id}
        triggerdetails={triggerdetails}
        action_id={action.action_id}
        action_info={action}
        workflow_helper={workflow_helper}
      />
    );
    last_action_id = action.action_id;
    return actions;
  });
  return actions;
}
