import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import logo from "../assets/images/LOGO-BLUE.png";



const get_hostname = () => {
  let url = window.location.host
  console.log("url", window.location)
  let hostname = ""
  if (url === "app.sandbox.datalabz.re") {
    hostname = "https://sandbox.datalabz.re/dashboard"
  }
  else if (url === "app.test.datalabz.re") {
    hostname = "https://app.test.datalabz.re/dashboard"
  }
  else if (url === "app.datalabz.re") {
    hostname = "https://datalabz.re/dashboard"
  }
  else {
    hostname = "http://localhost:8000/dashboard"
  }
  return hostname;
}

export const HeadNavBar = () => {

  const [urlLocation, setUrlLocation] = useState("build");
  const location = useLocation();
  const params = useParams();

  const locationFuntion = (pathname) => {
    console.log("pathname", typeof(pathname))
    if (
      pathname === "/forms/" ||
      pathname === "/forms" ||
      pathname === `/forms/create-form`
    ) {
      return setUrlLocation("dashboard");
    } else if (
      pathname === `/forms/build/${params.id}` ||
      pathname === `/forms/conditions/${params.id}`
    ) {
      setUrlLocation("build");
    }
  };
  // console.log("urlLocation", location.pathname, get_hostname(), urlLocation)
  useEffect(() => {
    locationFuntion(location.pathname);
  }, [location?.pathname]);

  return (
    <>
      {urlLocation === "dashboard" ? (
        <header className="header bg-white border-bottom" id="header">
          <div>
            <NavLink
              to={
                location.pathname === "/forms/" || "/forms" ? get_hostname() : "http://localhost:3000/forms/"
              }
            >
              <div className="d-flex align-items-center ms-2">
                <span className="navbar-brand m-0 p-0">
                  <img className="img-fluid" src={logo} width={30} alt="" />
                </span>
                <i className="bx bx-chevron-left nav_icon mx-md-2"></i>



                <span className="p-0 m-0">Dashboard</span>
              </div>
            </NavLink>
          </div>
        </header>
      ) : (
        <>
          <header className="header bg-white border-bottom" id="header">
            <div>
              <NavLink to="/forms/">
                <div className="d-flex align-items-center ms-2">
                  <span className="navbar-brand m-0 p-0">
                    <img className="img-fluid" src={logo} width={30} alt="" />
                  </span>
                  <i className="bx bx-chevron-left nav_icon mx-md-2"></i>

                  <span className="p-0 m-0">Dashboard</span>
                </div>
              </NavLink>
            </div>
            <ul
              className="nav nav-pills fmb-cont"
              id="pills-tab"
              role="tablist"
            >
              <NavLink
                to={
                  params.id !== "undefined" && params.id !== undefined
                    ? `/forms/build/${params.id}`
                    : "/forms/create-form"
                }
                className="nav-link fmb d-flex align-items-center"
                role="presentation"
              >
                {/* <div className="nav-link fmb d-flex align-items-center" data-bs-target="#pills-build"> */}
                <div
                  className="fmb-i rounded-circle d-flex align-items-center justify-content-center "
                  style={{ width: "35px", height: "35px" }}
                >
                  <i className="iconify fs-5" data-icon="codicon:settings" />
                </div>
                <span className="d-lg-block d-none">Build</span>
              </NavLink>
              <NavLink
                to={
                  params.id !== "undefined" && params.id !== undefined
                    ? `/forms/conditions/${params.id}`
                    : "/forms/create-form"
                }
                className="nav-item pointer nav-link fmb d-flex align-items-center "
                role="presentation"
              >
                {/* <div className="nav-link fmb d-flex align-items-center" data-bs-target="#pills-conditions"> */}
                <div
                  className="fmb-i rounded-circle d-flex align-items-center justify-content-center "
                  style={{ width: "35px", height: "35px" }}
                >
                  <i className="iconify fs-5" data-icon="carbon:branch" />
                </div>
                <span className="d-lg-block d-none">Conditions</span>
                {/* This temp solution */}
                <span class="rounded-pill fw-light text-dark badge bg-warning" style={{marginLeft:"5px"}}> Beta </span>  
                {/* </div> */}
              </NavLink>
              {/* 
              <a href="" className="nav-item pointer" role="presentation">
  <div
    className="nav-link active fmb d-flex align-items-center"
    data-bs-target="#pills-preview"
  >
    <div
      className="fmb-i rounded-circle d-flex align-items-center justify-content-center "
      style={{ width: 35, height: 35 }}
    >
      <i className="iconify fs-5" data-icon="carbon:settings" />
    </div>
    <span className="d-lg-block d-none">Settings</span>
  </div>
</a> */}

              <NavLink
                to={
                  params.id !== "undefined" && params.id !== undefined
                    ? `/forms/settings/${params.id}`
                    : "/forms/create-form"
                }
                className="nav-item pointer nav-link fmb d-flex align-items-center "
                role="presentation"
              >
                {/* <div className="nav-link fmb d-flex align-items-center" data-bs-target="#pills-conditions"> */}
                <div
                  className="fmb-i rounded-circle d-flex align-items-center justify-content-center "
                  style={{ width: 35, height: 35 }}
                >
                  <i className="iconify fs-5" data-icon="carbon:settings" />
                </div>
                <span className="d-lg-block d-none">Settings</span>

                {/* </div> */}
              </NavLink>

              <NavLink
                to={
                  params.id !== "undefined" && params.id !== undefined
                    ? `/forms/${params.id}`
                    : "/forms/create-form"
                }
                target="_blank"
                className="nav-item pointer nav-link fmb d-flex align-items-center"
                role="presentation"
              >
                {/* <div className="nav-link fmb d-flex align-items-center" data-bs-target="#pills-preview"> */}
                <div
                  className="fmb-i rounded-circle d-flex align-items-center justify-content-center "
                  style={{ width: "35px", height: "35px" }}
                >
                  <i
                    className="iconify fs-5"
                    data-icon="carbon:data-view-alt"
                  />
                </div>
                <span className="d-lg-block d-none">Preview</span>
                {/* </div> */}
              </NavLink>
            </ul>
            <div className="d-flex align-items-center">
              {/* {(//console.log("location.pathname", location?.pathname))}
        {location?.pathname === `/forms/conditions/${params?.id}` && (
          <div className="d-flex align-items-center btn btn-primary ms-3">
            <i
              className="iconify me-md-1 nav_icon"
              data-icon="ic:outline-save-alt"
            />
            <span
              className="d-md-block d-none"
              onClick={(e) => {
                handelSave(e);
              }}
            >
              Save Changes
            </span>
          </div>
        )} */}
            </div>
          </header>
        </>
      )}
    </>
  );
};

export const OppsNavBar = () => {
  return (
    <>
      <header className="header bg-white border-bottom" id="header">
        <div>
          <NavLink
            to={get_hostname()}
          >
            <div className="d-flex align-items-center ms-2">
              <span className="navbar-brand m-0 p-0">
                <img className="img-fluid" src={logo} width={30} alt="" />
              </span>
              <i className="bi bi-chevron-left nav_icon mx-md-2 mx-1"></i>

              <span className="p-0 m-0">Dashboard</span>
            </div>
          </NavLink>
        </div>
      </header>
    </>
  );
};
