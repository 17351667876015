import React from "react";

export const ErrorPage = ({updateerror}) => {
  return (
    <>
      <div className="container" style={{display:"flex",flexDirection:"column",alignItems:"center",marginTop:"182px"}}>
        <h2>Oops! Page not found.</h2>
        {/* <h1 style={{textAlign:"center"}}>{updateerror}</h1> */}
        <p>We can't find the page you're looking for.</p>
        <a href="/automation-builder/create-trigger">Go back home</a>
      </div>
    </>
  );
};
