import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import TextBox from "../../../components/TextBox";
import { generatekeys_per_action } from "../../../utils/parser";
import { uniqueId } from "lodash";
import Accordion from "../../../components/AccordionBasic";
import { WorkFlowSessionInfo } from "../../../pages/automation-builder/workflows/EditWorkflow";

const operators = [
  {"= Equals (Text)": {'operator': 'equals', 'data_type': 'text'}},
  // {'= Equals (Bool)': {'operator': 'equals', 'data_type': 'bool'}},
  {'!= Not Equals (Text)': {'operator': 'not_equals', 'data_type': 'text'}},
  // {'!= not_equals (Bool)': {'operator': 'not_equals', 'data_type': 'bool'}},
  {'Starts with (Text)': {'operator': 'equals', 'data_type': 'text'}},
  {'Ends with (Text)': {'operator': 'endswith', 'data_type': 'text'}},
  {'Contains (Text)': {'operator': 'contains', 'data_type': 'text'}},
  {"Doesn't Contains (Text)": {'operator': 'doesnt_contains', 'data_type': 'text'}},
  
  {"= Equals (Number)": {'operator': 'equals', 'data_type': 'decimal'}},
  {"!= Not Equals (Number)": {'operator': 'not_equals', 'data_type': 'decimal'}},
  {'Greater Than (Number)': {'operator': 'greater_than', 'data_type': 'decimal'}},
  {'Less Than (Number)': {'operator': 'less_than', 'data_type': 'decimal'}},
  {'Less Than or Equal to (Number)': {'operator': 'less_than_equal', 'data_type': 'decimal'}},
  {'Greater Than or Equal to (Number)': {'operator': 'greater_than_equal', 'data_type': 'decimal'}},
  
  {"= Equals (Date)": {'operator': 'equals', 'data_type': 'date'}},
  {"!= Not Equals (Date)": {'operator': 'not_equals', 'data_type': 'date'}},
  {'Greater Than (Date)': {'operator': 'greater_than', 'data_type': 'date'}},
  {'Less Than (Date)': {'operator': 'less_than', 'data_type': 'date'}},
  {'Less Than or Equal to (Date)': {'operator': 'less_than_equal', 'data_type': 'date'}},
  {'Greater Than or Equal to (Date)': {'operator': 'greater_than_equal', 'data_type': 'date'}},
  {'Exists': {'operator': 'exists', 'data_type': ''}},
  {'Not Exists': {'operator': 'not_exists', 'data_type': ''}},
]

const FilterSingleCondition = ({
  data_mapper,
  workflowHelper,
  action_id,
  orIndex,
  andIndex,
  andInput,
  conditions,
  setConditions,
}) => {
  const [insert_value, set_insert_value] = useState(null);
  const [insert_expectedvalue, set_insert_expectedvalue] = useState(null);
  const { session } = useContext(WorkFlowSessionInfo);
  const value_id = useMemo(() => uniqueId(), []);
  const expected_value_id = useMemo(() => uniqueId(), []);
  let field_value_key = "!#value";
  let field_expexted_value_key = "!#expected_value";
  let field_operator_key = "!#operator";
  let field_data_type_key = "!#data_type";
  const [value, setValue] = useState(andInput?.[field_value_key]);
  const [expectedValue, setExpectedValue] = useState(
    andInput?.[field_expexted_value_key]
  );
  const [operator, setOperator] = useState(andInput?.[field_operator_key]);
  const [operatorDisplay, setOperatorDisplay] = useState(andInput?.["!#operator"] || "" );
  const [data_type, setData_type] = useState(andInput?.[field_data_type_key]);
  const [selected_modal_id, select_modal_id] = useState("");
  const select_modal = useCallback((field_id) => {
    select_modal_id(field_id);
  }, []);

  useEffect(() => {
     if (selected_modal_id === value_id) {
       document.querySelector("#modal_" + value_id).style.display = "block";
     } else {
       document.querySelector("#modal_" + value_id).style.display = "none";
     }
     if (selected_modal_id === expected_value_id) {
       document.querySelector("#modal_" + expected_value_id).style.display =
         "block";
     } else {
       document.querySelector("#modal_" + expected_value_id).style.display =
         "none";
     }
   }, [selected_modal_id, value_id, expected_value_id]);
 

   useEffect(() => {
    const operatorValue = andInput?.["!#operator"];
    const dataTypeValue = andInput?.["!#data_type"];
  
    let foundOperator = null;
    let foundDisplayKey = null;
  
    for (const operatorObj of operators) {
      for (const key in operatorObj) {
        const operator = key;
        const data_type = operatorObj[key].data_type;
  
        if (operatorObj[key].operator === operatorValue && data_type === dataTypeValue) {
          foundDisplayKey = operator;
          foundOperator = operatorObj[key].operator;
          break; // Exit the loop once a match is found
        }
      }
      if (foundOperator && foundDisplayKey) {
        break; // Exit the outer loop if both values are found
      }
    }
  
    if (foundOperator !== null && foundDisplayKey !== null) {
      setOperator(foundOperator); // Set the actual operator state
      setOperatorDisplay(foundDisplayKey); // Set the display text state
    }
  }, [data_mapper]);

  var keys_ = generatekeys_per_action(session);
  let keys = {};
  const val = workflowHelper.get_parent_chain(action_id);

  const store = val.map((val) => {
    keys[val] = [];
    const checker = Object.keys(keys_).map((key) => {
      if (key === val) {
        return (keys[val] = keys_[val]);
      }
      if(key === "todays_date"){
        return (keys["todays_date"] = keys_["todays_date"]);
      }
    });
    return checker === undefined ? (keys_[val] = []) : checker;
  });

  const orderedKeys = {
    todays_date: keys.todays_date,
    ...keys,
  };

  useEffect(() => {
    const newConditions = [...conditions];
    const existingCondition = newConditions[orIndex][andIndex];
    existingCondition["!#value"] = value || "";
    existingCondition["!#operator"] = operator || "";
    existingCondition["!#expected_value"] = expectedValue || "";
    existingCondition["!#data_type"] = data_type || "";
    newConditions[orIndex][andIndex] = existingCondition;
    setConditions(newConditions);
  }, [value, operator, expectedValue, data_type]);

  return (
    <div className="w-100 ">
      <div className="">
        <div className="col-12 m-0 p-0 row position-relative">

          <div className="col-4 p-0">
            <div className="">
              <span className="text-truncate">{}</span>
              <div
                className="d-flex mb-3"
                onClick={(e) => select_modal(value_id)}
              >
                <div
                  className="d-flex align-items-center border bg-white p-1 pb-0 rounded-start txt-container input-bn fgoo"
                  role="textbox"
                >
                  <TextBox
                    text={value}
                    settext={setValue}
                    insert_value={insert_value}
                    set_insert_value={set_insert_value}
                    select_modal={select_modal}
                  />
                </div>
                {/* <span className="input-group-text rounded-0 rounded-end px-1 mloo">
                  <i
                    className="iconify fs-4"
                    data-icon="mdi:chevron-down"
                  ></i>
                </span> */}
              </div>
            </div>
            <Accordion
                key={value_id}
                keys={orderedKeys}
                set_insert_value={set_insert_value}
                field_id={value_id}
                select_modal={select_modal}
                acccordion_title={"filter_accordion1" + andIndex}
            />
          </div>
          
          <div className="col-4 ">
            <div className="input-group mb-3">
              <select
                className="form-control form-select input-bn"
                name="operator"
                onChange={(e) => {
                  setOperator(e.target.value.split("__")[0]);
                  setData_type(e.target.value.split("__")[1]);
                }}
              >
                <option hidden value={operator}>
                {operatorDisplay || "Select operator"}
                </option>
                {operators.map((item, index) => (
                  <option
                    key={index}
                    value={`${Object.values(item)[0].operator}__${Object.values(item)[0].data_type}`}
                  >
                    {Object.keys(item)}
                  </option>
                ))}
              </select>
              {/* <span className="input-group-text px-1">
                <i
                  className="iconify fs-4"
                  data-icon="mdi:chevron-down"
                ></i>
              </span> */}
            </div>
          </div>


          <div className="col-4 p-0">
          { operator !== "exists" && operator !== "not_exists" &&
            <div className="">
              <span className="text-truncate">{}</span>
              <div
                className="d-flex mb-3"
                onClick={(e) => select_modal(expected_value_id)}
              >
                <div
                  className="d-flex align-items-center border bg-white p-1 pb-0 rounded-start txt-container input-bn fgoo"
                  role="textbox"
                >
                  <TextBox
                    text={expectedValue}
                    settext={setExpectedValue}
                    insert_value={insert_expectedvalue}
                    set_insert_value={set_insert_expectedvalue}
                    select_modal={select_modal}
                  />
                </div>
                {/* <span className="input-group-text rounded-0 rounded-end px-1 mloo">
                  <i
                    className="iconify fs-4"
                    data-icon="mdi:chevron-down"
                  ></i>
                </span> */}
              </div>
            </div>
          }
            <Accordion
              key={expected_value_id}
              keys={orderedKeys}
              set_insert_value={set_insert_expectedvalue}
              field_id={expected_value_id}
              select_modal={select_modal}
              acccordion_title={"filter_accordion2" + andIndex}
            />
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default FilterSingleCondition;
