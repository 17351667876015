import { FormEntryDataTable } from "./FormEntryDataTable";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loaderr } from "../../../components/Loaderr";
import { useLocation, useNavigate } from "react-router-dom";
import useFormApiHelper from "../../../utils/useFormApiHelper";
import timeConverter from "../../../utils/UnixTimeStamConverter";
import { Controller, useForm, useFormContext } from "react-hook-form";

function FormEntryData() {
  const param = useParams();
  const navigate = useNavigate();
  const { FormEntry, GetFormData } = useFormApiHelper();
  const { formEntryLoading, formEntryError, formEntry } = FormEntry(param);
  const { formDataLoading, formDataError, formData } = GetFormData(param);

  if (formEntryLoading || formDataLoading) return <Loaderr />;
  console.log("formEntry", formEntry);
  console.log("formData", formData);
  return (
    <FormEntryDataUI
      navigate={navigate}
      formEntry={formEntry}
      formEntryLoading={formEntryLoading}
      formEntryError={formEntryError}
      formData={formData?.formData}
    />
  );
}

export default FormEntryData;

function FormEntryDataUI({
  navigate,
  formEntry,
  formData,
  formEntryLoading,
  formEntryError,
}) {
  const { register, handleSubmit, formState: { errors } } = useForm();

    const [editable, setEditable] = useState(true);
  const [formEntryData, setFormEntryData] = useState(formEntry);
  const { UpdateFormEntry } = useFormApiHelper();
  const {
    updateFormEntry,
    updateFormEntryLoading,
    updateFormEntryError,
    updateFormEntryData,
  } = UpdateFormEntry();
  const handleEdit = () => {
    setEditable(!editable);
  };
  const [error, setError] = useState(false);

  const handelSave = () => {
    //console.log("formEntry save update",formEntryData);
    setEditable(!editable);
    updateFormEntry({
      variables: {
        formEntryId: formEntry.formEntry.form_entry_id,
        formId: formEntry.formEntry.form_id,
        formEntryData: [formEntryData.formEntry.form_entry_data[0]],
      },
    });
  };
  const onSubmit = (data) => {
    console.log("entry<<", data);
  }

  const handleInputChange = (e,type,entry) => {
    // console.log("ee", [e.target.id], e.target.value);
  
    setFormEntryData({
      ...formEntryData,
      formEntry: {
        ...formEntryData.formEntry,
        form_entry_data: [
          {
            ...formEntryData.formEntry.form_entry_data[0],
            [e.target.id]: e.target.value,
            },
        ],
      },
    });
  };
  console.log("formEntryData>><<", errors);
  return (
    // <form onSubmit={handleSubmit(onSubmit)}>
    <form onSubmit={handleSubmit(onSubmit)}>

    <div>
      <div className="main fmc-main p-0">
        <section className="container-lg container-fluid pt-5">
          <b
            className="text-secondary pointer"
            onClick={() =>
              navigate(`/forms/entries/${formEntry?.formEntry?.form_id}`)
            }
          >
            <i className="iconify me-2" data-icon="mdi:arrow-left" />
            Back to Form Entries
          </b>

          <div className="p-4 bg-white border rounded-3 mb-3 mt-3 d-flex justify-content-between align-items-center">
            <span>
              <h5>
                <i className="iconify me-2" data-icon="tdesign:form" />
                {formData?.form_name}
              </h5>
              <hr className="hr op-10" />
              <p className="text-secondary mt-2 m-0">
                 <span className="text-dark me-2">Entry Date:</span>{timeConverter(formEntry?.formEntry?.entry_created_date).split(",")[0]}
             </p>
             <p className="text-secondary mt-2 m-0">
                 <span className="text-dark me-2">Entry Time:</span>{timeConverter(formEntry?.formEntry?.entry_created_date).split(",")[1]}
             </p>
            </span>

            {/* <button
              type="submit"
              className="btn btn-primary btn-md"
              onClick={() => (editable ? handleEdit() : handelSave())}
              disabled={error} // Disable if there are any errors

            >
              {" "}
              {editable ? "Edit" : "Save"}
            </button> */}
         

          </div>
          <section className="d-flex flex-column py-4 gap-3">
            <FormEntryDataTable
              formEntryData={formEntryData}
              formData={formData}
              editable={editable}
              handleInputChange={handleInputChange}
              setError={setError}
            />
          </section>
        </section>
      </div>
    </div>
     </form>
  );
}
