export const whisselTeamPendingFormBuyer =[
    {
        "elementid": "InputField-opp_last_name",
        "type": "InputField",
        "name": "Client Last Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Client Last Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-lead_type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "6433",
                    "value": "Agent Generated Lead",
                    "isCheck": false
                },
                {
                    "id": "7709",
                    "value": "Team Generated Lead",
                    "isCheck": false
                }
            ],
            "label": "Lead Type",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Lead Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-transaction_type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9763",
                    "value": "Regular Transaction",
                    "isCheck": false
                },
                {
                    "id": "6240",
                    "value": "New Construction",
                    "isCheck": false
                },
                {
                    "id": "4692",
                    "value": "Referral Fee Only",
                    "isCheck": false
                },
                {
                    "id": "6523",
                    "value": "Homelight Cash Close Program - 1st Leg",
                    "isCheck": false
                },
                {
                    "id": "8580",
                    "value": "Homelight Cash Close Program - 2nd Leg",
                    "isCheck": false
                },
                {
                    "id": "3907",
                    "value": "Homelight Trade-In Program - 1st Leg",
                    "isCheck": false
                },
                {
                    "id": "7339",
                    "value": "Homelight Trade-In Program - 2nd Leg",
                    "isCheck": false
                },
                {
                    "id": "4324",
                    "value": "Agents Capital",
                    "isCheck": false
                },
                {
                    "id": "5076",
                    "value": "REO or Auction",
                    "isCheck": false
                },
                {
                    "id": "3153",
                    "value": "Short Sale",
                    "isCheck": false
                },
                {
                    "id": "2915",
                    "value": "Commercial Transaction",
                    "isCheck": false
                },
                {
                    "id": "8685",
                    "value": "Rental Transaction",
                    "isCheck": false
                },
                {
                    "id": "1125",
                    "value": "Homelight Simple Sale",
                    "isCheck": false
                }
            ],
            "label": "Transaction Type",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Transaction Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is_there_a_secondary_client?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7885",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "8048",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is there a secondary client?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is there a secondary client?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-secondary_contact",
        "type": "InputField",
        "name": "Secondary Client",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Client",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-secondary_contact_email",
        "type": "Email",
        "name": "Secondary Client Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Client Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Contact-secondary_contact_phone",
        "type": "Contact",
        "name": "Secondary Client Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Client Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not add letters or extensions to this field"
        }
    },
    {
        "elementid": "DropDown-did_you_use_the_listing_manager_system?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "5875",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "9007",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Did you use the listing manager system?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Did you use the listing manager system?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "FreeText-address",
        "type": "FreeText",
        "name": "Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address",
        "type": "InputField",
        "name": "Address-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Street Address",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address2",
        "type": "InputField",
        "name": "Address-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_city",
        "type": "InputField",
        "name": "Address-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-City",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_state",
        "type": "InputField",
        "name": "Address-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-State",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_postal_code",
        "type": "InputField",
        "name": "Address-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Zip Code",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-opp_country",
        "type": "DropDown",
        "field_sub_type": "opportunity",
        "name": "Address of Appointment-Country",
        "settings": {
            "options": [
                {
                    "id": 25545,
                    "value": "USA",
                    "isCheck": false
                },
                {
                    "id": 2551,
                    "value": "Canada",
                    "isCheck": false
                }
            ],
            "label": "Country",
            "size": "large",
            "inputType": "dropdown",
            "required": false,
            "hidden": false,
            "minValue": "",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-transaction_amount",
        "type": "NumberField",
        "name": "Transaction Amount",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Transaction Amount",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-opp_under_contract_date",
        "type": "Date",
        "name": "Under Contract Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Under Contract Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-opp_forecasted_close_date",
        "type": "Date",
        "name": "Forecasted Close Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Forecasted Close Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-is_the_buyer_doing_a_home_inspection?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9234",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "4705",
                    "value": "No",
                    "isCheck": false
                },
                {
                    "id": "8923",
                    "value": "No - Seller to provide home inspection report",
                    "isCheck": false
                }
            ],
            "label": "Is the buyer doing a home inspection?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is the buyer doing a home inspection?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is_the_buyer_doing_a_termite_inspection?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4177",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "9933",
                    "value": "No",
                    "isCheck": false
                },
                {
                    "id": "5482",
                    "value": "No - Seller to provide termite inspection report",
                    "isCheck": false
                }
            ],
            "label": "Is the buyer doing a termite inspection?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is the buyer doing a termite inspection?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-opposite_side_client_name",
        "type": "InputField",
        "name": "Opposite Side Client Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opposite Side Client Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-is_there_an_opposite_side_secondary_client?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7905",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "8347",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is there an opposite side secondary client?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is there an opposite side secondary client?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-opposite_side_secondary_client_name",
        "type": "InputField",
        "name": "Opposite Side Secondary Client Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opposite Side Secondary Client Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-cooperating_agent_company",
        "type": "InputField",
        "name": "Cooperating Agent Company",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating Agent Company",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-cooperating_agent_name",
        "type": "InputField",
        "name": "Cooperating Agent Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating Agent Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Contact-cooperating_agent_phone",
        "type": "Contact",
        "name": "Cooperating Agent Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating Agent Phone",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not add letters or extensions to this field"
        }
    },
    {
        "elementid": "Email-cooperating_agent_email",
        "type": "Email",
        "name": "Cooperating Agent Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating Agent Email",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-cooperating_tc_name",
        "type": "InputField",
        "name": "Cooperating TC Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating TC Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-cooperating_tc_email",
        "type": "Email",
        "name": "Cooperating TC Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating TC Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Contact-cooperating_tc_phone",
        "type": "Contact",
        "name": "Cooperating TC Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating TC Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not add letters or extensions to this field"
        }
    },
    {
        "elementid": "DropDown-escrow_company",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8759",
                    "value": "Homelight Escrow - Michaela Pozner",
                    "isCheck": false
                },
                {
                    "id": "3895",
                    "value": "Homelight Escrow - Cash Close Trade-In Transactions",
                    "isCheck": false
                },
                {
                    "id": "2172",
                    "value": "Other",
                    "isCheck": false
                },
                {
                    "id": "7992",
                    "value": "Pinnacle Escrow - Team Liz",
                    "isCheck": false
                },
                {
                    "id": "9308",
                    "value": "Homelight Escrow - Mary Timmen",
                    "isCheck": false
                }
            ],
            "label": "Escrow Company",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Escrow Company",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-escrow_company_name",
        "type": "InputField",
        "name": "Escrow Company Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Escrow Company Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-escrow_officer_name",
        "type": "InputField",
        "name": "Escrow Officer Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Escrow Officer Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-escrow_officer_email_(the_contract_will_automatically_be_sen",
        "type": "Email",
        "name": "Escrow Officer Email (the contract will automatically be sent to this email when you upload it on page 2)",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Escrow Officer Email (the contract will automatically be sent to this email when you upload it on page 2)",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Contact-escrow_officer_phone",
        "type": "Contact",
        "name": "Escrow Officer Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Escrow Officer Phone",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not add letters or extensions to this field"
        }
    },
    {
        "elementid": "DropDown-financing_type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "6148",
                    "value": "Conventional",
                    "isCheck": false
                },
                {
                    "id": "0356",
                    "value": "VA",
                    "isCheck": false
                },
                {
                    "id": "7573",
                    "value": "FHA",
                    "isCheck": false
                },
                {
                    "id": "4382",
                    "value": "Cash",
                    "isCheck": false
                },
                {
                    "id": "9639",
                    "value": "Other",
                    "isCheck": false
                }
            ],
            "label": "Financing Type",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Financing Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-loan_officer",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "5957",
                    "value": "Jason Hall - Team Home Loans",
                    "isCheck": false
                },
                {
                    "id": "0708",
                    "value": "AJ Schackman - Mavin Mortgage",
                    "isCheck": false
                },
                {
                    "id": "1672",
                    "value": "Other",
                    "isCheck": false
                },
                {
                    "id": "2408",
                    "value": "Cross Country Mortgage - Team Evans",
                    "isCheck": false
                }
            ],
            "label": "Loan Officer",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Loan Officer",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-mortgage_company",
        "type": "InputField",
        "name": "Mortgage Company",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Mortgage Company",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-loan_officer_name",
        "type": "InputField",
        "name": "Loan Officer Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Loan Officer Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-loan_officer_email",
        "type": "Email",
        "name": "Loan Officer Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Loan Officer Email",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Contact-loan_officer_phone",
        "type": "Contact",
        "name": "Loan Officer Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Loan Officer Phone",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not add letters or extensions to this field"
        }
    },
    {
        "elementid": "DropDown-title_company",
        "name": "Title Company",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1589",
                    "value": "Fidelity National Title Company - Tom Chapman & Lynne Lapinski",
                    "isCheck": false
                },
                {
                    "id": "5753",
                    "value": "First American - Greg Ives",
                    "isCheck": false
                },
                {
                    "id": "2852",
                    "value": "Lawyer's Title - Dee Dee Burland (Homelight Leads)",
                    "isCheck": false
                },
                {
                    "id": "2757",
                    "value": "Other",
                    "isCheck": false
                }
            ],
            "label": "Title Company",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-title_company_name",
        "type": "InputField",
        "name": "Title Company Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Title Company Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-title_rep_name",
        "type": "InputField",
        "name": "Title Rep Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Title Rep Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-title_rep_email",
        "type": "Email",
        "name": "Title Rep Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Title Rep Email",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-is_there_an_hoa?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7934",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "5034",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is there an HOA?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is there an HOA?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-hoa_name",
        "type": "InputField",
        "name": "HOA Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "HOA Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-hoa_management_company",
        "type": "InputField",
        "name": "HOA Management Company",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "HOA Management Company",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Contact-hoa_management_phone",
        "type": "Contact",
        "name": "HOA Management Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "HOA Management Phone",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not add letters or extensions to this field"
        }
    },
    {
        "elementid": "DropDown-solar",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2157",
                    "value": "None",
                    "isCheck": false
                },
                {
                    "id": "6181",
                    "value": "Solar - Paid in Full",
                    "isCheck": false
                },
                {
                    "id": "1925",
                    "value": "Solar - Financed",
                    "isCheck": false
                },
                {
                    "id": "7343",
                    "value": "Solar - PPA",
                    "isCheck": false
                },
                {
                    "id": "8648",
                    "value": "Solar - Leased",
                    "isCheck": false
                },
                {
                    "id": "3111",
                    "value": "Solar - Ownership Unknown",
                    "isCheck": false
                }
            ],
            "label": "Solar",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Solar",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "NumberField-listing_agent_commission_percent",
        "type": "NumberField",
        "name": "Listing Agent Commission Percent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Listing Agent Commission Percent",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-are_you_charging_the_admin_fee__and_if_so__who_is_responsibl",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8861",
                    "value": "No - N A",
                    "isCheck": false
                },
                {
                    "id": "8689",
                    "value": "Yes - Seller Paying Fee",
                    "isCheck": false
                },
                {
                    "id": "0198",
                    "value": "Yes - Buyer Paying Fee",
                    "isCheck": false
                },
                {
                    "id": "1637",
                    "value": "Yes - Buyer-to-be-credited-by-Team-Home-Loans",
                    "isCheck": false
                }
            ],
            "label": "Are you charging the admin fee, and if so, who is responsible for paying it?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "1) The admin fee is assumed to be $400 unless specified otherwise.2) To charge the admin fee, you must have the client sign CAR Form CA (Commission Agreement)3) Per VA guidelines, the buyer cannot pay the admin fee if they are getting a VA loan."
        },
        "name": "Are you charging the admin fee, and if so, who is responsible for paying it?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-referral_fee",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4628",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "7956",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Referral Fee",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Referral Fee",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-referral_fee__company",
        "type": "InputField",
        "name": "Referral Fee: Company",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Referral Fee: Company",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-referral_fee__agent",
        "type": "InputField",
        "name": "Referral Fee: Agent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Referral Fee: Agent",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-referral_fee__percent",
        "type": "NumberField",
        "name": "Referral Fee: Percent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Referral Fee: Percent",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-are_you_co_representing_this_client_with_another_agent?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4577",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "6426",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Are you co-representing this client with another agent?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Are you co-representing this client with another agent?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-2nd_whissel_agent_name",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7240",
                    "value": "Abdullah Mohammed",
                    "isCheck": false
                },
                {
                    "id": "6349",
                    "value": "Adam House",
                    "isCheck": false
                },
                {
                    "id": "0740",
                    "value": "Adrian Quijano",
                    "isCheck": false
                },
                {
                    "id": "1522",
                    "value": "Alex Conti",
                    "isCheck": false
                },
                {
                    "id": "1626",
                    "value": "Alyssa Somsamouth",
                    "isCheck": false
                },
                {
                    "id": "2090",
                    "value": "Amanda Witherspoon",
                    "isCheck": false
                },
                {
                    "id": "6438",
                    "value": "Ana Jamo",
                    "isCheck": false
                },
                {
                    "id": "1260",
                    "value": "Andrea Fasulo",
                    "isCheck": false
                },
                {
                    "id": "6253",
                    "value": "Andrew Canavan",
                    "isCheck": false
                },
                {
                    "id": "0603",
                    "value": "Anninda Gibbs",
                    "isCheck": false
                },
                {
                    "id": "0550",
                    "value": "Antonella Marino",
                    "isCheck": false
                },
                {
                    "id": "3267",
                    "value": "Arquimedes Madrigal",
                    "isCheck": false
                },
                {
                    "id": "0615",
                    "value": "Austin Michelle",
                    "isCheck": false
                },
                {
                    "id": "3396",
                    "value": "Billie Guinto",
                    "isCheck": false
                },
                {
                    "id": "9542",
                    "value": "Bob David",
                    "isCheck": false
                },
                {
                    "id": "2393",
                    "value": "Brenda Alvarez",
                    "isCheck": false
                },
                {
                    "id": "3453",
                    "value": "Brian Meinert",
                    "isCheck": false
                },
                {
                    "id": "2547",
                    "value": "Brianda Arreola",
                    "isCheck": false
                },
                {
                    "id": "6494",
                    "value": "Bryan Mancuso",
                    "isCheck": false
                },
                {
                    "id": "6324",
                    "value": "Carlos Rey",
                    "isCheck": false
                },
                {
                    "id": "4882",
                    "value": "Carter Orchulli",
                    "isCheck": false
                },
                {
                    "id": "0672",
                    "value": "Celena Barone",
                    "isCheck": false
                },
                {
                    "id": "6406",
                    "value": "Christa Curtin",
                    "isCheck": false
                },
                {
                    "id": "5361",
                    "value": "Christopher Rossi",
                    "isCheck": false
                },
                {
                    "id": "0965",
                    "value": "Clayton Connolly",
                    "isCheck": false
                },
                {
                    "id": "8728",
                    "value": "Cody Stamm",
                    "isCheck": false
                },
                {
                    "id": "3679",
                    "value": "Corinne Mauro",
                    "isCheck": false
                },
                {
                    "id": "8695",
                    "value": "Courtney Renteria",
                    "isCheck": false
                },
                {
                    "id": "4844",
                    "value": "Courtney Truett",
                    "isCheck": false
                },
                {
                    "id": "9273",
                    "value": "Cynthia Mayorga",
                    "isCheck": false
                },
                {
                    "id": "4610",
                    "value": "Dan Benjamin",
                    "isCheck": false
                },
                {
                    "id": "6328",
                    "value": "Daniela Taylor",
                    "isCheck": false
                },
                {
                    "id": "3884",
                    "value": "Dany Lopez",
                    "isCheck": false
                },
                {
                    "id": "3452",
                    "value": "Dawn Cullen",
                    "isCheck": false
                },
                {
                    "id": "1382",
                    "value": "Domenica Picerni",
                    "isCheck": false
                },
                {
                    "id": "2872",
                    "value": "Elizabeth Diaz",
                    "isCheck": false
                },
                {
                    "id": "3048",
                    "value": "Emma Dearing",
                    "isCheck": false
                },
                {
                    "id": "7669",
                    "value": "Eric Gulal",
                    "isCheck": false
                },
                {
                    "id": "6300",
                    "value": "Evan Wagley",
                    "isCheck": false
                },
                {
                    "id": "9375",
                    "value": "Eve Flaglore",
                    "isCheck": false
                },
                {
                    "id": "5000",
                    "value": "Ewa Baska",
                    "isCheck": false
                },
                {
                    "id": "2577",
                    "value": "Fernando Garay",
                    "isCheck": false
                },
                {
                    "id": "9811",
                    "value": "Francis Ruiz",
                    "isCheck": false
                },
                {
                    "id": "8721",
                    "value": "Frank Perez",
                    "isCheck": false
                },
                {
                    "id": "0304",
                    "value": "Gabriel Montanez",
                    "isCheck": false
                },
                {
                    "id": "9449",
                    "value": "Gabriel Talamantes",
                    "isCheck": false
                },
                {
                    "id": "6665",
                    "value": "Gabriela Gonzalez",
                    "isCheck": false
                },
                {
                    "id": "1338",
                    "value": "Gautier Argemi",
                    "isCheck": false
                },
                {
                    "id": "0275",
                    "value": "Hannah Ohman",
                    "isCheck": false
                },
                {
                    "id": "2143",
                    "value": "Harrison Haynes",
                    "isCheck": false
                },
                {
                    "id": "0568",
                    "value": "Hazael Garay",
                    "isCheck": false
                },
                {
                    "id": "7038",
                    "value": "Ian Worthington",
                    "isCheck": false
                },
                {
                    "id": "1266",
                    "value": "India Bonin",
                    "isCheck": false
                },
                {
                    "id": "7684",
                    "value": "Jacob Carmona",
                    "isCheck": false
                },
                {
                    "id": "5876",
                    "value": "J.C. Agajanian",
                    "isCheck": false
                },
                {
                    "id": "2475",
                    "value": "Janet Carmody",
                    "isCheck": false
                },
                {
                    "id": "2781",
                    "value": "Jarrod Norris",
                    "isCheck": false
                },
                {
                    "id": "5281",
                    "value": "Jeremy McHone",
                    "isCheck": false
                },
                {
                    "id": "2909",
                    "value": "Jennifer Crosby",
                    "isCheck": false
                },
                {
                    "id": "4850",
                    "value": "Jen DiJulio",
                    "isCheck": false
                },
                {
                    "id": "5235",
                    "value": "Jenny Adib",
                    "isCheck": false
                },
                {
                    "id": "5356",
                    "value": "Jesse Hodges",
                    "isCheck": false
                },
                {
                    "id": "7845",
                    "value": "Jesus Soberanis",
                    "isCheck": false
                },
                {
                    "id": "4442",
                    "value": "Joe OMeara",
                    "isCheck": false
                },
                {
                    "id": "9039",
                    "value": "Joel Schneider",
                    "isCheck": false
                },
                {
                    "id": "6086",
                    "value": "Jordan Gibson",
                    "isCheck": false
                },
                {
                    "id": "3349",
                    "value": "Jordan Graham",
                    "isCheck": false
                },
                {
                    "id": "9345",
                    "value": "Julia Marchisio",
                    "isCheck": false
                },
                {
                    "id": "4931",
                    "value": "Justin Verdugo",
                    "isCheck": false
                },
                {
                    "id": "2343",
                    "value": "Karlee Van Dyke",
                    "isCheck": false
                },
                {
                    "id": "0152",
                    "value": "Katie Lussier",
                    "isCheck": false
                },
                {
                    "id": "5437",
                    "value": "Katy Carlson",
                    "isCheck": false
                },
                {
                    "id": "7435",
                    "value": "Katya Roukoz",
                    "isCheck": false
                },
                {
                    "id": "2584",
                    "value": "Kelli Metcalf",
                    "isCheck": false
                },
                {
                    "id": "9540",
                    "value": "Kennedy Hannom",
                    "isCheck": false
                },
                {
                    "id": "9524",
                    "value": "Kevin Piechota",
                    "isCheck": false
                },
                {
                    "id": "2931",
                    "value": "Kiki Parsons",
                    "isCheck": false
                },
                {
                    "id": "6841",
                    "value": "Kimberly La Bore",
                    "isCheck": false
                },
                {
                    "id": "0441",
                    "value": "Kimo Quance",
                    "isCheck": false
                },
                {
                    "id": "0480",
                    "value": "Koby Blankson",
                    "isCheck": false
                },
                {
                    "id": "9767",
                    "value": "Kristina Roberts",
                    "isCheck": false
                },
                {
                    "id": "7522",
                    "value": "Kwantida Payakka",
                    "isCheck": false
                },
                {
                    "id": "2493",
                    "value": "Kyle Whissel",
                    "isCheck": false
                },
                {
                    "id": "4107",
                    "value": "Leonard Franklin",
                    "isCheck": false
                },
                {
                    "id": "1415",
                    "value": "Lexie Lyons",
                    "isCheck": false
                },
                {
                    "id": "8713",
                    "value": "Lisa Cardoza",
                    "isCheck": false
                },
                {
                    "id": "1008",
                    "value": "Lisa Florendo",
                    "isCheck": false
                },
                {
                    "id": "1361",
                    "value": "Lisa Santos",
                    "isCheck": false
                },
                {
                    "id": "3715",
                    "value": "Louis Goletto",
                    "isCheck": false
                },
                {
                    "id": "3420",
                    "value": "Macavin Weems",
                    "isCheck": false
                },
                {
                    "id": "5429",
                    "value": "Mai Truong",
                    "isCheck": false
                },
                {
                    "id": "9838",
                    "value": "Marcus Bryant",
                    "isCheck": false
                },
                {
                    "id": "2505",
                    "value": "Marena Aon",
                    "isCheck": false
                },
                {
                    "id": "1492",
                    "value": "Matt Huneycutt",
                    "isCheck": false
                },
                {
                    "id": "0673",
                    "value": "Matthew Minshall",
                    "isCheck": false
                },
                {
                    "id": "4456",
                    "value": "Melissa Anderkin",
                    "isCheck": false
                },
                {
                    "id": "1831",
                    "value": "Michael Grandy",
                    "isCheck": false
                },
                {
                    "id": "0265",
                    "value": "Michael Szymczak",
                    "isCheck": false
                },
                {
                    "id": "5319",
                    "value": "Michelle House",
                    "isCheck": false
                },
                {
                    "id": "2057",
                    "value": "Mike Boyd",
                    "isCheck": false
                },
                {
                    "id": "1626",
                    "value": "Mike Proctor",
                    "isCheck": false
                },
                {
                    "id": "1009",
                    "value": "Myrna Yang",
                    "isCheck": false
                },
                {
                    "id": "4208",
                    "value": "Nakia Simms",
                    "isCheck": false
                },
                {
                    "id": "4083",
                    "value": "Nico Ayala",
                    "isCheck": false
                },
                {
                    "id": "1096",
                    "value": "Nicholas Coker",
                    "isCheck": false
                },
                {
                    "id": "8950",
                    "value": "Paul Rucker",
                    "isCheck": false
                },
                {
                    "id": "5198",
                    "value": "Perry Asghari",
                    "isCheck": false
                },
                {
                    "id": "4049",
                    "value": "Pratham Khera",
                    "isCheck": false
                },
                {
                    "id": "3824",
                    "value": "Quan Nguyen",
                    "isCheck": false
                },
                {
                    "id": "3191",
                    "value": "Rachel Ohara",
                    "isCheck": false
                },
                {
                    "id": "5927",
                    "value": "Ric Contreras",
                    "isCheck": false
                },
                {
                    "id": "9959",
                    "value": "Richelle Flores",
                    "isCheck": false
                },
                {
                    "id": "3684",
                    "value": "Sarah Pisciotta",
                    "isCheck": false
                },
                {
                    "id": "9839",
                    "value": "Sean Jamali",
                    "isCheck": false
                },
                {
                    "id": "5537",
                    "value": "Sean Jones",
                    "isCheck": false
                },
                {
                    "id": "3055",
                    "value": "Spencer Siegel",
                    "isCheck": false
                },
                {
                    "id": "8430",
                    "value": "Stephanie Wohlfiel",
                    "isCheck": false
                },
                {
                    "id": "4503",
                    "value": "Steve Schuster",
                    "isCheck": false
                },
                {
                    "id": "2643",
                    "value": "Steven Wener",
                    "isCheck": false
                },
                {
                    "id": "4508",
                    "value": "Tad Murroughs",
                    "isCheck": false
                },
                {
                    "id": "6185",
                    "value": "Tareq Vasquez",
                    "isCheck": false
                },
                {
                    "id": "2599",
                    "value": "Taylor Sandoval",
                    "isCheck": false
                },
                {
                    "id": "7468",
                    "value": "Toi Carden",
                    "isCheck": false
                },
                {
                    "id": "4832",
                    "value": "Troy Nunez",
                    "isCheck": false
                },
                {
                    "id": "9477",
                    "value": "Tom Lux",
                    "isCheck": false
                },
                {
                    "id": "5857",
                    "value": "Tommy House",
                    "isCheck": false
                },
                {
                    "id": "9157",
                    "value": "Twanda Turner",
                    "isCheck": false
                },
                {
                    "id": "0241",
                    "value": "Valerie Narmontas",
                    "isCheck": false
                },
                {
                    "id": "5187",
                    "value": "Vanessa Teo",
                    "isCheck": false
                },
                {
                    "id": "2371",
                    "value": "Vaughn Douglass",
                    "isCheck": false
                },
                {
                    "id": "5235",
                    "value": "Wes Parker",
                    "isCheck": false
                }
            ],
            "label": "2nd Whissel Agent Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "2nd Whissel Agent Name",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "TextArea-type_property_description_for_mls_and_marketing_(max_1600_ch",
        "type": "TextArea",
        "settings": {
            "label": "Type Property Description for MLS and Marketing (max 1600 characters)",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "DO NOT COPY ANYTHING FROM PREVIOUS REMARKS.If you are struggling with writing the property description, use the template that's posted on Workplace! Search for \"listing description template\" and it should be the first result."
        },
        "name": "Type Property Description for MLS and Marketing (max 1600 characters)"
    },
    {
        "elementid": "CheckBox-cancellation/expiration_fee",
        "type": "CheckBox",
        "settings": {
            "options": [
                {
                    "id": "9198",
                    "value": "I have read and agree to the cancellation/expiration fee in the popup window",
                    "isCheck": false
                }
            ],
            "label": "Cancellation/Expiration Fee",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Cancellation/Expiration Fee: When utilizing the listing management system, if the listing expires or cancels after the photo/video shoot is complete, a fee of $500 will be charged. This will be auto-debited from the credit card you have on file with the company."
        },
        "name": "Cancellation/Expiration Fee"
    },
    {
        "elementid": "TextArea-Notes",
        "type": "TextArea",
        "name": "Notes",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Notes",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp-key",
        "type": "InputField",
        "name": "Opp Key",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opp Key",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Do Not Update this field"
        }
    },
    {
        "elementid": "InputField-Follow-up-boss-lead-id",
        "type": "InputField",
        "name": "Follow Up Boss Lead ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Follow Up Boss Lead ID",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    }
]

export const whisselTeamPendingFormBuyerCondition=[
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-is_there_a_secondary_client?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-secondary_contact",
            "Email-secondary_contact_email",
            "Contact-secondary_contact_phone"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-is_there_an_opposite_side_secondary_client?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-opposite_side_secondary_client_name"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Other",
                    "operator": "equals",
                    "field_id": "DropDown-escrow_company"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-escrow_company_name",
            "InputField-escrow_officer_name",
            "Email-escrow_officer_email_(the_contract_will_automatically_be_sen",
            "Contact-escrow_officer_phone"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Conventional",
                    "operator": "equals",
                    "field_id": "DropDown-financing_type"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "VA",
                    "operator": "equals",
                    "field_id": "DropDown-financing_type"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "FHA",
                    "operator": "equals",
                    "field_id": "DropDown-financing_type"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Other",
                    "operator": "equals",
                    "field_id": "DropDown-financing_type"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-loan_officer"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Other",
                    "operator": "equals",
                    "field_id": "DropDown-loan_officer"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-mortgage_company",
            "InputField-loan_officer_name",
            "Email-loan_officer_email",
            "Contact-loan_officer_phone"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Other",
                    "operator": "equals",
                    "field_id": "DropDown-title_company"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-title_company_name",
            "InputField-title_company_name",
            "InputField-title_rep_name",
            "Email-title_rep_email"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-is_there_an_hoa?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-hoa_name",
            "InputField-hoa_management_company",
            "Contact-hoa_management_phone"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-referral_fee"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-referral_fee__company",
            "InputField-referral_fee__agent",
            "NumberField-referral_fee__percent"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Fidelity National Title Company - Tom Chapman & Lynne Lapinski",
                    "operator": "equals",
                    "field_id": "DropDown-title_company"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "First American - Greg Ives",
                    "operator": "equals",
                    "field_id": "DropDown-title_company"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "First American - Greg Ives",
                    "operator": "equals",
                    "field_id": "DropDown-title_company"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Other",
                    "operator": "equals",
                    "field_id": "DropDown-title_company"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "Email-title_rep_email",
            "InputField-title_rep_name",
            "TextArea-type_property_description_for_mls_and_marketing_(max_1600_ch",
            "CheckBox-cancellation/expiration_fee"
        ],
        "ConditionName": "Condition Name"
    }
]