import React, { useContext } from "react";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";

export const FreeTextUi = ({
  elementid,
  showHide,
  previewFlag = false,
}) => {
  const { board } = useContext(boardConetxt);
  const ElementSettingsData = board.find(
    (item) => item?.elementid === elementid
  ).settings;

  const { label, hidden, instructions } = ElementSettingsData;
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };
  const ElementName = board.find((item) => item?.elementid === elementid).name;
  return (
    <>
      <div
        className={previewFlag && GetStyleClasses(elementid) || ""}
        style={initStyle}
        hidden={hidden && previewFlag}
      >
        <div className="mt-2">

          {label == undefined || label == "" ? <h6>{ElementName}</h6> : <h6>{label}</h6>}
          {instructions && <p className="text-muted">{instructions}</p>}


        </div>
      </div>
    </>
  );
};
