import React, { useEffect, useRef, useState } from "react";
import useFormApiHelper from "../../utils/useFormApiHelper";
import fublogo from "../../assets/images/fub-circle.png";
import interfaceCircle from "../../assets/images/interface-circle.png";
import { gql, useMutation } from "@apollo/client";
import Select from "react-select/creatable";


const createData = gql`
  mutation CreateFieldMapping($fieldMappingData: GraphQLJSON) {
    CreateFieldMapping(field_mapping_data: $fieldMappingData) {
      team_id
    }
  }
`;

const UpdateFieldMapping = gql`
  mutation UpdateFieldMapping(
    $fieldMappingId: String
    $fieldMappingData: GraphQLJSON
  ) {
    UpdateFieldMapping(
      field_mapping_id: $fieldMappingId
      field_mapping_data: $fieldMappingData
    ) {
      team_id
    }
  }
`;

const ResourceTypes = [
  { key: "People", value: "people" },
  { key: "Deal", value: "deal" },
];

export const FieldMappingComponent = ({ refetch, index, field_mapping, customfields, dealcustomfields, customFieldsLoading,  dealFieldsLoading, activeTab}) => {

  const { GetFieldMappingFieldsByTeamId } = useFormApiHelper();
  const [interfaceField, setInterfaceField] = useState("");
  const [platformField, setPlatformField] = useState("");
  const {formFieldsData, loadingFields } = GetFieldMappingFieldsByTeamId();
  const [element, setElement] = useState(null);
  const [resourceType, setResourceType] = useState("");
  const [syncDirection, setSyncDirection] = useState("");
  const [typeError, setTypeError] = useState("");
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [createForms] = useMutation(createData, {
    onCompleted: () => {
      refetch();
    },
  });
  const [updateFieldMapping] = useMutation(UpdateFieldMapping, {
    onCompleted: () => {
      refetch();
    },
  });

  useEffect(() => {
    let temp = [];
  
    formFieldsData?.GetFieldMappingFieldsByTeamId?.forEach((item) => {
      if (activeTab === 'system') {
        // If activeTab is 'system', add only fields whose field_type is "primary"
        if (item.field_type === 'primary') {
          const obj = {
            elementid: item.field_id,
            name: item.field_name,
            type: item.input_type || "InputField",
            settings: item.settings == null ? {} : item.settings,
            opportunity_field_id: item.opportunity_field_id,
          };
          temp.push(obj);
        }
      } else {
        // If activeTab is not 'system', add all fields except those whose field_type is "primary"
        if (item.field_type !== 'primary') {
          const obj = {
            elementid: item.field_id,
            name: item.field_name,
            type: item.input_type || "InputField",
            settings: item.settings == null ? {} : item.settings,
            opportunity_field_id: item.opportunity_field_id,
          };
          temp.push(obj);
        }
      }
    });
    setElement(temp);
  }, [formFieldsData?.GetFieldMappingFieldsByTeamId, activeTab]);

  useEffect(() => {
    setInterfaceField(field_mapping?.interface_field_id);
    setPlatformField(field_mapping?.platform_field_id);
    field_mapping?.platform_resource_type? setResourceType(field_mapping?.platform_resource_type) : setResourceType("deal");
    setSyncDirection(field_mapping?.sync_direction);
  }, [field_mapping]);

  useEffect(() => {
    if (interfaceField !== "" && platformField !== "") {
      const interfaceFieldType = element?.find(
        (option) => option.opportunity_field_id === interfaceField
      )?.type;
      const platformFieldType = resourceType === "people"
        ? customfields?.find((option) => option.name === platformField)?.type
        : dealcustomfields?.find((option) => option.name === platformField)?.type;
      if ((interfaceFieldType === "Date" && platformFieldType !== "date") || (interfaceFieldType !== "Date" && platformFieldType === "date")){
        interfaceFieldType === "Date" && platformFieldType !== "date" && setTypeError("Expecting date field type for sync.") 
        interfaceFieldType !== "Date" && platformFieldType === "date" && setTypeError("Expecting date type for custom field.");
      }
      else if ((interfaceFieldType === "NumberField" && platformFieldType !== "number") || (interfaceFieldType !== "NumberField" && platformFieldType === "number")) {
        interfaceFieldType === "Number" && platformFieldType !== "number" && setTypeError("Expecting number field type for sync.")
        interfaceFieldType !== "Number" && platformFieldType === "number" && setTypeError("Expecting number type for custom field.");
      }
      // else if ((interfaceFieldType === "Email" && platformFieldType !== "email") || (interfaceFieldType !== "Email" && platformFieldType === "email")) {
      //   interfaceFieldType === "Email" && platformFieldType !== "email" && setTypeError("Expecting email field type for sync.")
      //   interfaceFieldType !== "Email" && platformFieldType === "email" && setTypeError("Expecting email type for custom field.");
      // }
      else {
        setTypeError("");
      }
    }
  }, [interfaceField, platformField, resourceType, element, customfields, dealcustomfields]);

  const handleSave = () => {
    const data = {
      interface_field_id: interfaceField,
      platform: "FUB",
      // sync_direction: syncDirection,
      sync_direction: "FROM_INTERFACE",
      platform_resource_type: resourceType,
      platform_field_id: platformField,
    };
    if (field_mapping && field_mapping?.id) {
      updateFieldMapping({
        variables: {
          fieldMappingId: field_mapping?.id,
          fieldMappingData: data,
        },
      });
    } else {
      createForms({
        variables: {
          fieldMappingData: data,
        },
      });
    }
  };

  const resetFields = () => { 
    if(index === "newModal") {
      setInterfaceField("");
      setPlatformField("");
      // setResourceType("deal");
      setSyncDirection("");
      setTypeError("");
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      whiteSpace: "pre-wrap",
      textAlign: "left",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      textAlign: "left",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      textAlign: "left",
    }),
  };

  const getInterfaceCustomFieldValue = () => {
    const field = element?.find(
      (option) => option.opportunity_field_id === interfaceField
    );
    return field ? { value: field.opportunity_field_id, label: field.name } : "";
  };

  const getPeopleCustomFieldValue = () => {
    const field = customfields?.find(
      (option) => option.name === platformField
    );
    return field
      ? { value: field.name, label: field.label }
      : "";
  };

  const getDealCustomFieldValue = () => {
    const field = dealcustomfields?.find(
      (option) => option.name === platformField
    );
    return field
      ? { value: field.name, label: field.label }
      : "";
  };

  const getResouceTypeValue = () => {
    const field = ResourceTypes.find((option) => option.value === resourceType);
    return field ? { value: field.value, label: field.key } : "";
  };

  return (
    <>
      <div
        className="modal show fade"
        id={index === "newModal" ? "new-sync" : "new-sync" + index}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-fullscreen-sm-down modal-dialog-scrollable h-100 mt-0 me-0 mb-0"
          ref={ref}
        >
          <div className="modal-content h-100">
            <div className="modal-header bg-color-1">
              <h5 className="modal-title fs-6" id="staticBackdropLabel">
                {`${index === "newModal" ? "Create new" : "Update "} mapping`}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => resetFields()}
              />
            </div>
            <div className="modal-body text-center py-4 px-lg-5 mx-xl-5 p-md-4">
              <p>
                Map custom fields to FUB for easy sync. and integration.{" "}
              </p>
              <div className="border p-4 rounded-3 mt-3 input-group align-items-center">
                <div className="p-2">
                  <img src={interfaceCircle} alt="Interface Icon" />
                </div>
                <div className="flex-grow-1">
                <Select
                  key={JSON.stringify(element)}
                  placeholder={`${loadingFields ? "Loading fields" : "Select custom field"}`}
                    isClearable={true}
                    value={getInterfaceCustomFieldValue()}
                    options={element?.map((option) => ({
                      value: option.opportunity_field_id,
                      label: option.name || option?.settings?.label,
                    }))}
                    onChange={(selectedOption) => {
                      setInterfaceField(selectedOption ? selectedOption?.value : null);
                  }}
                  styles={customStyles}
                  formatCreateLabel={() => undefined}
                />
                </div>
              </div>

              {interfaceField && (
                <>
                  <div className="d-flex justify-content-center">
                    <img
                      height={70}
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAACcCAYAAAANpekOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALPSURBVHgB7dyxbtNAHAbw7+8iyJiBBwhLU4kB2NhoHwDBC5C4U+lU+gRJ36BbE5ak6guwMLc8ARkpC3kAhrAVpPq4i5OqShPku/8lCvX3k9y4le/6yY7v4ot9AqVa2qs+zP4ciJgUBjX3NyMysD8HiZijb/39IRQECpuNblNgju1qdcEmI5v26PJs7xiBggPWG50D+1LoH5sNSb/39k4RICjgVnpSM5l8xeI9N2v0O3n0ZNjfHcFTghBGWigezqlWsqsPCBAU0Bh5Dk8G0kSAsD0I4x3QqiFAYMDVCQpoz6whfAkGCBB6iL2bDIMVBrxKKq79K95kCEauV0GAoICuPZPEHBYukElwl7eBQD8HnwePX7z+ZY/dS/trZe5Gds+JMfuXZ+87CKTqix3XqyBD20jyzDaQ4+ZHxJ5Expy6t0JI77E0tn82bkFE97MdXCUG1Fr7gA8QkW28d0BEtDxbjZO2WxBR1HbQXlq2JqttRMKuTosBtUr2aUZwASKiEuPYzDpiQC0G1OLgERH9T+zXYqlbEFHcwaNMepPVPiJhV6fFgFqlGzwagoioxOrNzg+3IKKoDfX0PuqY2BdrMaBW6QaPdkFEtDx24GjbLYgo9uDR+WRVfQP5FLs6LQbUYkAiojKxA0fnbkFEsQePthEZuzotBtQq2eARwh5yJiIqiINH64gBtaIFvH1yPE0/hkyYM9dS9uA1rn3mQ/onvge1GFCLAbUYUIsBtRhQiwG17lfAzUa35Z5+da/wNC1bf9f1mgvTK6AI8rndYNo+Id22rsy4bGLewINnwOwQk9vhi4a8HW5cVvzu7/IK6OYQFDE7RUPOhnNlfechDJ8o1tiL9Oncv5B2kmRfphfu7hHKLEteacPlRQPNhrQ1fbLrbyfrFzffmSjC5cUV7oS8W7sqXF6F0sKQEcLl1UQw53BHCZdXFclNSFdppHBERER6fwFUhwYQSq88XwAAAABJRU5ErkJggg=="
                      alt=""
                    />
                  </div>
                  <div className="border p-4 rounded-3 mt-3 align-items-center bg-color-1 dot-border">
                    <p>Select sync direction</p>
                    <div className="row gap-3 m-0 p-0">
                    <div class="list-group" id="list-tab" role="tablist">
                    {/* <div className="p-2 col border alert-primary bg-color-1 pointer rounded" >
                        <i className="iconify pointer" data-icon="fa:arrow-up" />
                        <i className="iconify pointer" data-icon="fa:arrow-down" />
                        Two way sync
                      </div> */}
<div
                        className="p-2 col border pointer rounded alert-primary"
                          // ${syncDirection === "FROM_INTERFACE" && "alert-primary"}`}
                        // onClick={() => setSyncDirection("FROM_INTERFACE")}
                      >                <i className="iconify pointer" data-icon="fa:arrow-down" />
                One way sync</div> 
                      </div>

                {/* Original Component starts here */}
                      {/* <div
                        className={`p-2 col border pointer rounded ${
                          syncDirection === "FROM_INTERFACE" && "alert-primary"
                        }`}
                        onClick={() => setSyncDirection("FROM_INTERFACE")}
                      >
                        <i
                          className="iconify pointer"
                          data-icon="fa:arrow-down"
                        />
                        One way sync
                      </div>
                      <div
                        className={`p-2 col border pointer rounded ${
                          syncDirection === "TWO_WAY" && "alert-primary"
                        }`}
                        onClick={() => setSyncDirection("TWO_WAY")}
                      >
                        <i
                          className="iconify pointer"
                          data-icon="fa:arrow-up"
                        />
                        <i
                          className="iconify pointer"
                          data-icon="fa:arrow-down"
                        />
                        Two way sync
                      </div>
                      <div
                        className={`p-2 col border pointer rounded ${
                          syncDirection === "TO_INTERFACE" && "alert-primary"
                        }`}
                        onClick={() => setSyncDirection("TO_INTERFACE")}
                      >
                        <i
                          className="iconify pointer"
                          data-icon="fa:arrow-up"
                        />
                        One way sync
                      </div> */}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <img
                      height={70}
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAACcCAYAAAANpekOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALPSURBVHgB7dyxbtNAHAbw7+8iyJiBBwhLU4kB2NhoHwDBC5C4U+lU+gRJ36BbE5ak6guwMLc8ARkpC3kAhrAVpPq4i5OqShPku/8lCvX3k9y4le/6yY7v4ot9AqVa2qs+zP4ciJgUBjX3NyMysD8HiZijb/39IRQECpuNblNgju1qdcEmI5v26PJs7xiBggPWG50D+1LoH5sNSb/39k4RICjgVnpSM5l8xeI9N2v0O3n0ZNjfHcFTghBGWigezqlWsqsPCBAU0Bh5Dk8G0kSAsD0I4x3QqiFAYMDVCQpoz6whfAkGCBB6iL2bDIMVBrxKKq79K95kCEauV0GAoICuPZPEHBYukElwl7eBQD8HnwePX7z+ZY/dS/trZe5Gds+JMfuXZ+87CKTqix3XqyBD20jyzDaQ4+ZHxJ5Expy6t0JI77E0tn82bkFE97MdXCUG1Fr7gA8QkW28d0BEtDxbjZO2WxBR1HbQXlq2JqttRMKuTosBtUr2aUZwASKiEuPYzDpiQC0G1OLgERH9T+zXYqlbEFHcwaNMepPVPiJhV6fFgFqlGzwagoioxOrNzg+3IKKoDfX0PuqY2BdrMaBW6QaPdkFEtDx24GjbLYgo9uDR+WRVfQP5FLs6LQbUYkAiojKxA0fnbkFEsQePthEZuzotBtQq2eARwh5yJiIqiINH64gBtaIFvH1yPE0/hkyYM9dS9uA1rn3mQ/onvge1GFCLAbUYUIsBtRhQiwG17lfAzUa35Z5+da/wNC1bf9f1mgvTK6AI8rndYNo+Id22rsy4bGLewINnwOwQk9vhi4a8HW5cVvzu7/IK6OYQFDE7RUPOhnNlfechDJ8o1tiL9Oncv5B2kmRfphfu7hHKLEteacPlRQPNhrQ1fbLrbyfrFzffmSjC5cUV7oS8W7sqXF6F0sKQEcLl1UQw53BHCZdXFclNSFdppHBERER6fwFUhwYQSq88XwAAAABJRU5ErkJggg=="
                      alt=""
                    />
                  </div>
                  <div className="border p-4 rounded-3 mt-3 align-items-center">
                    <div className="d-flex justify-content-between align-items-center">
                      <p>Selected platform to sync</p>
                    </div>
                    <div className="row m-0">
                      <div className="p-0 px-1">
                        {/* <div className="d-flex align-items-center gap-3 border pointer rounded-3 bg-white p-3 mb-3">
                  <div>
                    <img src={fublogo} />
                  </div>
                  <p className="m-0 p-0"> Open To Close</p>
                </div> */}
                      </div>
                      <div className="p-0 px-1">
                        <div className="d-flex align-items-center gap-3 border rounded-3 p-3 mb-3 alert-primary">
                          <div>
                            <img src={fublogo} alt="" />
                          </div>
                          
                          <p className="m-0 p-0">Follow Up boss</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <img
                      height={70}
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAACcCAYAAAANpekOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALPSURBVHgB7dyxbtNAHAbw7+8iyJiBBwhLU4kB2NhoHwDBC5C4U+lU+gRJ36BbE5ak6guwMLc8ARkpC3kAhrAVpPq4i5OqShPku/8lCvX3k9y4le/6yY7v4ot9AqVa2qs+zP4ciJgUBjX3NyMysD8HiZijb/39IRQECpuNblNgju1qdcEmI5v26PJs7xiBggPWG50D+1LoH5sNSb/39k4RICjgVnpSM5l8xeI9N2v0O3n0ZNjfHcFTghBGWigezqlWsqsPCBAU0Bh5Dk8G0kSAsD0I4x3QqiFAYMDVCQpoz6whfAkGCBB6iL2bDIMVBrxKKq79K95kCEauV0GAoICuPZPEHBYukElwl7eBQD8HnwePX7z+ZY/dS/trZe5Gds+JMfuXZ+87CKTqix3XqyBD20jyzDaQ4+ZHxJ5Expy6t0JI77E0tn82bkFE97MdXCUG1Fr7gA8QkW28d0BEtDxbjZO2WxBR1HbQXlq2JqttRMKuTosBtUr2aUZwASKiEuPYzDpiQC0G1OLgERH9T+zXYqlbEFHcwaNMepPVPiJhV6fFgFqlGzwagoioxOrNzg+3IKKoDfX0PuqY2BdrMaBW6QaPdkFEtDx24GjbLYgo9uDR+WRVfQP5FLs6LQbUYkAiojKxA0fnbkFEsQePthEZuzotBtQq2eARwh5yJiIqiINH64gBtaIFvH1yPE0/hkyYM9dS9uA1rn3mQ/onvge1GFCLAbUYUIsBtRhQiwG17lfAzUa35Z5+da/wNC1bf9f1mgvTK6AI8rndYNo+Id22rsy4bGLewINnwOwQk9vhi4a8HW5cVvzu7/IK6OYQFDE7RUPOhnNlfechDJ8o1tiL9Oncv5B2kmRfphfu7hHKLEteacPlRQPNhrQ1fbLrbyfrFzffmSjC5cUV7oS8W7sqXF6F0sKQEcLl1UQw53BHCZdXFclNSFdppHBERER6fwFUhwYQSq88XwAAAABJRU5ErkJggg=="
                      alt=""
                    />
                  </div>
                  <div className="border p-4 rounded-3 mt-3 align-items-center">
                    {/* <div className="d-flex justify-content-between align-items-center">
                      <p className="text-decoration-underline pointer">
                        Change platform
                      </p>
                    </div> */}
                    <div className="d-flex align-items-center gap-3 border rounded-3 bg-white p-3 mb-3">
                      <div>
                        <img src={fublogo} alt=""/>
                      </div>
                      <p className="m-0 p-0"> Follow Up boss</p>
                    </div>

                    <Select
                      className="mb-3"
                      placeholder="Select resource type"
                      isClearable={true}
                      value={getResouceTypeValue()}
                      options={ResourceTypes.map((option) => ({
                        value: option.value,
                        label: option.key,
                      }))}
                      onChange={(selectedOption) => {
                        setResourceType(selectedOption?.value);
                      }}
                      styles={customStyles}
                      formatCreateLabel={() => undefined}
                    />
                    {resourceType === "people" && (
                      <Select
                        placeholder={`${
                          customFieldsLoading ? "Loading fields" : "Select field"
                        }`}
                        isClearable={true}
                        value={platformField ? getPeopleCustomFieldValue() : null}
                        options={customfields?.map(
                          (option) => ({
                            value: option.name,
                            label: option.label,
                          })
                        )}
                        onChange={(selectedOption) => {
                          setPlatformField(selectedOption?.value);
                        }}
                        styles={customStyles}
                        formatCreateLabel={() => undefined}
                      />
                    )}
                    {resourceType === "deal" && (
                      <Select
                        placeholder={`${
                          dealFieldsLoading
                            ? "Loading fields"
                            : "Select deal field"
                        }`}
                        isClearable={true}
                        value={platformField ? getDealCustomFieldValue() : null}
                        options={dealcustomfields?.map(
                          (option) => ({
                            value: option.name,
                            label: option.label
                          })
                        )}
                        onChange={(selectedOption) => {
                          setPlatformField(selectedOption?.value);
                        }}
                        styles={customStyles}
                        formatCreateLabel={() => undefined}
                      />
                    )}
                     {typeError && resourceType && (
                  <p className="text-danger text-start mt-1">*{typeError}</p>
                  )}
                  </div>
                  <div className="d-flex py-4 gap-3 justify-content-end">
                    <button
                      type="button"
                      className="btn btn-primary-outline"
                      data-dismiss="modal"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => resetFields()}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        handleSave();
                        resetFields();
                      }}
                      disabled={!interfaceField || !platformField || !resourceType || typeError}
                    >
                      Save changes
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {show && <div class="modal-backdrop fade show"></div>}
    </>
  );
};
