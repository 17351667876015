import React, { useEffect,useState } from "react";

import { useContext } from "react";
import { boardConetxt } from "../../src/pages/form-builder/dashboard/ContextHelper"
import { FormPlanContext } from  "../../src/pages/form-builder/dashboard/ContextHelper"
import { Draggable } from "react-beautiful-dnd";

function DragableElement({
  id,
  index,
  element,
  settings,
  show,
  // isDragDisabled = false,
}) {
  // console.log("DragableElement>>", isDragDisabled);

  const {board} = useContext(boardConetxt);
  const {formPlan}=useContext(FormPlanContext)
  const [isDragDisabled, setIsDragDisabled] = useState(false);
  // console.log("harshad",board?.length,isDragDisabled,formPlan?.FieldLimit,id)

  useEffect(() => {
    if(board?.length >= formPlan?.FieldLimit ){
      console.log("boardCallling",board)
      setIsDragDisabled(true)
      // alert("You have reached the limit of fields")
     }else{
       setIsDragDisabled(false)
     }
  }, [board,formPlan?.FieldLimit]);

  return (
    <div className="tooltip-container">
    <Draggable
      key={id}
      draggableId={id}
      index={index}
      isDragDisabled={isDragDisabled}
    
      className={`${isDragDisabled ? "hover-overlay" : null}`}
      
    >
      {(provided, snapshot) => (
        <React.Fragment>
          <div
           
            id={id}
            settings={settings}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              transform: snapshot.isDragging
                ? provided.draggableProps.style?.transform
                : "translate(0px, 0px)",
            }}

          >

            {element}
            {provided.placeholder}
          </div>
          {show
            ? null
            : snapshot.isDragging && (
                <div style={{ transform: "none !important" }}>{element}</div>
              )}
        </React.Fragment>
      )}
    {/* {isDragDisabled && <div className="tooltip-text form-tooltipp">You have reached the limit of custom fields</div>} */}
    </Draggable>
    </div>

  );
}

DragableElement.propTypes = {
  // id: PropTypes.string.isRequired,
  // index: PropTypes.number.isRequired,
  // element: PropTypes.element,
};

export default DragableElement;
