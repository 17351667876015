export const WhisselDispositionForm =[
    {
        "elementid": "InputField-opp_last_name",
        "type": "InputField",
        "name": "Last Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Last Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-appt_disposition__did_the_appt_happen?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1210",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "9422",
                    "value": "No",
                    "isCheck": false
                },
                {
                    "id": "5874",
                    "value": "Rescheduled",
                    "isCheck": false
                }
            ],
            "label": "Appt Disposition: Did the Appt Happen?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appt Disposition: Did the Appt Happen?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Date-opp_appt_met_date",
        "type": "Date",
        "name": "Appointment Met Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Appointment Met Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-is_this_lead_willing_or_able_to_transact_in_the_next_90_days",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7690",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "2365",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is this lead willing or able to transact in the next 90 days?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is this lead willing or able to transact in the next 90 days?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-why_will_they_not_be_able_to_transact_in_the_next_90_days?",
        "type": "InputField",
        "name": "Why will they not be able to transact in the next 90 days?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Why will they not be able to transact in the next 90 days?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-did_you_present_the_vip?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "6454",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "4338",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Did you present the VIP?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Did you present the VIP?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-why_was_the_vip_not_presented?",
        "type": "InputField",
        "name": "Why was the VIP not presented?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Why was the VIP not presented?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-did_client_sign_a_vip_and/or_listing_agreement",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9523",
                    "value": "No Agreement Signed",
                    "isCheck": false
                },
                {
                    "id": "5663",
                    "value": "VIP",
                    "isCheck": false
                },
                {
                    "id": "0950",
                    "value": "Listing Agreement",
                    "isCheck": false
                },
                {
                    "id": "3322",
                    "value": "VIP and Listing Agreement",
                    "isCheck": false
                }
            ],
            "label": "Did client sign a VIP and/or listing agreement",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Did client sign a VIP and/or listing agreement",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-why_didn_t_the_appt_happen?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7163",
                    "value": "Client Cancelled",
                    "isCheck": false
                },
                {
                    "id": "2053",
                    "value": "Rescheduled",
                    "isCheck": false
                },
                {
                    "id": "5399",
                    "value": "No Show",
                    "isCheck": false
                },
                {
                    "id": "7154",
                    "value": "Property Already Pending",
                    "isCheck": false
                },
                {
                    "id": "8629",
                    "value": "Agent Cancelled",
                    "isCheck": false
                }
            ],
            "label": "Why Didn't the Appt Happen?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Why Didn't the Appt Happen?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-what_contact_stage_should_this_be_in_now?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2865",
                    "value": "A - Hot - 90 Days",
                    "isCheck": false
                },
                {
                    "id": "9870",
                    "value": "B -  Warm - 3 to 9 Months",
                    "isCheck": false
                },
                {
                    "id": "6549",
                    "value": "C - Cold - 9+ Months",
                    "isCheck": false
                },
                {
                    "id": "7718",
                    "value": "Active/Shopping Client",
                    "isCheck": false
                },
                {
                    "id": "6605",
                    "value": "Archive",
                    "isCheck": false
                }
            ],
            "label": "What contact stage should this be in now?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "What contact stage should this be in now?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-archive_reason_",
        "type": "InputField",
        "name": "Archive Reason:",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Archive Reason:",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "FreeText-rescheduled_appointment_details",
        "type": "FreeText",
        "name": "Rescheduled Appointment Details",
        "settings": {},
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Date-rescheduled-app-date",
        "type": "Date",
        "name": "Rescheduled Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Rescheduled Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Time-rescheduled-time",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "0278",
                    "value": "07:30 AM",
                    "isCheck": false
                },
                {
                    "id": "4827",
                    "value": "07:45 AM",
                    "isCheck": false
                },
                {
                    "id": "3097",
                    "value": "08:00 AM",
                    "isCheck": false
                },
                {
                    "id": "7964",
                    "value": "08:15 AM",
                    "isCheck": false
                },
                {
                    "id": "5362",
                    "value": "08:30 AM",
                    "isCheck": false
                },
                {
                    "id": "5227",
                    "value": "08:45 AM",
                    "isCheck": false
                },
                {
                    "id": "1508",
                    "value": "09:00 AM",
                    "isCheck": false
                },
                {
                    "id": "6066",
                    "value": "09:15 AM",
                    "isCheck": false
                },
                {
                    "id": "3031",
                    "value": "09:30 AM",
                    "isCheck": false
                },
                {
                    "id": "3773",
                    "value": "09:45 AM",
                    "isCheck": false
                },
                {
                    "id": "5032",
                    "value": "10:00 AM",
                    "isCheck": false
                },
                {
                    "id": "6691",
                    "value": "10:15 AM",
                    "isCheck": false
                },
                {
                    "id": "3287",
                    "value": "10:30 AM",
                    "isCheck": false
                },
                {
                    "id": "5581",
                    "value": "10:45 AM",
                    "isCheck": false
                },
                {
                    "id": "6383",
                    "value": "11:00 AM",
                    "isCheck": false
                },
                {
                    "id": "6710",
                    "value": "11:15 AM",
                    "isCheck": false
                },
                {
                    "id": "9769",
                    "value": "11:30 AM",
                    "isCheck": false
                },
                {
                    "id": "1865",
                    "value": "11:45 AM",
                    "isCheck": false
                },
                {
                    "id": "4633",
                    "value": "12:00 PM",
                    "isCheck": false
                },
                {
                    "id": "7411",
                    "value": "12:15 PM",
                    "isCheck": false
                },
                {
                    "id": "5667",
                    "value": "12:30 PM",
                    "isCheck": false
                },
                {
                    "id": "0497",
                    "value": "12:45 PM",
                    "isCheck": false
                },
                {
                    "id": "5232",
                    "value": "01:00 PM",
                    "isCheck": false
                },
                {
                    "id": "7314",
                    "value": "01:15 PM",
                    "isCheck": false
                },
                {
                    "id": "8533",
                    "value": "01:30 PM",
                    "isCheck": false
                },
                {
                    "id": "6220",
                    "value": "01:45 PM",
                    "isCheck": false
                },
                {
                    "id": "1853",
                    "value": "02:00 PM",
                    "isCheck": false
                },
                {
                    "id": "7723",
                    "value": "02:15 PM",
                    "isCheck": false
                },
                {
                    "id": "6085",
                    "value": "02:30 PM",
                    "isCheck": false
                },
                {
                    "id": "8949",
                    "value": "02:45 PM",
                    "isCheck": false
                },
                {
                    "id": "9039",
                    "value": "03:00 PM",
                    "isCheck": false
                },
                {
                    "id": "1077",
                    "value": "03:15 PM",
                    "isCheck": false
                },
                {
                    "id": "1948",
                    "value": "03:30 PM",
                    "isCheck": false
                },
                {
                    "id": "1670",
                    "value": "03:45 PM",
                    "isCheck": false
                },
                {
                    "id": "4348",
                    "value": "04:00 PM",
                    "isCheck": false
                },
                {
                    "id": "5532",
                    "value": "04:15 PM",
                    "isCheck": false
                },
                {
                    "id": "6202",
                    "value": "04:30 PM",
                    "isCheck": false
                },
                {
                    "id": "4517",
                    "value": "04:45 PM",
                    "isCheck": false
                },
                {
                    "id": "0788",
                    "value": "05:00 PM",
                    "isCheck": false
                },
                {
                    "id": "3100",
                    "value": "05:15 PM",
                    "isCheck": false
                },
                {
                    "id": "4335",
                    "value": "05:30 PM",
                    "isCheck": false
                },
                {
                    "id": "5098",
                    "value": "05:45 PM",
                    "isCheck": false
                },
                {
                    "id": "0778",
                    "value": "06:00 PM",
                    "isCheck": false
                },
                {
                    "id": "2377",
                    "value": "06:15 PM",
                    "isCheck": false
                },
                {
                    "id": "3559",
                    "value": "06:30 PM",
                    "isCheck": false
                },
                {
                    "id": "6345",
                    "value": "06:45 PM",
                    "isCheck": false
                },
                {
                    "id": "2604",
                    "value": "07:00 PM",
                    "isCheck": false
                },
                {
                    "id": "6428",
                    "value": "07:15 PM",
                    "isCheck": false
                },
                {
                    "id": "3161",
                    "value": "07:30 PM",
                    "isCheck": false
                },
                {
                    "id": "9243",
                    "value": "07:45 PM",
                    "isCheck": false
                },
                {
                    "id": "1703",
                    "value": "08:00 PM",
                    "isCheck": false
                },
                {
                    "id": "7870",
                    "value": "08:15 PM",
                    "isCheck": false
                },
                {
                    "id": "6897",
                    "value": "08:30 PM",
                    "isCheck": false
                }
            ],
            "label": "Rescheduled Time",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Rescheduled Time",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "TextArea-what_are_the_next_steps_to_get_the_client_to_the_finish_line",
        "type": "TextArea",
        "name": "What are the next steps to get the client to the finish line?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "What are the next steps to get the client to the finish line?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "TextArea-notes/feedback_for_the_isa__(n/a_if_not_isa_set_appt)",
        "type": "TextArea",
        "name": "Notes/Feedback for the ISA: (N/A if not ISA set appt)",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Notes/Feedback for the ISA: (N/A if not ISA set appt)",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp-key",
        "type": "InputField",
        "name": "Opp Key",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opp Key",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please Do Not Touch this Field"
        }
    },
    {
        "elementid": "InputField-Follow-up-boss-lead-id",
        "type": "InputField",
        "name": "Follow Up Boss Lead ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Follow Up Boss Lead ID",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    }
]

export const WhisselDispositionConditions = [
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-appt_disposition__did_the_appt_happen?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "Date-opp_appt_met_date",
            "DropDown-is_this_lead_willing_or_able_to_transact_in_the_next_90_days",
            "DropDown-did_you_present_the_vip?",
            "DropDown-what_contact_stage_should_this_be_in_now?"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-is_this_lead_willing_or_able_to_transact_in_the_next_90_days"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-did_client_sign_a_vip_and/or_listing_agreement"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "No",
                    "operator": "equals",
                    "field_id": "DropDown-is_this_lead_willing_or_able_to_transact_in_the_next_90_days"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-why_will_they_not_be_able_to_transact_in_the_next_90_days?"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "No",
                    "operator": "equals",
                    "field_id": "DropDown-appt_disposition__did_the_appt_happen?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-why_didn_t_the_appt_happen?"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Rescheduled",
                    "operator": "equals",
                    "field_id": "DropDown-appt_disposition__did_the_appt_happen?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "FreeText-rescheduled_appointment_details",
            "Date-rescheduled-app-date",
            "Time-rescheduled-time"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "No",
                    "operator": "equals",
                    "field_id": "DropDown-did_you_present_the_vip?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-why_was_the_vip_not_presented?"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-did_you_present_the_vip?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-did_client_sign_a_vip_and/or_listing_agreement"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Archive",
                    "operator": "equals",
                    "field_id": "DropDown-what_contact_stage_should_this_be_in_now?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-archive_reason_"
        ],
        "ConditionName": "Condition Name"
    }
]
