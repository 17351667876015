import cloneDeep from "lodash/cloneDeep";
// We can actually also make a api call to parse, but for now we will do it in the frontend
function getValue(dataAccessKey, data) {
    const keys = dataAccessKey.split('__');
    const currentAccessKey = keys[0];
    var nextAccessKey = keys.slice(1).join('__');

    var exp = /\[{1}[^[]*?\]{1}/g;
    const match = exp.exec(currentAccessKey)
    let index;
    let value = undefined;

    if (match) {
        const startIndex = match.index;
        const endIndex = startIndex + match[0].length;
        if (startIndex === 0) {
            index = parseInt(currentAccessKey.slice(startIndex + 1, endIndex - 1));
            if (!isNaN(index)) {
                if (index < 0) {
                    index = data.length + index;
                }
                if (Array.isArray(data) && data.length >= index + 1) {
                    // const remainingKeys = keys.slice(1).join('.');
                    const remainingKey = currentAccessKey.slice(endIndex);
                    nextAccessKey = remainingKey ? remainingKey + nextAccessKey : nextAccessKey;
                    value = data[index];
                } else {
                    value = undefined;
                }
            } else {
                value = undefined;
            }
        } else {
            if (typeof data === 'object' && data !== null) {
                const key = currentAccessKey.slice(0, startIndex);
                const remainingKey = currentAccessKey.slice(startIndex);
                // const remainingKeys = keys.slice(1).join('.');
                nextAccessKey = remainingKey ? remainingKey + nextAccessKey : nextAccessKey;
                value = data[key];
            } else {
                value = undefined;
            }
        }
    } else {
        if (typeof data === 'object' && data !== null) {
            value = data[currentAccessKey];
        } else {
            value = undefined;
        }
    }
    if (nextAccessKey) {
        return getValue(nextAccessKey, value);
    }
    else {
        return value;
    }
    // return [failed, value];
}

function __parseValue(value, data) {
    const exp = /\{{2}[^{]*?\}{2}/g;
    const result = value.match(exp);
    if (!result) {
        return value;
    }
    for (let r of new Set(result)) {
        const key = r.slice(2, -2);
        const v = getValue(key, data);
        if (result.length === 1) {
            if (v === null || v === undefined) {
                value = v
            }
            else if(typeof v === "boolean"){
                value = v.toString();
            }
            else if (value.replace(r, '')) {
                value = value.replace(r, v);
            } else {
                value = v;
            }
        } else {
            if (v === null || v === undefined) {
                value = value.replace(r, '');
            }
            else { value = value.replace(r, v); }
        }
    }
    return value;
}
export function parseValue(value, data) {
    data = cloneDeep(data);
    let eventCreatedDate = __parseValue('{{trigger__event__event_data__eventCreated}}', data);
    if (eventCreatedDate) {
        data['todays_date'] = new Date(eventCreatedDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).split(' ').join('-');
    }
    else{
    data['todays_date'] = new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).split(' ').join('-');
}
    return __parseValue(value, data);
    
}


function Createkeys(data) {
    let keys = [];

    function generateKeys(data, keychain) {


        if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                const updatedKeychain = `${keychain}[${i}]`;
                //   Uncomment Push here if you want every possible key
                //   keys.push(updatedKeychain);
                generateKeys(data[i], updatedKeychain);
            }
        } else if (typeof data === "object" && data !== null) {
            for (let key in data) {
                const updatedKeychain = keychain ? `${keychain}__${key}` : key;
                //   Uncomment Push here if you want every possible key
                //   keys.push(updatedKeychain);
                generateKeys(data[key], updatedKeychain);
            }
        }
        else {
            // Pushing here will give you only the leaf nodes
            keys.push({ 'key': keychain, 'value': data, 'key_show': keychain.split("__").slice(1).join('.') });
        }

    }

    generateKeys(data, "");

    return keys.map((k) => { return { 'key': `{{${k.key}}}`, 'value': k.value, 'key_show': k.key_show } });
}

export function generatekeys_per_action(data) {
    let keys = {};
    let todays_date =new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).split(' ').join('-')
    for (let action in data) {
        keys[action] = Createkeys({ [action]: data[action]?.action_output });
    }
    if (keys["trigger"]) {
        let eventCreatedObject = keys["trigger"].find(
          (obj) => obj.key === "{{trigger__event__event_data__eventCreated}}"
        );
      
        if (eventCreatedObject) {
          let dateObj = new Date(eventCreatedObject.value);
          let eventCreatedDate = dateObj.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).split(' ').join('-');
          keys["todays_date"] = [{ key: '{{todays_date}}', value: eventCreatedDate, key_show: "todays_date" }];
        }
        else {
          keys["todays_date"] = [{ key: '{{todays_date}}', value: todays_date, key_show: "todays_date" }];
        }
      } else {
        keys["todays_date"] = [{ key: '{{todays_date}}', value: todays_date, key_show: "todays_date" }];
      }
    return keys;
}

export function generatekeys(data) {
    let keys = {};
    for (let action in data) {
        keys[action] = Createkeys({ [action]: data[action] });
    }
    return keys;
}

export function parseValuesession(value, session) {
    let sessionparsed = {};
    for (let action in session) {
        sessionparsed[action] = session[action]?.action_output;
    }
    return parseValue(value, sessionparsed);
}

// {"action_id": {"ashdgash": "asdasd"}}

// {"action_id": {"action_ouput": {"ashdgash": "asdasd"}, "action_info": {}, "action_status": {}}}
