import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import SingleselecCustom from "../../../components/fields/SingleselecCustom"

const DateCalculator = ({
  data_mapper,
  action_id,
  select_modal,
  selected_modal_id,
  UpdateAction,
}) => {
  const dateCalculatorKey = "dateCalculator";
  const [dateCalculatorMapper, setDateCalculatorMapper] = useState(
    data_mapper?.[dateCalculatorKey] ? data_mapper?.[dateCalculatorKey] : []
  );

  useEffect(() => {
    UpdateAction({ data_mapper: {[dateCalculatorKey]: dateCalculatorMapper} });
  }, [dateCalculatorMapper]);


  return (
    <div className="mt-2">
      <p>Select Date Calculator</p>
      <div className="row col-12 p-0 m-0 mt-3 row position-relative">
      <div className="col-12 p-2">
        <SingleselecCustom
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"date_value"}
            field={{ name: "Date" }}
            text_value={dateCalculatorMapper["date_value"]}
            update_data_mapper={(key, value) => {
              setDateCalculatorMapper((prevMapper) => ({
                ...prevMapper,
                date_value: value,
              }));
            }}
            setUpdateUserMapper={setDateCalculatorMapper}
          />
        </div>
        <div className="col-6 p-2">
        <span className="text-truncate txt-gy">Operator</span>
      
          <div className="input-group mb-3 mt-1">
            <select
              className="form-control form-select input-bn p-3"
              name="operator"
              value={dateCalculatorMapper?.operator}
              onChange={(e)=> setDateCalculatorMapper((prevMapper) => ({
                ...prevMapper,
                operator: e.target.value,
              }))}
            >
              <option value="">Select Operator</option>
              <option value="+">+</option>
              <option value="-">-</option>
            </select>
          </div>
        </div>
        <div className="col-6 p-2">
        <SingleselecCustom
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"days"}
            field={{ name: "Days" }}
            text_value={dateCalculatorMapper["days"]}
            update_data_mapper={(key, value) => {
              setDateCalculatorMapper((prevMapper) => ({
                ...prevMapper,
                days: value,
              }));
            }}
            setUpdateUserMapper={setDateCalculatorMapper}
          />
        </div>
        {/* <div className="col-3 p-2">
        <SingleselecCustom
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"hours"}
            field={{ name: "Hours" }}
            text_value={dateCalculatorMapper["hours"]}
            update_data_mapper={(key, value) => {
              setDateCalculatorMapper((prevMapper) => ({
                ...prevMapper,
                hours: value,
              }));
            }}
            setUpdateUserMapper={setDateCalculatorMapper}
          />
        </div>
        <div className="col-3 p-2">
        <SingleselecCustom
            key={"minutes"}
            action_id={action_id}
            select_modal={select_modal}
            selected_modal_id={selected_modal_id}
            field_key={"minutes"}
            field={{ name: "Minutes" }}
            text_value={dateCalculatorMapper["minutes"]}
            update_data_mapper={(key, value) => {
              setDateCalculatorMapper((prevMapper) => ({
                ...prevMapper,
                minutes: value,
              }));
            }}
            setUpdateUserMapper={setDateCalculatorMapper}
          />
        </div> */}
      </div>
      <p>
        + indicator calculates the time after the selected date, - indicator
        calculates the time before the selected date
      </p>
    </div>
  );
};
export default DateCalculator;
