import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { generatekeys_per_action } from "../../utils/parser";
import { TextBox } from "../../components/TextBox";
import { WorkFlowSessionInfo } from "../../pages/automation-builder/workflows/EditWorkflow";
import { uniqueId } from "lodash";
import AccordionBasic from "../AccordionBasic";

const SingleselecCustom = ({
  // field_id,
  action_id,
  select_modal,
  selected_modal_id,
  field_key,
  field,
  text_value,
  update_data_mapper,
}) => {
  const { workflow_helper } = useContext(WorkFlowSessionInfo);
  const [insert_value, set_insert_value] = useState(null);
  const field_id = useMemo(() => uniqueId(), []);
  const [text, settext] = useState(
    text_value === undefined || text_value == null
      ? field?.default_value
      : text_value
  );
  const { session } = useContext(WorkFlowSessionInfo);

  useEffect(() => {
    if (selected_modal_id === field_id) {
      document.querySelector("#modal_" + field_id).style.display = "block";
    } else {
      document.querySelector("#modal_" + field_id).style.display = "none";
    }
  }, [selected_modal_id, field_id]);

  var keys_ = generatekeys_per_action(session);
  var keys = {};
  const triggerValues = workflow_helper.current.get_parent_chain(action_id);

  const store = triggerValues.map((val) => {
    keys[val] = [];
    const checker = Object.keys(keys_).map((key) => {
      if (key === val) {
        return (keys[val] = keys_[val]);
      }
      if(key === "todays_date"){
        return (keys["todays_date"] = keys_["todays_date"]);
      }
    });
    return checker === undefined ? (keys_[val] = []) : checker;
  });

  const orderedKeys = {
    todays_date: keys.todays_date,
    ...keys,
  };
  
  useEffect(() => {
    if (text !== null && text !== undefined) {
      update_data_mapper(field_key, text);
    }
  }, [text]);

  return (
    <>
      <div className="">
        <span className="text-truncate txt-gy">{field?.name}</span>
        <div
          className="d-flex mb-4 mt-1"
          onClick={(e) => {
            if (!field.readOnly) select_modal(field_id);
          }}
        >
          <div
            className="d-flex align-items-center border bg-white p-1 pb-0 rounded-start txt-container input-bn fgoo"
            role="textbox"
          >
            <TextBox
              text={text}
              settext={settext}
              insert_value={insert_value}
              set_insert_value={set_insert_value}
              readOnly={field?.readOnly}
              select_modal={select_modal}
            />
          </div>
          {/* <span className="input-group-text px-1 rounded-0 rounded-end mloo">
            <i className="iconify fs-4" data-icon="mdi:chevron-down"></i>
          </span> */}
        </div>
      </div>
      {/* {accordion_type === "tabbed" ? (
        <TabbedAccordion
          keys={keys}
          AutiomationfieldsInfo={AutiomationfieldsInfo}
          set_insert_value={set_insert_value}
          field_id={field_id}
          select_modal={select_modal}
          acccordion_title="generic_accordion"
          selectAccordionTab={selectAccordionTab}
          setSelectAccordionTab={setSelectAccordionTab}
        />
      ) : ( */}
        <AccordionBasic
          keys={orderedKeys}
          set_insert_value={set_insert_value}
          field_id={field_id}
          select_modal={select_modal}
          acccordion_title="generic_accordion"
        />
        {/* // <AccordionDefaultOptions */}
        {/* //   keys={keys}
        //   action_id={action_id}
        //   set_insert_value={set_insert_value}
        //   field_id={field_id}
        //   select_modal={select_modal}
        //   acccordion_title="generic_accordion"
        //   selectAccordionTab={selectAccordionTab}
        //   setSelectAccordionTab={setSelectAccordionTab}
        // />
      // )} */}
    </>
  );
};

export default SingleselecCustom;
