

const uuidGenerator = (itemId) => {
  // const uuid = Math.floor(Math.random() * 10000);
  const uuid=Math.floor(Math.random() * (55555 - 11111 + 1)) + 11111;
  
  const elementid = itemId +"-"+ uuid;
return (
  {elementid}
)
}

export default uuidGenerator