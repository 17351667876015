import TriggerIcon from "./images/trigger.png";
import FilterIcon from "./images/filter.avif";
import InterfaceLogo from "./images/LOGO-WHITE.png";
import FubLogo from "./images/fub.png";
import ExclamationIcon from "./images/exclamation-mark.png";


export const get_action_icon = (action_name) => {
  let action_icon = "";
  if (action_name === "trigger") {
    action_icon = TriggerIcon;
  } else if (action_name.includes("Fub")) {
    action_icon = FubLogo;
  } else if (action_name === "Filter") {
    action_icon = FilterIcon;
  } else if (action_name === "Unknown") {
    action_icon = ExclamationIcon;
  } else {
    action_icon = InterfaceLogo;
  }
  return action_icon;
};