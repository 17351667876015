import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import interfaceLogo from "../../assets/images/interface-circle.png";

const GetFormsByFieldId = gql`
  query GetFormsByFieldId($fieldId: String) {
    GetFormsByFieldId(field_id: $fieldId) {
      form_id
      form_name
    }
  }
`;

const get_hostname = () => {
  let url = window.location.host;
  let hostname = "";
  if (url === "app.sandbox.datalabz.re") {
    hostname = "https://app.sandbox.datalabz.re/forms/";
  } else if (url === "app.test.datalabz.re") {
    hostname = "https://app.test.datalabz.re/forms/";
  } else if (url === "app.datalabz.re") {
    hostname = "https://app.datalabz.re/forms/";
  } else {
    hostname = "http://localhost:3000/forms/";
  }
  return hostname;
};

const FieldConnections = ({ field_id, index, interface_field_id }) => {
  const [show, setShow] = useState(false);
  const { data: forms, loading } = useQuery(GetFormsByFieldId, {
    variables: { fieldId: interface_field_id },
  });

  return (
    <>
      <div
        className="modal show fade"
        id={"view-connection" + index}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog col-6 modal-fullscreen-sm-down modal-dialog-scrollable h-100 mt-0 me-0 mb-0">
          <div className="modal-content h-100">
            <div className="modal-header bg-color-1">
              <h5 className="modal-title fs-6" id="staticBackdropLabel">
                Field connections
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body py-4 px-4">
              <p>
                There are {forms?.GetFormsByFieldId?.length} Forms where mapped
                field is located or used
              </p>
              <div className="d-flex p-2 my-2 border rounded align-items-center gap-2 shadow">
                {/* <i className="fs-3 iconify" data-icon="gridicons:heading" /> */}
                <img src={interfaceLogo} alt="" />
                <span>{field_id}</span>
              </div>
              <div className="pt-3">
                {loading && <div className="text-center">Loading forms...</div>}
                {forms?.GetFormsByFieldId?.map((form, index) => (
                  <div className="d-flex align-items-center justify-content-between pointer mb-3 py-3 px-2 border-bottom">
                    <p className="m-0">{form?.form_name}</p>
                    <a
                      className="border-start ps-3"
                      href={`${get_hostname()}${form?.form_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="iconify fs-4" data-icon="mdi:arrow-right" />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && <div class="modal-backdrop fade show"></div>}
    </>
  );
};

export default FieldConnections;
