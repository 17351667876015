import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Loaderr } from '../../components/Loaderr';
import "../../../src/assets/css/embeddedapp.css"
import { NavLink } from 'react-router-dom';

const GetPipelineAndFormName = gql`
query FormNamesByPipeline($pipeline: String, $teamId: String, $getPipelinesTeamId2: String!) {
  formNamesByPipeline(pipeline: $pipeline, team_id: $teamId) {
    form_id
    form_name
  }
  GetPipelines(team_id: $getPipelinesTeamId2) {
    statusCode
    message
    data
  }
}`

const GetTeamIdByFubdomain = gql`
query GetTeamIdByFubdomain($fubDomain: String) {
  GetTeamIdByFubdomain(fub_domain: $fubDomain) {
    team_id
  }
}
`
console.log("Location1:", window.location.href)
export const CreateOpportunity = () => {
  const [domain, setDomain] = React.useState(null)
  const [personId, setPersonId] = React.useState(null)
  const [dropdownvalue, setDropdownvalue] = useState(null)
  const navigate = useNavigate()
  const Location = useLocation();
  const getURLParams = new URLSearchParams(Location.search);
  const context = getURLParams.get('context')
  const signature = getURLParams.get('signature')
  const [Formloading, setFormLoading] = useState(false);  // Add a loading state
  const [newForm, setNewForm] = useState({
    pipelineName: "",
    StageName: ""
  });
  const [pipeLineLoading, setPipeLineLoading] = useState(false)

  const { data: TeamDataByFubDomain, loading: TeamDataLoading } = useQuery(GetTeamIdByFubdomain, {
    variables: { fubDomain: domain },
  })

  const { data: TeamPipelineAndFormData, loading: TeamPipelineDataLoading } = useQuery(GetPipelineAndFormName, {
    variables: {
      pipeline: newForm.pipelineName,
      teamId: TeamDataByFubDomain?.GetTeamIdByFubdomain[0]?.team_id,
      getPipelinesTeamId2: TeamDataByFubDomain?.GetTeamIdByFubdomain[0]?.team_id
    },
    // skip: !newForm.pipelineName,
    notifyOnNetworkStatusChange: true,
    skip: !TeamDataByFubDomain?.GetTeamIdByFubdomain[0]?.team_id,
    onCompleted: () => {
      setFormLoading(false)
      setPipeLineLoading(false)
    }, // Stop loading when data is fetched
    onError: () => {
      setFormLoading(false)
      setPipeLineLoading(false)
    },

  })
  // console.log("TeamDataByFubDomain", TeamPipelineAndFormData)

  useEffect(() => {
    if (newForm.pipelineName === "" && TeamPipelineDataLoading) {
      setPipeLineLoading(true)
    }
    else {
      setPipeLineLoading(false)
    }
  }, [TeamPipelineDataLoading,newForm.pipelineName] 
  )



  useEffect(() => {
    if (context) {
      const temp = atob(context)
      const object = JSON.parse(temp)

      if (object['account'].domain) {
        setDomain(object['account'].domain)
      }
      if (object['person'].id) {
        setPersonId(object['person'].id)
      }
    }
  }, [context])


  // useEffect(() => {

  //   if (newForm.pipelineName !== "") {
  //     setFormLoading(true);

  //     refetch();
  //   }
  // }, [newForm.pipelineName])

  useEffect(() => {
    if (newForm.pipelineName) {
      setFormLoading(true)
    }
  }, [newForm.pipelineName])
  const inputHandler = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setNewForm({ ...newForm, [name]: value });
  };


  const HanndleGoback = () => {
    navigate(`/List-view?context=${context}&signature=${signature}`)
  }

  const handlePreviewClick = () => {
    console.log("Clicked")
    navigate(`/forms/${dropdownvalue}?context=${context}&signature=${signature}&InputField-Follow-up-boss-lead-id=${personId}`)
  }

  console.log("TeamDataByFubDomain", newForm.pipelineName !== "", pipeLineLoading, TeamPipelineDataLoading)

  if (TeamDataLoading) return <Loaderr />


  return (
    <div> <div id="loader" style={{ display: "none" }}>
    </div>
      <div id="demo" className="p-4 bg-white">
        <div
          className="d-flex justify-content-between align-items-center"
          id="back_button"
        >
          <img
            src="https://fs.hubspotusercontent00.net/hub/21128156/hubfs/Interface-dark-logo.png?width=108&height=108"
            width={45}
            alt='logo'
          />
          <b className="text-decoration-underline text-dark pointer">
            <i
              className="iconify"
              data-icon="material-symbols:arrow-back-rounded"
            ></i>
            <a
              onClick={() => { HanndleGoback() }} >
              Go back
            </a>
          </b>
        </div>
        <hr className="hr" />
        <div className="d-flex justify-content-center text-center mb-2">

          <b className="fs-6 text-center">Create New Opportunity</b>

        </div>
        <form method="POST" className="mb-5">

          {pipeLineLoading ? (<div className="d-flex justify-content-center pb-4">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>) : (<div className="mb-4 mx-auto col-11">
            <label className='text-center w-100'>
              <span class=" mb-2 d-flex align-items-center justify-content-center">
                <span>Select Pipeline</span>
              </span>
            </label>
            <select className="form-select input-bn" id="form_url" name="pipelineName" onChange={inputHandler} value={newForm.pipelineName}>
              <option selected hidden>Select Pipeline</option>
              {
                TeamPipelineAndFormData?.GetPipelines?.data?.map((pipeline) => {
                  return (
                    <option value={pipeline.id} >
                      {pipeline.name}
                    </option>
                  )
                })
              }
            </select>

          </div>

          )}



          {Formloading ? (
            <div className="d-flex justify-content-center pb-4">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            newForm.pipelineName && (
              <div className="mb-4 mx-auto col-11">
                <label className='text-center w-100'>
                  <span class=" mb-2 d-flex align-items-center justify-content-center">
                    <span>Select Form</span>
                  </span>
                </label>

                <select className="form-select input-bn" id="form_url" name="form_url" required
                  onChange={(e) => setDropdownvalue(e.target.value)}
                >
                  <option selected hidden>Select Form</option>
                  {
                    TeamPipelineAndFormData?.formNamesByPipeline?.map((form) => {
                      console.log("Form", form)
                      if (form.form_name !== "Terminate")
                        return (
                          <option value={form.form_id}>
                            {form.form_name}
                          </option>
                        )
                    }
                    )
                  }
                </select>
              </div>
            )
          )}





          <div className="d-flex">
            <div className="form-actions col form-group text-center">
              <p
                href="" className="btn-link" id="pipeline-link-btn">
                <button
                  onClick={() => { handlePreviewClick() }}
                  id="form-btn"
                  className="btn btn-primary col-8 center-btn"
                  type="button"
                  disabled={dropdownvalue == null ? true : false}
                >
                  Take me to the form
                </button>
                <NavLink 
                  to={dropdownvalue ? `/forms/${dropdownvalue}?context=${context}&signature=${signature}&InputField-Follow-up-boss-lead-id=${personId}` : "#"}
                  target="_blank"
                  rel="noreferrer"
                  className={`w-500 ${dropdownvalue == null ? "disabled-link" : ""}`}
> 
                  <span >
                <i
                    className="iconify mx-1 pointer text-secondary fs-5"
                    data-icon="fa:external-link"
                    title='Take me to the form'


                />
                  </span>
                </NavLink>
              </p>
            </div>
          </div>
        </form>
        <hr className="hr col-12" />
        <div className="mt-4 mb-4 d-flex flex-column justify-content-center align-items-center">
          <img
          alt="Interface Logo"
            src="https://datalabz.re/static/images/interface_logo.png"
            width={180}
          />
          <a
            href="https://knowledge.interface.re/fub-embedded-app-documentation"
            target="_blank"
            className="text-decoration-underline text-dark mt-2"
          >
            Learn more about InterFace
          </a>
        </div>
      </div>
    </div>
  )
}