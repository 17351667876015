import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { useNavigate, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react';
import {Loaderr} from '../../components/Loaderr';
import "../../../src/assets/css/embeddedapp.css"

const GetPipelineAndFormName = gql`
query FormNamesByPipeline($pipeline: String, $teamId: String, $getPipelinesTeamId2: String!) {
  formNamesByPipeline(pipeline: $pipeline, team_id: $teamId) {
    form_id
    form_name
  }
  GetPipelines(team_id: $getPipelinesTeamId2) {
    statusCode
    message
    data
  }
}`

const GetTeamIdByFubdomain = gql`
query GetTeamIdByFubdomain($fubDomain: String) {
  GetTeamIdByFubdomain(fub_domain: $fubDomain) {
    team_id
  }
}
`


export const UpdateOpportunity = () => {
  const [domain, setDomain] = useState(null);
  const [personId, setPersonId] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [newForm, setNewForm] = useState({
    pipelineName: '',
    stageName: ''
  });
  const [selectedFormId, setSelectedFormId] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const context = urlParams.get('context');
  const signature = urlParams.get('signature');
  const opportunityKey = urlParams.get('opp_key');
  const previousStage = urlParams.get('stage');
  const previousPipeline = urlParams.get('pipeline');

  useEffect(() => {
    if (context) {
      const decodedContext = atob(context);
      const parsedContext = JSON.parse(decodedContext);

      if (parsedContext['account'].domain) {
        setDomain(parsedContext['account'].domain);
      }
      if (parsedContext['person'].id) {
        setPersonId(parsedContext['person'].id);
      }
    }
  }, [context]);

  const { data: TeamDataByFubDomain, loading: teamLoading } = useQuery(GetTeamIdByFubdomain, {
    variables: { fubDomain: domain },
  });

  const { data: TeamPipelineAndFormData, loading: TeamPipelineDataLoading, refetch } = useQuery(GetPipelineAndFormName, {
    variables: {
      pipeline: newForm.pipelineName,
      teamId: TeamDataByFubDomain?.GetTeamIdByFubdomain[0].team_id,
      getPipelinesTeamId2: TeamDataByFubDomain?.GetTeamIdByFubdomain[0].team_id
    },
    // skip: !newForm.pipelineName,
    skip: !TeamDataByFubDomain?.GetTeamIdByFubdomain[0].team_id,
    onCompleted: () => setFormLoading(false), // Stop loading when data is fetched
    onError: () => setFormLoading(false),

  })


  useEffect(() => {
    setNewForm({
      pipelineName: previousPipeline,
      stageName: previousStage
    });
  }, [previousPipeline, previousStage]);
  useEffect(() => {
    if (newForm.pipelineName) {
      setFormLoading(true);
      refetch();
    }
  }, [newForm.pipelineName]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleGoBack = () => {
    navigate(`/List-view?context=${context}&signature=${signature}`);
  };

  const handlePreviewClick = () => {
    navigate(`/forms/${selectedFormId}?context=${context}&signature=${signature}&InputField-Follow-up-boss-lead-id=${personId}&InputField-opp-key=${opportunityKey}`);
  };

  const handleTerminateClick = () => {
    navigate('/forms/bdaf927f-7be9-410c-831f-a0d4fe145ffb?context=null&signature=null');
  };

  if (teamLoading ||  TeamPipelineDataLoading ) return <Loaderr />;

  return (
    <div>
      <div id="loader" style={{ display: 'none' }}></div>
      <div id="demo" className="p-4 bg-white">
        <div className="d-flex justify-content-between align-items-center" id="back_button">
          <img
            src="https://fs.hubspotusercontent00.net/hub/21128156/hubfs/Interface-dark-logo.png?width=108&height=108"
            width={45}
          />
          <b className="text-decoration-underline text-dark pointer">
            <i className="iconify" data-icon="material-symbols:arrow-back-rounded"></i>
            <a onClick={handleGoBack}>Go back</a>
          </b>
        </div>
        <hr className="hr" />
        <div className="d-flex justify-content-center text-center mb-2">
          <b className="fs-6 text-center">Update Opportunity</b>
        </div>
        <div className="text-lg-end" style={{float:"right"}}>
          {/* <a onClick={handleTerminateClick} className="text-decoration-underline text-danger pointer terminate_button text-end">
            Terminate<i className="iconify" data-icon="ri:indeterminate-circle-line"></i>
          </a> */}
        </div>
        <form method="POST" className="mb-5">
          <div className="mb-4 mx-auto col-11">
            <label className="text-center w-100">
              <span className="mb-2 d-flex align-items-center justify-content-center">
                <span>Select Pipeline</span>
              </span>
            </label>
            <select
              className="form-select input-bn"
              id="form_url"
              name="pipelineName"
              onChange={handleInputChange}
              value={newForm.pipelineName}
              disabled
            >
              <option selected hidden>Select Pipeline</option>
              {TeamPipelineAndFormData?.GetPipelines?.data?.map((pipeline) => (
                <option key={pipeline.id} value={pipeline.id} disabled>
                  {pipeline.name}
                </option>
              ))}
            </select>
          </div>

          {formLoading ? (
            <div className="d-flex justify-content-center pb-4">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            newForm.pipelineName && (
              <div className="mb-4 mx-auto col-11">
                <label className="text-center w-100">
                  <span className="mb-2 d-flex align-items-center justify-content-center">
                    <span>Select Form</span>
                  </span>
                </label>
                <select
                  className="form-select input-bn"
                  id="form_url"
                  name="form_url"
                  required
                  onChange={(e) => setSelectedFormId(e.target.value)}
                >
                  <option selected hidden>Select Form</option>
                    {TeamPipelineAndFormData?.formNamesByPipeline?.map((form) => (
                    form.form_name !== 'Terminate' && (
                      <option key={form.form_id} value={form.form_id}>
                        {form.form_name}
                      </option>
                    )
                  ))}
                </select>
              </div>
            )
          )}

          <div className="d-flex">
            <div className="form-actions col form-group text-center">
              <p id="pipeline-link-btn">
                <button
                  onClick={handlePreviewClick}
                  id="form-btn"
                  className="btn btn-primary col-11 py-2"
                  type="button"
                  disabled={!selectedFormId}
                >
                  Take me to the form
                </button>
              </p>
            </div>
          </div>
        </form>
        <hr className="hr col-12" />
        <div className="mt-4 mb-4 d-flex flex-column justify-content-center align-items-center">
          <img
            src="https://datalabz.re/static/images/interface_logo.png"
            width={180}
          />
          <a
            href="https://knowledge.interface.re/fub-embedded-app-documentation"
            target="_blank"
            className="text-decoration-underline text-dark mt-2"
          >
            Learn more about InterFace
          </a>
        </div>
      </div>
    </div>
  );
};