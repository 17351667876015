import React from "react";
import ArrPng from "../../assets/images/arr.png";

const FubViewOnly = ({ action_index, selectAutomation, test_result=false }) => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <img height="70" src={ArrPng} alt="" />
      </div>
      <div className="dot-border rounded-3 bg-white gx-2 mb-2 p-4 border position-relative">
      { test_result &&
          (
          <div className="d-flex align-items-start mb-3 tooltip-container bvjk">
            <i
              className="iconify text-danger mns-auto"
              data-icon="mdi:info-circle"
            ></i>
            <div className="tooltip-text">
              Please test the action before saving workflow!{" "}
            </div>
          </div>
        )}
        <div className="">
          <div className="w-100 mb-3">
            <div className="d-flex align-items-center bg-white pt-3 pb-2">
              <strong> {action_index + ". "}</strong>
              <img
                className="me-2 p-0 fs-5 ms-2"
                height="20"
                src="https://assets-global.website-files.com/5c87ecb83f656c685ed4cf87/5caf60869e5e1c447811df96_logo_alt.png "
                alt=""
              />
            </div>
          </div>
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center p-3 border-0 alert-primary col text-dark form-control-sm">
              {selectAutomation
                ? "Selected Automation : " + selectAutomation
                : "Select an automation"}
              <i
                className="iconify fs-5"
                data-icon="akar-icons:settings-horizontal"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FubViewOnly;
