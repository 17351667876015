import React, { useState } from "react";
import Switch from "react-switch";
import { gql, useMutation } from "@apollo/client";
import TypingDots from "../assets/images/typing-dots.gif";

const UpdateWorkflowTemplateStatus = gql`
  mutation UpdateWorkflowTemplateStatus(
    $templateId: String!
    $status: Boolean!
  ) {
    UpdateWorkflowTemplateStatus(template_id: $templateId, status: $status) {
      statusCode
      message
      data
    }
  }
`;

const SingleTemplate = ({ template }) => {
  const [templateStatusSwitchDisabled, setTemplateStatusSwitchDisabled] = useState(template.status);

  const [
    updateWorkflowTemplateStatus,
    {
      loading: templateStatusLoading,
    },
  ] = useMutation(UpdateWorkflowTemplateStatus, {
    // onCompleted: (data) => {
    //   data?.UpdateWorkflowTemplateStatus?.statusCode >= 300
    //     ? data?.UpdateWorkflowTemplateStatus?.message
    //       ? alert(data?.UpdateWorkflowTemplateStatus?.message) 
    //       : alert("An unkown error occured.")
    //     : setTemplateStatusSwitchDisabled(!templateStatusSwitchDisabled);
    // },
    onCompleted: (data) => {
      if (data?.UpdateWorkflowTemplateStatus?.statusCode >= 300) {
        if (data?.UpdateWorkflowTemplateStatus?.message) {
          alert(data?.UpdateWorkflowTemplateStatus?.message);
        } else {
          alert("An unknown error occurred.");
        }
      } else {
        setTemplateStatusSwitchDisabled(!templateStatusSwitchDisabled);
      }
    },
    onError: (error) => {
      alert("Something went wrong. Please try again.");
    }
  });

  const onChangeStatus = (templateId, status) => {
    updateWorkflowTemplateStatus({
      variables: {
        status: status,
        templateId: templateId,
      },
    });
  };

  return (
    template?.show_in_ui && (
    <div className="p-3">
    <div className="pointer bg-white my-md-3 my-2 p-3 px-4 border rounded-3">
      { templateStatusLoading ? (
        <>
       <div className="d-flex flex-column ">
        <div
          className="d-flex flex-column align-items-center"
          style={{ height: "169px" }}
        >
          <p className="m-0 mt-5">Updating status</p>
          <div>
            <img
              style={{ height: "60px" }}
              src={TypingDots}
              alt="Updating statusr"
            />
          </div>
        </div>
      </div>
        </>
      ):(
      <div className="d-flex flex-column">
          <Switch
            width={50}
            className="switch-rect pb-4"
            height={25}
            checked={templateStatusSwitchDisabled}
            onChange={(checked) => {
              onChangeStatus(template.template_id, checked);
            }}
          />

          <b className="fs-5">{template.template_name}</b>

          <span className="py-4">{template.template_description}</span>
        </div>
      )}
    </div>
    </div>
    )
  );
};

export default SingleTemplate;
