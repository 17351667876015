import React from "react";

const TriggerViewOnly = ({ action_index, triggerdetails }) => {
  return (
    <>
      <div className=" bg-white p-4 rounded-3 border">
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <div className="d-flex mb-3">
            <strong> {action_index + ". "}</strong>
            <i
              className="iconify p-0 fs-2 text-primary"
              data-icon="ic:sharp-bolt"
            ></i>
            <span>
              <span className="text-capitalize">
                {triggerdetails.data_mapper?.type}
              </span>
              <br />
              <span className="text-secondary small">
                {triggerdetails.data_mapper?.description}
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default TriggerViewOnly;
