import React, { useEffect } from 'react'
import { BoardDeleteHandler } from '../../BoardHandlerFunctions'
import { boardConetxt } from '../../../pages/form-builder/dashboard/ContextHelper'
import { useContext } from 'react'
import useFormApiHelper from '../../../utils/useFormApiHelper'
import { useParams } from 'react-router-dom'

export const DeleteModalComponet = ({ DeleteField }) => {
  const params = useParams()
  const { board, setboard } = useContext(boardConetxt);
  const [DeleteFieldId, setDeleteFieldId] = React.useState(null)

  const [showConitionFuelds, setShowConitionFuelds] = React.useState(false);
  const { GetFormData } = useFormApiHelper();
  const { formData } = GetFormData(params);



  useEffect(() => {
    let found = true;
    for (let i = 0; i < formData?.formData?.form_conditions.length; i++) {
      for (let j = 0; j < formData?.formData?.form_conditions[i].Fields?.length; j++) {
        if (formData?.formData?.form_conditions[i].Fields[j] === DeleteField) {
          found = false;
          break;
        }
      }


    }
    const temp = formData?.formData?.form_conditions
    console.log("Temo", temp)
    temp?.map((item) => {
      console.log("showConitionFuelds", item)
      item?.condtions?.map((field) => {
        console.log("showConitionFuelds", field)
        field.map((fields) => {
          console.log("showConitionFuelds", fields)
          if (fields.field_id === DeleteField) {
            found = false
          }

        })

      }
      )

    }
    )
    board?.map((item) => {
      if (item?.elementid === DeleteField) {
        return setDeleteFieldId(item?.settings?.label || item?.name || item?.elementid)
      }
    })




    if (found) {
      setShowConitionFuelds(false)
    }
    else {
      setShowConitionFuelds(true)
    }

  }, [DeleteField])




  return (<>
    <div
      className="modal fade "
      id="exampleModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Are you sure you want to delete this field?
            </h5>
            {/* <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button> */}
          </div>
          <div className="modal-body">{showConitionFuelds ? <span > <span className='text-danger'>Warning :</span> Deleting  <b>{DeleteFieldId}</b> field will affect conditions associated conditions. Proceed with caution!</span> : <span><span className='text-warning'>Warning :</span> Are you sure you want to delete <b>{DeleteFieldId}</b> ?</span>}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={(e) => { BoardDeleteHandler(e, DeleteField, board, setboard, setShowConitionFuelds) }}
            >
              Yes
            </button>
            <button type="button" className="btn btn-success" data-dismiss="modal" data-bs-dismiss="modal">
              NO
            </button>
          </div>
        </div>
      </div>
    </div>
  </>


  )
}
