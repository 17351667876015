import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import useFormApiHelper from "../../../utils/useFormApiHelper";
import { Loaderr } from "../../../components/Loaderr";
import logo from "../../../assets/images/LOGO-BLUE.png";

const GetForm = gql`
query FormData($formId: String) {
  formData(form_id: $formId) {
    form_id
    form_name
    form_description
    base_form_template
    status
  }
}`;

function FormEntries() {
  const navigate = useNavigate();
  const params = useParams();
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { GetFormEntries, DeleteFormEntry, GetFormData, CountFormEntries } = useFormApiHelper();

  const [entries, setEntries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingEntries, setLoadingEntries] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const entriesPerPage = 10; // Number of entries per page
  const pageRange = 2; // Number of page links to show on either side of the current page

  let { formEntriesLoading, formEntriesError, formEntries } = GetFormEntries(params);
  const { countFormEntriesInOneCallData } = CountFormEntries(params.id);

  const {
    deleteFormEntry,
    deleteFormEntryLoading,
    deleteFormEntryData,
  } = DeleteFormEntry();

  const { formData, formDataLoading, formDataError } = GetFormData(params);

  const handleEntryView = (id) => {
    sessionStorage.setItem("currentPage", currentPage);  // Save the current page number
    navigate(`/forms/${params.id}/entries/${id}`);
  };

  const handleEntryDelete = (id) => {
    deleteFormEntry({
      variables: {
        formEntryId: id,
        formId: params.id,
      },
    });
  };

  useEffect(() => {
    if (deleteFormEntryData?.DeleteFormEntry?.form_entry_id) {
      window.location.reload();
    }
  }, [deleteFormEntryData]);

  // useEffect(() => {
  //   setEntries(formEntries?.formEntries || []);
  //   // setEntries((formEntries?.formEntries || []).reverse());

  // }, [formEntries]);
  useEffect(() => {
    if (formEntries?.formEntries) {
      // Reverse the entries only if data is present
      setEntries([...formEntries.formEntries].reverse());
    } else {
      console.error("Error: No form entries available to display.");
    }
  }, [formEntries]);
  useEffect(() => {
    // Reset to first page when filters are applied
    setCurrentPage(1);
  }, [searchQuery, startDate, endDate]);

  useEffect(() => {
    // Retrieve the saved page number from sessionStorage when component mounts
    const savedPage = sessionStorage.getItem("currentPage");
    if (savedPage) {
      setCurrentPage(parseInt(savedPage, 10));  // Restore the saved page number
    }
  }, []);

  const filteredEntries = entries?.filter((entry) => {
    // Ensure entry_date is converted to a Date object
    const entryDate = new Date(parseInt(entry?.entry_created_date, 10));
  
    // Handle cases where startDate or endDate might be empty
    const formattedStartDate = startDate ? new Date(startDate) : null;
    const formattedEndDate = endDate ? new Date(endDate) : null;
  
    // Check if the entry date is within the range or if no range is set
    const isWithinDateRange =
      (!formattedStartDate || entryDate >= formattedStartDate) &&
      (!formattedEndDate || entryDate <= formattedEndDate);
  
    // Check if the entry matches search query
    const leadIdMatches = entry?.form_entry_data[0]["InputField-Follow-up-boss-lead-id"]
      ?.toString()
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
  
    const lastNameMatches = entry?.form_entry_data[0]["InputField-opp_type"]
      ?.toString()
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
  
    const oppKeyMatches = entry?.form_entry_data[0]["InputField-opp-key"]
      ?.toString()
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
  
    // Return true if the entry is within date range and matches search query
    if (searchQuery === "") {
      return isWithinDateRange;
    } else {
      return isWithinDateRange && (leadIdMatches || lastNameMatches || oppKeyMatches);
    }
  });

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredEntries?.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(filteredEntries?.length / entriesPerPage);

  useEffect(() => {
    // Check if there are no filtered entries and searchQuery or date range is applied
    if (filteredEntries?.length === 0 && (searchQuery || startDate || endDate)) {
      setErrorMessage('No entries found matching your search criteria.');
    } else {
      setErrorMessage('');
    }
  }, [filteredEntries, searchQuery, startDate, endDate]);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const paginate = (pageNumber) => {
    setLoadingEntries(true);
    setCurrentPage(pageNumber);

    setTimeout(() => {
      setLoadingEntries(false);
    }, 500);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    const startPage = Math.max(2, currentPage - pageRange);
    const endPage = Math.min(totalPages - 1, currentPage + pageRange);

    pageNumbers.push(
      <li key={1} className={`page-item ${currentPage === 1 ? "active" : ""}`}>
        <a onClick={() => paginate(1)} className="page-link px-3 text-dark">
          1
        </a>
      </li>
    );

    if (startPage > 2) {
      pageNumbers.push(
        <li key="ellipsis-start" className="page-item disabled">
          <span className="page-link px-3 text-dark">...</span>
        </li>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${currentPage === i ? "active" : ""}`}>
          <a onClick={() => paginate(i)} className="page-link px-3 text-dark">
            {i}
          </a>
        </li>
      );
    }

    if (endPage < totalPages - 1) {
      pageNumbers.push(
        <li key="ellipsis-end" className="page-item disabled">
          <span className="page-link px-3 text-dark">...</span>
        </li>
      );
    }

    if (totalPages > 1) {
      pageNumbers.push(
        <li key={totalPages} className={`page-item ${currentPage === totalPages ? "active" : ""}`}>
          <a onClick={() => paginate(totalPages)} className="page-link px-3 text-dark">
            {totalPages}
          </a>
        </li>
      );
    }

    return pageNumbers;
  };

  if (formEntriesLoading || deleteFormEntryLoading || formDataLoading) return <Loaderr />;

  const convertDate = (entryDate) => {
    var dateAsInt = parseInt(entryDate, 10);
    const newDate = new Date(dateAsInt);
    const formattedDate = newDate.toLocaleString('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });
    return formattedDate;
  };
  const handleOppEntryView = (oppKey) => {
    if (oppKey === undefined) return;
    navigate(`/forms/opportunities/${oppKey}`);
  };
console.log("formData",formEntries)
  return (
    <>
      <header className="header bg-white border-bottom" id="header">
        <div>
          <div className="pointer" onClick={() => navigate("/forms/")}>
            <div className="d-flex align-items-center ms-2">
              <span className="navbar-brand m-0 p-0">
                <img className="img-fluid" src={logo} width="30" alt="" />
              </span>
              <i className="bx bx-chevron-left nav_icon mx-md-2 mx-1"></i>
              <span className="p-0 m-0 text-decoration-underline">Form Dashboard</span>
          </div>
          </div>
        </div>
      </header>
      <div className="main fmc-main p-0">
        <section className="container-lg container-fluid pt-5">
          <div className="p-4 bg-white border rounded-3 mb-3 mt-3">
            <h5>
              <i className="iconify me-2" data-icon="tdesign:form" />
              {formData?.formData?.form_name}
            </h5>
            <p className="text-secondary mt-2 m-0">
              <span className="text-dark me-2">Description:</span>{formData?.formData?.form_description}
            </p>
            <p className="text-secondary mt-2 m-0">
              <span className="text-dark me-2">Form Type:</span>{formData?.formData?.form_type}
            </p>
            <p className="text-secondary mt-2 m-0">
              <span className="text-dark me-2">Form template:</span>{formData?.formData?.base_form_template === "" ? "Custom Form" : formData?.formData?.base_form_template}
            </p>
            <hr className="hr op-10" />
            <div className="d-flex mt-3">
              <div className="pe-5 border-end text-center">
                <span>Total entries</span>
                <h3 className="mt-2">{countFormEntriesInOneCallData?.countFormEntries.count}</h3>
              </div>
              <div className="px-5 border-end text-center">
                <span>Today</span>
                <h3 className="mt-2">{countFormEntriesInOneCallData?.countFormEntriesToday?.count}</h3>
              </div>
            </div>
          </div>
          <div className="d-md-flex align-items-center">
            <div className="col-md-6 col-lg-7 col-xxl-4">
              <label className="small">Search</label>
            <input
                className="form-control input-bn search-fgy mb-3"
                placeholder="search entries"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            </div>
            <div className="d-flex align-items-end justify-content-md-end input-group mb-3">
              <i className="iconify me-2 my-auto fs-5" data-icon="mdi:table-filter"></i>
              <div>
                <label className="small">Start date</label>
            <input
                  className="form-control input-bn search-fgy dy-filt"
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
            />
              </div>
              <hr className="col-1 mb-4 my-auto" />
              <div>
                <label className="small">End date</label>
            <input
                  className="form-control input-bn search-fgy dy-filt rounded-0"
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </div>
              <div className="btn btn-primary fs-6 d-flex align-items-center justify-content-center search-fgy">
                <i className="iconify" data-icon="mdi:calendar" />
        </div>
            </div>
          </div>
          
          <section className="d-flex flex-column py-4 gap-3">
            <div className="d-md-flex flex-wrap border rounded-3 d-none fm-list-container-thead">
              <div className="d-flex flex-wrap col-12 col-md-3 align-items-center justify-content-between pb-2 pt-4 py-md-3 px-4 border-0 border-end border">
                Entries
              </div>
              <div className="d-flex align-items-center justify-content-center px-4 col col-md-2 border-0 border-end border">
                Opp Type
              </div>
              <div className="d-flex align-items-center justify-content-center px-4 col col-md-2 border-0 border-end border">
                FUB Lead ID
              </div>
              <div className="d-flex align-items-center justify-content-center px-4 col col-md-4 border-0 border-end border">
                Opp Key
              </div>
              <div className="d-flex align-items-center justify-content-center px-4 col col-md-1"></div>
            </div>
            {loadingEntries ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : currentEntries?.length > 0 ? (
              currentEntries.map((entry, index) => (
                <div
                  className="d-flex flex-wrap border rounded-3 bg-white fm-list-container"
                  key={index}
                >
                  <div className="d-flex flex-wrap col-12 col-md-3 align-items-center justify-content-between pb-2 pt-4 py-md-4 px-4 border-0 border-end border">
                    <p className="fs-6 p-0 m-0">
                      {convertDate(entry?.entry_created_date)}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center p-4 gap-1 col col-md-2 border-0 border-end border">
                    <p>{entry?.form_entry_data[0]["InputField-opp_type"]}</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center p-4 gap-1 col col-md-2 border-0 border-end border">
                    <p>{entry?.form_entry_data[0]["InputField-Follow-up-boss-lead-id"]}</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center p-4 gap-1 col col-md-4 border-0 border-end border">
                    {/* <p>{entry?.form_entry_data[0]["InputField-opp-key"]}</p> */}
                    <span className="text-decoration-underline pointer"
                      onClick={() => handleOppEntryView(entry?.form_entry_data[0]["InputField-opp-key"])}
                    >
                     {
                      entry?.form_entry_data[0]["InputField-opp-key"] === undefined ? "N/A" : "View Opportunity"
                     }
                    </span>

                  </div>
                  <div className="d-flex align-items-center justify-content-center p-4 col col-md-1">
                    <span
                      className="text-decoration-underline pointer"
                      onClick={() => handleEntryView(entry.form_entry_id)}
                    >
                      View Entries
                    </span>
                  </div>
                </div>
              ))
            ) : (
              
            <div className="d-flex alert alert-danger justify-content-center">
              {errorMessage}
        </div>

            )}
          </section>
          <div className="d-flex justify-content-center">
            <nav>
              <ul className="pagination justify-content-center mt-4">
              <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                  <button className="page-link px-3 text-dark" onClick={() => paginate(currentPage - 1)}>
                    <span>&lt;</span>
                  </button>
              </li>
              {renderPageNumbers()}
              <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                  <button className="page-link px-3 text-dark" onClick={() => paginate(currentPage + 1)}>
                    <span>&gt;</span>
                  </button>
              </li>
            </ul>
          </nav>
          </div>
      </section>
      </div>
    </>
  );
}

export default FormEntries;
