import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TypingDots from "../../../assets/images/typing-dots.gif";
import { gql, useMutation } from "@apollo/client";
import { Modal, Button } from "react-bootstrap";
import Switch from "react-switch";

const ArchiveWorkflow = gql`
  mutation ArchiveWorkflow($workflowId: String!) {
    ArchiveWorkflow(workflow_id: $workflowId) {
      statusCode
      message
      data
    }
  }
`;

const SingleWorkflow = ({
  workflow,
  workflowsList,
  setWorkflowsList
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteWorkflowId, setDeleteWorkflowId] = useState("");
  const navigate = useNavigate();

  const [
    archive_workflow,
    {
      loading: archiveWorkflowLoading
    },
  ] = useMutation(ArchiveWorkflow, {
    onCompleted: (data) => {
      if (data?.ArchiveWorkflow?.statusCode === 200) {
        const updatedWorkflowsList = workflowsList.filter(
          (workflow) => workflow.workflow_id !== deleteWorkflowId
        );
        setWorkflowsList(updatedWorkflowsList);
        setDeleteWorkflowId("");
      } else {
        alert("Something went wrong. Please try again.");
      }
    },
    onError: (error) => {
      alert("Something went wrong. Please try again.");
    },
  });

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleShowModal = (id) => {
    setShowDeleteModal(true);
    setDeleteWorkflowId(id);
  };

  const handleDelete = () => {
    archive_workflow({
      variables: {
        workflowId: deleteWorkflowId,
      },
    });
    handleCloseModal();
  };

  const handleClickRoutes = (id) => {
    navigate(`/automation-builder/edit-workflow/${id}`);
  };

  const DeleteWorkflowCard = () => {
    return (
      <div className="row gx-4 gy-1 flex-wrap ">
        <div className="col-xdl-6 col-md-12 col-12">
          <div className="d-flex flex-column justify-content-center pointer bd-l bg-white my-md-3 my-2 p-3 px-4 border shaddow-lg rounded-3">
            <div className="d-flex flex-column align-items-center">
              <p className="m-0 mt-5">Deleting Workflow</p>
              <div className="mb-2">
                <img
                  style={{ height: "60px" }}
                  src={TypingDots}
                  alt="Deleting Workflow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (archiveWorkflowLoading && deleteWorkflowId === workflow.workflow_id)
    return <DeleteWorkflowCard />;

  return (
    <>
      <div
        className="row gx-4 gy-1 flex-wrap "
        onClick={() => {
          handleClickRoutes(workflow.workflow_id);
        }}
      >
        <div className="col-xdl-6 col-md-12 col-12">
          <div className="d-flex flex-column justify-content-center pointer bd-l bg-white my-md-3 my-2 p-3 px-4 border shaddow-lg rounded-3">
            <div className="d-flex justify-content-between align-items-start">
              <div className="d-flex align-items-center">
                <div className="d-flex flex-column">
                  <b className="fs-6">{workflow.workflow_name}</b>
                  <span className="mt-1">Fub automation</span>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <i
                  className="iconify p-0 fs-3 text-primary"
                  data-icon="ic:sharp-bolt"
                ></i>
                <span
                  className="text-decoration-underline"
                  onClick={() => {
                    handleClickRoutes(workflow.workflow_id);
                  }}
                >
                  {workflow.trigger_details?.type}
                </span>
              </div>
            </div>

            <span className="mt-3 text-secondary">
              {workflow.workflow_description}
            </span>

            <hr className="hr op-10" />

            <div className="d-flex justify-content-between align-items-center">
              <span className="mt-1">
                <Switch
                  width={50}
                  className="switch-rect"
                  height={25}
                  checked={workflow?.status}
                  disabled={true}
                />
              </span>
              <div className="d-flex">
                <div className="d-flex align-items-center">
                  <i
                    className="iconify p-0 fs-5 mx-2 text-primary"
                    data-icon="akar-icons:settings-horizontal"
                  ></i>
                </div>
                <div
                  className="d-flex align-items-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShowModal(workflow.workflow_id);
                  }}
                >
                  <i
                    className="iconify p-0 fs-5 text-secondary"
                    data-icon="mdi:trash"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal for confirmation */}
      <Modal show={showDeleteModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you really want to delete this workflow? This process cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SingleWorkflow;
