import React from 'react'
import Cookies from "js-cookie";
import { makeApiCall } from "./modules/MakeApiRequest";
import { Outlet, Route } from 'react-router-dom'

import { useLocation } from 'react-router-dom';


export default function PrivateRoute({element, ...props}) {
  const location = useLocation();

   makeApiCall("GET", "user/csrf/", "")
      .then((data) => {

        if (data !== undefined && data.redirected === true) {
          if (data.url.includes("localhost")) {

            // setShowPriview(true);
            return (window.location.href =
              data.url +
              "?redirect=" +
              location.pathname +
              "&subdomain=" +
              window.location.hostname +
              ":" +
              window.location.port);
          } else {
            // setShowPriview(true);
            return (window.location.href =
              data.url +
              "?redirect=" +
              location.pathname +
              "&subdomain=" +
              window.location.hostname);
          }
        }
        return data !== undefined && data.json();
      })
      .then((data1) => {
        const token = data1.csrftoken;
        Cookies.set("csrftoken", token, { secure: true, sameSite: "none" });
      });
  return <Outlet {...props} element={element} />;
   
  
}
