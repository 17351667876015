export const whisselTeamAppointmentFormSeller = [
    {
        "elementid": "InputField-opp_last_name",
        "type": "InputField",
        "name": "Last Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Last Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-Appointment-Set-By",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2603",
                    "value": "ISA",
                    "isCheck": false
                },
                {
                    "id": "6926",
                    "value": "OSA",
                    "isCheck": false
                }
            ],
            "label": "Appt Set By",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appt Set By",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-opp_isa",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1548",
                    "value": "Andrew Canavan",
                    "isCheck": false
                },
                {
                    "id": "0791",
                    "value": "Francis Ruiz",
                    "isCheck": false
                },
                {
                    "id": "2561",
                    "value": "Marcus Bryant",
                    "isCheck": false
                },
                {
                    "id": "4832",
                    "value": "Paul Harrison",
                    "isCheck": false
                },
                {
                    "id": "4430",
                    "value": "Tad Murroughs",
                    "isCheck": false
                },
                {
                    "id": "2089",
                    "value": "Trystin Mesa",
                    "isCheck": false
                }
            ],
            "label": "ISAs",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "ISAs",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-opp_assigned_osa",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9592",
                    "value": "Abdullah Mohammed",
                    "isCheck": false
                },
                {
                    "id": "3971",
                    "value": "Adrian Quijano",
                    "isCheck": false
                },
                {
                    "id": "1695",
                    "value": "Alex Conti",
                    "isCheck": false
                },
                {
                    "id": "0402",
                    "value": "Alyssa Somsamouth",
                    "isCheck": false
                },
                {
                    "id": "4152",
                    "value": "Amanda Witherspoon",
                    "isCheck": false
                },
                {
                    "id": "0831",
                    "value": "Ana Jamo",
                    "isCheck": false
                },
                {
                    "id": "9086",
                    "value": "Andrea Fasulo",
                    "isCheck": false
                },
                {
                    "id": "1774",
                    "value": "Andrew Canavan",
                    "isCheck": false
                },
                {
                    "id": "4523",
                    "value": "Anninda Gibbs",
                    "isCheck": false
                },
                {
                    "id": "0970",
                    "value": "Antonella Marino",
                    "isCheck": false
                },
                {
                    "id": "2922",
                    "value": "Arquimedes Madrigal",
                    "isCheck": false
                },
                {
                    "id": "6117",
                    "value": "Austin Michelle",
                    "isCheck": false
                },
                {
                    "id": "8653",
                    "value": "Billie Guinto",
                    "isCheck": false
                },
                {
                    "id": "9262",
                    "value": "Bob David",
                    "isCheck": false
                },
                {
                    "id": "4015",
                    "value": "Brenda Alvarez",
                    "isCheck": false
                },
                {
                    "id": "2260",
                    "value": "Brian Meinert",
                    "isCheck": false
                },
                {
                    "id": "5136",
                    "value": "Brianda Arreola",
                    "isCheck": false
                },
                {
                    "id": "6651",
                    "value": "Brooke Feuling",
                    "isCheck": false
                },
                {
                    "id": "4027",
                    "value": "Bryan Mancuso",
                    "isCheck": false
                },
                {
                    "id": "2094",
                    "value": "Carlos Rey",
                    "isCheck": false
                },
                {
                    "id": "4857",
                    "value": "Carter Orchulli",
                    "isCheck": false
                },
                {
                    "id": "0825",
                    "value": "Celena Barone",
                    "isCheck": false
                },
                {
                    "id": "0317",
                    "value": "Chris VanderValk",
                    "isCheck": false
                },
                {
                    "id": "8946",
                    "value": "Christa Curtin",
                    "isCheck": false
                },
                {
                    "id": "7347",
                    "value": "Christopher Rossi",
                    "isCheck": false
                },
                {
                    "id": "2571",
                    "value": "Clayton Connolly",
                    "isCheck": false
                },
                {
                    "id": "5246",
                    "value": "Cody Stamm",
                    "isCheck": false
                },
                {
                    "id": "8579",
                    "value": "Corinne Mauro",
                    "isCheck": false
                },
                {
                    "id": "1250",
                    "value": "Courtney Renteria",
                    "isCheck": false
                },
                {
                    "id": "2191",
                    "value": "Courtney Truett",
                    "isCheck": false
                },
                {
                    "id": "3118",
                    "value": "Cynthia Mayorga",
                    "isCheck": false
                },
                {
                    "id": "1136",
                    "value": "Dan Benjamin",
                    "isCheck": false
                },
                {
                    "id": "5123",
                    "value": "Daniela Taylor",
                    "isCheck": false
                },
                {
                    "id": "3546",
                    "value": "Dany Lopez",
                    "isCheck": false
                },
                {
                    "id": "1170",
                    "value": "Dawn Cullen",
                    "isCheck": false
                },
                {
                    "id": "6382",
                    "value": "Domenica Picerni",
                    "isCheck": false
                },
                {
                    "id": "5629",
                    "value": "Elizabeth Diaz",
                    "isCheck": false
                },
                {
                    "id": "4597",
                    "value": "Emma Dearing",
                    "isCheck": false
                },
                {
                    "id": "9780",
                    "value": "Eric Gulal",
                    "isCheck": false
                },
                {
                    "id": "4915",
                    "value": "Erick Gonzalez",
                    "isCheck": false
                },
                {
                    "id": "1949",
                    "value": "Evan Wagley",
                    "isCheck": false
                },
                {
                    "id": "4522",
                    "value": "Eve Flaglore",
                    "isCheck": false
                },
                {
                    "id": "5416",
                    "value": "Ewa Baska",
                    "isCheck": false
                },
                {
                    "id": "7958",
                    "value": "Fernando Garay",
                    "isCheck": false
                },
                {
                    "id": "7429",
                    "value": "Frank Perez",
                    "isCheck": false
                },
                {
                    "id": "4319",
                    "value": "Gabriel Montanez",
                    "isCheck": false
                },
                {
                    "id": "6175",
                    "value": "Gabriel Talamantes",
                    "isCheck": false
                },
                {
                    "id": "2104",
                    "value": "Gabriela Gonzalez",
                    "isCheck": false
                },
                {
                    "id": "5850",
                    "value": "Gautier Argemi",
                    "isCheck": false
                },
                {
                    "id": "7718",
                    "value": "Hannah Ohman",
                    "isCheck": false
                },
                {
                    "id": "6402",
                    "value": "Harrison Haynes",
                    "isCheck": false
                },
                {
                    "id": "4399",
                    "value": "Hazael Garay",
                    "isCheck": false
                },
                {
                    "id": "7955",
                    "value": "Ian Worthington",
                    "isCheck": false
                },
                {
                    "id": "8084",
                    "value": "India Bonin",
                    "isCheck": false
                },
                {
                    "id": "6620",
                    "value": "J.C. Agajanian",
                    "isCheck": false
                },
                {
                    "id": "9737",
                    "value": "Jacob Carmona",
                    "isCheck": false
                },
                {
                    "id": "2312",
                    "value": "Janet Carmody",
                    "isCheck": false
                },
                {
                    "id": "2136",
                    "value": "Jarrod Norris",
                    "isCheck": false
                },
                {
                    "id": "0652",
                    "value": "Jen DiJulio",
                    "isCheck": false
                },
                {
                    "id": "9372",
                    "value": "Jennifer Crosby",
                    "isCheck": false
                },
                {
                    "id": "4368",
                    "value": "Jenny Adib",
                    "isCheck": false
                },
                {
                    "id": "4757",
                    "value": "Jeremy McHone",
                    "isCheck": false
                },
                {
                    "id": "5823",
                    "value": "Jesse Hodges",
                    "isCheck": false
                },
                {
                    "id": "4799",
                    "value": "Jesus Soberanis",
                    "isCheck": false
                },
                {
                    "id": "1633",
                    "value": "Joe OMeara",
                    "isCheck": false
                },
                {
                    "id": "7310",
                    "value": "Joel Schneider",
                    "isCheck": false
                },
                {
                    "id": "0502",
                    "value": "Jordan Gibson",
                    "isCheck": false
                },
                {
                    "id": "3839",
                    "value": "Jordan Graham",
                    "isCheck": false
                },
                {
                    "id": "0608",
                    "value": "Jules Marchisio",
                    "isCheck": false
                },
                {
                    "id": "0325",
                    "value": "Justin Verdugo",
                    "isCheck": false
                },
                {
                    "id": "2563",
                    "value": "Karlee Van Dyke",
                    "isCheck": false
                },
                {
                    "id": "4477",
                    "value": "Katie Lussier",
                    "isCheck": false
                },
                {
                    "id": "6203",
                    "value": "Katy Carlson",
                    "isCheck": false
                },
                {
                    "id": "3553",
                    "value": "Katya Roukoz",
                    "isCheck": false
                },
                {
                    "id": "6452",
                    "value": "Kelli Metcalf",
                    "isCheck": false
                },
                {
                    "id": "7282",
                    "value": "Kennedy Hannom",
                    "isCheck": false
                },
                {
                    "id": "1613",
                    "value": "Kevin Piechota",
                    "isCheck": false
                },
                {
                    "id": "2680",
                    "value": "Kiki Parsons",
                    "isCheck": false
                },
                {
                    "id": "2410",
                    "value": "Kimberlee La Bore",
                    "isCheck": false
                },
                {
                    "id": "4526",
                    "value": "Kimo Quance",
                    "isCheck": false
                },
                {
                    "id": "1656",
                    "value": "Koby Blankson",
                    "isCheck": false
                },
                {
                    "id": "1053",
                    "value": "Kristina Roberts",
                    "isCheck": false
                },
                {
                    "id": "1238",
                    "value": "Kwantida Payakka",
                    "isCheck": false
                },
                {
                    "id": "9103",
                    "value": "Kyle Whissel",
                    "isCheck": false
                },
                {
                    "id": "9656",
                    "value": "Leonard Franklin",
                    "isCheck": false
                },
                {
                    "id": "6857",
                    "value": "Lexie Lyons",
                    "isCheck": false
                },
                {
                    "id": "8423",
                    "value": "Lisa Cardoza",
                    "isCheck": false
                },
                {
                    "id": "0128",
                    "value": "Lisa Florendo",
                    "isCheck": false
                },
                {
                    "id": "1723",
                    "value": "Lisa Santos",
                    "isCheck": false
                },
                {
                    "id": "6144",
                    "value": "Louis Goletto",
                    "isCheck": false
                },
                {
                    "id": "6617",
                    "value": "Macavin Weems",
                    "isCheck": false
                },
                {
                    "id": "6227",
                    "value": "Mai Truong",
                    "isCheck": false
                },
                {
                    "id": "2206",
                    "value": "Marena Aon",
                    "isCheck": false
                },
                {
                    "id": "9909",
                    "value": "Matt Huneycutt",
                    "isCheck": false
                },
                {
                    "id": "3209",
                    "value": "Matthew Minshall",
                    "isCheck": false
                },
                {
                    "id": "4613",
                    "value": "Melissa Anderkin",
                    "isCheck": false
                },
                {
                    "id": "9788",
                    "value": "Michael Grandy",
                    "isCheck": false
                },
                {
                    "id": "6427",
                    "value": "Michael Szymczak",
                    "isCheck": false
                },
                {
                    "id": "8710",
                    "value": "Michelle House",
                    "isCheck": false
                },
                {
                    "id": "7321",
                    "value": "Mike Boyd",
                    "isCheck": false
                },
                {
                    "id": "7288",
                    "value": "Mike Proctor",
                    "isCheck": false
                },
                {
                    "id": "6893",
                    "value": "Myrna Yang",
                    "isCheck": false
                },
                {
                    "id": "9032",
                    "value": "Nakia Simms",
                    "isCheck": false
                },
                {
                    "id": "8926",
                    "value": "Nico Ayala",
                    "isCheck": false
                },
                {
                    "id": "5335",
                    "value": "Nicholas Coker",
                    "isCheck": false
                },
                {
                    "id": "7393",
                    "value": "Paul Rucker",
                    "isCheck": false
                },
                {
                    "id": "0525",
                    "value": "Perry Asghari",
                    "isCheck": false
                },
                {
                    "id": "2616",
                    "value": "Pratham Khera",
                    "isCheck": false
                },
                {
                    "id": "7075",
                    "value": "Rachel Ohara",
                    "isCheck": false
                },
                {
                    "id": "8053",
                    "value": "Ric Contreras",
                    "isCheck": false
                },
                {
                    "id": "1077",
                    "value": "Richelle Flores",
                    "isCheck": false
                },
                {
                    "id": "9987",
                    "value": "Sarah Pisciotta",
                    "isCheck": false
                },
                {
                    "id": "0998",
                    "value": "Sean Jamali",
                    "isCheck": false
                },
                {
                    "id": "0840",
                    "value": "Sean Jones",
                    "isCheck": false
                },
                {
                    "id": "6521",
                    "value": "Shane Summers",
                    "isCheck": false
                },
                {
                    "id": "7992",
                    "value": "Spencer Siegel",
                    "isCheck": false
                },
                {
                    "id": "8887",
                    "value": "Stephanie Wohlfiel",
                    "isCheck": false
                },
                {
                    "id": "4216",
                    "value": "Steve Schuster",
                    "isCheck": false
                },
                {
                    "id": "7465",
                    "value": "Tareq Vasquez",
                    "isCheck": false
                },
                {
                    "id": "1935",
                    "value": "Taylor Sandoval",
                    "isCheck": false
                },
                {
                    "id": "1447",
                    "value": "Toi Carden",
                    "isCheck": false
                },
                {
                    "id": "9268",
                    "value": "Troy Nunez",
                    "isCheck": false
                },
                {
                    "id": "7787",
                    "value": "Tom Lux",
                    "isCheck": false
                },
                {
                    "id": "3449",
                    "value": "Tommy House",
                    "isCheck": false
                },
                {
                    "id": "1446",
                    "value": "Twanda Turner",
                    "isCheck": false
                },
                {
                    "id": "6277",
                    "value": "Valerie Narmontas",
                    "isCheck": false
                },
                {
                    "id": "4280",
                    "value": "Vanessa Teo",
                    "isCheck": false
                },
                {
                    "id": "8311",
                    "value": "Vaughn Douglass",
                    "isCheck": false
                },
                {
                    "id": "2782",
                    "value": "Wes Parker",
                    "isCheck": false
                }
            ],
            "label": "Assigned OSA",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Assigned OSA",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Headings-Appointment_Details",
        "type": "Headings",
        "settings": {
            "title": "Appointment Details",
            "size": "left",
            "inputType": "text"
        },
        "name": "Appointment Details"
    },
    {
        "elementid": "Date-opp_appt_date",
        "type": "Date",
        "name": "Appointment Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Appointment Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-appointment_time",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8549",
                    "value": "07:30 AM",
                    "isCheck": false
                },
                {
                    "id": "0622",
                    "value": "07:45 AM",
                    "isCheck": false
                },
                {
                    "id": "1053",
                    "value": "08:00 AM",
                    "isCheck": false
                },
                {
                    "id": "2429",
                    "value": "08:15 AM",
                    "isCheck": false
                },
                {
                    "id": "7607",
                    "value": "08:30 AM",
                    "isCheck": false
                },
                {
                    "id": "7015",
                    "value": "08:45 AM",
                    "isCheck": false
                },
                {
                    "id": "8754",
                    "value": "09:00 AM",
                    "isCheck": false
                },
                {
                    "id": "2661",
                    "value": "09:15 AM",
                    "isCheck": false
                },
                {
                    "id": "5901",
                    "value": "09:30 AM",
                    "isCheck": false
                },
                {
                    "id": "4511",
                    "value": "09:45 AM",
                    "isCheck": false
                },
                {
                    "id": "7091",
                    "value": "10:00 AM",
                    "isCheck": false
                },
                {
                    "id": "7097",
                    "value": "10:15 AM",
                    "isCheck": false
                },
                {
                    "id": "1382",
                    "value": "10:30 AM",
                    "isCheck": false
                },
                {
                    "id": "5593",
                    "value": "10:45 AM",
                    "isCheck": false
                },
                {
                    "id": "4954",
                    "value": "11:00 AM",
                    "isCheck": false
                },
                {
                    "id": "2253",
                    "value": "11:15 AM",
                    "isCheck": false
                },
                {
                    "id": "9829",
                    "value": "11:30 AM",
                    "isCheck": false
                },
                {
                    "id": "9547",
                    "value": "11:45 AM",
                    "isCheck": false
                },
                {
                    "id": "4389",
                    "value": "12:00 PM",
                    "isCheck": false
                },
                {
                    "id": "2574",
                    "value": "12:15 PM",
                    "isCheck": false
                },
                {
                    "id": "7299",
                    "value": "12:30 PM",
                    "isCheck": false
                },
                {
                    "id": "5511",
                    "value": "12:45 PM",
                    "isCheck": false
                },
                {
                    "id": "3709",
                    "value": "01:00 PM",
                    "isCheck": false
                },
                {
                    "id": "4504",
                    "value": "01:15 PM",
                    "isCheck": false
                },
                {
                    "id": "9839",
                    "value": "01:30 PM",
                    "isCheck": false
                },
                {
                    "id": "5701",
                    "value": "01:45 PM",
                    "isCheck": false
                },
                {
                    "id": "7845",
                    "value": "02:00 PM",
                    "isCheck": false
                },
                {
                    "id": "2670",
                    "value": "02:15 PM",
                    "isCheck": false
                },
                {
                    "id": "3748",
                    "value": "02:30 PM",
                    "isCheck": false
                },
                {
                    "id": "1009",
                    "value": "02:45 PM",
                    "isCheck": false
                },
                {
                    "id": "4116",
                    "value": "03:00 PM",
                    "isCheck": false
                },
                {
                    "id": "9611",
                    "value": "03:15 PM",
                    "isCheck": false
                },
                {
                    "id": "5398",
                    "value": "03:30 PM",
                    "isCheck": false
                },
                {
                    "id": "6397",
                    "value": "03:45 PM",
                    "isCheck": false
                },
                {
                    "id": "9668",
                    "value": "04:00 PM",
                    "isCheck": false
                },
                {
                    "id": "2302",
                    "value": "04:15 PM",
                    "isCheck": false
                },
                {
                    "id": "4081",
                    "value": "04:30 PM",
                    "isCheck": false
                },
                {
                    "id": "0982",
                    "value": "04:45 PM",
                    "isCheck": false
                },
                {
                    "id": "6533",
                    "value": "05:00 PM",
                    "isCheck": false
                },
                {
                    "id": "9547",
                    "value": "05:15 PM",
                    "isCheck": false
                },
                {
                    "id": "4524",
                    "value": "05:30 PM",
                    "isCheck": false
                },
                {
                    "id": "4888",
                    "value": "05:45 PM",
                    "isCheck": false
                },
                {
                    "id": "2253",
                    "value": "06:00 PM",
                    "isCheck": false
                },
                {
                    "id": "8042",
                    "value": "06:15 PM",
                    "isCheck": false
                },
                {
                    "id": "5531",
                    "value": "06:30 PM",
                    "isCheck": false
                },
                {
                    "id": "9782",
                    "value": "06:45 PM",
                    "isCheck": false
                },
                {
                    "id": "6503",
                    "value": "07:00 PM",
                    "isCheck": false
                },
                {
                    "id": "2563",
                    "value": "07:15 PM",
                    "isCheck": false
                },
                {
                    "id": "3195",
                    "value": "07:30 PM",
                    "isCheck": false
                },
                {
                    "id": "2061",
                    "value": "07:45 PM",
                    "isCheck": false
                },
                {
                    "id": "8391",
                    "value": "08:00 PM",
                    "isCheck": false
                },
                {
                    "id": "1604",
                    "value": "08:15 PM",
                    "isCheck": false
                },
                {
                    "id": "1468",
                    "value": "08:30 PM",
                    "isCheck": false
                }
            ],
            "label": "Appointment Time",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appointment Time",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-Appt-Location",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "5484",
                    "value": "Carlsbad Office",
                    "isCheck": false
                },
                {
                    "id": "6709",
                    "value": "La Mesa Office",
                    "isCheck": false
                },
                {
                    "id": "9927",
                    "value": "North Park Office",
                    "isCheck": false
                },
                {
                    "id": "0865",
                    "value": "Temecula Office",
                    "isCheck": false
                },
                {
                    "id": "2231",
                    "value": "Other Address",
                    "isCheck": false
                },
                {
                    "id": "7205",
                    "value": "Seller Property Address",
                    "isCheck": false
                },
                {
                    "id": "0146",
                    "value": "Buyer Inquiry Address",
                    "isCheck": false
                },
                {
                    "id": "9290",
                    "value": "Zoom",
                    "isCheck": false
                },
                {
                    "id": "4834",
                    "value": "Phone",
                    "isCheck": false
                }
            ],
            "label": "Appointment Location",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appointment Location",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-zoom-link",
        "type": "InputField",
        "name": "Zoom Link",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Zoom Link",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "FreeText-Address_of_Appointment",
        "type": "FreeText",
        "name": "Address of Appointment",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address",
        "type": "InputField",
        "name": "Address of Appointment-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-Street Address",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address2",
        "type": "InputField",
        "name": "Address of Appointment-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_city",
        "type": "InputField",
        "name": "Address of Appointment-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-City",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_state",
        "type": "InputField",
        "name": "Address of Appointment-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-State",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_postal_code",
        "type": "InputField",
        "name": "Address of Appointment-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-Zip Code",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-opp_country",
        "type": "DropDown",
        "linked_to": "opp_country",
        "name": "Address of Appointment-Country",
        "settings": {
            "options": [
                {
                    "id": 25545,
                    "value": "USA",
                    "isCheck": false
                },
                {
                    "id": 2551,
                    "value": "Canada",
                    "isCheck": false
                }
            ],
            "label": "Country",
            "size": "large",
            "inputType": "dropdown",
            "required": false,
            "hidden": false,
            "minValue": "",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "TextArea-App-Description",
        "type": "TextArea",
        "name": "Appointment Description (Clients will see this)",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Appointment Description (Clients will see this)",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-Are_you_the_primary_decision_maker",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2609",
                    "value": "Yes - Primary and Only Decision Maker",
                    "isCheck": false
                },
                {
                    "id": "0688",
                    "value": "Yes - Primary but NOT Only Decision Maker",
                    "isCheck": false
                },
                {
                    "id": "7732",
                    "value": "No - Not Primary Decision Maker",
                    "isCheck": false
                }
            ],
            "label": "Are you the primary decision maker, and are there any other decision makers?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Are you the primary decision maker, and are there any other decision makers?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-secondary_contact",
        "type": "InputField",
        "name": "Secondary Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-Will_they_be_available_for_the_appointment",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1615",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "4146",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Will they be available for the appointment?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Will they be available for the appointment?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Contact-secondary_contact_phone",
        "type": "Contact",
        "name": "Secondary Contact Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-secondary_contact_email",
        "type": "Email",
        "name": "Secondary Contact Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Headings-Seller_Section",
        "type": "Headings",
        "settings": {
            "title": "Seller Section",
            "size": "left",
            "inputType": "text"
        },
        "name": "Seller Section"
    },
    {
        "elementid": "NumberField-do_you_know_what_you_owe_on_your_home?",
        "type": "NumberField",
        "name": "What do they owe on their home?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "What do they owe on their home?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-home_value_low",
        "type": "NumberField",
        "name": "Home Value Low",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Home Value Low",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "How much are you hoping to sell your home for? What price range do you think it's in? Just give me your best guess."
        }
    },
    {
        "elementid": "NumberField-home_value_high",
        "type": "NumberField",
        "name": "Home Value High",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Home Value High",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "How much are you hoping to sell your home for? What price range do you think it's in? Just give me your best guess."
        }
    },
    {
        "elementid": "DropDown-would_they_prefer_to_buy_before_listing_their_home__or_do_th",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "5585",
                    "value": "Buy First",
                    "isCheck": false
                },
                {
                    "id": "3675",
                    "value": "Sell First",
                    "isCheck": false
                }
            ],
            "label": "Would they prefer to buy before listing their home, or do they want to sell first?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Would they prefer to buy before listing their home, or do they want to sell first?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-if_they_were_to_move__would_they_be_staying_in_the_area_or_m",
        "type": "InputField",
        "name": "If they were to move, would they be staying in the area or moving out of the area?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "If they were to move, would they be staying in the area or moving out of the area?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "FreeText-the_address_of_the_home_you_are_selling_is_",
        "type": "FreeText",
        "name": "The address of the home they are selling is:",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "The address of the home they are selling is:",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-the_address_of_the_home_you_are_selling_is__street_address",
        "type": "InputField",
        "name": "The address of the home they are selling is:-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "The address of the home they are selling is:-Street Address",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-the_address_of_the_home_you_are_selling_is__address_line_2",
        "type": "InputField",
        "name": "The address of the home they are selling is:-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "The address of the home they are selling is:-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-the_address_of_the_home_you_are_selling_is__city",
        "type": "InputField",
        "name": "The address of the home they are selling is:-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "The address of the home they are selling is:-City",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-the_address_of_the_home_you_are_selling_is__state",
        "type": "InputField",
        "name": "The address of the home they are selling is:-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "The address of the home they are selling is:-State",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-the_address_of_the_home_you_are_selling_is__zip_code",
        "type": "InputField",
        "name": "The address of the home they are selling is:-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "The address of the home they are selling is:-Zip Code",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-the_address_of_the_home_you_are_selling_is__country",
        "type": "DropDown",
        "name": "The address of the home they are selling is:-Country",
        "field_sub_type": "opportunity",
        "settings": {
            "options": [
                {
                    "id": 25545,
                    "value": "USA",
                    "isCheck": false
                },
                {
                    "id": 2551,
                    "value": "Canada",
                    "isCheck": false
                }
            ],
            "label": "The address of the home they are selling is:-Country",
            "size": "large",
            "inputType": "dropdown",
            "required": false,
            "hidden": false,
            "minValue": "",
            "maxValue": "",
            "instructions": ""
        }

        
    },
    {
        "elementid": "InputField-what_are_the_most_important_things_to_you_in_the_sale_of_you",
        "type": "InputField",
        "name": "What are the most important things to you in the sale of your home?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "What are the most important things to you in the sale of your home?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-what_have_you_done_to_your_home__if_anything__since_you_boug",
        "type": "InputField",
        "name": "What have you done to your home, if anything, since you bought it?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "What have you done to your home, if anything, since you bought it?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-house_details_",
        "type": "InputField",
        "name": "House Details:",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "House Details:",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Headings-Additional_Information",
        "type": "Headings",
        "settings": {
            "title": "Additional Information",
            "size": "left",
            "inputType": "text"
        },
        "name": "Additional Information"
    },
    {
        "elementid": "InputField-bio_",
        "type": "InputField",
        "name": "Bio:",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Bio:",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-what_is_the_clients_timeline?",
        "type": "InputField",
        "name": "What is the clients timeline?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "What is the clients timeline?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-what_s_the_clients_motivation?",
        "type": "InputField",
        "name": "What's the clients Motivation?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "What's the clients Motivation?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-finances_",
        "type": "InputField",
        "name": "Finances:",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Finances:",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "CheckBox-D_I_S_C-Profile",
        "type": "CheckBox",
        "settings": {
            "options": [
                {
                    "id": "5203",
                    "value": "(D)ominant",
                    "isCheck": false
                },
                {
                    "id": "8398",
                    "value": "(I)nfluencer",
                    "isCheck": false
                },
                {
                    "id": "6345",
                    "value": "(S)upportive",
                    "isCheck": false
                },
                {
                    "id": "5450",
                    "value": "(C)onscientiousness",
                    "isCheck": false
                }
            ],
            "label": "D.I.S.C. Profile",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "D.I.S.C. Profile",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "TextArea-Notes",
        "type": "TextArea",
        "name": "Notes",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Notes",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp-key",
        "type": "InputField",
        "name": "Opp Key",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opp Key",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please Do not Touch this field"
        }
    },
    {
        "elementid": "InputField-Follow-up-boss-lead-id",
        "type": "InputField",
        "name": "Follow Up Boss Lead ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Follow Up Boss Lead ID",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    }
]

export const whisselTeamAppointmentFormSellerConditions=[
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Other Address",
                    "operator": "equals",
                    "field_id": "DropDown-Appt-Location"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "FreeText-Address_of_Appointment",
            "InputField-opp_address",
            "InputField-opp_address2",
            "InputField-opp_city",
            "InputField-opp_state",
            "InputField-opp_postal_code",
            "DropDown-opp_country"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes - Primary but NOT Only Decision Maker",
                    "operator": "equals",
                    "field_id": "DropDown-Are_you_the_primary_decision_maker"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "No - Not Primary Decision Maker",
                    "operator": "equals",
                    "field_id": "DropDown-Are_you_the_primary_decision_maker"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-secondary_contact",
            "DropDown-Will_they_be_available_for_the_appointment",
            "Contact-secondary_contact_phone",
            "Email-secondary_contact_email"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "ISA",
                    "operator": "equals",
                    "field_id": "DropDown-Appointment-Set-By"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-opp_isa"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Zoom",
                    "operator": "equals",
                    "field_id": "DropDown-Appt-Location"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-zoom-link"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "ISA",
                    "operator": "equals",
                    "field_id": "DropDown-Appointment-Set-By"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-Are_you_the_primary_decision_maker"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "ISA",
                    "operator": "equals",
                    "field_id": "DropDown-Appointment-Set-By"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-Are_you_the_primary_decision_maker",
            "InputField-bio_",
            "InputField-finances_",
            "InputField-what_s_the_clients_motivation?",
            "InputField-house_details_",
            "InputField-what_is_the_clients_timeline?",
            "NumberField-home_value_low",
            "NumberField-home_value_high",
            "NumberField-do_you_know_what_you_owe_on_your_home?",
            "InputField-if_they_were_to_move__would_they_be_staying_in_the_area_or_m",
            "DropDown-would_they_prefer_to_buy_before_listing_their_home__or_do_th",
            "InputField-what_are_the_most_important_things_to_you_in_the_sale_of_you",
            "InputField-what_have_you_done_to_your_home__if_anything__since_you_boug"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "OSA",
                    "operator": "equals",
                    "field_id": "DropDown-Appointment-Set-By"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-opp_assigned_osa"
        ],
        "ConditionName": "Condition Name"
    }
]