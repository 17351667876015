import { useContext, useState } from "react";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { SideElements } from "./BoardElementsSettingsUi/SidebarElement";
import { ElementContext } from "../../../pages/form-builder/dashboard/SettingContext";


const ElementsSettingsModal = ({ elementid, type ,IsaList,osaList,AdminList}) => {
  const maxLengthofLabel = 100;
  const { board } = useContext(boardConetxt);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { setSetElement } = useContext(ElementContext);
  const [showSettingModal, setShowsettingModal] = useState(true);

  // getting UI from SideElements for rendering
  const FindMatchElement = SideElements.find((e) => e.id === type);

  // this is the UI component that will be rendered
  const UI = FindMatchElement.ui;

  const showingModalStyle = showSettingModal ? { display: "block" } : { display: "none" };

  const handleModalOutsideClick = (e) => {
    if (e.target.id === "option-setting2") {
      setShowsettingModal(!showSettingModal);
      setSetElement({});
    }
  };
  const handleCloseModalClick = (e) => {
    e.stopPropagation();
    setShowsettingModal(!showSettingModal);
    setSetElement({});
  };



  const elementSettingsData = board?.find(
    (item) => item?.elementid === elementid
  )?.settings;

  return (
    <>
      <div
        className="modal fade show"
        onClick={handleModalOutsideClick}
        id="option-setting2"
        style={showingModalStyle}
      >
        <div
          style={{ float: "right", width: "100%" }}
          className="modal-dialog modal-lg modal-fullscreen-sm-down modal-dialog-scrollable h-100 mt-0 me-0 mb-0"
        >
          <div className="modal-content h-100">
            <div className="modal-header bg-color-1">
              <h5 className="modal-title fs-6" id="staticBackdropLabel">
                Customise Element
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                className="btn-close"
                onClick={(e) => {
                  handleCloseModalClick(e);
                }}
              />
            </div>
            {/* Rendring UI */}
            <UI
              elementid={elementid}
              elementSettingsData={elementSettingsData}
              board={board}
              handleCloseModalClick={handleCloseModalClick}
              showErrorMessage={showErrorMessage}
              setShowErrorMessage={setShowErrorMessage}
              maxLengthofLabel={maxLengthofLabel}
              IsaList={IsaList}
              osaList={osaList}
              AdminList={AdminList}
            />
          </div>
        </div>
      </div>

      {showSettingModal && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

// ElementsSettingsModal.propTypes = {
//   elementid: PropTypes.string.isRequired,
//   type: PropTypes.string.isRequired,
//   settings: PropTypes.object.isRequired,
// };

export default ElementsSettingsModal;
