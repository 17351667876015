import { useState, useContext, useEffect } from "react";

import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { Controller, useFormContext } from "react-hook-form";
export const StarRatingUi = ({
  elementid,
  showHide,
  previewFlag = false,
  oppFields,
}) => {
  const { board } = useContext(boardConetxt);
  const ElementSetting = board.find(
    (item) => item?.elementid === elementid
  ).settings;
  const {
    label,

    instructions,

    required,
    hidden,
  } = ElementSetting;
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };
  const disableElementForBuild = window.location.pathname.includes("build");

  const {
    formState: { errors },
    setValue,
    control,
  } = useFormContext();

  useEffect(() => {
    if (oppFields) {
      setValue(elementid, oppFields);
      setRating(oppFields);
    }
  }, [oppFields, elementid, setValue]);

  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const ElementName = board.find((item) => item?.elementid === elementid).name;
  const checkingValidation = () => {
    if (required === true && showHide === "show") {
      return false;
    } else if (required === false && showHide === "show") {
      return false;
    } else if (required === true && showHide === "hide") {
      return false;
    } else if (required === false && showHide === "hide") {
      return false;
    } else if (required === true && hidden === true) {
      return false;
    } else if (required === true) {
      return true;
    } else {
      return false;
    }
  };
  const normalizeFieldName = (name) => {
    return name.replace(/[.\s]/g, '_'); // Replace dots and spaces with underscores
  };
  return (
    <div
      className={(previewFlag && GetStyleClasses(elementid)) || ""}
      id={elementid}
      style={initStyle}
      hidden={hidden && previewFlag}
    >
      <label>
        <span className="d-flex mb-1 align-items-center">
          <span>
            {label == undefined || label == "" ? ElementName : label}

            {required ? <span style={{ color: "red" }}>*</span> : ""}
          </span>
        </span>
      </label>
      <div className="form-floating w-100 form-group">
        <Controller
          name={normalizeFieldName(elementid)}
          control={control}
          value={rating}
          render={({ field }) => (
            <div className="star-rating">
              {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                  <button
                    type="button"
                    key={index}
                    disabled={disableElementForBuild}
                    className={index <= (hover || rating) ? "on" : "off"}
                    onClick={(e) => {
                      field.onChange(index);
                      setRating(index);
                    }}
                    onMouseEnter={() => setHover(index)}
                    onMouseLeave={() => setHover(rating)}
                    {...field}
                  >
                    <span className="star fs-4">&#9733;</span>
                  </button>
                );
              })}
            </div>
          )}
        />
      </div>

      <small className="text-secondary">{instructions}</small>
      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>
    </div>
  );
};
