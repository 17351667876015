import React from "react";
import CustomSelectField from "../../components/fields/CustomSelectField";
import SingleselecCustom from "../../components/fields/SingleselecCustom";

const DefaultFielsdMappers = {
  firstName: [{ key: "firstName", value: "firstName", keyShow: "First Name" }],
  lastName: [{ key: "lastName", value: "lastName", keyShow: "Last Name" }],
  emails: [{ key: "emails", value: "emails", keyShow: "Emails" }],
  phones: [{ key: "phones", value: "phones", keyShow: "Phones" }],
  assignedTo: [{ key: "assignedTo", value: "assignedTo", keyShow: "Assigned To" }],
  assignedUserId: [{ key: "assignedUserId", value: "assignedUserId", keyShow: "Assigned User Id" }],
  assignedPondId: [{ key: "assignedPondId", value: "assignedPondId", keyShow: "Assigned Pond Id" }],
  assignedLenderName: [{ key: "assignedLenderName", value: "assignedLenderName", keyShow: "Assigned Lender Name" }],
  assignedLenderId: [{ key: "assignedLenderId", value: "assignedLenderId", keyShow: "Assigned Lender Id" }],
};

const FubPersonDefaultFields = ({
  data_mapper,
  action_id,
  select_modal,
  selected_modal_id,
  field,
  customFieldsMapper,
  setCustomFieldsMapper,
  defaultFields
}) => {

  const fieldOptions=(field)=>{
    return DefaultFielsdMappers[field];
  }


  const default_fields = customFieldsMapper.map((value, index) => {
    if (value.key !== "emails" && value.key !== "phones" && defaultFields.includes(value.key)) {
      return (
        <fieldset
          key={value.uuid}
          className="border rounded-2 px-2 mb-3 bg-color-1"
        >
          <div className="row p-0 m-0">
            <div className="col-12 p-0 m-0 mt-3 row position-relative">
              <div className="col-6">
                <CustomSelectField
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id=""
                  field_key={"key"}
                  field={field["defaultKey"]}
                  field_options={fieldOptions(value.key)}
                  text_value={customFieldsMapper[index]["key"]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                  data_mapper={data_mapper}
                />
              </div>
              <div className="col-6">
                <SingleselecCustom
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id={selected_modal_id}
                  field_key={"value"}
                  field={field["defaultValue"]}
                  field_options={[]}
                  text_value={customFieldsMapper[index]["value"]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                />
              </div>
            </div>
          </div>
        </fieldset>
      );
    } else if (value.key === "emails" || value.key === "phones") {
      return (
        <fieldset
          key={value.uuid}
          className="border rounded-2 px-2 mb-3 bg-color-1"
        >
          <div className="row p-0 m-0">
            <div className="col-12 p-0 m-0 mt-3 row position-relative">
              <div className="col-6">
                <CustomSelectField
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id=""
                  field_key={"key"}
                  field={field["defaultKey"]}
                  field_options={fieldOptions(value.key)}
                  text_value={customFieldsMapper[index]["key"]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                  data_mapper={data_mapper}
                />
              </div>
              <div className="col-6">
                <SingleselecCustom
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id={selected_modal_id}
                  field_key={"value"}
                  field={field["defaultValue"]}
                  field_options={[]}
                  text_value={customFieldsMapper[index]["value"][0]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key][0] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                />
              </div>
            </div>
          </div>
        </fieldset>
      );
    }
  });

  return <>{default_fields}</>;
};
export default FubPersonDefaultFields;
