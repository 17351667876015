import { createContext, useState } from "react";

export const boardConetxt = createContext(null);

export const ElemContext = createContext(null);

export const conditionContext = createContext(null);

export const FormPlanContext = createContext(null);

export const elementVisiblity = createContext(null);

export const LeaderboardContext = createContext(null);

export const BordContextProvider = ({ children }) => {
    const [board] = useState([]);
    return <boardConetxt.Provider value={board}>{children}</boardConetxt.Provider>;

};