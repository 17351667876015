import Elements from "../../../components/PreviewElem";



export const BoardElemForPre = ({ element, oppData, showHide, setElementVisiblity, previewFlag, OppFieldName,IsaList,osaList,AdminList }) => {

  

  console.log("element>>", oppData,OppFieldName)
  


  // console.log("currentOptions",osaList)
  // console.log("NumberFieldUi",oppFields)
  const elem = Elements.find((e) => e.id === element.type);

  let UI=elem?.ui;
 







  return (
    <UI
      elementid={element.elementid}
      element={element}
      oppFields={oppData}
      OppFieldName={OppFieldName}
      showHide={showHide}
      setElementVisiblity={setElementVisiblity}
      previewFlag={previewFlag}
      IsaList={IsaList}
      osaList={osaList}
      AdminList={AdminList}
    />
  );
};
