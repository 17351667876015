import React, { useState } from "react";
import interfaceLogo from "../../assets/images/interface-circle.png";
import fublogo from "../../assets/images/fub-circle.png";
import { Modal, Button } from "react-bootstrap";
import { gql, useMutation } from "@apollo/client";
import FieldConnections from "./FieldConnections";

const DeleteFieldMapping = gql`
  mutation DeleteFieldMapping($fieldMappingId: String) {
    DeleteFieldMapping(field_mapping_id: $fieldMappingId) {
      team_id
    }
  }
`;

const SingleMapping = ({
  field_mapping,
  index,
  refetch,
  dealcustomfields,
  customfields,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteWorkflowId, setDeleteWorkflowId] = useState("");
  const [showFieldConnections, setShowFieldConnections] = useState(false);

  const [deleteFieldMapping] = useMutation(DeleteFieldMapping, {
    onCompleted: () => {
      refetch();
    },
  });

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleShowModal = (id) => {
    setShowDeleteModal(true);
    setDeleteWorkflowId(id);
  };

  const handleDelete = () => {
    deleteFieldMapping({
      variables: {
        fieldMappingId: deleteWorkflowId,
      },
    });
    handleCloseModal();
  };

  const getFieldLabel = (fieldId, fields, type) => {
    if (type === "fub_field") {
      const field = fields?.find((f) => f.name === fieldId);
      return field ? field.label : fieldId;
    } else {
      const field = fields?.find((f) => f.opportunity_field_id === fieldId);
      return field ? field.field_name : fieldId;
    }
  };

  const getLabelForFieldMapping = (fieldId) => {
    const { platform_resource_type, platform_field_id } =
      field_mapping;
    if (fieldId === platform_field_id) {
      if (platform_resource_type === "deal") {
        return getFieldLabel(platform_field_id, dealcustomfields, "fub_field");
      } else if (platform_resource_type === "people") {
        return getFieldLabel(platform_field_id, customfields, "fub_field");
      } else {
        return platform_field_id;
      }
    }
  };

  return (
    <>
      <div className="d-flex flex-wrap border rounded-3 bg-white fm-list-container mb-3">
        <div className="d-flex flex-wrap col-xxl-3 col-lg-4 align-items-center justify-content-start pb-2 pt-4 py-md-4 px-4">
          <p className="d-flex align-items-center gap-2 fs-6 p-0 m-0">
            <img src={interfaceLogo} alt="" />
              {field_mapping?.field_name}
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-center p-4 fs-4 col">
          <i
            className="iconify fs-1 text-warning"
            data-icon={`${
              field_mapping?.sync_direction === "TWO_WAY"
                ? "fluent:arrow-swap-20-regular"
                : field_mapping?.sync_direction === "TO_INTERFACE"
                ? "fluent:arrow-left-20-regular"
                : "fluent:arrow-right-20-regular"
            }`}
          />
        </div>
        <div className="d-flex flex-wrap col-xxl-5 col-lg-4 align-items-center justify-content-start pb-2 pt-4 py-md-4 px-4">
          <p className="d-flex align-items-center gap-2 fs-6 p-0 m-0">
            <img src={fublogo} alt="" />
            {field_mapping?.platform_resource_type?.charAt(0).toUpperCase() +
              field_mapping?.platform_resource_type?.slice(1)}{" "}
            - {getLabelForFieldMapping(field_mapping?.platform_field_id)}
          </p>
        </div>
        <div
          className="d-flex align-items-center justify-content-between p-4 fs-4 gap-1 col col-md-2 border-0 border-start border"
          style={{ color: "#696868", fontSize: "16px !important" }}
        >
          <div onClick={() => setShowFieldConnections(true)}>
          <i
            className="iconify pointer"
            data-bs-toggle="modal"
            data-bs-target={"#view-connection" + index}
            data-icon="fa:eye"
            data-title="View Connection"
          />
          </div>
          <i
            className="iconify pointer"
            data-icon="fa:cogs"
            data-bs-toggle="modal"
            data-bs-target={"#new-sync" + index}
            data-title="Edit Mapping"
          />
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleShowModal(field_mapping?.id);
            }}
          >
            <i className="iconify pointer" data-icon="fa:trash" data-title="Delete Mapping" />
          </div>
        </div>
      </div>
      
      {/* Field connections modal */}
      {showFieldConnections && <FieldConnections field_id ={getLabelForFieldMapping(field_mapping?.interface_field_id)} index={index} interface_field_id={field_mapping?.interface_field_id} />}
      
      {/* Modal for confirmation */}
      <Modal show={showDeleteModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you really want to delete this mapping? This process cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SingleMapping;
