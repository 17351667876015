import React,{ useState }  from 'react'
import imgage1 from '../../../assets/images/medal1.png'
import imgage2 from '../../../assets/images/medal2.png'
import imgage3 from '../../../assets/images/medal3.png'
import '../../../assets/css/leaderboard-export.css'
import { LeaderboardContext } from './ContextHelper'
import html2canvas from 'html2canvas';



export const NewLeaderBoard = () => {
  const [theme, setTheme] = useState('light');
  const { leaderboard } = React.useContext(LeaderboardContext)
  // Sample start date and end date
const startDates = leaderboard["Start Date"];
const endDates = leaderboard["End Date"];


// Convert start date and end date to JavaScript Date objects
const startDateObj = new Date(startDates?.replace(/-/g, '/'));
const endDateObj = new Date(endDates?.replace(/-/g, '/'));

// Filter the array of objects based on the date range
const filteredArray = leaderboard?.opplist?.filter(obj => {
  if (obj?.opp_appt_date) {
      const objDate = new Date(obj.opp_appt_date.slice(0, 4) + '/' + obj.opp_appt_date.slice(4, 6) + '/' + obj.opp_appt_date.slice(6));
      return objDate >= startDateObj && objDate <= endDateObj;
  }
  // If opp_appt_date is empty or undefined, filter it out
  return false;
});

const downloadAsImage = () => {
  const input = document.getElementById('dashboard');
  html2canvas(input)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'dashboard.jpg';
      link.click();
    });
};


console.log("opp>", filteredArray);


  // Sample start date and end date
// const startDates = leaderboard["Start Date"];
// const endDates = leaderboard["End Date"];

// // Array of objects with dates in "yyyyMMdd" format
// const arrayOfObjects = [
//     { date: "20230520", data: "some data" },
//     { date: "20240525", data: "some data" },
//     { date: "20240430", data: "some data" },
//     { date: "20230605", data: "some data" }
//     // Add more objects as needed
// ];

// // Split the dates and reorder them to YYYY-MM-DD format
// const startDateParts = startDates.split('-').reverse().join('-');
// const endDateParts = endDates.split('-').reverse().join('-');

// // Convert start date and end date to JavaScript Date objects
// const startDateObj = new Date(startDateParts);
// const endDateObj = new Date(endDateParts);

// const filteredArray = leaderboard?.opplist?.filter(obj => {
//   // Check if obj.opp_appt_date is not null and not undefined
//   if (obj?.opp_appt_date) {
//       const objDate = new Date(`${obj.opp_appt_date.slice(0, 4)}-${obj.opp_appt_date.slice(4, 6)}-${obj.opp_appt_date.slice(6)}`);
//       return objDate >= startDateObj && objDate <= endDateObj;
//   }
//   // If obj.opp_appt_date is null or undefined, filter it out
//   return false;
// });

// console.log("Filyer",filteredArray,startDateObj,endDateObj,leaderboard?.opplist,leaderboard["Start Date"],leaderboard["End Date"],arrayOfObjects,startDateObj,endDateObj);





  const [toggle, setToggle] = React.useState(null)
  const handlemode = () => {


  }
  const startDate = new Date(leaderboard["Start Date"]);
  const endDate = new Date(leaderboard["End Date"]);

  const startDay = startDate?.getDate();
  const startMonth = startDate?.toLocaleString('default', { month: 'long' });
  const startYear = startDate?.getFullYear();

  const endDay = endDate?.getDate();
  const endMonth = endDate?.toLocaleString('default', { month: 'long' });
  const endYear = endDate?.getFullYear();
  console.log("Leaderboard", leaderboard)



  const temp = [
    {
      "opp_assigned_osa": "Daniel Omolola",
      "opp_price": "$345,000"

    },
    {
      "opp_assigned_osa": "Daniel Omolola",
      "opp_price": "$289,000"
    },
    {
      "opp_assigned_osa": "Daniel Omolola",
      "opp_price": "$105,700"
    },
    {
      "opp_assigned_osa": "Varad More",
      "opp_price": "$105,700"
    },
    {
      "opp_assigned_osa": "Varad More",
      "opp_price": "$105,700"
    },
    {
      "opp_assigned_osa": "Harshad Chavan",
      "opp_price": "$105,700"
    },
    {
      "opp_assigned_osa": "Daniel Omolola",
      "opp_price": "$105,700"
    },
    {
      "opp_assigned_osa": "Daniel Omolola",
      "opp_price": "$105,700"
    },
    {
      "opp_assigned_osa": "Daniel Omolola",
      "opp_price": "$105,700"
    },
    {
      "opp_assigned_osa": "Daniel Omolola",
      "opp_price": "$105,700"
    },
    {
      "opp_assigned_osa": "Daniel Omolola",
      "opp_price": "$105,700"
    },
    {
      "opp_assigned_osa": "Daniel Omolola",
      "opp_price": "$105,700"
    }
  ];

  // const combinedPrices = {};

  // leaderboard?.opplist?.forEach(item => {
  //     if (combinedPrices[item.opp_assigned_osa]) {
  //         combinedPrices[item.opp_assigned_osa] += parseFloat(item.opp_price.replace('$', '').replace(',', ''));
  //     } else {
  //         combinedPrices[item.opp_assigned_osa] = parseFloat(item.opp_price.replace('$', '').replace(',', ''));
  //     }
  // });

  // const combinedPricesArray = Object.keys(combinedPrices).map(key => ({ name: key, price: combinedPrices[key] }));

  // // Sort the array in descending order of prices
  // combinedPricesArray.sort((a, b) => b.price - a.price);

  // // Format prices with dollar sign
  // combinedPricesArray.forEach(item => {
  //     item.price = `$${item.price.toLocaleString()}`;
  // });

  // console.log(">>",combinedPricesArray);

  const showLeaderBoard = () => {

    if (leaderboard["Selected Parameter"] === "Agents" && leaderboard["Selected Option"] === "addCol") {
      console.log(">>", leaderboard?.opplist)
      if (leaderboard["AddcolParameter"] == "Price") {
        const combinedPrices = {};

        filteredArray?.forEach(item => {
          const price = typeof item.opp_price === 'string' ? parseFloat(item.opp_price.replace('$', '').replace(',', '')) : parseFloat(item.opp_price);
          if (!isNaN(price)) {
            if (combinedPrices[item.opp_assigned_osa]) {
              combinedPrices[item.opp_assigned_osa] += price;
            } else {
              combinedPrices[item.opp_assigned_osa] = price;
            }
          }
        });

        const combinedPricesArray = Object.keys(combinedPrices).map(key => ({ name: key, price: combinedPrices[key] }));

        // Sort the array in descending order of prices
        const totalPrice = combinedPricesArray.reduce((acc, curr) => acc + curr.price, 0);

        combinedPricesArray.sort((a, b) => b.price - a.price);

        // Format prices with dollar sign
        combinedPricesArray.forEach(item => {
          item.price = `$${item.price.toLocaleString()}`;
        });
        return (
          <>
          <section className="ld-stand-cont flex-wrap flex-lg-nowrap w-100 mt-5">
          <div className="ld-stand">
            <img src={imgage1} width="60px" />
            <h5 className="m-0 p-0">{combinedPricesArray[0]?.price }</h5>
            <p className="m-0 p-0">{combinedPricesArray[0]?.name || "Demo"}</p>
          </div>
          <div className="ld-stand">
            <img src={imgage2} width="60px" />
            <h5 className="m-0 p-0">{combinedPricesArray[1]?.price }</h5>
            <p className="m-0 p-0">{combinedPricesArray[1]?.name}</p>
          </div>
          <div className="ld-stand">
            <img src={imgage3} width="60px" />
            <h5 className="m-0 p-0">{combinedPricesArray[2]?.price }</h5>
            <p className="m-0 p-0">{combinedPricesArray[2]?.name}</p>
          </div>
        </section>
          <section className="mt-5 w-100 ">
            <div className="d-flex flex-column align-items-start rounded-3">
              <div className="ld-th-title">
                <span className="m-0 p-0">Closed Price</span>
                <span className="m-0 p-0">Total: ${totalPrice.toLocaleString()}</span>
              </div>
              <div className="ld-tr d-flex w-100 align-items-start align-self-stretch ">
                <div className="ld-td-1 ld-th col-1">
                  <span>Rank</span>
                </div>
                <div className="ld-td-2 ld-th">
                  <span>Agent</span>
                </div>
                <div className="ld-td-3 ld-th col-4">
                  <span>Price</span>
                </div>
              </div>
              {
                combinedPricesArray.map((item, index) => {
                  return (
                    <div className="ld-tr d-flex w-100 align-items-start align-self-stretch ">
                      <div className="ld-td-1 col-1">
                        <span>{index + 1}</span>
                      </div>
                      <div className="ld-td-2">
                        <span>{item.name}</span>
                      </div>
                      <div className="ld-td-3 col-4">
                        <span>{item.price}</span>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </section>
          </>

        )
     
      } else if (leaderboard["AddcolParameter"] == "Commission Percent") {
        const combinedPrices = {};

        filteredArray?.forEach(item => {
          const commissionPercent = typeof item.opp_commission_percent === 'string' ? parseFloat(item.opp_commission_percent.replace('%', '')) : parseFloat(item.opp_commission_percent);
          if (!isNaN(commissionPercent)) {
            if (combinedPrices[item.opp_assigned_osa]) {
              combinedPrices[item.opp_assigned_osa] += commissionPercent;
            } else {
              combinedPrices[item.opp_assigned_osa] = commissionPercent;
            }
          }
        });
        

        const combinedPricesArray = Object.keys(combinedPrices).map(key => ({ name: key, price: combinedPrices[key] }));

        const totalCommissionPercent = combinedPricesArray.reduce((acc, curr) => acc + curr.price, 0);

        // Sort the array in descending order of prices
        combinedPricesArray.sort((a, b) => b.price - a.price);

        // Format prices with dollar sign
        combinedPricesArray.forEach(item => {
          item.price = `${item?.price}%`;
        });
        return (
          <>
           <section className="ld-stand-cont flex-wrap flex-lg-nowrap w-100 mt-5">
          <div className="ld-stand">
            <img src={imgage1} width="60px" />
            <h5 className="m-0 p-0">{combinedPricesArray[0]?.price }</h5>
            <p className="m-0 p-0">{combinedPricesArray[0]?.name }</p>
          </div>
          <div className="ld-stand">
            <img src={imgage2} width="60px" />
            <h5 className="m-0 p-0">{combinedPricesArray[1]?.price }</h5>
            <p className="m-0 p-0">{combinedPricesArray[1]?.name}</p>
          </div>
          <div className="ld-stand">
            <img src={imgage3} width="60px" />
            <h5 className="m-0 p-0">{combinedPricesArray[2]?.price }</h5>
            <p className="m-0 p-0">{combinedPricesArray[2]?.name}</p>
          </div>
        </section>
          <section className="mt-5 w-100 ">
            <div className="d-flex flex-column align-items-start rounded-3">
              <div className="ld-th-title">
                <span className="m-0 p-0">Commission Percent</span>
                <span className="m-0 p-0">Total: {totalCommissionPercent}%</span>
              </div>
              <div className="ld-tr d-flex w-100 align-items-start align-self-stretch ">
                <div className="ld-td-1 ld-th col-1">
                  <span>Rank</span>
                </div>
                <div className="ld-td-2 ld-th">
                  <span>Agent</span>
                </div>
                <div className="ld-td-3 ld-th col-4">
                  <span>Commission Percent</span>
                </div>
              </div>
              {
                combinedPricesArray.map((item, index) => {
                  return (
                    <div className="ld-tr d-flex w-100 align-items-start align-self-stretch ">
                      <div className="ld-td-1 col-1">
                        <span>{index + 1}</span>
                      </div>
                      <div className="ld-td-2">
                        <span>{item.name}</span>
                      </div>
                      <div className="ld-td-3 col-4">
                        <span>{item.price}</span>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </section>
          </>

        )
      }


    } else if (leaderboard["Selected Parameter"] === "Agents" && leaderboard["Selected Option"] === "countRow") {
      console.log(">>", leaderboard?.opplist)
      const countNames = {};

      filteredArray?.forEach(item => {
        const name = item.opp_assigned_osa;
        const count = countNames[name] || 0;
        countNames[name] = count + 1;
      });

      // Convert the object into an array of objects
      const countNamesArray = Object.keys(countNames).map(name => ({
        name: name,
        count: countNames[name]
      }));

      // Sort the array in descending order of counts
      countNamesArray.sort((a, b) => b.count - a.count);

      return (
        <>
          <section className="ld-stand-cont flex-wrap flex-lg-nowrap w-100 mt-5">
          <div className="ld-stand">
            <img src={imgage1} width="60px" />
            <h5 className="m-0 p-0">{countNamesArray[0]?.count }</h5>
            <p className="m-0 p-0">{countNamesArray[0]?.name}</p>
          </div>
          <div className="ld-stand">
            <img src={imgage2} width="60px" />
            <h5 className="m-0 p-0">{countNamesArray[1]?.count }</h5>
            <p className="m-0 p-0">{countNamesArray[1]?.name}</p>
          </div>
          <div className="ld-stand">
            <img src={imgage3} width="60px" />
            <h5 className="m-0 p-0">{countNamesArray[2]?.count }</h5>
            <p className="m-0 p-0">{countNamesArray[2]?.name}</p>
          </div>
        </section>

          <section className="mt-5 w-100 ">
            <div className="d-flex flex-column align-items-start rounded-3">
              <div className="ld-th-title">
                <span className="m-0 p-0">Count</span>
                <span className="m-0 p-0">Total: {leaderboard?.opplist?.length}</span>
              </div>
              <div className="ld-tr d-flex w-100 align-items-start align-self-stretch ">
                <div className="ld-td-1 ld-th col-1">
                  <span>Rank</span>
                </div>
                <div className="ld-td-2 ld-th">
                  <span>Agent</span>
                </div>
                <div className="ld-td-3 ld-th col-4">
                  <span>Count</span>
                </div>
              </div>
              {
                countNamesArray?.map((item, index) => {
                  return (
                    <div className="ld-tr d-flex w-100 align-items-start align-self-stretch ">
                      <div className="ld-td-1 col-1">
                        <span>{index + 1}</span>
                      </div>
                      <div className="ld-td-2">
                        <span>{item?.name}</span>
                      </div>
                      <div className="ld-td-3 col-4">
                        <span>{item?.count}</span>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </section>
        </>
      )

    }



  }















  return (
    <>
      <div className={`ld-header container ${toggle}`}>
        <h3>Leadway Real estate NY</h3>
        <div className="ld-nav-right">
        <button onClick={downloadAsImage} style={{ background: 'none', border: 'none', padding: 0 }}>
            <i className="iconify" data-icon="fa6-solid:download" style={{ fontSize: '24px', cursor: 'pointer' }} />
          </button> <div className="vr border" style={{ height: 28 }} />
          <span onClick={() => { handlemode() }}>
            <i id="theme-toggle" className="iconify" data-icon="fa6-solid:moon" />
          </span>
        </div>
      </div>
      <section className="container py-5 "  id="dashboard">
        <div className="d-flex align-items-center gap-2">
          <i className="iconify" data-icon="fa6-solid:ranking-star" />
          <p className="m-0 p-0">
            {/* {`Leaderboards generated for ${leaderboard["Start Date"]} to ${leaderboard["End Date"]}`} */}
            {`Leaderboards generated for ${startDay} ${startMonth} ${startYear} to ${endDay} ${endMonth} ${endYear}`}

          </p>
        </div>
     
        <section className="mt-5 w-100 ">
  
            {
              showLeaderBoard()
            }
       

        </section>
      </section>
   
    </>

  )
}