import React, { useContext, useEffect } from "react";

import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import {
  boardConetxt,



} from "../../../pages/form-builder/dashboard/ContextHelper";
import { Controller, useFormContext } from "react-hook-form";



export function EmailUi({
  elementid,

  showHide,
  oppFields,
  previewFlag = false,
}) {

  const { board } = useContext(boardConetxt);
  const { formState: { errors }, control, setValue } = useFormContext({
    mode: "onFocus",
    reValidateMode: "onChange",
  });
  const disableElementForBuild = window.location.pathname.includes("build")

  // useEffect(() => {
  //   if (oppFields !== undefined && oppFields !== null) {
  //     setValue(elementid, oppFields.toString()); // Assuming oppFields is a string or can be converted to string
  //   }
  // }, [oppFields, setValue, elementid]);
console.log("oppFields",oppFields,elementid)

useEffect(() => {
  console.log("Setting value:", elementid, oppFields);
  if (oppFields) {
    setValue(elementid, oppFields);
  }
}, [oppFields, elementid, setValue]);


  const ElementSettingsData = board.find(
    (item) => item?.elementid === elementid
  ).settings;
  const {
    label,
    inputType,
    instructions,
    minValue,
    maxValue,
    predefine,
    required,
    hidden
  } = ElementSettingsData;

  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };
  const Elemlabel = board.find((item) => item?.elementid === elementid).name;

  // useEffect(() => {
  //   if(predefine !== undefined){
  //     setValue(elementid, predefine)
    
  //   }
  // }, [predefine])
  // console.log("showHide", predefine)

  const checkingValidation = () => {
    if ((required === true) && (showHide === "show")) {
      return false
    } else if ((required === false) && (showHide === "show")) {
      return false
    } else if ((required === true) && (showHide === "hide")) {
      return false
    } else if ((required === false) && (showHide === "hide")) {
      return false
    } else if ((required === true) && (hidden === true)) {
      return false
    } else if (required === true) {
      return true
    } else {
      return false
    }

  }
  const normalizeFieldName = (name) => {
    return name.replace(/[.\s]/g, '_'); // Replace dots and spaces with underscores
  };
  return (
    <div
      className={previewFlag && GetStyleClasses(elementid) || ""}
      id={elementid}
      style={initStyle}
      hidden={hidden && previewFlag}
    >
      <div className="form-floating w-100 form-group">

        {Object.keys(ElementSettingsData).length === 0 ? (
          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            rules={
              {
                maxLength: {
                  value: 50,
                  message: `Please Enter less then  30 maxValue Characters`,
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid Email Address",
                },


              }
            }
            render={({ field }) => (
              <input
                type="email"
                className="form-control input-bn"
                name="email"
                placeholder="Enter Email"
                disabled={disableElementForBuild}
                {...field}

              />
            )}
          />
        ) : (
          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            rules={
              {
                required: {
                  value: checkingValidation(),
                  message: "This field is required",
                },
                minLength: {
                  value: minValue === "" ? 0 : minValue,
                  message: `Please Enter More then ${minValue === "" ? 0 : minValue
                    } Characters`,
                },
                maxLength: {
                  value: maxValue == "" ? 50 : maxValue,
                  message: `Please Enter less then ${maxValue == "" ? 50 : maxValue} Characters`,
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid Email Address",
                },


              }
            }
            render={({ field }) => (
              <input
                type={inputType ? inputType : "email"}
                className="form-control input-bn"
                name="email"
                // defaultValue={predefine === undefined ? "" : predefine}
                placeholder="Enter Email"
                disabled={disableElementForBuild}
                {...field}

              />
            )}
          />
        )}

        <label>
          <span className="d-flex align-items-center">
            <i className="iconify me-1 mr-1 fs-5" data-icon="carbon:email" />
            <span>

              {label == undefined || label == "" ? Elemlabel : label}





              {required ? <span style={{ color: "red" }}>*</span> : ""}
            </span>
          </span>
        </label>
      </div>
      <small className="text-secondary">
        {instructions ? instructions : ""}
      </small>

      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>


    </div>
  );
}
