import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import WorkflowHeader from "../../../components/WorkflowHeader";
import { gql, useMutation } from '@apollo/client';
import { Loaderr } from '../../../components/Loaderr';


const CREATEWORKFLOW = gql`
  # Increments a back-end counter and gets its resulting value
  mutation createfowkflow($workflowName: String!, $workflowDescription: String!){
    CreateWorkflow(workflow_name: $workflowName, workflow_description: $workflowDescription) {
      workflow_id
    }
  }
`;

function CreateWorkflow() { 
  const [newWorkflow, setNewWorkflow] = useState({
    name: "",
    description: "",
  });
  const navigate = useNavigate();
  var [creatwworkflow, { loading }] = useMutation(CREATEWORKFLOW,
    {onCompleted: (data) => {
      const workflow_id = data.CreateWorkflow.workflow_id
      navigate(`/automation-builder/edit-workflow/${workflow_id}`)
    }}
    );
  if (loading) return <Loaderr />
 
  const inputHandler = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setNewWorkflow({ ...newWorkflow, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createWorkflowHandler({name: newWorkflow.name, description: newWorkflow.description})
  };

  const createWorkflowHandler = (payload) => {
    creatwworkflow({
      variables: {
        workflowName: payload.name,
        workflowDescription: payload.description
      }
    })
  };


  return (
    <>
      <WorkflowHeader />
      <div className="main container mx-auto" id="body-pd">
        <div
          className="d-lg-none alert alert-warning banner-top p-3 py-2 rounded-0 m-0"
          role="alert"
        >
          <div className="d-flex flex-wrap align-items-center">
            <div className="d-flex align-items-center my-2">
              <i className="bx bx-mobile fs-5" />
              <i className="bx bx-arrow-to-right me-2 fs-5" />
              <i className="bx bx-desktop me-2 fs-5" />
            </div>
            <span className="my-2">
              Please switch to desktop and optimize this view. Smaller screens
              are not efficient. To learn more click here
            </span>
          </div>
        </div>
        <section className="col-xl-7 mx-auto">
          <div className="bg-white p-4 rounded-3 border">
            <h5>New Workflow</h5>
            <p className="text-secondary mt-2">
              Add a title and description for your workflow
            </p>
            <form className="mt-lg-5 mt-4" action="" onSubmit={handleSubmit}>
              <div className="mb-4">
                <div className="form-floating w-100 form-group">
                  <input
                    placeholder="Workspace title "
                    className="form-control input-bn"
                    type="text"
                    required
                    maxLength={30}
                    name="name"
                    value={newWorkflow.name}
                    onChange={inputHandler}
                  />
                  <label>
                    <span className="d-flex align-items-center">
                      <i
                        className="iconify me-1 mr-1 fs-5"
                        data-icon="ri:flow-chart"
                      />
                      <span>Workflow Title</span>
                    </span>
                  </label>
                </div>
                {/* placeholder for errors */}
              </div>
              <div className="mb-4">
                <div className="form-floating w-100 form-group">
                  <textarea
                    placeholder="Workflow Description "
                    className="form-control input-bn"
                    type="text"
                    // required
                    maxLength={100}
                    name="description"
                    value={newWorkflow.description}
                    onChange={inputHandler}
                  />
                  <label>
                    <span className="d-flex align-items-center">
                      <i
                        className="iconify me-1 mr-1 fs-5"
                        data-icon="mdi:text"
                      />
                      <span>Workflow Description</span>
                    </span>
                  </label>
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="d-flex align-items-center btn btn-primary"
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
}

export default CreateWorkflow;
