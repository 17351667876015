import React, { useState } from "react";
import Action from "./Action";
import SelectAutomation from "./SelectAutomation";

export default function Actions({
  workflow_helper,
  triggerdetails,
  setTriggerDetails,
  setActionsCount
}) {
  let actions = [];
  const [selected_action, setSelected_action] = useState(null);
  let last_action_id = null;
  // useEffect(() => {
  //   setActionsCount(workflow_helper.current.get_actions_by_order().length)

  // }, [workflow_helper.current.get_actions_by_order().length])
  workflow_helper.current.get_actions_by_order().map((action, index) =>
    {
      actions.push(
      <Action
        action_index={index + 1}
        key={action.action_id}
        triggerdetails={triggerdetails}
        setTriggerDetails={setTriggerDetails}
        action_id={action.action_id}
        // Add this to context later
        workflowHelper={workflow_helper.current}
        action_info={action}
        selected_action={selected_action}
        setSelected_action={setSelected_action}
      />
    );
    last_action_id = action.action_id;
    // setActionsCount(index + 1);
    return actions
  }
  );
  actions.push(
    <SelectAutomation
      key={"select_automation"}
      selected_action={selected_action}
      setSelected_action={setSelected_action}
      prev_action_id={last_action_id}
      
    />
  );
  return actions;
}
