export const Elements = [
  {
    id: "Headings",
    type: "UIBuilder",
  
  },
  {
    id: "FreeText",
    type: "UIBuilder",
  },
  {
    id: "Email",
    type: "UIBuilder",
  
  },
  {
    id: "Contact",
    type: "UIBuilder",

  },
  {
    id: "InputField",
    type: "UIBuilder",
  },
  {
    id: "NumberField",
    type: "UIBuilder",
  },
  {
    id: "TextArea",
    type: "UIBuilder",
  },
  {
    id: "DropDown",
    type: "UIBuilder",
  },
  {
    id: "RadioButton",
    type: "UIBuilder",
  },
  {
    id: "CheckBox",
    type: "UIBuilder",
  },
  {
    id: "Date",
    type: "UIBuilder",
  },
  {
    id: "Time",
    type: "UIBuilder",
  },
  {
    id: "Ratings",
    type: "UIBuilder",
  },
];
